<!-- timeline block -->
<div class=" m-0">
  <div class="w-100" style="margin-left: -15px;" *ngIf="!isDownload">
    <span class="f-12 _700">{{ fullView | translate }} <img (click)="viewFullTimeline()" class="eye-icon"
        [src]="isFullView ? '.../../../../../assets/img/project-v2/eye-open-icon.svg' : '.../../../../../assets/img/project-v2/eye-close-icon.svg'" /></span>
  </div>
  <div class="card" [ngClass]="{'download-comp-width mb-5':isDownload}">
    <div class="row mr-3 fullview-section"
      [ngClass]="{'blur-timeline':!isFullView,'mt-3': !isDownload}">

      <!-- Milstone popup -->
      <div *ngIf="!isDownload && currentMilestone" class="milestone-popup" [style.margin-left]="milestonePopupMargin+'px'" [style.border]="'3px solid ' + currentMilestone.blockColor">
        <div class="w-100 text-center">
          <i class="fa fa-caret-right fa-lg top-arrow-icon" [style.color]="currentMilestone.blockColor"></i>
        </div>
        <div class="row py-1 ml-0 mr-0" style="top: -2px; position: relative;" [style.background]="currentMilestone.blockColor">
          <div class="col-12 p-0">
            <span class="timeline-mad-div-text ">{{currentMilestone.mad}}</span>
          </div>
          <div class="col-12 p-0">
            <span class="timeline-mad-div-text">{{ currentMilestone.driveDate | date: 'dd.MM.YYYY'}}</span>
          </div>
        </div>
        <div class="text-center" [style.background]="'#FFFFFF'">
          <span *ngIf="currentMilestone.blockName" class="timeline-name-div-text justify-content-center p-1 text-center _700">{{ currentMilestone.blockName }}</span>
          <span class="timeline-name-div-text text-center justify-content-center p-1 ">{{ currentMilestone.name }}</span>
        </div>
      </div>

      <!-- Milestone Blocks -->
      <div *ngFor="let milestone of timeLineMilestoneData" class="col p-0 fullview-milestone-block" (mouseenter)="showMilestonePopup(milestone)" (mouseleave)="hideMilestonePopup(milestone)">
        <div class="full-timeline-mad" [style.height]="'9.5px'" [style.background]="milestone.blockColor">
          <span class="full-timeline-mad-text">{{milestone.mad}}</span>
        </div>
        <div class="full-timeline-name" [style.height]="'22px'">
          <span class="full-timeline-name-text">{{milestone.name}}</span>
        </div>
      </div>

    </div>
  </div>
</div>
