import { NotificationService } from 'app/shared/services/notification.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormBuilder, AbstractControl, ValidationErrors } from "@angular/forms";
import { ProjectService } from "app/shared/services/project.service";
import * as moment from 'moment';
import 'moment-timezone';
import { NgbActiveModal, NgbDateParserFormatter, NgbDateStruct, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from 'app/shared/services/helper.service';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { ValidatorService } from 'app/shared/services/validator.service';
import { CustomDateParserFormatter } from "app/shared/services/datepicker-adapter";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectSection, ProjectStatusList, ProjectSubsection, SectionUpdateErrorCodes } from 'app/shared/interfaces';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { OverlayService } from 'app/shared/services/overlayService';

@Component({
	selector: 'mtm-edit-section',
	templateUrl: './edit-section.component.html',
	styleUrls: ['./edit-section.component.scss'],
	providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class EditSectionComponent implements OnInit, OnDestroy {

	@ViewChild('popover', { static: true }) popover: NgbPopover;
	submitted: boolean = false;
	sending: boolean = false;
	public section: any;
	public subSection: any;
	minDate;
	ngUnsubscribe = new Subject();
	maxDate;
	targetStatusList: string[] = [];
	ProjectStatus = ProjectStatusList;
	editSubSectionForm = this.fb.group({
		subSection: ['', [Validators.required]],
		startDate: ['', {
			validators: [Validators.required]
		}],
		endDate: ['', {
			validators: [Validators.required]
		}],
		status: [ProjectStatusList.TO_COME, {
			validators: [Validators.required]
		}],
		description: ['', []],
		mediaType: ['', []]
	}, {
		validators: [this.validateDateRange()]
	});
	isDeletable: boolean = false;

	constructor(private fb: FormBuilder, 
		public service: ProjectService, 
		public activeModal: NgbActiveModal,
		private notificationService: NotificationService, 
		private translatePipe: TranslatePipe,
		private overlayService: OverlayService
	) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.loadFormGroup();
		this.isDeletable = this.isDeletableSubsection() && HelperService.isUUID(this.subSection.id);

		this.minDate = DateHelperService.fromDateToDatepicker(this.service.project.startDate);
		this.maxDate = DateHelperService.fromDateToDatepicker(this.service.project.plannedEndDate);
	}

	get statusValue(): string {
		return this.editSubSectionForm.get('status').value;
	}


	private isDeletableSubsection() {
		if (this.subSection.section === 'SHOOTING') {
			return false;
		}

		const existingSubsection = this.service.subsections.find(ss => ss.subSection === this.subSection.subSection);
		if (!existingSubsection) return true;

		if (!existingSubsection.dynamic) {
			return false;
		}

		return true;
	}

	/**
	 * Load form
	 */
	private loadFormGroup() {
		// If subsection was created previously (so, .dates doest exist)
		// then check if startDate and endDate are set - it means that need to show date picker
		if (this.subSection.dates === undefined) {
			if (this.subSection.startDate && this.subSection.endDate) {
				this.subSection.dates = true;
			}
		}

		// Build FormGroup
		this.editSubSectionForm.patchValue({
			subSection: this.subSection.subSection,
			startDate: DateHelperService.fromDateToDatepicker(this.subSection.startDate),
			endDate: DateHelperService.fromDateToDatepicker(this.subSection.endDate),
			mediaType: this.subSection.mediaType,
			description: this.subSection.description,
			status: this.subSection.status ?? ProjectStatusList.TO_COME
		});
		
		console.log('subSection', this.subSection, 'form', this.editSubSectionForm.value);
		this.targetStatusList.push(this.statusValue);
		switch (this.statusValue) {
			case ProjectStatusList.IN_PROGRESS:
			case ProjectStatusList.UPCOMING_EXPIRY:
			case ProjectStatusList.DELAYED:
			case ProjectStatusList.BEWARE:
				this.targetStatusList.push(ProjectStatusList.COMPLETED);
				break;
			case ProjectStatusList.TO_COME:
			case ProjectStatusList.COMPLETED:
				this.targetStatusList.push(ProjectStatusList.IN_PROGRESS);
				break;
		}
	}

	changeStatus(newStatus: string) {
		this.editSubSectionForm.patchValue({
			status: newStatus
		});
	}

	/**
	 * Upload data on submit
	 */
	onSubmit(): void {
		this.submitted = true;

		if (this.editSubSectionForm.valid && !this.isValidDate()) {
			this.sending = true;
			this.save(this.editSubSectionForm.value);
		}
	}

	/**
	 * Check if form field is valid
	 *
	 * @param field
	 */
	isValid(field: string): boolean {
		return (this.editSubSectionForm.get(field).invalid && this.submitted);
	}

	isValidDate() {
		if (!this.submitted)
			return this.submitted;

		let startDate = this.editSubSectionForm.value.startDate;
		let endDate = this.editSubSectionForm.value.endDate;
		return !startDate || !endDate || DateHelperService.fromDatePickerToDate(startDate) > DateHelperService.fromDatePickerToDate(endDate);
	}

	/**
	 * Save Sub-section
	 *
	 * @param data
	 */
	private save(data: any): void {

		let startDate: Date = null;
		let endDate: Date = null;

		// MTM-4253 - now allow user to enter subsection dates regardless of dates property
		//if (this.subSection.dates) {
		startDate = DateHelperService.fromDatePickerToDate(data.startDate);
		endDate = DateHelperService.fromDatePickerToDate(data.endDate);
		//}

		let subsection = {
			name: this.subSection.name,
			subSection: data.subSection,
			section: this.section.section,
			projectSectionId: this.section.id,
			phase: this.section.phase,
			description: data.description,
			orderNumber: this.subSection.orderNumber,
			mediaType: data.mediaType,
			status: data.status
		};

		subsection['startDate'] = DateHelperService.fromDateToApi(startDate);
		subsection['endDate'] = DateHelperService.fromDateToApi(endDate, DateHelperService.END_OF_DAY);

		// If this.subSection.id is UUID format, then it means we are editing a subsection
		// so need to add 'id' field in subsection object
		// If this.subSection.id is not UUID format, then it means we are creating/adding a new subsection
		// so no need to add 'id'
		const isEditingSubsection = HelperService.isUUID(this.subSection.id);
		if (isEditingSubsection) {
			subsection['id'] = this.subSection.id;
			subsection['status'] = this.subSection.status;
		}

		const isCreatingSection = !HelperService.isUUID(this.section.id);

		if (isCreatingSection) {
			this.section.startDate = DateHelperService.fromDateToApi(startDate); // .format();
			this.section.endDate = DateHelperService.fromDateToApi(endDate, DateHelperService.END_OF_DAY); // .format();
		}

		if (isCreatingSection) {
			this.saveSectionAndSubsection(subsection);
		} else {
			this.saveSubsection(subsection);
		}

	}

	/**
	 * Add or save subsection
	 * @param subsection
	 */
	private saveSectionAndSubsection(subsection: any) {

		this.service.createSection(this.section).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (result: any) => {
				this.section.id = result.id;
				subsection.projectSectionId = result.id;
				this.saveSubsection(subsection);
			}, error: (err: any) => {
				this.sending = false;
				this.handleStatusUpdateError(err);
			}
		});
	}

	private saveSubsection(subsection: any) {
		this.service.createSubsection(this.section.id, subsection).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (res) => {
				//this.service.prepareProjectInit(this.service.project.id);
				this.sending = false;
				this.activeModal.close(res);
			},
			error: (err: any) => {
				this.handleStatusUpdateError(err);
				this.sending = false;
			}
		})
	}

	private handleStatusUpdateError(error: any) {
		if (!error) {
			return;
		}
		if ('errorCode' in error) {
			let message = '';
			switch (error.errorCode) {
				case SectionUpdateErrorCodes.START_DATE_AFTER_END_DATE:
					message = 'sectionUpdate_START_DATE_AFTER_END_DATE';
					break;
				case SectionUpdateErrorCodes.WRONG_START_DATE:
					message = 'sectionUpdate_WRONG_START_DATE';
					break;
				case SectionUpdateErrorCodes.WRONG_END_DATE:
					message = 'sectionUpdate_WRONG_END_DATE';
					break;
			}
			this.notificationService.open({
				title: 'Error',
				description: this.translatePipe.transform(message),
				confirmBtn: this.translatePipe.transform('accept')
			});
		}
	}


	/**
	 * Delete sub-section
	 */
	delete(): void {
		this.notificationService.open({
			title: this.translatePipe.transform('project_stages_deleteSubsection'),
			description: this.translatePipe.transform('project_stages_confirmDeleteSubsection', { name: this.subSection.name }),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: result => {
				if (!result) {
					return;
				}
				this.sending = true;
				this.service.deleteSubsection(this.section.id, this.subSection.id).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe({
					next: result => {
						this.activeModal.close(true);
						this.sending = false;
					},
					error: (err: any) => {
						this.sending = false;
						this.overlayService.showError(this.translatePipe.transform('project_stages_deleteSubsectionFailed'));
					}
				});
			},
		});
	}

	/**
	 * Check if this subsection has ID
	 */
	exists(): boolean {
		return HelperService.isUUID(this.subSection.id);
	}

	cancel() {
		this.activeModal.dismiss();
	}

	validateDateRange() {
		return (control: AbstractControl): ValidationErrors | null => {
			const startControl = control.get('startDate');
			const startValue = startControl.value;
			const endValue = control.get('endDate').value;

			const startDate = DateHelperService.fromDatePickerToDate(startValue);
			const endDate = DateHelperService.fromDatePickerToDate(endValue);

			if (!startDate || !endDate) {
				return null;
			}
			if (startDate > endDate) {
				startControl.setErrors({ dateRange: true });
				return { dateRange: true };
			} else {
				const anyError = startControl.getError('dateRange');
				if (anyError) {
					delete startControl.errors['firstError'];
					startControl.updateValueAndValidity();
				}
			}
			return null;
		}
	}
}
