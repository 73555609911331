export enum ProjectStatus {
	ToDo = 'TO_COME',
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	BEWARE = 'BEWARE',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	Delayed = 'DELAYED',
	ARCHIVED = 'ARCHIVED'
}

export enum ProjectColor {
	TO_COME = 'purple',
	IN_PROGRESS = 'blue',
	COMPLETED = 'green',
	BEWARE = 'yellow',
	UPCOMING_EXPIRY = 'yellow',
	DELAYED = 'orange',
	ARCHIVED = 'gray',
	UNKNOWN = 'gray'
}

export enum DriveCampaignStatus {
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	Delayed = 'DELAYED'
}

export enum DriveCampaignColor {
	IN_PROGRESS = 'gray',
	COMPLETED = 'green',
	UPCOMING_EXPIRY = 'orange',
	DELAYED = 'orange'
}

export enum AssetListStatus {
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	NotAffected = 'NOT_AFFECTED',
	Delayed = 'DELAYED',
	Unknown = 'UNKNOWN',
	ToDo = 'TO_COME'
}

export enum AssetListColor {
	TO_COME = 'purple',
	IN_PROGRESS = 'black',
	COMPLETED = 'green',
	UPCOMING_EXPIRY = 'orange',
	NOT_AFFECTED = 'gray',
	DELAYED = 'orange',
	UNKNOWN = 'gray'
}