<div class="card">
    <div class="row d-flex justify-content-center align-items-center mt-3">
        <div class="group-logo">
            <img width="51px" height="27px" src="../../../../../../assets/svg/user-group.svg" alt="">
            <i [style.color]="'#FD5021'" class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-1">
        <span class="f-18 _700" [style.color]="'#18182D'">New Group</span>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="mt-3 horizontal-line"></div>
    </div>

    <!-- create team section  -->
    <div class="create-group-div mt-3 ml-4 mr-3">
        <div class="row ml-1">
            <div class="col ml-1 mt-1" style="max-width: fit-content; cursor: default;"
                (click)="$event.stopPropagation()">
                <i (click)="toggleEditName()" class="fa fa-pencil fa-lg pointer"
                    [style.color]="editMode ? '#2E2E53' : '#2E2E53'" aria-hidden="true"></i>
                <span (click)="toggleEditName()" *ngIf="!editMode" #visualizationNameElement class="ml-2 f-14 _700">{{groupName}}</span>
                <input *ngIf="editMode" #editInput type="text" [(ngModel)]="groupName" (blur)="toggleEditName()">
            </div>
        </div>
        <div class="row ml-1 mt-3">
            <div class="col">
                <div class="d-flex align-items-center" ngbDropdown>
                    <div class="mt-auto d-flex align-items-center w-100" ngbDropdownToggle>
                        <div class="w-100">
                            <input #addUserInput type="text" placeholder="Type name or email to add..." class="add-users" [(ngModel)]="searchInput"
                                (ngModelChange)="searchParticipants()" />
                        </div>
                    </div>
                    <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                        <div *ngFor="let participant of participants"
                            class="task-dropdown-item d-flex align-items-center pointer" (click)="selectUser(participant)"
                            ngbDropdownItem>
                            <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                                [ngClass]="{ 'selected': participant.selected }">
                                <i class="fa fa-check" *ngIf="participant.selected"></i>
                            </div>
                            <img class="participant-img" *ngIf="participant.avatarUrl" [src]="participant.avatarUrl" alt="">
                            <span *ngIf="!participant.avatarUrl"
                                class="d-flex justify-content-center align-items-center c-full-white participant-img text-uppercase"
                                [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                                {{participant.fullName | initialName}}
                            </span>
                            <span class="ml-2 _600">{{participant.fullName}}</span>
                        </div>
                    </div>
                </div>
                      <div class="d-flex justify-content-start align-items-center my-2 ">
                            <div *ngIf="selectedUser?.length" class="task-collaborators">
                                <div class="task-collaborator-wrapper d-flex align-items-center">
                                    <div class="collaborator-avatar mr-1" *ngFor="let owner of selectedUser">
                                        <img class="selected-participant-img" width="35px" height="35px" *ngIf="owner.avatarUrl" [src]="owner.avatarUrl" alt="">
                                        <span *ngIf="!owner.avatarUrl"
                                            class="d-flex justify-content-center align-items-center c-full-white selected-participant-img text-uppercase"
                                            [style.backgroundColor]="timelineService.getColorFromName(owner.fullName)">
                                            {{owner.fullName | initialName}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col d-flex justify-content-center align-items-center">
            <select class="role-select"name="teamSelect" id="teamSelect" (change)="onTeamSelect($event)">
                <option value="null">Select Team</option>
                <option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</option>
            </select>
        </div>
    </div>

    <!-- button section -->
    <div class="row d-flex justify-content-center align-items-center my-3">
        <div class="col d-flex justify-content-end align-items-center">
            <div class="d-flex">
                <button (click)="cancel()" class="f-12 _700 cancel-btn text-uppercase">cancel</button>
            </div>
        </div>
        <div class="col d-flex">
            <div class="d-flex">
                <button class="f-12 _700 create-team-btn text-uppercase"> CREATE TEAM</button>
            </div>
        </div>
    </div>
</div>