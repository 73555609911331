import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UseronboardingtoolRoutingModule } from './useronboardingtool-routing.module';

import { UsertourprogressComponent } from './usertourprogress/usertourprogress.component';
import { UsertourComponent } from './usertour/usertour.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsertourdashboardComponent } from './usertourdashboard/usertourdashboard.component';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { MtmChatModule } from 'app/sub-modules/mtm-chat-module/mtm-chat.module';

@NgModule({
  declarations: [
    UsertourComponent,
    UsertourdashboardComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    UseronboardingtoolRoutingModule,
    NgbDropdownModule,
    SharedModule,
    MtmChatModule
  ]
})
export class UseronboardingtoolModule { }
