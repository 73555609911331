import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AssetListService {

    constructor(private apiService: ApiService) { }

    getAssetById(assetListId: string) {
        return this.apiService.httpGet(`/api/loreal-asset-list/${assetListId}/project`);
    }

    getAffectedAssetByCampaignId(campaignId: string) {
        return this.apiService.httpGet(`/api/loreal-asset-list/project/by-campaign/${campaignId}`);
    }

    updateAssetListStatusOrDelivery(data) {
        return this.apiService.httpPost(`/api/loreal-asset-list/project`, data);
    }

    getAssetListSummary(campaignId: string, milestoneId: string,) {
        return this.apiService.httpGet(`/api/loreal-asset-list/summary-by-milestone/${campaignId}/${milestoneId}`);
    }

}
