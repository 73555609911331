import Dexie from "dexie";

export const TransferDbVersion = 1;
export const TransferDbName = 'transfers';

export const TransferDbSchema = {
  uploads: `id, name, owner, startDate`,
  uploadChunks: `++id, [uploadId+part]`,
  downloads: `id, name, owner, startDate`,
  downloadChunks: `++id, [downloadId+part]`
}

export interface TransferRecord {
  id: string;
  name: string;
  owner: string;
  contentType: string;
  status: string;
  startDate: number;
  percentage: number;
}

export interface ChunkRecord {
  id?: number;
  part: number;
  content: Uint8Array;
}

export interface UploadRecord extends TransferRecord {
  startIndex: number;
  totalSize: number;
  preUploadUrl?: string;
  postUploadUrl?: string;
  step: string;
  pipelines: string;
  sessionUrl: string;
  additionalData: string; //string from MTMCustomFile's dbFileObject
  entity?: string; //represents entity info
  postUploadPayload?: string;
  parentUrl: string;
  extraInfo: string; //JSON containing extra info for UI purpose
}

export interface UploadChunkRecord extends ChunkRecord {
  uploadId: string;
}

export interface DownloadRecord extends TransferRecord{
  url: string;
  size: number;
  displayName: string;
}

export interface DownloadChunkRecord extends ChunkRecord {
  downloadId: string;
}

export class TransferDb extends Dexie {
  uploads: Dexie.Table<UploadRecord, string>;
  uploadChunks: Dexie.Table<UploadChunkRecord, number>;
  downloads: Dexie.Table<DownloadRecord, string>;
  downloadChunks: Dexie.Table<DownloadChunkRecord, number>;

  constructor() {
    super(TransferDbName);
    this.version(TransferDbVersion).stores(TransferDbSchema);
    this.uploads = this.table('uploads');
    this.uploadChunks = this.table('uploadChunks');
    this.downloads =  this.table('downloads');
    this.downloadChunks = this.table('downloadChunks');
  }
}
