
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';

import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, Response, ResponseContentType } from "@angular/http";
import { environment } from "../../../environments/environment";
import { HelperService } from "./helper.service";

import { Router } from "@angular/router";
import { OverlayService } from "./overlayService";
import { HttpClient, HttpContext } from "@angular/common/http";
import { IS_UNCANCELABLE_REQUEST } from "../models";
import { HelpHeroService } from './help-hero.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningLoggedoutModalComponent } from '../components/warning-loggedout-modal/warning-loggedout-modal.component';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseUrl: string;
	LOGOUT_SESSION_EXPIRY_KEY = 'loggedOutDueToSessionExpiryMessage';

	constructor(
		private http: Http,
		private httpClient: HttpClient,
		private helpHeroService: HelpHeroService,
		private overlayService: OverlayService,
		private router: Router,
		private modalService: NgbModal,
		private translatePipe: TranslatePipe
	) {
		this.baseUrl = environment.api.baseUrl;

	}

	//task http headers and observable 
	taskhttpGet<T>(res: string, mockapi: boolean = false, customHeaders: any = {}, options: any = {}): Observable<T> {
		let headers = HelperService.getHttpHeaders(mockapi, customHeaders);
		let baseUrl = (mockapi) ? environment.api.mockapiUrl : this.baseUrl;
		const getOptions: any = { headers, withCredentials: true };

		if (options.blobResponse) {
			getOptions.responseType = ResponseContentType.Blob;
		}

		// return  this.http.get(baseUrl + res, getOptions).pipe(
		// 	catchError(error => {
		// 	  this.healthCheck();
		// 	  return this.handleError(error);
		// 	})
		//   );
		return this.http.get(baseUrl + res, getOptions).pipe(
			map((data: Response) => {
				if (options.blobResponse) {
					return data.blob();
				} else {
					return data.text() ? data.json() : {};
				}
			}),
			catchError(error => {
				this.healthCheck()
				return this.handleError(error)
			}),);
	}

	/**
	 * HTTP Get
	 * @param res
	 * @returns {Observable<R>}
	 */
	httpGet(res, mockapi: boolean = false, customHeaders: any = {}, options: any = {}): Observable<Response> {
		let headers = HelperService.getHttpHeaders(mockapi, customHeaders);
		let baseUrl = (mockapi) ? environment.api.mockapiUrl : this.baseUrl;
		const getOptions: any = { headers, withCredentials: true };

		if (options.blobResponse) {
			getOptions.responseType = ResponseContentType.Blob;
		}

		return this.http.get(baseUrl + res, getOptions).pipe(
			map((data: Response) => {
				if (options.blobResponse) {
					return data.blob();
				} else {
					return data.text() ? data.json() : {};
				}
			}),
			catchError(error => {
				this.healthCheck()
				return this.handleError(error)
			}),);
	}


	/**
	 * HTTP Post
	 * @param res
	 * @param dataPost
	 * @returns {Observable<R>}
	 */
	httpPost(res, dataPost, uploadFile = false, cancellable: boolean = true): Observable<Response> {
		let headers = HelperService.getHttpHeaders(uploadFile);
		let json = typeof dataPost !== "string" ? JSON.stringify(dataPost) : dataPost;

		return this.http.post(this.baseUrl + res, json, { headers: headers, withCredentials: true }).pipe(
			map(response => response.json()),
			catchError(error => {
				this.healthCheck();
				return this.handleError(error)
			}));
	}

	httpPostWithoutBody(res, uploadFile = false, cancellable: boolean = true): Observable<Response> {
		let headers = HelperService.getHttpHeaders(uploadFile);

		return this.http.post(this.baseUrl + res, null, { headers: headers, withCredentials: true }).pipe(
			map(response => response.json()),
			catchError(error => {
				this.healthCheck();
				return this.handleError(error);
			})
		);
	}

	downloadPPThttpPost(res, dataPost, uploadFile = false, images?): Observable<Response> {
		let headers = HelperService.getHttpHeaders(uploadFile);
		headers['images'] = images;
		let json = typeof dataPost !== "string" ? JSON.stringify(dataPost) : dataPost;

		return this.http.post(this.baseUrl + res, json, { headers: headers, withCredentials: true }).pipe(
			map(response => response.json()),
			catchError(error => {
				this.healthCheck();
				return this.handleError(error)
			}),);
	}

	newPost(endpoint: string, payload: any, options: any): Observable<any> {
		let json = typeof payload !== "string" ? JSON.stringify(payload) : payload;
		const headers: Record<string, string> = {};
		HelperService.getHttpHeaders(options?.uploadFile || false).forEach((values, name) => {
			if (values.length && name) {
				headers[name] = values[0];
			}
		});

		if (options.headers) {
			Object.assign(headers, options.headers);
		}

		const postOptions: any = { headers, withCredentials: true };

		if (options.uncancelable) {
			postOptions.context = new HttpContext().set(IS_UNCANCELABLE_REQUEST, true);
		}

		if (options.responseType) {
			postOptions.responseType = options.responseType;
		}

		return this.httpClient.post(this.baseUrl + endpoint, json, postOptions).pipe(
			//map(response => response.json()),
			catchError(error => {
				this.healthCheck();
				return this.handleError(error)
			}));
	}

	/**
	 * HTTP Put
	 * @param res
	 * @param dataPost
	 * @param localApi
	 * @returns {Observable<R>}
	 */
	httpPut(res, dataPost, mockapi: boolean = false): Observable<Response> {
		let headers = HelperService.getHttpHeaders(mockapi);
		let json = typeof dataPost !== "string" ? JSON.stringify(dataPost) : dataPost;

		let baseUrl = (mockapi) ? environment.api.mockapiUrl : this.baseUrl;

		return this.http.put(baseUrl + res, json, { headers: headers, withCredentials: true }).pipe(
			map(response => response.json()),
			catchError(error => {
				this.healthCheck()
				return this.handleError(error)
			}),);
	}

	newPut(endpoint: string, payload: any, options: any): Observable<any> {
		let json = typeof payload !== "string" ? JSON.stringify(payload) : payload;
		const headers: Record<string, string> = {};
		HelperService.getHttpHeaders(options?.uploadFile || false).forEach((values, name) => {
			if (values.length && name) {
				headers[name] = values[0];
			}
		});

		const putOptions: any = { headers, withCredentials: true };
		if (options.uncancelable) {
			putOptions.context = new HttpContext().set(IS_UNCANCELABLE_REQUEST, true);
		}
		return this.httpClient.put(this.baseUrl + endpoint, json, putOptions).pipe(
			catchError(error => {
				this.healthCheck();
				return this.handleError(error)
			}));
	}

	/**
	 * HTTP Delete
	 * @param res
	 * @param attempts
	 * @returns {Observable<R>}
	 */
	httpDelete(res, mockapi: boolean = false, body: any = null): Observable<Response> {
		let headers = HelperService.getHttpHeaders(mockapi);
		let baseUrl = (mockapi) ? environment.api.mockapiUrl : this.baseUrl;
		const options: any = { headers: headers, withCredentials: true };

		if (body != null) {
			options.body = body;
		}

		return this.http.delete(baseUrl + res, options).pipe(
			map(response => response.json()),
			catchError(error => {
				this.healthCheck()
				return this.handleError(error)
			}),);
	}

	/**
	 * Check if the platform is up and redirect to maintenance page if not
	 */
	public healthCheck() {
		if (localStorage.getItem("health_check") != "checking") {
			localStorage.setItem("health_check", "checking")
			this.http.get(environment.healthCheckURL)
				.subscribe(
					() => {
						localStorage.removeItem("health_check")
					},
					(err) => {
						console.error(err, 'Health check failed')
						localStorage.removeItem("token")
						localStorage.removeItem("health_check")
						this.router.navigate(['entry', 'maintenance'], { skipLocationChange: true })
					}
				);
		}
	}

	/**
	 * Handle error
	 * @param error
	 * @returns {any}
	 */
	private handleError(error: any) {
		if (error.status === 401) {
			if (error._body) {
				const errorCode = (error.json()).errorCode;
				if (errorCode === 'NOT_PROJECT_MEMBER') {
					this.overlayService.showError('You don\'t have access to view this page', 'Access Denied');
					return throwError(error.json ? error.json() : error);
				} else if (errorCode == 'ADMIN_ACCESS_ONLY_ACCESS_DENIED') {
					return throwError(error.json ? error.json() : error);
				} else {
					return this.http.post(this.baseUrl + '/api/auth/logout', null, { withCredentials: true })
						.pipe(
							map(response => response.json()),
							finalize(() => {
								const lang = localStorage.getItem('auth_user_language');
								localStorage.clear();
								sessionStorage.clear();
								HelperService.deleteAuthCookie();
								if (lang) {
									localStorage.setItem('auth_user_language', lang)
								}
								this.helpHeroService.resetUser();
								this.router.navigateByUrl('/entry').then(() => {
									let modalRef = this.modalService.open(WarningLoggedoutModalComponent);
									modalRef.componentInstance.logoutMessageKey = this.LOGOUT_SESSION_EXPIRY_KEY; // Translations key for session expiry logout message
								});
							})
						)
				}
			} else {
				return throwError(error);
			}
		}
		else if (error.status === 403) {
			// Add specific handling for 403 Forbidden errors
			// Only showing toast when not in production
			if (!environment.isProductionSide) this.overlayService.showError(this.translatePipe.transform('accessForbiddenMessage'), this.translatePipe.transform('accessForbiddenTitle'));
			return throwError(() => error);
		}
		else {
			if (!environment.isProductionSide) {
				//TODO: find a way to show this only if the error is not handled
				//this.overlayService.showError(this.translatePipe.transform('error'));
			}
			return throwError(() => error.json ? error.json() : error);
		}
	}

	/**
	 * Retrieve item's files
	 *
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param itemId
	 */
	public getItemFiles(projectId: string, sectionId: string, subsectionId: string, itemId: string): Observable<any> {
		return this.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/files`);
	}

	/**
	 * Delete item file
	 *
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param itemId
	 * @param fileId
	 */
	public deleteItemFile(projectId: string, sectionId: string, subsectionId: string, itemId: string, fileId: string): Observable<any> {
		return this.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/files/${fileId}`);
	}

	/**
	 * Sort files list
	 *
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param itemId
	 * @param fileList
	 */
	public updateFilesOrder(projectId: string, sectionId: string, subsectionId: string, itemId: string, fileList: any[]): Observable<any> {
		return this.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/files`, fileList);
	}


	public setProfileAndImagesProbForItems(url): Observable<any> {
		return new Observable(observe => {
			this.httpGet(url).subscribe(
				(data) => {
					if (!data || !Array.isArray(data)) {
						observe.next(data);
						observe.complete();
					}

					this.setProfileAndImagesProb(data);

					observe.next(data);
					observe.complete();
				}, err => observe.error(err));
		});
	}

	private setProfileAndImagesProb(items) {
		items.map((s: any) => {
			let elementFiles = s.files;
			s.items = elementFiles;

			if (s.urls)
				s.items = s.items.concat(s.urls);



			s.updatePreviewImage = () => {
			}

			// setProfileAndImagesProb(s, elementFiles);
		});
	}

	/**
	 *
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param itemId
	 * @param fileId
	 * @param displayName
	 * @returns
	 */
	public updateDisplayName(projectId: string, sectionId: string, subsectionId: string, itemId: string, fileId: string, displayName: any): Observable<any> {
		return this.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/files/${fileId}/updateDisplayName?displayName=${displayName}`, displayName);
	}
}
export class UrlSanitizer {
	static sanitizeUrl(url: string): string {
		// Define the regular expression pattern to match characters not allowed in URLs
		const pattern = /[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/g;

		// Use replace method to remove invalid characters from the URL
		return url.replace(pattern, '');
	}
}
