import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkspaceV2RoutingModule } from './workspace-v2-routing.module';
import { IndexComponent } from './index/index.component';
import { WorkspaceDashboardComponent } from './workspace-dashboard/workspace-dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignDriveComponent } from './campaign-drive/campaign-drive.component';
import { TimelineGeneratorModule } from './timeline-generator/timeline-generator.module';
import { AuthService } from 'app/shared/services/auth.service';
import { WorkspaceCreationGuard } from 'app/workspace/guards/workspace-creation.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { AnalyticsModule } from './analytics/analytics.module';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DriveCampaignNavComponent } from './drive-campaign-nav/drive-campaign-nav.component';
import { StandAloneProjectsComponent } from './stand-alone-projects/stand-alone-projects.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { ProjectsComponent } from './projects/projects.component';
import { SharedComponent } from './shared/shared.component';
import { MtmCampaignProjectsComponent } from "./campaign-drive-dashboard/overview/mtm-campaign-projects/mtm-campaign-projects.component"

@NgModule({
  declarations: [
    IndexComponent,
    WorkspaceDashboardComponent,
    CampaignDriveComponent,
    DriveCampaignNavComponent,
    StandAloneProjectsComponent,
    CreateCampaignComponent,
    ProjectsComponent,
    SharedComponent,
    MtmCampaignProjectsComponent
  ],
  imports: [
    CommonModule,
    WorkspaceV2RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TimelineGeneratorModule,
    NgSelectModule,
    SharedModule,
    NgbDropdownModule,
    NgbModule
  ],
  exports: [
    MtmCampaignProjectsComponent
  ],
  providers: [
		AuthService,
		WorkspaceCreationGuard,
	]
})
export class WorkspaceV2Module { }
