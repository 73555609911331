import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Http } from '@angular/http';
import { HelperService } from './helper.service';
import { environment } from "../../../environments/environment";
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { DummyDataService } from './dummy-data.service';
import { Router } from '@angular/router';
import { TimelineService } from './timeline.service';
import { CryptoService } from './crypto.service';
import { EmitterService } from './emitter.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectV2ServiceService implements OnDestroy {
  baseUrl: string;
  timelineId: string;
  timelineData: any;
  isFullView: boolean;
  driveCoordinatorTimeline: any = {
    timelines: null,
    project: null
  };
  driveCoordinatorMilestone: any;
  ngUnsubscribe = new Subject();

  private campaigndriveTimelineId: string;
  public timelineDataEmmit: EventEmitter<any> = new EventEmitter<any>();

  private formData = {
    general: {},
    criteria: {}
  };
  //TO_COME, IN_PROGRESS,

  colors = {
    'CAMPAIGN': '#000000',
    'PRODUCT': '#1E43A1',
    'MAGELLAN': '#1E43A1',
    'COLLECTOR / PRODUCT': '#086289',
    'GIFT': '#923065',
    'SET': '#502B9D'
  };

  statusColor = {
    TO_COME: '#FFFFFF',
    IN_PROGRESS: '#FFFFFF',
    UPCOMING_EXPIRY: '#FF9A3D',
    COMPLETED: '#55C982',
    DELAYED: '#F86868',
    SUPER_DELAYED: '#F86868'
  }

  milestoneTypes: any[] = [{
    value: 'all',
    label: 'All Campaign'
  }, {
    value: 'campaign',
    label: 'Campaign'
  }, {
    value: 'product',
    label: 'Product'
  }, {
    value: 'magellan',
    label: 'Magellan'
  }, {
    value: 'gift',
    label: 'Gift'
  }, {
    value: 'set',
    label: 'Set'
  }];

  private dropdownValue: Subject<any> = new Subject();

  public getDropdownValue(): Observable<any> {
    return this.dropdownValue.asObservable();
  }

  public setDropdownValue(value: any): void {
    this.dropdownValue.next(value);
  }
  private timlineSource = new BehaviorSubject({});
  currentTimelineData = this.timlineSource.asObservable();

  private formDataUpdated = new BehaviorSubject({});
  formDataUpdated$ = this.formDataUpdated.asObservable();

  private timlineFullView = new BehaviorSubject(true)
  currentVisibility = this.timlineFullView.asObservable();

  private driveSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private selectedCampaignDrive = new BehaviorSubject({});
  campaignDriveMilestoneData = this.selectedCampaignDrive.asObservable();

  //timeline opacity
  private isHiddenTimeline = new BehaviorSubject(true);
  hideTimeline = this.isHiddenTimeline.asObservable();

  //share workspace id
  public workspaceIdSubject: Subject<string> = new Subject<string>();
  public workspaceId: Observable<string> = this.workspaceIdSubject.asObservable();

  // hide recape header
  public recapHiddenHeader: Subject<boolean> = new Subject<boolean>();
  public isHeaderHidden: Observable<boolean> = this.recapHiddenHeader.asObservable();

  // update selected milestone color
  public selectedDCmilestone = new BehaviorSubject({ name: '', color: '', items: [], isDCtimeline: false });
  public milestoneColorUpdated = this.selectedDCmilestone.asObservable();

  // public selectedDCmilestone: Subject<any> = new Subject<any>({time,tit,false});
  // public milestoneColorUpdated: Observable<any> = this.selectedDCmilestone.asObservable();
  public timeline = new BehaviorSubject({});
  public updatedStatusTimeline = this.timeline.asObservable();

  // updated milestone
  public selectedMilestone = new BehaviorSubject({});
  public updateMilestone = this.selectedMilestone.asObservable();

  //set selected workspace name from general component for
  workspaceName: string;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private http: Http,
    private HelperService: HelperService,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private loarealDummyDataService: DummyDataService,
    private router: Router
  ) {
    this.baseUrl = environment.api.baseUrl;
    loarealDummyDataService.timelineEmitter.subscribe(data => {
      this.notificationTimelineData.data = data.project;
    });    // this.getDriveCoordinatorTimeline();
  }
  authUser: any;
  notificationTimelineData = {
    data: {

    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  updatedTimeline() {
    this.updatedStatusTimeline.subscribe(
      timeline => {
        this.handleTimelineData(timeline)
      }
    )
  }

  updatedMilestone(milestone: any) {
    this.selectedMilestone.next(milestone)
  }

  getDriveCoordinatorTimeline() {
    this.loarealDummyDataService.timelineEmitter.subscribe(data => {
      return data.project;
    });
  }

  sendData(data: string, madDate: any) {
    this.timlineSource.next({ data, madDate })
  }

  getCampaignDriveTimeline(data: any) {
    this.selectedCampaignDrive.next(data);
  }

  toggleVisibility(isVisible: boolean) {
    this.timlineFullView.next(isVisible);
  }

  //store form data
  updateFormData(generalFormData, criteriaFormData) {
    this.formData.general = generalFormData;
    this.formData.criteria = criteriaFormData;
    this.formDataUpdated.next(this.formData);
    EmitterService.get('DRIVE_CAMPAIGN:FORM_UPDATED').emit(this.formData);
  }

  //return form data
  getFormData() {
    return this.formData;
  }

  setOpacity(opacity: boolean) {
    this.isHiddenTimeline.next(opacity);
  }

  // destructue timeline data and add custom fields
  async handleTimelineData(timeline) {
    let driveCampaignId = timeline.id;
    if (timeline) {
      // if ( timeline.timelines.timelines) {
      //   if (typeof timeline.timelines.timelines == "string") {
      //     timeline = JSON.parse(timeline.timelines);
      //   } else {
      //     timeline = timeline.timelines.timelines
      //   }

      // } else {
      // }
      timeline = JSON.parse(timeline.timelines);
      await timeline.forEach((elem) => {
        // remove null mad items in subitems
        elem.items.forEach((milestone) => {
          if (milestone.items && milestone.items.length) {
            milestone.items = milestone.items.filter((subItem) => subItem.name && subItem.mad && subItem.driveDate);
          }
        });

        // remove null mad
        elem.items = elem.items.filter((milestone) => {
          if (milestone.name && milestone.items && milestone.items.length) {
            return true;
          } else {
            if (milestone.name && milestone.mad && milestone.driveDate) {
              return true;
            } else {
              return false;
            }
          }
        });

        // handle showblock
        if (elem.items.length > 0) {
          elem["showBlock"] = true;
          elem["showToggleIcons"] = true;
          elem["removeBlock"] = false;
        } else {
          elem["showBlock"] = false;
          elem["showToggleIcons"] = true;
          elem["removeBlock"] = true;
        }

        // set block color
        let color = '#FFFFFF';
        switch (elem.name) {
          case 'PRODUCT':
            color = this.colors.PRODUCT;
            elem.showToggleIcons = true;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              elem.items.forEach(element => {
                this.randomValues(element);
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                element.blockColor = this.colors.PRODUCT
                if (element.items && element.items.length) {
                  this.randomValues(element);
                  element.items.forEach(subitem => {
                    subitem.blockColor = this.colors.PRODUCT
                  });
                }
              });
            }
            break;
          case 'CAMPAIGN':
            color = this.colors.CAMPAIGN;
            elem.showToggleIcons = false;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              elem.items.forEach((element, i) => {
                this.randomValues(element);
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                element.blockColor = this.colors.CAMPAIGN
                if (element.items && element.items.length) {
                  element.items.forEach((subitem, j) => {
                    this.randomValues(element);
                    const opacity = (0.2 * (j + 1));
                    subitem.blockName = element.name;
                    subitem.blockColor = this.colors.CAMPAIGN;
                    // subitem.subItemBlockColor = `linear-gradient(0deg, rgba(255, 255, 255, ${opacity}), rgba(255, 255, 255, ${opacity})), ${this.colors.CAMPAIGN}`;
                    subitem.subItemBlockColor = '#EEF2F9';
                    subitem.subItemTextColor = '#000000';

                  });
                }
              });
            }
            break;
          case 'MAGELLAN':
            color = this.colors.MAGELLAN;
            elem.showToggleIcons = true;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              elem.items.forEach(element => {
                this.randomValues(element);
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                element.blockColor = this.colors.MAGELLAN
                if (element.items && element.items.length) {
                  element.items.forEach(subitem => {
                    this.randomValues(element);
                    subitem.blockColor = this.colors.MAGELLAN
                  });
                }
              });
            }
            break;
          case 'COLLECTOR / PRODUCT':
            color = this.colors['COLLECTOR / PRODUCT'];
            elem.showToggleIcons = true;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              this.randomValues(elem);
              elem.items.forEach(element => {
                this.randomValues(element);
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                element.blockColor = this.colors['COLLECTOR / PRODUCT']
                if (element.items && element.items.length) {
                  element.items.forEach(subitem => {
                    this.randomValues(element);
                    subitem.blockColor = this.colors['COLLECTOR / PRODUCT']
                  });
                }
              });
            }
            break;
          case 'GIFT':
            color = this.colors.GIFT;
            elem.showToggleIcons = true;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              this.randomValues(elem);
              elem.items.forEach(element => {
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                this.randomValues(element);
                element.blockColor = this.colors.GIFT
                if (element.items && element.items.length) {
                  element.items.forEach(subitem => {
                    this.randomValues(element);
                    subitem.blockColor = this.colors.GIFT
                  });
                }
              });
            }
            break;
          case 'SET':
            color = this.colors.SET;
            elem.showToggleIcons = true;
            elem.showProductionToggleIcons = false;
            if (elem.items) {
              this.randomValues(elem);
              elem.items.forEach((element, i) => {
                element['showMilestone'] = false;
                element['showTasks'] = false;
                element['showProjects'] = false;
                element['showProjectsBlock'] = false;
                element['showProjectStage'] = false;
                element['driveCampaignId'] = driveCampaignId;
                element['isNegociated'] = false;
                this.randomValues(element);
                element.blockColor = this.colors.SET
                if (element.items && element.items.length) {
                  element.items.forEach(subitem => {
                    this.randomValues(element);
                    const opacity = (0.2 * (i + 1));
                    subitem.blockName = element.name;
                    subitem.blockColor = this.colors.SET;
                    subitem.subItemBlockColor = `linear-gradient(0deg, rgba(255, 255, 255, ${opacity}), rgba(255, 255, 255, ${opacity})), ${this.colors.SET}`;
                    subitem.subItemTextColor = '#FFFFFF';
                  });
                }
              });
            }
            break;
          default:
            break;
        };

        // add color keys
        elem["blockColor"] = color;
        elem["milestoneFlagColor"] = '#FFFFFF';

        // pin block key
        elem["pinBlock"] = false;
      });
      // return new Promise<any>((resolve, reject) => {
      //   resolve(timeline);
      // });

      return timeline;

    }
  }

  // filter milestone array from timeline
  async filterMilestonesFromTimeline(timelines: any) {
    let milestones = [];
    if (timelines) {
      await timelines.forEach(timeline => {
        if (timeline.items) {
          timeline.items.forEach((elem, index) => {
            // add items
            if (elem.name && elem.mad && elem.driveDate) {
              milestones.push(elem);
            }
            if (elem.items) {
              elem.items.forEach(subItem => {
                // add sub items
                if (subItem.name && subItem.mad && subItem.driveDate) {
                  milestones.push(subItem);
                }
              });
            }
          });
        }
      });

      return milestones;
    }
  }

  randomValues(element) {
    // const milestoneData = [
    //   { total: 7, completed: 5 },
    //   { total: 5, completed: 1 },
    //   { total: 17, completed: 5 },
    //   { total: 16, completed: 8 },
    //   { total: 18, completed: 4 }

    // ];

    // // workSpaces.forEach((item) => {
    // // Add random driveStatus
    // const randomStatus = milestoneData[Math.floor(Math.random() * milestoneData.length)];
    // element.totalProject = randomStatus.total;
    // element.completedProject = randomStatus.completed;
    // element.totalTask = randomStatus.total;
    // element.completedTask = randomStatus.completed;
    // // });

    // return element;
  }

  async updateDriveCampaignOnly(workspaceId: string, driveCampaignId: string) {
    try {
      const drive = await this.getSingleDriveCampaign(workspaceId, driveCampaignId).pipe(takeUntil(this.ngUnsubscribe)).toPromise();
      const expectedMadDate = new Date(drive.expectedMad);
      const formattedExpectedMad = expectedMadDate.toISOString();

      // Extract relevant data from the drive object
      const { id, name, description, logo, projectLeaders, cncManager, productName, expectedMad, priority, criteria, timelines, banner, status, activeProduct } = drive;

      // Populate generalFormData
      const generalFormData = {
        id,
        name,
        description,
        logo, // You can update this if needed
        workspaceId,
        projectLeaders, // You can update this if needed
        cncManager,
        expectedMad: formattedExpectedMad,
        priority,
        criteria, // If criteria is a JSON string, no need to parse it,
        timelines,
        banner,
        status,
        productName,
        activeProduct
      };

      // Populate criteriaFormData (you can directly assign it, as it's already a valid object)
      const criteriaFormData = JSON.parse(criteria);

      // Call the updateFormData function
      this.updateFormData(generalFormData, criteriaFormData);

      this.timelineId = drive.id;
      EmitterService.get('DRIVE_CAMPAIGN:FORM_UPDATED').emit(drive);
    } catch (e) { }
  }

  async updateSelectedDriveCampaign(workspaceId: string, driveCampaignId: string, redirectToRecap: boolean = false) {
    try {
      const drive = await this.getSingleDriveCampaign(workspaceId, driveCampaignId).pipe(takeUntil(this.ngUnsubscribe)).toPromise();
      // Convert UNIX timestamp to formatted date-time string
      const expectedMadDate = new Date(drive.expectedMad);
      const formattedExpectedMad = expectedMadDate.toISOString(); // "2023-09-01T18:30:00.000Z"

      // Extract relevant data from the drive object
      const { id, name, description, logo, projectLeaders, cncManager, productName, expectedMad, priority, criteria, timelines, banner, status, activeProduct } = drive;

      // Populate generalFormData
      const generalFormData = {
        id,
        name,
        description,
        logo, // You can update this if needed
        workspaceId,
        projectLeaders, // You can update this if needed
        cncManager,
        expectedMad: formattedExpectedMad,
        priority,
        criteria, // If criteria is a JSON string, no need to parse it,
        timelines,
        banner,
        status,
        productName,
        activeProduct
      };

      // Populate criteriaFormData (you can directly assign it, as it's already a valid object)
      const criteriaFormData = JSON.parse(criteria);

      // Call the updateFormData function
      this.updateFormData(generalFormData, criteriaFormData);

      this.timelineId = drive.id;
      setTimeout(() => {
        if (redirectToRecap) {
          this.router.navigate([
            `workspaces/campaigndrive/${drive.workspaceId}/recap`], {
            queryParams: {
              wid: this.cryptoService.encryptString(drive.workspaceId),
              dc: this.cryptoService.encryptString(generalFormData.id),
            }
          });
        } else {
          this.router.navigate([`/workspaces/campaigndrive/${drive.workspaceId}/general`], {
            queryParams: {
              wid: this.cryptoService.encryptString(drive.workspaceId),
              dcId: this.cryptoService.encryptString(this.timelineId),
            }
          });
        }
        EmitterService.get('DRIVE_CAMPAIGN:FORM_UPDATED').emit(drive);
      }, 0);
    } catch (error) {
      console.error('Error fetching drive:', error);
    }
  }


  getDrive() {
    return this.driveSubject.asObservable();
  }

  setDrive(drive: any) {
    this.driveSubject.next(drive);
  }
  //get masterData
  public getMasterData(): Observable<any> {
    return this.apiService.httpGet('/api/loreal-timeline/master-data');
  }

  //get saved Timeline
  public getTimelines(): Observable<any> {
    return this.apiService.httpGet('/api/loreal-timeline/drive-campaigns');
  }

  //create Timeline
  public createTimeline(data): Observable<any> {
    return this.apiService.httpPost('/api/loreal-timeline/drive-campaigns', data);
  }

  //update Timeline
  public updateTimeline(data): Observable<any> {
    return this.apiService.httpPut('/api/loreal-timeline/find', data);
  }

  //get saved driveCampaigns using workspaceId
  public getDriveCampaign(workspaceId?: string, archived: boolean = true): Observable<any> {
    let urlParams = [];
    let url: string;

    if (workspaceId) {
      url = `/api/loreal-timeline/drive-campaigns/all/${workspaceId}`;

    } else {
      url = `/api/loreal-timeline/drive-campaigns/all`;

    }
    // let url = `/api/loreal-timeline/drive-campaigns/all/${workspaceId}`
    // if (limit) {
    // 	urlParams.push(`limit=${limit}`);
    // }
    if (archived || !archived) {
      urlParams.push(`isArchived=${archived}`);
    }
    url += '?' + urlParams.join('&');
    return this.apiService.httpGet(url);
    // return this.apiService.httpGet(`/api/loreal-timeline/drive-campaigns/${workspaceId}`);
  }

  //get selected driveCampaign using workspaceId
  public getSingleDriveCampaign(workspaceId: string, driveCampaignId: string): Observable<any> {
    return this.apiService.httpGet(`/api/loreal-timeline/drive-campaigns/${workspaceId}/${driveCampaignId}`);
  }

  public downloadPPT(data): Observable<any> {
    let headers = new HttpHeaders;
    let token = localStorage.getItem('token');
    if (token) {
      headers.append('Authorization', 'Bearer ' + token);
    }
    return this.httpClient.post(this.baseUrl + '/api/power-point/generate', data, { headers: headers, responseType: 'blob' });
  }

  updateDriveCampaign(data) {
    return this.apiService.httpPost(`/api/loreal-timeline/drive-campaigns`, data);
  }

  deleteDriveCampaign(workspaceId, id) {
    return this.apiService.httpDelete(`/api/loreal-timeline/drive-campaigns/${workspaceId}/${id}`);
  }

  //upload timeline avtar image
  // /api/loreal-timeline/drive-campaigns/{workspaceId}/{id}/logo
}
