<div class="team-editor-container">
  <div class="modal-header text-center">
    <h3 class="modal-title w-100 font-weight-bolder d-flex flex-column justify-content-center align-items-center">
      <i class="d-block mtm-icon mtm-icon-add-group mr-3"></i>
      <span class="mt-2" *ngIf="!teamId">{{'team_newTitle' | translate}}</span>
      <span class="mt-2" *ngIf="teamId">{{'team_editTitle' | translate}}</span>
    </h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="teamForm" (ngSubmit)="submit()">

      <div class="form-group">
        <label for="name">{{'team_name' | translate }}</label>
        <input type="text" class="form-control" id="name" formControlName="name" [placeholder]="'team_name' | translate"
        [readonly]="!isCreator">
      </div>

      <div class="form-group">
        <label for="members">{{'team_members' | translate }}</label>
        <ng-select appearance="outline" [items]="availableUsers" multiple="true" formControlName="members"
          bindValue="username" bindLabel="fullName" [searchable]="true" id="members" [placeholder]="'Select user' | translate"
          (change)="onSelectionChange()" [readonly]="!isCreator">
          <ng-template ng-multi-label-tmp>
            <span style="display:block" class="ng-multi-label mr-3"> {{ teamForm?.value?.members?.length}}
              {{'usersSelected' | translate}}
            </span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="d-flex align-items-center">
              <mtm-user-profile-picture [forceLoadProfile]="true" [username]="item.username" [height]="22" [width]="22" [user]="item">
              </mtm-user-profile-picture>
              <span class="ml-2" [title]="item.username">{{ item.fullName?.trim() || item.username }}</span>
            </div>
          </ng-template>
        </ng-select>

        <div class="members-container">
          <div *ngFor="let user of selectedMembers; trackBy: userTrackBy" class="d-flex align-items-center px-3 py-2">
            <span class="d-inline-block mr-2" role="button" (click)="onUserRemoved(user.username)" *ngIf="isCreator">
              <i class="fa fa-times text-danger"></i>
            </span>
            <mtm-user-profile-picture [forceLoadProfile]="true" [username]="user.username" [height]="22" [width]="22"
            [user]="user">
            </mtm-user-profile-picture>
            <span class="name ml-2" [title]="user.username">{{user.fullName?.trim() || user.username}}</span>
          </div>
        </div>

      </div>

      

      <!--
      <div class="form-check mb-2">
        <input type="checkbox" class="form-check-input" id="overrideRoles" formControlName="overrideRoles"
          (ngModelChange)="adjustRoles()">
        <div class="form-check-label" for="overrideRoles">Override Roles</div>
      </div>

      <div class="form-group mb-4">
        <ng-select formControlName="roles" [multiple]="true" [items]="availableRoles" appearance="outline"
          [searchable]="false" bindValue="id" bindLabel="name" [readonly]="! teamForm.value.overrideRoles">
        </ng-select>
      </div>
      -->

      <div class="form-group d-flex align-items-center" *ngIf="creator">
        <label class="mr-2 mb-0">{{'team_creator' | translate }}:</label>
        <mtm-user-profile-picture [forceLoadProfile]="true" [username]="creator.username" [height]="22" [width]="22" [user]="creator">
        </mtm-user-profile-picture>
        <span class="name ml-2" [title]="creator.username">{{creator.fullName?.trim() || creator.username}}</span>
      </div>

      <div class="buttons text-right">
        <button type="button" class="btn btn-cancel text-uppercase mr-3" (click)="cancel()">{{'cancel' | translate
          }}</button>
        <button type="submit" class="btn btn-save text-uppercase" [disabled]="isLoading || !isCreator">{{'save' | translate
          }}</button>
      </div>
    </form>
  </div>
</div>
