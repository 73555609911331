import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MTMCustomFile, TransferType } from "./mtm-custom-file";
import { empty, observable, Observable, Subject, zip } from "rxjs";
import { ApiService } from "../api.service";
import { TransferStatus } from "./transfer-status";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TransferDbManagerService } from "./transfer-db-manager.service";
import { DownloadRecord, UploadRecord } from "./idx-schema";
import {
  listenerFileDownloadLoaded,
  listenerFileDownloadRemoved,
  listenerFileUploadCompleted,
  listenerFileUploaded,
  listenerFileUploadLoaded,
  listenerUploadStatusChanged
} from "./listeners";
import { OverlayService } from "../overlayService";
import { supportedFileExtensions } from "./file-upload";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { UploadRequest, FileUploader, EntityFiles } from "./file.uploader";
import { SignedUrlUploader } from "./signed-url-uploader";
import { DirectFileUploader } from "./direct-file-uploader";
import { TransferMonitorService } from "./transfer-monitor.service";
import { AuthService } from "../auth.service";
import { MTMFileDownload } from "./mtm-file-download";
import { SignedUrlDownloader } from "./signed-url-downloader";
import { UUID } from "angular2-uuid";

const downloadChunkSize: number = 50 * 1024 * 1024; //50Mb

@Injectable({
  providedIn: 'root'
})
export class FileTransferService {
  filesToUpload: MTMCustomFile[] = [];
  filesToDownload: MTMFileDownload[] = [];


  constructor(private http: HttpClient,
    private apiService: ApiService,
    private modalService: NgbModal,
    private overlayService: OverlayService,
    private translatePipe: TranslatePipe,
    private dbManager: TransferDbManagerService,
    private resumableUploader: SignedUrlUploader,
    public transferMonitor: TransferMonitorService,
    private authService: AuthService,
    private directUploader: DirectFileUploader,
    private signedUrlDownloader: SignedUrlDownloader) {

    this.dbManager.deleteExpiredTransfers().subscribe();

  }

  loadActiveUploads(): Observable<Array<MTMCustomFile>> {
    const subject = new Subject<Array<MTMCustomFile>>();
    const owner = this.authService.getAuthUserName();
    this.filesToUpload = [];
    this.dbManager.getAllUploads(owner).subscribe({
      next: (data: Array<UploadRecord>) => {
        const activeUploads: Array<MTMCustomFile> = [];
        data.forEach((upload: UploadRecord) => {
          if (upload.status != TransferStatus.COMPLETED) {
            const file = MTMCustomFile.FromUploadRecord(upload);
            file.uploadStatusCode = TransferStatus.INACTIVE;
            activeUploads.push(file);
          }
        });
        this.filesToUpload.push(...activeUploads);
        listenerFileUploadLoaded.next(activeUploads);
        subject.next(activeUploads);
        subject.complete();
      }
    });

    return subject.asObservable();
  }

  ///#region upload files

  uploadFiles(request: UploadRequest, hideProgress = false) {
    const owner = this.authService.getAuthUserName();
    request.files.forEach(file => {
      file.transferType = request.uploadType;
      file.owner = owner;
      this.sanitizeFileNames(file);
      if (request.uploadType != TransferType.Reference) {
        file.setTimeToStartUploading();
        file.setUploadStatusCode(TransferStatus.WAITING);
      } else {
        file.timeToStartUploading = +new Date();
        file.uploadStatusCode = TransferStatus.WAITING;
        MTMCustomFile.MakeMTMCustomFileAdapter(file);
      }
    });

    if (request.uploadType != TransferType.Reference && !hideProgress) {
      this.filesToUpload.push(...request.files);
    }

    switch (request.uploadType) {
      case TransferType.SignedURL:
      case TransferType.ResumableSignedURL:
      case TransferType.Reference:
        this.resumableUploader.uploadFiles(request);
        break;
      case TransferType.DirectUpload:
        this.directUploader.uploadFiles(request);
        break;
    }
  }

  getUploaderByType(uploadType: string): FileUploader {
    switch (uploadType) {
      case TransferType.SignedURL:
      case TransferType.ResumableSignedURL:
      case TransferType.Reference:
        return this.resumableUploader;
      case TransferType.DirectUpload:
        return this.directUploader;
    }

    return null;
  }

  private sanitizeFileNames(file: MTMCustomFile) {
    let fileStringArray = file.fileName.split('.');
    let fileNameArr = (fileStringArray.length > 1) ? file.fileName.split('.').slice(0, -1) : fileStringArray;
    let ext = (fileStringArray.length > 1) ? file.fileName.split('.').pop() : '';
    const originalName = fileNameArr.join('.');
    let fileName = originalName.replace(/[^a-zA-Z0-9_\-]/g, '_') || 'mtm-file';
    const isSameName = originalName == fileName;
    if (!isSameName) {
      file.name = fileName + (ext ? ext : '');
    }
    let foundIndex = supportedFileExtensions.indexOf(ext) >= 0;
    if (!foundIndex) {
      let contentType = file.fileType || file.type;
      let typeExt = contentType.substr(contentType.indexOf("/") + 1);
      let foundExtIndex = supportedFileExtensions.indexOf(typeExt) >= 0;
      if (foundExtIndex) {
        if (typeExt === 'vnd.openxmlformats-officedocument.presentationml.presentation') {
          ext = 'pptx';
        } else if (typeExt === 'vnd.ms-powerpoint') {
          ext = 'ppt';
        } else if (typeExt === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          ext = 'xlsx';
        } else if (typeExt === 'vnd.ms-excel') {
          ext = 'xls';
        } else if (typeExt === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
          ext = 'docx';
        } else if (typeExt === 'msword') {
          ext = 'docx';
        } else {
          ext = typeExt;
        }
        fileName = fileStringArray.join('.');
      } else {
        fileName = fileNameArr.join('.');
      }
    }
    file.fileName = fileName + '_' + (file.createTime || (file as any).timeCreated) + (ext ? ('.' + ext) : '');
    // hide for now - MTM-4414
    // if (!isSameName) {
    //   this.overlayService.showWarning(this.translatePipe.transform('fileNameChanged'));
    // }
  }

  pauseUpload(file: MTMCustomFile) {
    if (!file.isResumable()) {
      return;
    }

    this.resumableUploader.pauseUpload(file);
  }

  pauseAllTransfers() {
    if (this.filesToUpload.length > 0) {
      this.filesToUpload.forEach(file => {
        if (file.isResumable()) {
          this.pauseUpload(file);
        } else {
          this.cancelUpload(file);
        }
      });
    }
  }

  resumeUpload(file: MTMCustomFile) {
    if (!file.isResumable()) {
      return;
    }
    const wasInactive = file.uploadStatusCode == TransferStatus.INACTIVE;
    this.resumableUploader.resumeUpload(file);
    if (wasInactive) {
      this.transferMonitor.sendFileToMonitor(file.preUploadUrl, file.entity, file);
    }
  }

  myEmptyObservable: Observable<any> = empty();

  cancelUpload(file: MTMCustomFile): Observable<any> {
    file.uploadStatusCode = TransferStatus.CANCELED;
    this.filesToUpload.splice(this.filesToUpload.indexOf(file), 1);
    listenerUploadStatusChanged.next(file);
    const uploader = this.getUploaderByType(file.transferType);
    if (!uploader) {
      return this.myEmptyObservable;
    }

    this.transferMonitor.removeFileFromUploadMonitor(file.preUploadUrl, file);
    return uploader.cancelUpload(file);
  }


  //#endregion

  //#region download files

  loadActiveDownloads(): Observable<Array<MTMFileDownload>> {
    const subject = new Subject<Array<MTMFileDownload>>();
    const owner = this.authService.getAuthUserName();

    this.filesToDownload = [];
    this.dbManager.getAllDownloads(owner).subscribe({
      next: (data: Array<DownloadRecord>) => {
        const downloads: Array<MTMFileDownload> = [];
        data.forEach((download: DownloadRecord) => {
          if (download.status != TransferStatus.COMPLETED) {
            const file = MTMFileDownload.fromDownloadRecord(download);
            file.status = TransferStatus.INACTIVE;
            this.handleDownloadCompleted(file);
            downloads.push(file);
          }
        });
        this.filesToDownload.push(...downloads);
        listenerFileDownloadLoaded.next(downloads);
        subject.next(downloads);
        subject.complete();
      }
    });

    return subject.asObservable();
  }

  downloadFile(file: MTMFileDownload) {
    if (!file.id) {
      file.id = UUID.UUID();
    }
    file.startDate = + new Date();
    file.owner = this.authService.getAuthUserName();
    this.signedUrlDownloader.download(file);
    if(! file.isSilent){
      this.filesToDownload.push(file);
    }
    this.handleDownloadCompleted(file);
  }

  pauseDownload(file: MTMFileDownload) {
    if(! file.isResumable) {
      return;
    }
    this.signedUrlDownloader.pauseDownload(file);
  }

  resumeDownload(file: MTMFileDownload) {
    if(! file.isResumable){
      return;
    }
    this.signedUrlDownloader.resumeDownload(file);
  }

  cancelDownload(file: MTMFileDownload) {
    if (file.status == TransferStatus.COMPLETED) {
      this.filesToDownload.splice(this.filesToDownload.indexOf(file), 1);
      listenerFileDownloadRemoved.next(file);
      return;
    }

    this.signedUrlDownloader.cancelDownload(file).subscribe(() => {
      this.filesToDownload.splice(this.filesToDownload.indexOf(file), 1);
      listenerFileDownloadRemoved.next(file);
    });
  }

  handleDownloadCompleted(file: MTMFileDownload){
    console.log(file);
    file.done$.subscribe(file => {
      const blob = new Blob([file.buffer], { type: file.contentType });
      (window as any).saveAs(blob, file.displayName || file.name, {}, true);
    });
  }

  //#endregion
}
