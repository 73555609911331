import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { onLeaveFromProject, onLoadProject, ProjectService } from "app/shared/services/project.service";
import { HelperService } from "app/shared/services/helper.service";
import { NotificationService } from "app/shared/services/notification.service";

import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "../../../shared/services/datepicker-adapter";
import { AuthService } from 'app/shared/services/auth.service';
import { Authority, PermissionService } from "../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ThemeService } from 'app/shared/services/theme.service';
import { ActiveWorkspaceUpdatedEvent, Brand, DriveCampaign, HeaderInfo, WorkspaceDTO } from "app/shared/interfaces";
import { environment } from 'environments/environment';

@Component({
	selector: 'mtm-new-project',
	templateUrl: './new-project.component.html',
	styleUrls: ['./new-project.component.scss'],
	providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class NewProjectComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
	private status: string = 'New';
	projectId: string = null;
	project: any = null;
	index = 0;
	user: any;
	private userAccessed: boolean = false;
	ngUnsubscribe = new Subject();
	totalSections: number = 7;
	links: any[] = [];
	workspaceId: string;
	driveCampaignId: string;
	isCpd: boolean = false;

	constructor(
		public service: ProjectService, private route: ActivatedRoute, private router: Router, public auth: AuthService, private notificationService: NotificationService,
		public permissionService: PermissionService,
		private themeService: ThemeService) {
		this.route.params.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params) => {
			if (HelperService.isUUID(params['projectId'])) {
				this.user = this.auth.getAuthUser();
				this.isCpd = this.user.companyId == environment.ltg.cpdCompanyId;
				this.projectId = params['projectId'];
				this.service.prepareProjectInit(this.projectId);
				this.status = 'Edit';
				localStorage.setItem('workspaceId', this.workspaceId);
				localStorage.setItem('dcId', this.driveCampaignId);
				this.setMenu();
			}
			this.workspaceId = this.route.snapshot.queryParams.workspaceId;
			this.driveCampaignId = this.route.snapshot.queryParams.dcId;
		});

		onLoadProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((project) => {
			this.workspaceId = project.workspaceId;
			this.driveCampaignId = project.campaignId;
			this.setMenu();
		})

		this.route.url.subscribe(url => {
			if (this.service.project && this.service.project.id)
				this.project = this.service.project;
			this.totalSections = this.service.sections.length;
			if (this.totalSections == 0) this.totalSections = 7;
		});
	}

	private setMenu() {
		let generalQueryParams: any = {};
		if (this.workspaceId && this.driveCampaignId) {
			if (this.driveCampaignId) {
				generalQueryParams = { workspaceId: this.workspaceId, dcId: this.driveCampaignId, isdc: true };
			} else {
				generalQueryParams = { workspaceId: this.workspaceId };
			}
		}
		this.links = [];
		this.links.push({
			url: 'company',
			queryParams: generalQueryParams,
			title: "general",
			text: "companyText",
			subText: '',
			authority: Authority.E,
			globalScope: true,
			requiresProject: false
		});
		this.links.push({
			url: 'users-permissions',
			title: "userPermissions",
			text: "userPermissionText",
			subText: "userPermissionSubText",
			authority: Authority.U,
			globalScope: false,
			requiresProject: true
		});
		if (this.isCpd && this.driveCampaignId) {
			this.links.push({
				url: 'asset-list',
				title: "addAsset",
				text: "addAssetText",
				subText: "addAssetSubText",
				authority: Authority.U,
				globalScope: false,
				requiresProject: true
			});
		}
		this.links.push({
			url: 'assets',
			title: "teamFiles",
			ext: "teamFilesText",
			subText: "teamFilesSubText",
			authority: Authority.U,
			globalScope: false,
			requiresProject: true
		})
	}

	setUserAccess() {
		if (this.project && this.user) {
			if (this.permissionService.hasAuthority(Authority.Z, null)) {
				return;
			}

			if (this.permissionService.hasAuthority(Authority.P, null)) {
				return;
			}

			this.links = _.filter(this.links, link => this.permissionService.hasAuthority(link.authority,
				link.globalScope ? null : this.project.id));
			this.userAccessed = true;
			this.router.navigate(['/projects', 'edit', this.project.id, this.links[0].url]);
		}
	}

	ngOnInit() {
		this.user = this.auth.getAuthUser();
		this.isCpd = this.user.companyId == environment.ltg.cpdCompanyId;
		this.workspaceId = this.route.snapshot.queryParams.workspaceId;
		this.driveCampaignId = this.route.snapshot.queryParams.dcId;
		this.setMenu();
	}

	ngOnDestroy() {
		localStorage.removeItem('workspaceId');
		localStorage.removeItem('dcId');
		onLeaveFromProject.emit();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
		this.themeService.resetOptions();
	}

	ngAfterViewInit(): void {
		this.themeService.setContent({
			rightPane: null,
			backgroundImage: null,
			fullWidthContent: true,
			header: this.headerContent
		});
	}

	/**
	 * Check if a step given is complete
	 *
	 * @param i
	 */
	isComplete(i: number) {
		let index: number;
		this.links.map((link: any) => {
			if (this.isActive(link.url)) {
				index = this.links.indexOf(link);
				this.index = index;
			}
		});
		return i < index;
	}

	/**
	 * Check if an url given is active
	 *
	 * @param url
	 */
	private isActive(url: string): boolean {
		if (this.service.project.id)
			return this.router.isActive('/projects/edit/' + this.service.project.id + '/' + url, false);
		else
			return this.router.isActive('/projects/new/' + url, false);
	}


	/**
	 * Archive project
	 */
	private onArchiveProject() {
		this.notificationService.open({
			title: 'Archive Project',
			description: `Are you sure you want to archive this project?`,
			confirmBtn: 'Yes, archive',
			cancelBtn: 'Cancel'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			// Only if user clicked on Yes in previous popup
			if (confirm) {

				// Delete or archive project
				this.service.deleteOrArchiveProject(this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					() => {
						this.router.navigate(['/projects']);
					},
					(err: any) => {
						this.notificationService.open({
							title: 'Archive Project',
							description: 'There was an error trying to archive this project.\nPlease, try again.',
							confirmBtn: 'Accept'
						});
					})
			}
		});

		return false;
	}

	private syncHeaderInfo() {
		let headerInfo: HeaderInfo = {
			title: '',
			subtitle: '',
			avatarName: '',
			avatarImage: '',
			subtitleParams: {}
		};
		//   if (this.campaign) {
		// 	headerInfo.subtitle = 'menu_campaignSubtitle';
		// 	headerInfo.subtitleParams = { name: this.campaign.name };
		//   }

		//   if (this.workspace) {
		// 	headerInfo.avatarImage = this.workspace.avatarUrl;
		// 	headerInfo.avatarName = this.workspace.name;
		// 	headerInfo.title = this.workspace.name;
		//   } else {
		// 	headerInfo.avatarImage = this.currentBrand?.logo || '';
		// 	headerInfo.avatarName = this.currentBrand?.name || '';
		//   }

		let url = location.pathname;

		if (url.endsWith('/')) {
			url = url.substring(0, url.length - 1);
		}

		//   if(url === '/workspaces/campaign-drive') {
		// 	if(this.workspace){
		// 	  headerInfo.subtitle = 'menu_campaignsProjects';
		// 	} else {
		// 	  headerInfo.title = 'menu_campaignsProjects';
		// 	}
		//   } else if(url === '/workspaces'){
		// 	headerInfo.title = 'workspaces';
		//   } else if(url === '/workspaces/projects'){
		// 	headerInfo.title = 'projects';
		//   } else if(url.includes('/workspaces/analytics')){
		// 	headerInfo.title = 'leftBarReporting';
		//   }

		this.themeService.updateHeaderInfo(headerInfo);
	}
}
