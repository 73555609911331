<div class="p-3 d-lg-flex p-xl-4 invitation-landing">
  <aside class="d-flex flex-column align-items-center justify-content-between
    rounded-xl py-3 mb-4 mb-lg-0 intro-column">

    <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1 logo-container">
      <img ngSrc="/assets/img/login/login-logo.svg" width="134" height="112" alt="MTM Logo" class="mb-4" />

      <div class="motto-desc">
        <span class="mr-1 mr-sm-0">{{'login_mottoDescription1' | translate}}</span>
        <span>{{'login_mottoDescription2' | translate}}</span>
      </div>

      <h2 class="fw-700 motto">{{'entry_aside_motto' | translate}}</h2>
    </div>

    <div class="text-center copyright">©{{year}} {{'entry_aside_copyright' | translate}} MASTER THE MONSTER</div>

  </aside>
  <div class="col-12 col-lg-9 d-flex flex-column align-items-center justify-content-center main-container">

    <h1 class="fw-700 text-center mb-4 title">{{'entry_landing_createAccount' | translate }}</h1>

    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center px-3 mb-4 social-buttons">
      <button type="button" class="btn btn-social-login d-flex align-items-center justify-content-start px-3"
        (click)="continueWithGoogle()">
        <img class="mr-3" src="/assets/img/login/google.svg" [alt]="'entry_landing_continueWithGoogle' | translate"
          width="20" height="20" />
        <span class="fw-700">{{'entry_landing_continueWithGoogle' | translate }}</span>
      </button>
      <button type="button" class="btn btn-social-login d-flex align-items-center justify-content-start px-3"
        (click)="continueWithMicrosoft()">
        <img class="mr-3" src="/assets/img/login/microsoft.svg"
          [alt]="'entry_landing_continueWithMicrosoft' | translate" width="21" height="21" />
        <span class="fw-700">{{'entry_landing_continueWithMicrosoft' | translate }}</span>
      </button>
    </div>

    <div class="email-form">
      <div class="text-center mt-2 mb-4 email-label-container">
        <span>{{'entry_landing_continueEmailDescription' | translate }}</span>
      </div>

      <div class="form-group mb-3">
        <label for="email" class="fw-700">{{'Email' | translate}}</label>
        <input type="email" id="email" class="form-control py-1 px-2" disabled [value]="email" />
      </div>

      <button type="button" class="btn btn-orange btn-continue w-100 fw-700 text-white"
        (click)="continueWithEmail()">{{'continue' | translate }}</button>
    </div>

  </div>

</div>