import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
	GetVersionFilesParams, HeaderInfo, OptionInfo,
	OptionVersion,
	OptionVersionSimple,
	ProjectSection,
	ProjectSubsection, QueryState, TopicInfo,
	TopicsLoadParams,
	TopicUpdateDto,
	GridOption
} from "../interfaces";
import { takeUntil, tap } from "rxjs/operators";

export type ActiveSubsectionState = {
	section: ProjectSection;
	subsection: ProjectSubsection;
	activeTopic?: TopicInfo;
	activeOption?: OptionInfo;
	activeVersion?: OptionVersionSimple;
	activeFile: any;
	activeFileId?: string;
} | null;


@Injectable({
	providedIn: 'root'
})
export class SubsectionService {
	files: any = {};
	ngUnsubscribe = new Subject();
	subsectionState: ActiveSubsectionState = null;
	subsection$ = new BehaviorSubject<ActiveSubsectionState>(this.subsectionState);
	topics$ = new BehaviorSubject<QueryState<TopicInfo>>({
		data: [],
		isLoading: false,
		error: null
	});
	options$ = new BehaviorSubject<QueryState<OptionInfo>>({
		data: [],
		isLoading: false,
		error: null
	});


	constructor(private apiService: ApiService) {
	}
	private changedVersion = new BehaviorSubject({ changedVersionFiles: {}, versionelementId: null, versionChanged: false });
	getSelectedVersion = this.changedVersion.asObservable();

	selectedVersion(versionFiles: any, keys: any, elementId: number, isVersionChanged: boolean) {
		this.getVersion(keys)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				this.files = { ...result.files };
				if (result) {
					if (this.files) {
						this.changedVersion.next({ changedVersionFiles: this.files, versionelementId: elementId, versionChanged: isVersionChanged })
					}
				}
			})
	}

	createNextVersion(projectId: string, sectionId: string, subsectionId: string, itemId: string): Observable<any> {
		const url =
			`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/createNextVersion/${itemId}`;
		return this.apiService.httpPost(url, {});
	}

	deleteVersions(projectId: string, sectionId: string, subsectionId: string, itemId: string, versions: number[]): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/deleteVersion/${itemId}`;
		return this.apiService.httpDelete(url, false, versions);
	}

	reorderVersions(projectId: string, sectionId: string, subsectionId: string, itemId: string, versions: number[]): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/reOrderVersion/${itemId}`;
		return this.apiService.httpPost(url, versions);
	}

	reorderVersionFiles(projectId: string, sectionId: string, subsectionId: string, itemId: string, version: number, fileOrder: any): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/reOrderVersionFiles/${itemId}/${version}`;
		return this.apiService.httpPut(url, fileOrder);
	}

	deleteFile(projectId: string, sectionId: string, subsectionId: string, itemId: string, fileId: string, version: number = 0): Observable<any> {
		let deleteUrl = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/` +
			`${itemId}/files/${fileId}`;
		if (version > 0) {
			deleteUrl += `?versionNumber=${version}`;
		}
		return this.apiService.httpDelete(deleteUrl);
	}

	getVersionFiles(projectId: string, sectionId: string, subsectionId: string, itemId: string, version: number): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-items/${itemId}` +
			`/files?version=${version}`;
		return this.apiService.httpGet(url);
	}


	public getVersionDetail(args: GetVersionFilesParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-versioning/${args.itemId}/${args.versionNumber}`;

		return this.apiService.httpGet(url);
	}


	public getVersionDetailById(projectId: string, sectionId: string, subsectionId: string, versionId: string, versionNumber: any): Observable<any> {
		const versionChange = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-versioning/${versionId}/${versionNumber}`;
		return this.apiService.httpGet(versionChange);
	}

	//region topic functions

	public getTopics(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/versioning/topics/?project=${projectId}&section=${sectionId}&subsection=${subsectionId}`;
		return this.apiService.httpGet(url);
	}

	public loadTopicsForComponent(args: TopicsLoadParams): Observable<any> {
		return new Observable(subscriber => {
			const { projectId, sectionId, subsectionId, component } = args;
			this.getTopics(projectId, sectionId, subsectionId)
				.pipe(takeUntil(component.ngUnsubscribe))
				.subscribe(
					data => {
						data.forEach(data => {
							if (data.topicid) {
								data.id = data.topicid;
							}
						})
						data.sort((t1, t2) => t1.title.localeCompare(t2.title));
						component.topics = data || [];
						subscriber.next(true);
						subscriber.complete();
					}, (err) => {
						subscriber.error(err);
						subscriber.complete();
					}
				);
		})
	}

	public getTopic(projectId: string, sectionId: string, subsectionId: string, topicId: string): Observable<any> {
		const url = `/api/versioning/topics/${topicId}?project=${projectId}&section=${sectionId}&subsection=${subsectionId}`;
		return this.apiService.httpGet(url);
	}

	public createTopic(info: TopicUpdateDto): Observable<any> {
		let url = `/api/versioning/topics/?project=${info.projectId}&section=${info.sectionId}&subsection=${info.subsectionId}`;
		if (info.title) {
			url += '&topic=' + encodeURIComponent(info.title);
		}
		if (info.description) {
			url += '&description=' + encodeURIComponent(info.description);
		}
		return this.apiService.httpPost(url, {});
	}

	public updateTopic(info: TopicUpdateDto): Observable<any> {
		let url = `/api/versioning/topics/?project=${info.projectId}&section=${info.sectionId}&subsection=${info.subsectionId}&topicId=${info.topicId}`;
		if (info.title) {
			url += '&topic=' + encodeURIComponent(info.title);
		}
		if (info.description) {
			url += '&description=' + encodeURIComponent(info.description);
		}

		return this.apiService.httpPut(url, {});
	}

	public renameTopic(projectId: string, sectionId: string, subsectionId: string, topicid: string, newName: string): Observable<any> {
		const updatedName = encodeURIComponent(newName);
    return this.apiService.httpPut(`/api/versioning/topics/rename?project=${projectId}&section=${sectionId}&subsection=${subsectionId}&topicId=${topicid}&topic=${updatedName}`, {});
	}

	public deleteTopic(projectId: string, sectionId: string, subsectionId: string, topicId: string): Observable<any> {
		const url = `/api/versioning/topics/?project=${projectId}&section=${sectionId}&subsection=${subsectionId}&topicId=${topicId}`;
		return this.apiService.httpDelete(url);
	}

	//endregion

	//#region option functions

	getOptions(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/versioning/options?project=${projectId}&section=${sectionId}&subsection=${subsectionId}`;
		return this.apiService.httpGet(url)
			.pipe(tap((data: any) => {
				data.forEach((option: any) => {
					if (option.title == null)
						option.title = '';
					if (option?.optionid) {
						option.id = option.optionid;
					}
					// if (option.simpleVersions) {
					// 	option.simpleVersions.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
					// }
					// In this modified code, we first check if e1.order is equal to e2.order, and if they are equal, we return 0 as required for equal values. If they are not equal, we return -1 or 1 based on the comparison of the order properties.
					// This ensures that the sort method works correctly when comparing elements with equal order values.
					if (option.simpleVersions) {
						option.simpleVersions.sort((e1, e2) => {
							if (e1.order === e2.order) {
								return 0;
							}
							return e1.order < e2.order ? -1 : 1;
						});
					}
				});
				data.sort((a1, a2) => a1.title.localeCompare(a2.title));
			}));
	}


	//#endregion
	deleteOptions(args: any): Observable<any> {
		const url = `/api/versioning/options?optionid=${args.optionid}&project=${args.project}&section=${args.section}&subsection=${args.subsection}`;
		return this.apiService.httpDelete(url);
	}

	//#region common utils
	mapThumbnailsToFiles(thumbnails: any[]) {
		if (!thumbnails) {
			return [];
		}

		const files = [];
		Object.entries(thumbnails).forEach((el: any) => {
			const order = el[0];
			const { id, contentType, url } = el[1];
			const displayName = url.split('/').pop();
			files.push({
				displayName,
				fileName: displayName,
				signedURL: url,
				contentType,
				id
			});
		});
		return files;
	}

	prepareOptionsForPreview(options: any[]) {
		options.forEach(edit => {
			if (edit.thumbnails) {
				edit.items = this.mapThumbnailsToFiles(edit.thumbnails);
				edit.files = edit.items;
			} else {
				edit.files = [];
				edit.items = [];
			}
		});
	}

	convertOptionToViewModel(option: OptionInfo): GridOption {
		const gridOption: GridOption = { ...option, files: [], items: [], versionCache: {} };
		if (option?.simpleVersions?.length) {
			gridOption.currentStatus = option.simpleVersions[0].status;
			gridOption.currentVersion = option.simpleVersions[0].versionNumber;
		}
		if (!!option.thumbnails) {
			const files = [];
			Object.entries(option.thumbnails).forEach((el) => {
				const { id, contentType, url } = el[1];
				const displayName = url.split('/').pop();
				files.push({
					displayName,
					fileName: displayName,
					signedURL: url,
					contentType,
					id
				});
			});

			gridOption.files = files;
			gridOption.items = files;
		}
		return gridOption;

	}
	//#endregion

	public getVersion(args: GetVersionFilesParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/` +
			`v-versioning/${args.itemId}/${args.versionNumber}`;
		return this.apiService.httpGet(url);
	}

	sortFilesByFileOrder(version: OptionVersion) {
		if (version.fileOrder) {
			version.files.forEach(f => f.fOrder = 9999);
			Object.entries(version.fileOrder).forEach(([key, value]: any[]) => {
				let file = version.files.find(f => f.id == value.id);
				if (file) {
					file.fOrder = key;
				}
			});
			version.files.sort((a, b) => a.fOrder - b.fOrder);
			version.files.forEach(f => delete f.fOrder);
		}
	}

	//#region subsection functions

	setSubsectionState(state: ActiveSubsectionState) {
		this.subsectionState = state;
		this.subsection$.next(this.subsectionState);
	}

	updateSubsectionState(state: Partial<ActiveSubsectionState>) {
		console.log('state to update', state);
		this.subsectionState = { ...this.subsectionState, ...state };
		this.subsection$.next(this.subsectionState);
	}

	clearSubsectionState() {
		this.subsection$.next(null);
	}

	//#endregion

	//#region option functions

	getOption(projectId: string, sectionId: string, subsectionId: string, optionId: string): Observable<any> {
		const url = `/api/versioning/options/${optionId}?project=${projectId}&section=${sectionId}&subsection=${subsectionId}&optionId=${optionId}`;
		return this.apiService.httpGet(url);
	}

	createOption(option: Partial<OptionInfo>) {
		const url = `/api/versioning/options`;
		return this.apiService.httpPost(url, option);
	}

	updateOption(option: Partial<OptionInfo>): Observable<any> {
		const url = `/api/versioning/options`;
		return this.apiService.httpPut(url, option);
	}

	deleteOption(projectId: string, sectionId: string, subsectionId: string, optionId: string): Observable<any> {
		const url = `/api/versioning/options?project=${projectId}&section=${sectionId}&subsection=${subsectionId}&optionId=${optionId}`;
		return this.apiService.httpDelete(url);
	}

	//#endregion

}
