import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import { ApiService } from "../../shared/services/api.service";
import { ComponentStateService } from "app/shared/services/component-state.service";

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	constructor(private apiService: ApiService, 
		private authService: AuthService, 
		private subscriptionService: SubscriptionService, 
		private router: Router,
		private componentStateService: ComponentStateService
	) { }

	onBehalf(user) {
		return this.apiService.httpPost(`/api/admin/switchUser`, { username: user.username });
	}

	clearOriginalStorage() {
		this.componentStateService.clearIdleWatcher();
		let lang = localStorage.getItem('auth_user_language');
		localStorage.clear();
		sessionStorage.clear();
		if (lang) {
			localStorage.setItem('auth_user_language', lang);
		}
		EmitterService.get('user.logout').emit();
	}

	switchToAdmin() {
		let adminToken = localStorage.getItem('admin_token');
		let adminUser = JSON.parse(localStorage.getItem('admin_user'));
		this.clearOriginalStorage();
		this.authService.loginWithToken(adminToken, adminUser.username)
			.subscribe(res => {
				this.subscriptionService.getCurrentPlan().subscribe(res2 => {
					this.authService.setAuthUserSubscription(res2);
				});
				this.router.navigateByUrl('/admin/users');
			});
	}

	switchUser(credentials) {
		let adminToken = localStorage.getItem('token');
		let adminUser = this.authService.getAuthUser();
		this.clearOriginalStorage();
		this.authService.loginWithToken(credentials.auth.token, credentials.user.email)
			.subscribe(res => {
				localStorage.setItem('admin_token', adminToken);
				localStorage.setItem('admin_user', JSON.stringify(adminUser));
				this.subscriptionService.getCurrentPlan().subscribe(res2 => {
					this.authService.setAuthUserSubscription(res2);
				})
				this.router.navigateByUrl('/entry');
			});
	}

}
