<div class="mtm-file-download-control" (click)="$event.stopImmediatePropagation();downloadFile()">
	<div *ngIf="displayType==='icon'">
		<i *ngIf="!iconUrl && !(fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.state === transferStatus.WAITING)"
			class="fa fa-download fa-lg" [ngClass]="{ 'light-icon': lightIcon }" aria-hidden="true"></i>
		<img *ngIf="!!iconUrl && !(fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.status === transferStatus.WAITING)"
			src="{{iconUrl}}" [ngClass]="{ 'light-icon': lightIcon }" title="Download" width="28" />
		<mtm-download-upload-progress-icon
			[isOnLoading]="fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.status === transferStatus.WAITING"
			[widthInPixel]="iconSize" [iconColor]="iconColor">
		</mtm-download-upload-progress-icon>
	</div>
	<div *ngIf="displayType==='compact'" class="d-flex align-items-center">
		<div class="mtm-file-download-icon d-flex align-items-center justify-content-center mr-1">
			<img *ngIf="!file?.folder && !(fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.status === transferStatus.WAITING)"
				type="image/svg+xml" [src]="file?.type" class="file-icon-img"
				onError="this.src='assets/img/icons/file.svg'">
			<img *ngIf="file?.folder && !(fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.status === transferStatus.WAITING)"
				type="image/svg+xml" src="/assets/img/icons/blue_folder.svg" class="file-icon-img">
			<mtm-download-upload-progress-icon
				[isOnLoading]="fileDownloadInfo?.status === transferStatus.DOWNLOADING || fileDownloadInfo?.status === transferStatus.WAITING"
				[widthInPixel]="iconSize" [iconColor]="iconColor">
			</mtm-download-upload-progress-icon>
		</div>
		<div class="mtm-file-download-meta d-flex flex-column">
			<div class="mtm-file-download-filename" fileNameDisplay [fileName]="file?.displayName"></div>
			<div class="d-flex align-items-center mtm-file-download-info">
				<div class="d-flex align-items-center mr-1">
					<span class="_600 mr-1">Size: </span>
					<div>{{ file?.displaySize }}</div>
				</div>
				<div *ngIf="showUploadTime" class="d-flex align-items-center">
					<span class="_600 mr-1">Upload time: </span>
					<div class="mr-2">{{ file?.uploadedDate | date: dateFormat }}</div>
					<div>{{ file?.uploadedDate | mtmTimePipe }}</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="displayType == 'unknown-download'"  class="btn btn-unknown-download d-flex align-items-center">
		<img class="mr-2" src="/assets/img/versioning/unknown-file-download.svg" alt="Download" width="22" height="22" />
		<span class="text-uppercase">{{'mediaPlayer_downloadFile' | translate }}</span>
	</div>
</div>