import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { MyProjectsComponent } from 'app/layouts/my-projects/my-projects.component';
import { NewProjectComponent } from 'app/layouts/my-projects/new-project/new-project.component';
import { ProjectCompanyComponent } from 'app/layouts/my-projects/new-project/project-company/project-company.component';
import { ProjectPermissionsComponent } from 'app/layouts/my-projects/new-project/project-permissions/project-permissions.component';
import { ProjectAssetsComponent } from 'app/layouts/my-projects/new-project/project-assets/project-assets.component';
import { ProjectProposalComponent } from 'app/layouts/my-projects/project-proposal/project-proposal.component';
import { ProjectProposalBriefComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-brief/project-proposal-brief.component';
import { ProjectProposalTeamComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-team/project-proposal-team.component';
import { ProjectProposalQaSessionComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-qaSession/project-proposal-qaSession.component';
import { ProjectProposalUploadProposalComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-uploadProposal/project-proposal-uploadProposal.component';
import { ProjectProposalDiscussionComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-discussion/project-proposal-discussion.component';
import { ProjectProposalStages } from "app/layouts/my-projects/project-proposal/project-proposal-stages/project-proposal-stages.component";
import { ProjectProposalProjectDetails } from "app/layouts/my-projects/project-proposal/project-proposal-projectDetails/project-proposal-projectDetails.component";
import { ProjectProposalUserPermissions } from "app/layouts/my-projects/project-proposal/project-proposal-userPermissions/project-proposal-userPermissions.component";
import { ProjectInvitationComponent } from "app/layouts/my-projects/project-invites/project-invitation.component";
import { ProjectProposalsComponent } from "app/layouts/my-projects/project-proposals/project-proposals.component";
import { ArchivedProjectsComponent } from "app/layouts/my-projects/archived-projects/archived-projects.component";
import { ProposalProjectLinksGuard } from 'app/shared/guards/proposal-project-links.guard';
import { RateProdCompComponent } from "app/layouts/my-projects/rate-prod-comp/rate-prod-comp.component";
import { ProjectCreationGuard } from "app/shared/guards/project-creation.guard";
import { ProjectNotificationSettingComponent } from "app/layouts/my-projects/new-project/project-notification-setting/project-notification-setting.component";
import { SearchVideoComponent } from 'app/layouts/my-projects/search-video/search-video.component';
import { ProjectRouteGuard } from 'app/shared/guards/project-route.guard';
import { ProjectAssetListComponent } from 'app/layouts/my-projects/new-project/project-asset-list/project-asset-list.component';

export const routes: Routes = [
	{
		path: '',
		canActivate: [ProjectRouteGuard],
		component: MyProjectsComponent
	},
	{
		path: 'project-invitations',
		component: ProjectInvitationComponent,
	},
	{
		path: 'archived-projects',
		component: ArchivedProjectsComponent,
	},
	{
		path: 'project-proposals',
		component: ProjectProposalsComponent,
	},
	{
		path: 'rate-production-company/:projectId',
		component: RateProdCompComponent,
	},
	{
		path: 'search-video',
		component: SearchVideoComponent
	},
	{
		path: 'new',
		component: NewProjectComponent,
		canActivate: [ProjectCreationGuard],
		children: [
			{
				path: '',
				redirectTo: 'company',
				pathMatch: 'full',
			},
			{
				path: 'company',
				component: ProjectCompanyComponent
			}
			// {
			// 	path: 'notification-setting',
			// 	component: ProjectNotificationSettingComponent
			// },
		]
	},
	{
		path: 'edit/:projectId',
		component: NewProjectComponent,
		children: [
			{
				path: '',
				redirectTo: 'company',
				pathMatch: 'full',
			},
			{
				path: 'company',
				component: ProjectCompanyComponent
			},
			{
				path: 'users-permissions',
				component: ProjectPermissionsComponent
			},
			{
				path: 'assets',
				component: ProjectAssetsComponent
			},
			{
				path: 'asset-list',
				component: ProjectAssetListComponent
			}
			// {
			// 	path: 'notification-setting',
			// 	component: ProjectNotificationSettingComponent
			// },
		]
	},
	{
		path: ':projectId/notification-setting',
		component: ProjectNotificationSettingComponent
	},
	{
		path: ':projectId/proposal/:proposalId',
		component: ProjectProposalComponent,
		children: [
			{
				path: '',
				redirectTo: 'brief',
				pathMatch: 'full',
			},
			{
				path: 'brief',
				component: ProjectProposalBriefComponent
			},
			{
				path: 'team',
				component: ProjectProposalTeamComponent
			},
			{
				path: 'qaSession',
				component: ProjectProposalQaSessionComponent
			},
			{
				path: 'uploadProposal',
				component: ProjectProposalUploadProposalComponent
			},
			{
				path: 'discussion',
				component: ProjectProposalDiscussionComponent
			},
			{
				path: 'projectDetails',
				component: ProjectProposalProjectDetails,
				canActivate: [ProposalProjectLinksGuard]
			},
			{
				path: 'userPermissions',
				component: ProjectProposalUserPermissions,
				canActivate: [ProposalProjectLinksGuard]
			},
			{
				path: 'projectStages',
				component: ProjectProposalStages,
				canActivate: [ProposalProjectLinksGuard]
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProjectsRoutingModule {
}
