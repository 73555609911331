<div class=" campaign-overview-component ml-md-4">
  <!--change start-->
  <div *ngIf="!isProjectCollapse" class="mt-4">
    <div class="d-block">
      <div class="d-none d-md-block">
        <div class="d-flex align-items-center page-tab-control">
          <div class="section-title d-flex align-items-center w-100">
            <div class="d-flex align-items-center poiner">
              <span class="px-3 no-wrap">{{ 'All Projects' | translate}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center ml-auto project-nav-tabs">
            <div class="tab-item pointer text-center " (click)="allProjectItems(); activeTab='all'"
              [ngClass]="{ 'active': isAllProject }">
              <span>{{ 'All' | translate }}</span>
            </div>
            <div class="tab-item pointer text-center " (click)="favoriteProjectItems();activeTab='Fav'"
              [ngClass]="{ 'active': isFavoritesProject }">
              <span>{{ 'Favorites' | translate }}</span>
            </div>
            <div class="tab-item pointer text-center " (click)="getArchiveProjects(); activeTab='Achived'"
              [ngClass]="{ 'active': isArchivedProject }">
              <span>{{ 'Archived' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- change end-->
  <div *ngIf="!isLoaded && !isError" class="skeleton">
    <mtm-grid-skeleton [borderRadius]="6" [gridCount]="9" [widthSizeInPixels]="180"
      [HeightSizeInPixels]="235"></mtm-grid-skeleton>
  </div>
  <div class="projects-list position-relative w-100 " [style.top]="'25px'">
    <div *ngIf="canCreateProject && isStandAlone && projects.length == 0" class=" project pointer">
      <div class="campaign-drive-btn d-flex justify-content-center align-items-center w-100">
        <!-- <button class="campaign-drive-btn " (click)="createProject()">
            <div class="circle-div">
              <i class="fa fa-plus d-flex" aria-hidden="true"></i>
            </div>
            <h6 class="text-uppercase _700 f-10 mt-3 line-height" [style.color]="'#898C98'">{{'createNewProject' | translate}}
            </h6>
          </button> -->
        <button class="btn btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
          [style.min-width]="'100px'" [style.width]="'auto'" (click)="createProject()">
          <i class="p-1 fa fa-plus" aria-hidden="true"></i>
          <span class="ml-2">{{'newProject' | translate}}</span>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center w-75" *ngIf="isError">
      <span class="_600 f-12 ">{{'loreal_dashboard_somethingWentWrong' | translate}}</span>
    </div>
    <div class="mb-4 pointer project project-item" *ngFor="let item of projects" (click)="redirectToDashboard(item)"
      [ngClass]="{ 'locked-project': !item.isHaveAccess }">
      <div>
        <div class="lock-project-icon d-flex" (click)="$event.stopImmediatePropagation()" *ngIf="!item.isHaveAccess">
          <div class="lock-icon-container d-flex align-items-center justify-content-center">
            <i class="mtm-icon mtm-icon-lock"></i>
          </div>
        </div>
        <div class="card-img">
          <img *ngIf="item.avatarUrl" class="image-style" [src]="item.avatarUrl + '?v=' + cacheBuster" alt="">
          <span *ngIf="!item.avatarUrl" class="c-full-white profile-text text-uppercase h-100 w-100"
            [style.backgroundColor]="timelineService.getColorFromName(item.name)">
            {{item.name | slice:0:2}}
          </span>
          <span class="badge badge-edit-button">
            <i (click)="$event.stopImmediatePropagation();markAsFavorite(item)" *ngIf="!item.isFavorite"
              class="fa fa-star-o" aria-hidden="true"></i>
            <i (click)="$event.stopImmediatePropagation();removeFavorite(item)" *ngIf="item.isFavorite"
              class="fa fa-star" [style.color]="'#ffca4c'" aria-hidden="true"></i>
          </span>
        </div>
        <div class="card border-0 shadow-sm">
          <div class="col">
            <div class="row" *ngIf="item.isHaveAccess">
              <div class="col">
                <span class="f-10 _700 brand-name" [style.color]="'#898C98'">
                  <ng-container *ngIf="shouldShowEllipsis(workspaceName || item.workspaceName); else fullWorkspace">
                    <span [ngbTooltip]="workspaceName || item.workspaceName">
                      {{ (workspaceName || item.workspaceName) | slice:0:10 }}...
                    </span>
                  </ng-container>
                  <ng-template #fullWorkspace>
                    {{ workspaceName || item.workspaceName }}
                  </ng-template>

                  <ng-container *ngIf="campaignName || item.campaignName">
                    /
                    <ng-container *ngIf="shouldShowEllipsis(campaignName || item.campaignName); else fullCampaign">
                      <span [ngbTooltip]="campaignName || item.campaignName">
                        {{ (campaignName || item.campaignName) | slice:0:10 }}...
                      </span>
                    </ng-container>
                    <ng-template #fullCampaign>
                      {{ campaignName || item.campaignName }}
                    </ng-template>
                  </ng-container>
                </span>
              </div>
            </div>
            <div class="row" [style.height]="'45px'">
              <div class="col">
                <span class="f-15 _700 c-full-black" [ngbTooltip]="shouldShowEllipsis(item.name) ? item.name : null">
                  {{ shouldShowEllipsis(item.name) ? (item.name | slice:0:10) + '...' : item.name }}</span>
              </div>
              <div class="pointer" [style.height]="'20px'" style="position: relative; top: -5px;"
                (click)="$event.stopImmediatePropagation()" *ngIf="item.isHaveAccess">
                <div ngbDropdown class="dropdown" container="body" placement="bottom-right">
                  <button ngbDropdownToggle class="edit-button">
                    <i class="fa fa-cog fa-md" [style.color]="'#898D98'" aria-hidden="true"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-left arrow_box" aria-labelledby="dropdownMenuLink">
                    <div class="col options hover-text">
                      <div ngbDropdownItem *ngIf="!workspaceId" class="col pointer p-0 d-flex align-items-center my-3 "
                        (click)="gotoWorkspace(item)">
                        <i class="_500 fa fa-home fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                        <span class="ml-2 f-10 _700 text-uppercase"> {{ 'gotoWorkspace' | translate }}</span>
                      </div>
                      <div ngbDropdownItem *ngIf="item.campaignName" class="col pointer p-0 d-flex align-items-center my-3 "
                        (click)="gotoCampaign(item)">
                        <i class="_500 fa fa-rocket fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                        <span class="ml-2 f-10 _700 text-uppercase"> {{ 'gotoCampaign' | translate }} </span>
                      </div>
                      <div ngbDropdownItem class="col pointer pl-0 d-flex align-items-center my-3 " (click)="editProject(item)">
                        <i class="_500 fa fa-pencil fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                        <span class="ml-2 f-10 _700"> {{'settings' | translate}}</span>
                      </div>
                      <div ngbDropdownItem class="col pointer p-0 d-flex align-items-center my-3"
                        (click)="!item.isFavorite ? markAsFavorite(item) : removeFavorite(item) ">
                        <i *ngIf="!item.isFavorite" class="_500 fa fa-star-o fa-lg" [style.color]="'#181E39'"
                          aria-hidden="true"></i>
                        <i *ngIf="item.isFavorite" class="_500 fa fa-star fa-lg " [style.color]="'#ffca4c'"
                          aria-hidden="true"></i>
                        <span class="ml-2 f-10 _700"> {{ !item.isFavorite ? 'MARK AS FAVORITE' : 'REMOVE FAVORITE' }}
                        </span>
                      </div>
                      <div ngbDropdownItem class="col pointer p-0 d-flex align-items-center my-3" (click)="archiveRestoreProject(item)"
                        *ngIf="(item.status === projectStatusList.Completed || item.status === projectStatusList.ARCHIVED) &&
                      (isAdminUser || isAccountOwner || item.manager == authUser.username)">
                        <img [style.color]="'#181E39'" width="15" height="12" src="/assets/svg/archive-svg.svg" alt="">
                        <span class="ml-2 f-10 _700"> {{!item.deleted ? 'ARCHIVE' : 'UNARCHIVE'}} </span>
                      </div>
                      <div ngbDropdownItem
                        *ngIf="item.status === projectStatusList.ARCHIVED && (isAdminUser || isAccountOwner || item.manager == authUser.username)"
                        class="col pointer p-0 d-flex align-items-center my-3" (click)="deleteProject(item)">
                        <i class="_500 fa fa-trash-o fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                        <span class="ml-2 f-10 _700"> DELETE </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row date-section" *ngIf="item.isHaveAccess">
              <div class="col-12 pr-0">
                <div class="d-flex col p-0">
                  <div [style.width]="'35px'"> <span
                      class="d-flex f-10 _700 c-full-black text-uppercase my-1">Start</span></div>
                  <div> <span class="f-10 _700 ml-1">{{item.startDate | date:'dd.MM.YYYY'}}</span> </div>
                </div>
                <div class="d-flex col p-0">
                  <div [style.width]="'35px'"><span class="d-flex f-10 _700 c-full-black text-uppercase my-1">end</span>
                  </div>
                  <div><span class="f-10 _700 ml-1">{{ (item.completionDate || item.plannedEndDate) |
                      date:'dd.MM.YYYY'}}</span></div>
                </div>
              </div>
              <div class="col-12 pr-0" (click)="$event.stopImmediatePropagation()">
                <div ngbDropdown class="d-flex align-items-center" [dropdownClass]="'status-'+item.id"
                  [autoClose]="true" #projectStatusDropdown>
                  <button
                    [disabled]="(!isAdminUser && !isAccountOwner && item.manager !== authUser.username ) || item.status === projectStatusList.ARCHIVED"
                    (click)="$event.stopImmediatePropagation()" type="button"
                    class="d-flex align-items-center btn project-status-dropdown pd-5" ngbDropdownToggle>
                    <div class="d-flex align-items-center project-status {{item.status}}">
                      <div class="project-union-icon-container">
                        <i class="project-union-icon mtm-icon mtm-icon-union-{{projectColorList[item.status]}}"></i>
                      </div>
                      <span class="mr-2 status-text">{{'task_'+item.status|translate}}</span>
                      <i class="fa fa-chevron-down ml-auto pointer"></i>
                    </div>
                  </button>
                  <div class="project-dropdown-menu" ngbDropdownMenu>
                    <div *ngIf="!item.status||item.status==projectStatusList.Completed"
                      (click)="$event.stopImmediatePropagation();changeProjectStatus(item,projectStatusList.InProgress)"
                      class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                      <div class="d-flex align-items-center project-status-display IN_PROGRESS">
                        <i class="project-status-circle-color mr-2"></i>
                        <span>{{'task_IN_PROGRESS'|translate}}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="item.status==projectStatusList.InProgress||item.status==projectStatusList.UpcomingExpiry||item.status==projectStatusList.Delayed"
                      (click)="$event.stopImmediatePropagation();changeProjectStatus(item,projectStatusList.Completed)"
                      class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                      <div class="d-flex align-items-center project-status-display COMPLETED">
                        <i class="project-status-circle-color mr-2"></i>
                        <span>{{'task_COMPLETED'|translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #searchPanel>
  <div class="d-flex flex-column flex-md-row align-items-center search-panel">
    <div class="position-relative search-container">
      <input [(ngModel)]="projectSearch" class="search-box pl-2 pr-4" (ngModelChange)="searchProject()" type="text"
        placeholder="{{'search' | translate}}" />
      <i class="fa fa-search search-icon" aria-hidden="true"></i>
    </div>
  </div>
  <ng-template>