import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { contentLanguageUpdated, NotificationService } from "app/shared/services/notification.service";
import { UserService } from "../../../shared/services/user.service";
import { environment } from 'environments/environment';
import { ProjectService } from "../../../shared/services/project.service";
import { OverlayService } from "../../../shared/services/overlayService";
import { ActivatedRoute, Router } from "@angular/router";
import { CreditCardDetailsModalComponent } from "../../../payment/credit-card-details/credit-card-details-modal.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MtmDatePipe } from "../../../shared/pipes/mtm-date.pipe";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ClickableNotificationService } from "app/shared/services/clickable-notification.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { ActiveWorkspaceUpdatedEvent } from "app/shared/interfaces/workspace.interface";
import { AuthService } from "app/shared/services/auth.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { NotificationViewMode } from "app/shared/interfaces";
import { AnalyticsService } from "app/shared/services/analytics.service";
import { ProjectV2ServiceService } from "app/shared/services/project-v2-service.service";
import { DummyDataService } from "app/shared/services/dummy-data.service";
import { LorealDummyDataService } from "app/shared/services/loreal-dummy-data.service";

@Component({
	selector: 'notification-view',
	templateUrl: './notification-view.component.html',
	styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent implements OnInit, OnDestroy {

	@Input() notification: any = null;
	@Input() isNotificationProject: boolean = true;
	@Input() isSeenNotification: boolean = true;
	@Input() mode: NotificationViewMode = NotificationViewMode.List;
	@Input() showSectionBorder: boolean = false;
	@Input() duration: number;
	@Input() showCloseButton: boolean = false;
	@Output() notificationFinish: EventEmitter<any> = new EventEmitter();

	get avatarSize(): number {
		return this.mode == NotificationViewMode.List ? 50 : 35;
	}

	notificationDisplay: any = null;
	hasName = true;
	hasObject = true;
	ngUnsubscribe = new Subject();
	nameVisible: boolean = false;
	myWorkspaces: any[] = [];
	otherWorkspaces: any[] = [];
	filteredWorkspaces: any[] = [];
	authUser: any = null
	workspaces: any[] = [];
	workspaceData: any;
	workspaceName: string;
	showAllWorkspaces: boolean = false;
	private project = null;
	projectMoved: boolean = false;

	interval: any;
	viewOpacity: any = 1;

	NotificationViewType = NotificationViewMode;

	// check user
	isHrUser: boolean = false;
	isYslUser: boolean = false;
	userType: string;
	lorealUser :boolean = false;

	constructor(private notificationService: NotificationService,
		public userService: UserService,
		private projectService: ProjectService,
		private activatedRoute: ActivatedRoute,
		private mtmDate: MtmDatePipe,
		private overlayService: OverlayService,
		private clickableNotificationService: ClickableNotificationService,
		private router: Router, private modalService: NgbModal,
		private workspaceService: WorkspaceService,
		private authService: AuthService,
		private translatePipe: TranslatePipe,
		private analyticService: AnalyticsService,
		private timelineService: ProjectV2ServiceService,
		private lorealDummyDataService: DummyDataService,
		private dummyDataService: LorealDummyDataService,
	) {
		contentLanguageUpdated.subscribe(lang => {
			setTimeout(() => {
				this.notification.notificationDisplay = this.notificationService.getMessage(this.notification.code, this.notification.object);
				this.notificationDisplay = this.notification.notificationDisplay;
			}, 500);
		})
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		this.workspaces = this.workspaceService.getCachedWorkspaces();
		this.filteredWorkspaces = this.workspaces;
		if (!this.notification.hasOwnProperty('object') || this.notification.object == null) {
			this.hasObject = false;
			this.hasName = false;
		} else {
			if (!this.notification.object.hasOwnProperty('sectionName'))
				this.hasName = false;
		}

		if (this.notification && this.notification.object) {
			if (this.notification.object.notifiedByUsername && !this.notification.object.notifiedByAvatarUrl) {
				this.notification.object.notifiedByAvatarUrl = environment.web.avatarUrl.replace('%USERNAME%', this.notification.object.notifiedByUsername);
				this.notificationDisplay = this.notification.notificationDisplay;
			} else
				this.notificationDisplay = this.notification.notificationDisplay;
		}
		//this.notificationService.changeMessage(this.notificationDisplay.label);
		//hiding project name when project is moved from workspace to another workspace
		if (this.notification.projectId === '00000000-0000-0000-0000-000000000000') {
			this.projectMoved = true;
		} else {
			this.projectMoved = false;
		}
		if (this.mode == this.NotificationViewType.Toast) {
			this.startToastNotification();
		}
	}

	mouseEnter() {
		this.nameVisible = true;
	}

	mouseLeave() {
		this.nameVisible = false;
	}

	openDownpaymentModal() {
		const projectId = this.notification.projectId;
		const proposalId = this.notification.object.proposalId;

		if (this.projectService.project.id == projectId && this.projectService.projectOnloaded)
			this.navigateToDownPaymnetPageAfterCheck(projectId, proposalId, this.projectService.project)
		else
			this.projectService.getProject(projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((project) => this.navigateToDownPaymnetPageAfterCheck(projectId, proposalId, project));
	}

	navigateToDownPaymnetPageAfterCheck(projectId: string, proposalId: string, project: any) {
		if (project.downPaymentPaid) {
			this.overlayService.showWarning('Downpayment already paid on ' + this.formatDate(project.downPaymentPayDate));
			return;
		}

		this.router.navigate(['projects', projectId, "projectpayment", "downpayment", proposalId]);
	}

	onPaymentEvent() {
		let projectId = this.notification.projectId;
		this.projectService.getProject(projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((project) => {
			this.projectService.getAllProjectProposals(projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(proposals => {

				if (project.finalPaymentPaid) {
					this.overlayService.showWarning('Finalpayment already paid on ' + this.formatDate(project.finalPaymentPayDate));
					return;
				}
				if (project.downPaymentPaid == false) {
					this.openDownpaymentModal();
				}
				else {
					let modal = this.modalService.open(CreditCardDetailsModalComponent, { size: 'lg', windowClass: 'company-matched-blur-color', backdrop: false });
					modal.componentInstance.projectId = projectId;

					modal.componentInstance.projectType = project.projectType;
					modal.componentInstance.paymentType = 'FINAL_PAYMENT';

					proposals.forEach(proposal => {
						if (proposal.status == 'ACCEPTED')
							modal.componentInstance.selectedProposal = proposal;
					});

					modal.result.then(res => { this.projectService.prepareProjectInit(projectId); })
				}
			});
		})

	}

	formatDate(dateTime) {
		return this.mtmDate.transform(dateTime);
	}

	onNotificationClick(e: any, notification: any) {
		e.preventDefault();
		if (notification.projectId === '00000000-0000-0000-0000-000000000000') {
			this.workspaceName = notification.object.workspaceName;
			this.popularWorkspaceGroups();
			this.workSpaceNavigation(notification);
		}
		const projectId = notification?.projectId || notification?.object?.projectId;
		if (!projectId) {
			return;
		}
		if (this.projectService.project && this.projectService.project.id && (this.projectService.project.id === projectId)) {
			this.clickableNotificationService.gotoNotificationSource(this.projectService.sections, notification);
		} else {
			this.projectService.leaveFromProject();
			this.projectService.getSectionsAndSubsections(projectId)
				.subscribe(res => {
					this.clickableNotificationService.gotoNotificationSource(res, notification);
				});
		}

	}

	/**
	 * @param notification setting routes for workspace
	 */
	workSpaceNavigation(notification: any) {
		//when user create a new workspace
		if (notification.code === 'WORKSPACE_CREATED') {
			if (this.workspaceData !== undefined) {
				this.setActiveWorkspace(this.workspaceData);
			} else {
				this.router.navigate(['/projects'])
			}
		}
		// when user added to workspace redirect to workspace
		if (notification.code === 'WORKSPACE_ADDED_USER') {
			if (this.workspaceData !== undefined) {
				this.setActiveWorkspace(this.workspaceData);
			} else {
				this.openWorkspaceList();
			}
		}
		//when user deleted from a workspace
		if (notification.code === 'WORKSPACE_DELETED_USER') {
			this.router.navigate(['/projects'])
		}
		//when user role change
		if (notification.code === 'WORKSPACE_EDITED_USER') {
			this.router.navigate(['/projects'])
		}
		//when workspace owner change name of workspace
		if (notification.code === 'WORKSPACE_EDITED') {
			if (this.workspaceData !== undefined) {
				this.setActiveWorkspace(this.workspaceData);
			} else {
				this.openWorkspaceList();
			}
		}
		// workspace deleted
		if (notification.code === 'WORKSPACE_DELETED') {
			this.notificationService.open({
				title: this.translatePipe.transform('successful'),
				notificationType: 'success',
				centerHeader: true,
				notificationInfo: this.translatePipe.transform('notification_workspace_delete'),
				confirmBtn: this.translatePipe.transform('ok')
			});
		}
		// When project moved to new workspace
		if (notification.code === 'WORKSPACE_MOVED_PROJECT') {
			this.projectMoved = false;
			if (this.workspaceData !== undefined) {
				this.setActiveWorkspace(this.workspaceData);
			} else {
				this.openWorkspaceList();
			}
		}
	}

	/**
	 * for redirecting when workspace name is changes or it not found
	 */
	openWorkspaceList() {
		this.project = {};
		this.showAllWorkspaces = true;
		this.workspaceService.unsetActiveWorkspace();
		this.router.navigate(['/projects']);
	}

	/**
	 *  redirecting user to the workspace
	 * @param workspace
	 */
	setActiveWorkspace(workspace: any) {
		this.router.navigate(['/projects']);
		this.workspaceService.setActiveWorkspace(workspace);
	}

	/**
	 *
	 * @returns worskpace data for setting active workspace
	 */
	private popularWorkspaceGroups() {
		if (!this.filteredWorkspaces) {
			this.myWorkspaces = [];
			this.otherWorkspaces = [];
			return;
		}
		this.myWorkspaces = this.filteredWorkspaces.filter(w => w.companyId == this.authUser.company.id);
		const myWorkSpacedata = this.myWorkspaces.filter(val => val.name === this.workspaceName);
		this.workspaceData = myWorkSpacedata[0];
	}

	startToastNotification() {
		this.interval = setInterval(() => {
			this.closeNotification();
		}, this.duration);
	}

	closeNotification() {
		if (this.interval) {
			clearInterval(this.interval);
			this.viewOpacity = 0;
			this.notificationFinish.emit(this.notification);
		}
	}

	onWokspaceV2NotificationClick(event, notification) {
		console.log('notification', notification)
		const notifCheck = notification.notificationDisplay.label;
		this.lorealDummyDataService.filteredProjects.forEach(project => {
			project.created = true;
			// if (project.id === notification.projectId) {
			// }
		});
		if (notifCheck == "Task has been completed" && notification.username == "testim+scenario2.hr@mtm.video" || notification.username == "testim+scenario2.ysl@mtm.video") {
				const projects = this.lorealDummyDataService.filteredProjects;
				console.log('projects', projects)
				projects.forEach(element => {
					console.log('element', element)
					if (element.id == notification.projectId) {
						element.timelines.timelines.forEach(timeline => {
							timeline.items.forEach(milestone => {
								if (milestone.name == "HERO SHOOT") {
									this.timelineService.selectedDCmilestone.next({name: milestone.name, color:'#55C982',items: milestone.items, isDCtimeline: true})
									milestone["statusColor"] = '#55C982';
									milestone["status"] = 'Completed';
									milestone["projectId"] = element.id
									this.lorealDummyDataService.selectedMilestone = milestone;
								}
							});
						});
					}
				});
			this.router.navigate(['workspaces/campaigndrive/5806860095/dashboard'], { queryParams: { 'from': 'notification' } });
		}

		if (notifCheck == "You are invited on a new" || notifCheck == "You are invited on") {
			if (notification.username == "testim+scenario2.hr@mtm.video" || notification.username == "testim+scenario2.ysl@mtm.video") {
				this.router.navigate(['workspaces/analytics/all-milestones']);
			} else {
				this.router.navigate(['workspaces/campaigndrive/recap'], { queryParams: { 'from': 'notification' } });
			}
		}

		if (notifCheck == "There is an issue with") {
			this.router.navigate(['/workspaces/campaigndrive/5806860095/dashboard'], { queryParams: { 'milestone': 'GO KEY VISUALS' } });
		}

		if (notifCheck == "The Hero Shoot task you created have been completed") {
			const projects = this.lorealDummyDataService.filteredProjects;
				console.log('projects', projects)
				projects.forEach(element => {
					console.log('element', element)
					if (element.id == notification.projectId) {
						element.timelines.timelines.forEach(timeline => {
							timeline.items.forEach(milestone => {
								if (milestone.name == "HERO SHOOT") {
									this.timelineService.selectedDCmilestone.next({name: milestone.name, color:'#55C982',items: milestone.items, isDCtimeline: true})
									milestone["statusColor"] = '#55C982';
									milestone["status"] = 'Completed';
									milestone["projectId"] = element.id
									this.lorealDummyDataService.selectedMilestone = milestone;
								}
							});
						});
					}
				});
			this.router.navigate(['/workspaces/analytics/all-milestones']);
		}
	}

	getProjectName(notification){
		if (notification && notification.object && notification.object.projectName) {
			const projectName = notification.object.projectName;
			const shortenedName = projectName.length > 20 ? projectName.slice(0, 20) + '...' : projectName;
			return shortenedName;

		  } else {
			return ;
			// return console.log("Notification object or projectName is undefined.");
		  }
	}
}
