import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'selector-permissions-info-modal',
    templateUrl: './permissions-info-modal.component.html',
    styleUrls: ['./permissions-info-modal.component.scss']
})
export class PermissionsInfoModal {
    @Input() CLIENTorPROD: string = 'PROD';

    private productionPrmissionsInfo = [
        { label: 'rolePRODUCTION_OWNER', weight: 5 },
        { label: 'rolePRODUCTION_MANAGER', weight: 4 },
        //{ label: 'rolePRODUCTION_SUPERVISOR', weight: 3 },
        //{ label: 'rolePRODUCTION_SENIOR_STAFF', weight: 2 },
        { label: 'rolePRODUCTION_STAFF', weight: 1 }
    ];

    private clientPrmissionsInfo = [
        { label: 'rolePROJECT_OWNER', weight: 5, brandTeam: true, isEndOfBrand: false },
        { label: 'rolePROJECT_MANAGER', weight: 4, brandTeam: true, isEndOfBrand: false },
        //{ label: 'rolePROJECT_SUPERVISOR', weight: 3, brandTeam: true, isEndOfBrand: false },
        //{ label: 'rolePROJECT_SENIOR_STAFF', weight: 2, brandTeam: true, isEndOfBrand: false },
        { label: 'rolePROJECT_STAFF', weight: 1, brandTeam: true, isEndOfBrand: true },
        { label: 'rolePRODUCTION_OWNER', weight: 5, brandTeam: false, isEndOfBrand: false },
        { label: 'rolePRODUCTION_MANAGER', weight: 4, brandTeam: false, isEndOfBrand: false },
        //{ label: 'rolePRODUCTION_SUPERVISOR', weight: 3, brandTeam: false, isEndOfBrand: false },
        //{ label: 'rolePRODUCTION_SENIOR_STAFF', weight: 2, brandTeam: false, isEndOfBrand: false },
        { label: 'rolePRODUCTION_STAFF', weight: 1, brandTeam: false, isEndOfBrand: false }
    ];

    viewList: any[] = [];

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
        this.init();
    }

    ngOnChanges() {
        this.init();
    }

    private init() {
        switch (this.CLIENTorPROD) {
            case 'CLIENT': this.viewList = this.clientPrmissionsInfo; break;
            case 'PRODUCTION': this.viewList = this.productionPrmissionsInfo; break;
            default: this.viewList = this.productionPrmissionsInfo; break;
        }
    }

}
