import { AfterViewInit, Component, inject, OnDestroy, OnInit } from "@angular/core";
import {
  FileTransferService,
  listenerFileUploadLoaded, listenerUploadProgressChanged, listenerUploadResumeFailed,
  listenerUploadStatusChanged,
  MTMCustomFile,
  TransferStatus
} from "app/shared/services/signed-url";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmitterService } from "app/shared/services/emitter.service";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import * as moment from "moment";

@Component({
  selector: 'mtm-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss']
})
export class UploadPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  transferService = inject(FileTransferService);
  overlayService = inject(OverlayService);
  translatePipe = inject(TranslatePipe);
  ngUnsubscribe = new Subject<boolean>();
  filesToUpload: MTMCustomFile[] = [];
  isExpanded: boolean = true;
  caption: string = '';
  captionParams: any = {};
  inProgressStatusList = [TransferStatus.NEW, TransferStatus.WAITING, TransferStatus.SENDING];
  remainingTimeText: string = '';

  ngOnInit() {
    listenerUploadStatusChanged.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.syncFilesToUpload();
        this.updateCaption();
        this.syncRemainingTime();
      }
    });

    listenerUploadResumeFailed.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (file) => {
        this.transferService.cancelUpload(file);
        this.overlayService.showError(this.translatePipe.transform('fileTransfer_cantResumeUpload'));
      }
    });

    listenerFileUploadLoaded.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (files) => {
        this.syncFilesToUpload();
        this.updateCaption();
        this.syncRemainingTime();
      }
    });

    listenerUploadProgressChanged.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      console.log('progress triggered');
      this.syncRemainingTime();
    });

    EmitterService.get('user.logout').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.transferService.pauseAllTransfers();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.transferService.loadActiveUploads()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => { })
  }

  private syncFilesToUpload() {
    const filesToUpload = [...this.transferService.filesToUpload];
    filesToUpload.forEach(file => {
      const fileType = file.type || file.fileType;
      let mime = fileType.substring(fileType.lastIndexOf("/"));
      if (!file.iconUrl) {
        file.iconUrl = '/assets/img/icons/' + mime + '.svg';
      }
    });
    this.filesToUpload = filesToUpload;
  }

  toggleExpanded(event: MouseEvent) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }

  updateCaption() {
    this.captionParams = {};
    if (this.filesToUpload.some(file => this.inProgressStatusList.includes(file.uploadStatusCode))) {
      this.caption = 'fileTransfer_uploadInProgress';
      return;
    }
    const statusList = new Set(this.filesToUpload.map(file => file.uploadStatusCode));
    if (statusList.has(TransferStatus.COMPLETED)) {
      const totalCompleted = this.filesToUpload.filter(file => file.uploadStatusCode === TransferStatus.COMPLETED).length;
      this.caption = `fileTransfer_uploadCompleted`;
      this.captionParams = { count: totalCompleted };
    } else if (statusList.has(TransferStatus.PAUSED)) {
      this.caption = 'fileTransfer_uploadPaused';
    } else if (statusList.has(TransferStatus.ERROR)) {
      this.caption = 'fileTransfer_uploadFailed';
    } else if (statusList.has(TransferStatus.INACTIVE)) {
      this.caption = 'fileTransfer_uploadInactive';
    } else if (statusList.has(TransferStatus.CANCELED)) {
      this.caption = 'fileTransfer_uploadCanceled';
    }
    return;
  }

  private syncRemainingTime() {
    const hour = 3600 * 1000;
    const minute = 60000;
    const second = 1000;
    let remaining = 0;
    this.filesToUpload.forEach(file => {
      if (!file.isResumable() && file.uploadStatusCode === TransferStatus.SENDING
        && !!file.extraInfo.remainingTime && file.extraInfo.remainingTime > remaining) {
        remaining = file.extraInfo.remainingTime;
      }
    });
    if (remaining > 0) {
      const duration = moment.duration(remaining);
      if (remaining >= hour) {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_hoursRemaining', { remaining: Math.floor(duration.asHours()) });
      } else if (remaining >= minute) {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_minutesRemaining', { remaining: Math.floor(duration.asMinutes()) });
      } else {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_minutesRemaining', { remaining: 1 });
      }
    } else {
      this.remainingTimeText = '';
    }
  }
}
