<ng-template ngbModalContainer></ng-template>
<mtm-banner [bannerType]="bannerTypes.MaintenanceOnly"></mtm-banner>


<mtm-loreal-layout [themeOptions]="themeOptions" [headerInfo]="headerInfo" [userProfile]="userProfile"
  (projectNavigationClick)="gotoProject()" *ngIf="hasLtgPermission"></mtm-loreal-layout>
<mtm-standard-layout [themeOptions]="themeOptions" [headerInfo]="headerInfo" [userProfile]="userProfile"
  (projectNavigationClick)="gotoProject()" *ngIf="!hasLtgPermission"></mtm-standard-layout>

<selector-file-reorder-modal-component *ngIf="serviceFileReorder.isFileReorderShowState">
</selector-file-reorder-modal-component>
<mtm-subtitle-uploader-container></mtm-subtitle-uploader-container>
<mtm-comment-file-uploader-container></mtm-comment-file-uploader-container>
<mtm-ai-framing-folder-builder-container></mtm-ai-framing-folder-builder-container>
<mtm-ai-framing-movie-builder-container></mtm-ai-framing-movie-builder-container>
