<div class="component-wrapper ltg p-3">
	<div class="card w-100 p-3" [formGroup]="projectCompanyForm" style="border: unset">
		<div class="general-information-container mb-3">
			<label class="header-title _700 mb-3">{{'General Informations'|translate}}</label>
			<div class="project-info d-flex flex-column flex-md-row">
				<div class="project-cover mx-auto ml-md-auto mb-3">
					<div class="d-flex align-items-center edit-nav w-100 h-100">
						<mtm-upload-file class="w-100 h-100" [preDefinedFilter]="['IMAGE_FILTER']"
							[isRoundedCircle]=false [isRedirectImageLoadError]=false
							[imageURL]=profilesInfo.projectProfileImage (files)="fileChangeListener($event, 'PROJECT')"
							[disabled]="isReadMode">
						</mtm-upload-file>
					</div>
				</div>
				<div class="project-info-detail d-flex flex-column w-100">
					<div class="d-flex align-items-center mb-3" *ngIf="!campaignId">
						<div class="d-flex flex-column pl-0 pl-md-3 w-100"
							[ngClass]="{'has-danger': isValid('workspaceId')}">
							<div class="form-label _700 mb-2">{{'Workspace'|translate}}</div>
							<div class="w-100">
								<mtm-dropdown [form]="projectCompanyForm"
									[isDisabled]="isReadMode || uneditableWorkspace" [items]="companyWorkspaces"
									[itemValueAccessor]="'id'" [itemLabelAccessor]="'name'" [isMultipleSelect]="false"
									[refObject]=this [formControlAccessor]="'workspaceId'"
									[isDangerBorder]="isValid('workspaceId')">
								</mtm-dropdown>
							</div>
						</div>
					</div>
					<div class="d-flex align-items-center flex-column flex-md-row mb-3">
						<div class="d-flex flex-fill flex-column pl-0 pl-md-3 mb-3 mb-md-0 w-100"
							[ngClass]="{'has-danger': isValid('projectName')}">
							<div class="form-label _700 mb-2">{{'Project Name'|translate}}</div>
							<input [ngModel]="" class="form-control project-name" formControlName="projectName"
								placeholder="{{'Project Name'|translate}}" type="text">
						</div>
						<div class="d-flex flex-column pl-0 pl-md-3 milestone-control"
							*ngIf="hasLtgPermission && !standaloneProject">
							<div class="form-label _700 text-nowrap">{{'Related Milestone'|translate}}</div>
							<select class="select-workspace mt-1 w-100" formControlName="milestoneId">
								<option class="text-uppercase" [value]="emptyId">{{'No Milestone' | translate}}</option>
								<option *ngFor="let milestone of milestones" [value]="milestone.id">{{ milestone.name
									}}</option>
							</select>
						</div>
					</div>
					<div class="d-flex align-items-center flex-column flex-lg-row pl-0 pl-md-3 w-100 mb-md-3">
						<div class="d-flex flex-column project-start-date-container"
							[ngClass]="{'has-danger': isValidDate('startDate')}">
							<div class="form-label _700 mb-2">{{'Start Date'|translate}}</div>
							<div class="input-group h-100">
								<input class="form-control h-100 date-picker" [container]="'body'" ngbDatepicker
									formControlName="startDate" placeholder="{{'Start Date'|translate}}"
									#startDate="ngbDatepicker" firstDayOfWeek="1" (click)="startDate.toggle()">
								<div class="input-group-addon d-flex flex-column justify-content-center"
									(click)="startDate.toggle()">
									<img src="'../../../../../assets/img/square-calendar.svg" alt="">
								</div>
							</div>
						</div>
						<div class="d-flex flex-column pl-3 project-start-date-container"
							[ngClass]="{'has-danger': isValidDate('plannedEndDate')}">
							<div class="form-label _700 mb-2">{{'End Date'|translate}}</div>
							<div class="input-group h-100">
								<input class="form-control date-picker" formControlName="plannedEndDate"
									[container]="'body'" ngbDatepicker #due="ngbDatepicker"
									placeholder="{{'End Date'|translate}}" firstDayOfWeek="1">
								<div class="input-group-addon d-flex flex-column justify-content-center"
									(click)="due.toggle()">
									<img src="'../../../../../assets/img/square-calendar.svg" alt="">
								</div>
							</div>
						</div>
						<div class="d-flex flex-column pl-3 project-start-date-container"
							[ngClass]="{'has-danger': isValid('manager')}">
							<div class="form-label _700 mb-2">{{ 'Project Leader' | translate }}</div>

							<!-- Prefilled Manager's Name with Avatar for New Projects -->
							<div *ngIf="isNew" class="d-flex flex-column" [class.disabled]="isNew">
								<div class="form-field col-section"
									[ngClass]="{'has-danger': isValid('manager'), disabled: !isNew }">
									<div class="d-flex align-items-center p-2 border" style="border-radius: 4px;">
										<div class="collaborator-avatar mr-2 d-flex align-items-center">
											<img class="participant-img" *ngIf="selectedManager?.avatarUrl"
												[src]="selectedManager.avatarUrl" alt=""
												style="border-radius: 50%; width: 30px; height: 30px;">
											<span *ngIf="!selectedManager?.avatarUrl"
												class="c-full-white participant-img text-uppercase"
												[style.backgroundColor]="timelineService.getColorFromName(selectedManager?.fullName)"
												style="border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">
												{{ selectedManager?.fullName | initialName }}
											</span>
										</div>
										<div>
											{{ selectedManager?.fullName }}
										</div>
									</div>
								</div>
							</div>

							<!-- Dropdown for Editing Existing Projects -->
							<div *ngIf="!isNew" class="d-flex flex-column" formControlName="manager">
								<div class="form-field col-section" [ngClass]="{'has-danger': isValid('manager')}">
								  <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown [class.disabled]="isReadMode">
									<div 
									  class="mt-auto d-flex align-items-center py-1 px-2 w-100 border"
									  [ngClass]="{'pointer': !isReadMode}" 
									  style="border-radius: 4px;" 
									  ngbDropdownToggle 
									  [attr.disabled]="isReadMode ? true : null"
									>
									  <div class="flex-fill mr-1">
										<div class="task-collaborators">
										  <div class="task-collaborator-wrapper d-flex align-items-center">
											<div *ngIf="!!selectedManager" class="collaborator-avatar mr-2 d-flex align-items-center">
											  <img 
												class="participant-img" 
												*ngIf="selectedManager.avatarUrl" 
												[src]="selectedManager.avatarUrl" 
												alt="" 
												style="border-radius: 50%; width: 30px; height: 30px;"
											  >
											  <span 
												*ngIf="!selectedManager.avatarUrl"
												class="c-full-white participant-img text-uppercase"
												[style.backgroundColor]="timelineService.getColorFromName(selectedManager.fullName)"
												style="border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;"
											  >
												{{ selectedManager.fullName | initialName }}
											  </span>
											  <span class="ml-2">{{ selectedManager?.fullName }}</span>
											</div>
											<div *ngIf="!selectedManager" class="collaborator-avatar mr-2 d-flex align-items-center">
											  {{ 'Select manager...' | translate }}
											</div>
										  </div>
										</div>
									  </div>
									  <i class="fa fa-chevron-down ml-auto pointer mr-1" *ngIf="!isReadMode"></i>
									</div>
							  
									<!-- Dropdown menu should only be visible and clickable if isReadMode is false -->
									<div *ngIf="!isReadMode" class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
									  <div class="p-1 w-100">
										<input type="text" placeholder="{{ 'Search' | translate }}" class="search-text form-control" (keyup)="searchParticipants($event)" />
									  </div>
									  <div 
										*ngFor="let user of displayedUsers" 
										(click)="projectLeaderSelected(user)"
										class="d-flex align-items-center pointer" 
										[ngClass]="{ 'selected': user.username == selectedManager?.username }" 
										ngbDropdownItem
									  >
										<div class="d-flex align-items-center collaborator-selected-flag mr-2">
										  <i class="fa fa-check"></i>
										</div>
										<img 
										  class="participant-img" 
										  *ngIf="user.avatarUrl" 
										  [src]="user.avatarUrl" 
										  alt="" 
										  style="border-radius: 50%; width: 30px; height: 30px;"
										>
										<span 
										  *ngIf="!user.avatarUrl"
										  class="c-full-white participant-img text-uppercase"
										  [style.backgroundColor]="timelineService.getColorFromName(user.fullName)"
										  style="border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;"
										>
										  {{ user.fullName | slice: 0: 2 }}
										</span>
										<span class="ml-2 _600">{{ user.fullName }}</span>
									  </div>
									</div>
								  </div>
								</div>
							  </div>  
						</div>
					</div>
					<div class="d-flex flex-column mb-3 pl-0 pl-md-3"
						[ngClass]="{'has-danger': isValid('description')}">
						<div class="form-label _700 mb-2">{{'Description' | translate}}</div>
						<textarea formControlName="description" [disabled]="isReadMode" class="form-control description"
							type="text" placeholder="{{'Description' | translate}}"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="project-stages d-flex flex-column" *ngIf="!isNew && project?.id">
			<label class="header-title _700 mb-1">{{'Project Stages'|translate}}</label>
			<label class="header-info _700 mb-3">{{'projectStageInfo'|translate}}</label>
			<mtm-project-stages></mtm-project-stages>
		</div>
		<div class="w-100 footer-content">
			<div *ngIf="!isCompanyProfiles" class="d-flex flex-column flex-md-row w-100">
				<button *ngIf="service.project.id"
					class="btn btn-lg border-dark btn-light mr-2 mb-3 mb-md-0 text-uppercase" type="button"
					(click)="returnToProject()">
					<span>{{'Return to projects' | translate}}</span>
				</button>

				<button class="btn btn-lg btn-dark ml-md-auto text-uppercase mb-3 mb-md-0" type="button"
					(click)="onEdit()" *ngIf="isReadMode">
					<span>{{'edit' | translate}}</span>
				</button>

				<button class="btn b-mtm-black c-mtm-full-white ml-md-auto text-uppercase mb-3 mb-md-0" type="button"
					(click)="onSaveAndContinue(true)" [disabled]="sending" *ngIf="isNew && ! isReadMode">
					<span>{{'next' | translate }}</span>
				</button>

				<ng-container *ngIf="!isNew && ! isReadMode">
					<button *ngIf="service.project.id"
						class="btn btn-lg border-dark btn-light mr-2 mb-3 mb-md-0 text-uppercase" type="button"
						(click)="onCancelChanges()">
						<span>{{'cancel' | translate}}</span>
					</button>

					<button class="btn b-mtm-black c-mtm-full-white ml-md-auto text-uppercase" type="button"
						(click)="onSaveChanges()" [disabled]="sending">
						<span>{{'saveChanges' | translate }}</span>
					</button>
				</ng-container>
			</div>
		</div>
	</div>
</div>