export interface WorkspaceDTO {
	companyId: string;
	id?: string;
	name: string;
	projectIds?: string[];
	usernames?: string[];
	coverUrl?: string;
  avatarUrl?: string;
  defaultWorkspace?: boolean;
}

export const WorkspaceUpdatedEvent = 'workspace.updated';
export const WorkspaceAvatarUpdatedEvent = 'workspace.avatarUpdated';
export const WorkspaceCoverUpdatedEvent = 'workspace.coverUpdated';
export const ProjectWorkspaceUpdatedEvent = 'workspace.projectWorkspaceUpdated';
export const ActiveWorkspaceUpdatedEvent = 'activeWorkspace.updated';
export const CachedWorkspacesUpdatedEvent = 'workspace.cachedWorkspacesUpdated';
export const CachedWorkspaceProjectsUpdatedEvent = 'workspace.cachedProjectsUpdated';
export const ComapanyCoverUpdatedEvent = 'company.coverUpdated';
export const WorkspaceDeleted = 'workspace.deleted';
