import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * This component is used to display a modal to the user indicating that they have been logged out
 * automatically due to inactivity.
 */
@Component({
  selector: 'warning-loggedout-modal',
  styleUrls: ['./warning-loggedout-modal.component.scss'],
  templateUrl: './warning-loggedout-modal.component.html'
})
export class WarningLoggedoutModalComponent {
  @Input() logoutMessageKey: string;
  constructor(public activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}