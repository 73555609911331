import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { EmitterService } from "app/shared/services/emitter.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PreviewAllAssetComponent } from "./preview-all-asset/preview-all-asset.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from 'lodash';
import { SubsectionService } from "app/shared/services/subsection.service";
import { MTMFileDownload } from "app/shared/services/signed-url/mtm-file-download";
import { FileTransferService } from "app/shared/services/signed-url";
import { HelperService } from "app/shared/services/helper.service";
import { AuthService } from "app/shared/services/auth.service";

@Component({
	selector: 'mtm-preview-asset',
	styleUrls: ['./preview-asset.component.scss'],
	templateUrl: './preview-asset.component.html'
})
export class PreviewAssetComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {
	@Input() asset: any;
	@Input() index: any;
	@Input() isEditSection: boolean = false;
	@Input() selectedIndex: number;
	@Input() exportFormatPreview: boolean = false;
	@Input() hideTitle: boolean = false;
	@Input() showEmptyMediaIcon: boolean = false;
	@Input() emptyMediaMessage: string = 'Add Media';
	@Input() showFileIcon: boolean = false;
	@Output() onAssetClicked = new EventEmitter<any>();
	@Input() lazyLoading: boolean = false;
	@Input() singleMode: boolean = false;
	@Input() backgroundColor: string = "#444464";
	@Input() selectedVersionNumber: any;
	@Input() showFolderIfEmpty: boolean = false;
	@Input() hasDownloadSupport: boolean = false; //if enabled, show download dropdown for pdf files which have been transcoded
	@Output() currentVersionChange: EventEmitter<any> = new EventEmitter<any>();
	ngUnsubscribe = new Subject();
	isVersionChanged: boolean = false;
	changedVersionFiles: any;
	changedVersionFilesData: any[] = [];
	versionBackup: any[] = [];
	changedVersionFilesOptionId: string;
	versionNumber: Object;
	assetLocalId: number;
	versionElemntId: number;
	selectedVersion: any;

	constructor(
		private authService: AuthService,
		private modalService: NgbModal,
		private subsectionService: SubsectionService,
		private transferService: FileTransferService
	) {
	}

	ngOnInit(): void {
		// Init
		this.subsectionService.getSelectedVersion.subscribe((data) => { this.isVersionChanged = data.versionChanged });
		//check version in asset array
		this.checkVersion();

		if (this.asset) {

			/*
				if (this.asset || (this.asset && this.asset.animationElement == 'loading')) {
					if (!this.asset.files) {
						return;
					}
			*/
			EmitterService.get('title-updated-' + this.asset.id).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((res) => {
				this.asset = { ...this.asset, ...res };
				this.ngOnInit.call(this);
			})
			EmitterService.get('file-upload-done-type-' + this.asset.id).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(() => {
				this.ngOnInit.call(this);
			});
			if (!_.isUndefined(this.asset.files)) {
				let hasYTVideo = false;

				if (this.asset.items) {
					hasYTVideo = this.asset.items.some(i => typeof i === 'string');
				}

				if (!hasYTVideo) {
					this.asset.items = this.asset.files;
				}
			}
			if (this.asset?.items) {
				this.asset.items.forEach((item, i) => {
					if (typeof item === 'string')
						return;

					item.index = i;
					item.thumbLength = this.asset.items.length;
					item.isEditSection = this.isEditSection;
				});
				this.setActiveIndex();
			}
		}
	}

	ngOnDestroy(): void {
		// Destroy
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngAfterContentInit(): void {
		this.checkVersion();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//get selected version
		this.changeSelectedVersionFiles();
		if (changes.selectedIndex) {
			this.selectedIndex = changes.selectedIndex.currentValue;
			this.setActiveIndex();
		}
		if (changes.asset) {
			if (!this.asset.simpleVersions) {
				this.asset.simpleVersions = [];
			}
			if (!this.asset.currentVersion && this.asset.simpleVersions.length) {
				this.asset.currentVersion = this.asset.simpleVersions[0].versionNumber;
			}
		}
		if (changes.selectedVersionNumber) {
			this.selectedVersion = this.asset.simpleVersions.find(v => v.versionNumber == parseInt(changes.selectedVersionNumber.currentValue, 10)) || this.asset.simpleVersions[0];
		}
	}

	// changing view of selected assest
	changeSelectedVersionFiles() {
		this.subsectionService.getSelectedVersion.subscribe((data) => {
			this.isVersionChanged = data.versionChanged
			this.changedVersionFiles = data.changedVersionFiles;
			this.changedVersionFilesData = [data.changedVersionFiles]
			this.versionElemntId = data.versionelementId
			if (this.isVersionChanged && this.asset.localId == data.versionelementId) {
				this.asset.items = Object.assign([], data.changedVersionFiles);
				this.asset.files = Object.assign([], data.changedVersionFiles);
			}
		});
	}

	checkVersion() {
		if (this.asset?.simpleVersions?.length > 0) {
			this.assetLocalId = this.asset.localId;
			this.versionBackup = [this.asset?.simpleVersions[0]];
			this.versionNumber = [this.asset.simpleVersions[0].versionNumber];
			//this.asset.items = this.asset?.simpleVersions[0].files;
		}
	}

	setActiveIndex() {
		if (this.asset.items && this.asset.items.length >= this.selectedIndex) {
			this.asset.items.forEach((item, index) => {
				if (typeof item === 'string') {
					return;
				}
				item["isActive"] = false;
				if (index == this.selectedIndex) {
					item['isActive'] = true;
				}
			});
		}
	}

	clickAsset(index: any, item: any) {
		if (this.asset.items && this.asset.items.length > 0) {
			this.onAssetClicked.emit({
				item: item,
				index: index,
				asset: this.asset
			});
		} else if (item == null && this.lazyLoading) {
			this.onAssetClicked.emit({
				item: item,
				index: 0,
				asset: this.asset
			});
		}
	}

	showAllAssets(e) {
		e.stopImmediatePropagation();
		let modalInstance = this.modalService.open(PreviewAllAssetComponent, { modalDialogClass: 'responsive-modal' });
		modalInstance.componentInstance.asset = this.asset;

		modalInstance.result.then((data) => {
			if (data) {
				this.clickAsset(data.index, data.item);
			}
		});
	}

	versionChange(version) {
		this.selectedVersion = version;
		this.asset.currentVersion = version.versionNumber;
		this.currentVersionChange.emit({ asset: this.asset, index: this.index, item: this.asset.items?.length ? this.asset.items[0] : null });
	}

	isTranscodedOfficeFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isOfficeMimeType(item.contentType) && item.transcodeMetadata?.length > 0;
		} catch (e) {
			return false;
		}
	}

	isTranscodedImageFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isConvertibleImageFormat(item) && item.transcodeMetadata?.length > 0;
		} catch (e) {
			return false;
		}
	}

	isPowerPointFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isPowerPointMimeType(item.contentType);
		} catch (e) {
			return false;
		}
	}

	isExcelFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isExcelMimeType(item.contentType);
		} catch (e) {
			return false;
		}
	}

	isWordFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isWordMimeType(item.contentType);
		} catch (e) {
			return false;
		}
	}

	isOpenDocumentFile(): boolean {
		try {
			const item = this.asset.items[this.selectedIndex];
			return HelperService.isOpenDocumentMimeType(item.contentType);
		} catch (e) {
			return false;
		}
	}

	downloadConvertedPDF() {
		const file = this.asset.items[this.selectedIndex];
		const downloadItem = new MTMFileDownload();
		const userSettings = this.authService.getAuthUserSettings();
		if (userSettings?.uploadResumable && typeof userSettings?.uploadResumable === 'boolean') {
			downloadItem.isResumable = userSettings.uploadResumable;
		}
		downloadItem.name = file.name;
		downloadItem.url = file.signedURL + '?thumbnail=_PDF';
		downloadItem.contentType = 'application/pdf';
		downloadItem.size = file.size;
		const displayName = HelperService.getFileDisplayName(file.displayName);
		const fileExtension = displayName.split('.').pop();
		downloadItem.displayName = displayName.replace('.' + fileExtension, '.pdf');
		this.transferService.downloadFile(downloadItem);
	}

	downloadOfficeFile() {
		const file = this.asset.items[this.selectedIndex];
		const downloadItem = new MTMFileDownload();
		const userSettings = this.authService.getAuthUserSettings();
		if (userSettings?.uploadResumable && typeof userSettings?.uploadResumable === 'boolean') {
			downloadItem.isResumable = userSettings.uploadResumable;
		}
		downloadItem.name = file.name;
		downloadItem.url = file.signedURL;
		downloadItem.contentType = file.contentType;
		downloadItem.size = file.size;
		downloadItem.displayName = HelperService.getFileDisplayName(file.displayName);
		this.transferService.downloadFile(downloadItem);
	}

	downloadOriginalFile() {
		const file = this.asset.items[this.selectedIndex];
		const downloadItem = new MTMFileDownload();
		const userSettings = this.authService.getAuthUserSettings();
		if (userSettings?.uploadResumable && typeof userSettings?.uploadResumable === 'boolean') {
			downloadItem.isResumable = userSettings.uploadResumable;
		}
		downloadItem.name = file.name;
		downloadItem.url = file.signedURL;
		downloadItem.contentType = file.contentType;
		downloadItem.size = file.size;
		downloadItem.displayName = HelperService.getFileDisplayName(file.displayName);
		this.transferService.downloadFile(downloadItem);
	}

	downloadConvertedImage() {
		const file = this.asset.items[this.selectedIndex];
		const downloadItem = new MTMFileDownload();
		const userSettings = this.authService.getAuthUserSettings();
		if (userSettings?.uploadResumable && typeof userSettings?.uploadResumable === 'boolean') {
			downloadItem.isResumable = userSettings.uploadResumable;
		}
		downloadItem.name = file.name;
		downloadItem.url = file.signedURL + '?thumbnail=_IMG';
		downloadItem.contentType = 'image/png';
		downloadItem.size = file.size;
		const displayName = HelperService.getFileDisplayName(file.displayName);
		const fileExtension = displayName.split('.').pop();
		downloadItem.displayName = displayName.replace('.' + fileExtension, '.png');
		this.transferService.downloadFile(downloadItem);
	}
}
