<div class="comment-component-container d-none d-lg-block mt-3" [class.private-container]="privateCondition && !isLoading" >

	<div class="d-flex align-items-center justify-content-center text-danger w-100 h-100 bg-transparent" *ngIf="isLoading">
		<i class="fa fa-spinner fa-pulse fa-fw fa-2x" aria-hidden="true"></i>
	</div>

	<div class="list-group-item input-comment input-general-comment pb-4 pr-3 pl-0"
		*ngIf="!previewAnnotation && isCommentable && !isAnnotationComment && !isLoading">
		<div class="comment-input-textarea d-flex align-items-center flex-fill">
			<div class="comment-input-annotation-time-range-comment d-flex p-3"
				*ngIf="isAnnotationCommentEnable && isVideoFile && !isTimeRangeCommentShow">
				<div class="time-range-content d-flex align-items-center">
					<i class="fa fa-plus-circle ml-auto tooltipped" [attr.data-title]="'Comment on a specific frame'"
						(click)="addNewVideoComment();$event.stopPropagation();"></i>
				</div>
			</div>
			<div class="comment-input-annotation-time-range-comment d-flex p-3"
				*ngIf="isAnnotationCommentEnable && isVideoFile && isTimeRangeCommentShow">
				<div class="close-time-range" (click)="onTimeRangeCloseClick();$event.stopPropagation();">
					<i class="fa fa-times" aria-hidden="true"></i>
				</div>
				<div class="time-range-content d-flex align-items-center">
					<div class="start-time-range">
						<i class="fa fa-caret-up d-flex align-items-center justify-content-center pl-2"
							aria-hidden="true" (click)="onStartTimeRangeChange('add')"></i>
						<span class="time-range-value text-center d-block pl-2">{{getTimeDisplay(startTime,
							true)}}</span>
						<i class="fa fa-send fa-fw d-flex align-items-center justify-content-center pl-2"
							aria-hidden="true" (click)="onStartTimeRangeChange('min')"></i>
					</div>
					<i class="fa fa-plus-circle ml-auto pl-1 tooltipped"
						[attr.data-title]="'Comment on a specific sequence'" [ngClass]="{ 'pr-1': isRangeActive }"
						(click)="newAnnotationAdd();$event.stopPropagation();"></i>
					<div class="end-time-range" *ngIf="isRangeActive">
						<i class="fa fa-caret-up d-flex align-items-center justify-content-center pr-2"
							aria-hidden="true" (click)="onEndTimeRangeChange('add')"></i>
						<span class="time-range-value text-center d-block pr-2">{{getTimeDisplay(endTime,
							false)}}</span>
						<i class="fa fa-send fa-fw d-flex align-items-center justify-content-center pr-2"
							aria-hidden="true" (click)="onEndTimeRangeChange('min')"></i>
					</div>
				</div>
			</div>
			<div class="comment-input-textarea-content d-flex align-items-center flex-fill">
				<div class="d-flex flex-fill">
					<mtm-text-editor (keydown.enter)="$event.preventDefault()" [(value)]="message" class="flex-fill"
						(keyup)="$event.stopPropagation()" (enterKeyPress)="onSendComment()" [quillActive]="true"
						(click)="$event.stopPropagation()" [showBottomToolbar]="true" [projectId]="projectId"
						[sectionId]="sectionId" [subSectionId]="subsectionId" [commentId]="comment.id"
						[(attachments)]="comment.files" (uploadFileCallback)="uploadFileCallback($event)"
						(deleteFileCallback)="deleteFileCallback($event, comment)">
					</mtm-text-editor>
					<div class="d-flex align-items-start text-center flex-wrap">
						<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of newFiles">
							<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
								(onTrashFile)="onDeleteNewFile($event, attachment)"></hr-badgebtn-subcmp>
							<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true"
								class="w-100"></hr-grid-subcmp>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex comment-input-button" [class.disabled]="!message && !newFiles.length">
				<span class="input-group-addon comment-span ml-auto"
					(click)="onSendComment();$event.stopPropagation();">
					<i class="fa fa-send fa-fw" aria-hidden="true"></i>
				</span>
			</div>
		</div>
	</div>

	<div class="comments" observe-attributes *ngIf="!isLoading">
		<div class="comment mb-3" *ngFor="let comment of commentsToDisplay"
			[ngStyle]="{ 'border-radius': !!previewAnnotation ? '0.25rem' : '0', border: !!previewAnnotation ? ('2px solid ' + borderColor) : 'none' }"
			[ngClass]="{ 'highlight': hightLightedComment?.id === comment.id, 'mb-3': !previewAnnotation, 'comment-preview-active': replyShowId === comment.id,
			'pt-3':  hightLightedComment?.id !== comment.id}">
			<div class="comment-section" [ngClass]="{ 'in-preview': !!previewAnnotation }">
				<div class="d-flex comment-inner">
					<div class="-d-flex align-items-start">
						<mtm-user-profile-picture [username]="comment.username" [height]="previewAnnotation ? 26 : 36"
							[width]="previewAnnotation ? 26 : 36">
						</mtm-user-profile-picture>
					</div>
					<div class="d-flex flex-fill username-message">
						<div class="d-flex align-items-center w-100 pt-1 pb-1 username-wrapper">
							<div class="username mr-2" [ngClass]="{'preview-comment': !!previewAnnotation}"
								*ngIf="users[comment.username]">
								{{ userService.getUserInfo(comment.username) }}
							</div>
							<!-- <div *ngIf="comment.annotation?.id && (isVideoFile || isAudioFile) && !!previewAnnotation"
								class="annotation-comment-meta" [ngClass]="{'preview-comment': !!previewAnnotation}">
								<div class="meta-content d-inline-flex align-items-center"
									(click)="selectComment(comment);$event.stopPropagation();"
									[style.background-color]="comment.annotation.colorOpacity">
									<div *ngIf="showCommentsTotal"
										class="meta-total-reply d-flex align-items-center justify-content-center"
										[style.background-color]="comment.annotation.color">
										<span class="pl-2 pr-2">{{comment.replies.length + 1}}</span>
									</div>
									<div class="meta-time-start d-inline-flex align-items-center justify-content-center pt-0 pb-0">
										<span class="pl-1 pr-2">{{comment.annotation.rangeInTimeString?.start}}</span>
										<span *ngIf="comment.annotation.isCommentInTimeRange">-</span>
										<span *ngIf="comment.annotation.isCommentInTimeRange"
											class="pl-1 pr-2">{{comment.annotation.rangeInTimeString?.end}}</span>
									</div>
								</div>
							</div> -->
							<div class="timestamp">{{comment.datetime | commentDate}}</div>
							<div class="comment-actions d-flex flex-fill">
								<div class="d-flex w-100 align-items-center options-container mr-3">
									<a class="act-edit mr-2 ml-3" [sourceUrl]="getCommentLink(comment)" shortenedUrl
										[style.position]="'absolute'">
										<span class="tooltiptext" directiveCommentMinButtons>{{'copyLink' | translate }}</span>
										<i class="fa fa-link fa-fw" aria-hidden="true"></i>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<a *ngIf="!previewAnnotation && comment.username === myUser.username  && (comment.isMessageEdit==null || !comment.isMessageEdit) && isCommentable && !isMessageEdit"
										class="act-edit mr-2 ml-auto"
										(click)="editComment(comment);$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{'edit' |
											translate}}</span>
										<i class="fa fa-pencil fa-fw" aria-hidden="true"></i>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<a *ngIf="!previewAnnotation && comment.username === myUser.username && isCommentable && !isMessageEdit"
										class="act-delete mr-2" [ngClass]="{ 'ml-auto': !(!previewAnnotation && comment.username === myUser.username  && (comment.isMessageEdit==null || !comment.isMessageEdit) && isCommentable && !isMessageEdit) }"
										(click)="confirmDeletion(comment);$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{'delete' |
											translate}}</span>
										<i class="fa fa-trash fa-fw" aria-hidden="true"></i>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<a *ngIf="!!previewAnnotation" class="act-close mr-2" (click)="closeMarker()">
										<span class="tooltiptext" directiveCommentMinButtons>{{ 'close' | translate
											}}</span>
										<i class="fa fa-times fa-fw close-marker" aria-hidden="true"></i>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
								</div>
							</div>
						</div>
						<div *ngIf="comment.annotation?.id && (isVideoFile || isAudioFile) && !comment.annotation.range.isGeneralComment"
							class="annotation-comment-meta" [ngClass]="{'preview-comment': !!previewAnnotation}">
							<div class="meta-content d-inline-flex align-items-center"
								(click)="selectComment(comment);$event.stopPropagation();"
								[style.background-color]="comment.annotation.colorOpacity">
								<div *ngIf="showCommentsTotal"
									class="meta-total-reply d-flex align-items-center justify-content-center"
									[style.background-color]="comment.annotation.color">
									<span class="pl-2 pr-2">{{comment.replies.length + 1}}</span>
								</div>
								<div class="meta-time-start d-inline-flex align-items-center justify-content-center">
									<span class="pl-2 pr-1">{{comment.annotation.rangeInTimeString?.start | toTime}}</span>
									<span *ngIf="comment.annotation.isCommentInTimeRange">-</span>
									<span *ngIf="comment.annotation.isCommentInTimeRange"
										class="pl-1 pr-2">{{comment.annotation.rangeInTimeString?.end | toTime}}</span>
								</div>
							</div>
						</div>
						<div class="w-100 d-flex flex-column comment-wrapper">
							<div class="w-100 d-flex flex-column mt-3">
								<span *ngIf="!comment.isMessageEdit" class="d-inline-block new-lines"
									[ngClass]="{'marker-comment': !!previewAnnotation}">
									<mtm-comment-preview [value]="comment.body" [enableAudioTranscription]="true"
										[isEdited]="comment.datetime != comment.updatetime" [subSectionId]="subsectionId"
										[dateEdited]="comment.updatetime" [truncateText]="!!previewAnnotation"
										[fontSize]="!!previewAnnotation ? '15px' : 'inherit'"
										[textColor]="textColor"
										[(attachments)]="comment.files" [showDeleteFile]="comment.username === myUser.username"
										(deleteFileCallback)="deleteFileCallback($event, comment)">
									</mtm-comment-preview>
									<div class="d-flex align-items-start text-center flex-wrap" *ngIf="uploadingFiles[comment.id]?.length">
										<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of uploadingFiles[comment.id]">
											<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
												(onTrashFile)="onDeleteNewFile($event, attachment)" [uploading]="true"></hr-badgebtn-subcmp>
											<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true" [uploading]="true"
												class="w-100"></hr-grid-subcmp>
										</div>
									</div>
								</span>
								<div class="edit-comment mr-4" *ngIf="isCommentable && comment.isMessageEdit">
									<div class="comment-input-textarea-content mb-2">
										<mtm-text-editor (keydown.enter)="$event.preventDefault()"
											[(value)]="comment.editingMessage" class="flex-fill"
											(keyup)="$event.stopPropagation()" [relatedCommentId]="comment.id"
											[quillActive]="true"
											(enterKeyPress)="sendEditedComment(comment, comment.files)"
											[projectId]="projectId" [sectionId]="sectionId"
											[subSectionId]="subsectionId" [commentId]="comment.id"
											(click)="$event.stopPropagation()"
											[(attachments)]="comment.files"
											(uploadFileCallback)="uploadFileCallback($event)"
											(deleteFileCallback)="deleteFileCallback($event, comment)">
										</mtm-text-editor>
										<div class="d-flex align-items-start text-center flex-wrap">
											<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of newFiles">
												<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
													(onTrashFile)="onDeleteNewFile($event, attachment)"></hr-badgebtn-subcmp>
												<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true"
													class="w-100"></hr-grid-subcmp>
											</div>
										</div>
									</div>
									<div *ngIf="isCommentable && comment.username === myUser.username && comment.isMessageEdit"
										class="comment-input-button d-flex comment-save-edit" [class.disabled]="!comment.editingMessage && (editedComment.annotation?.id && !newFiles.length)"
										(click)="sendEditedComment(comment, comment.files);$event.stopPropagation();">
										<span class="input-group-addon comment-span ml-auto">
											<i class="fa fa-send fa-fw" aria-hidden="true"></i>
										</span>
									</div>
								</div>
								<div *ngIf="!comment.parentId && !!previewAnnotation && !comment.isMessageEdit && !(comment.isReplying)"
									class="comment-actions mt-1 mb-2 d-flex flex-row ml-auto">
									<a *ngIf="isCommentable" class="act-reply preview mr-2"
										[ngClass]="{ 'preview-comment': !!previewAnnotation }"
										(click)="comment.isMessageEdit = false;comment.editingMessage='';comment.isReplying = false;resetComponent();replyComment(comment);$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{ 'reply' | translate
											}}</span>
										<span class="reply-comment">{{ 'reply' | translate }}</span>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<!-- <a *ngIf="comment.username === myUser.username  && (comment.isMessageEdit==null || !comment.isMessageEdit) && isCommentable && !isMessageEdit"
										[ngClass]="{ 'preview-comment': !!previewAnnotation }"
										class="act-edit preview mr-2"
										(click)="editComment(comment);$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{'edit' |
											translate}}</span>
										<span class="reply-comment">{{ 'edit' | translate }}</span>
										<span class="text-uppercase font-weight-bold"></span>
									</a> -->
									<a *ngIf="comment.username === myUser.username && isCommentable && !isMessageEdit"
										class="act-delete preview mr-2"
										[ngClass]="{ 'preview-comment': !!previewAnnotation }"
										(click)="confirmDeletion(comment);$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{'delete' |
											translate}}</span>
										<span class="reply-comment">{{ 'delete' | translate }}</span>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<div class="input-comment mr-4" *ngIf="comment.id === replyCommentId">
										<div class="comment-input-textarea-content">
											<textarea (keydown.enter)="$event.preventDefault()"
												(keyup)="$event.stopPropagation()" [(ngModel)]="replyMessage"
												(keyup.enter)="onReplyComment(comment)"
												placeholder="{{ 'replyComment' | translate }}"
												class="comment-area flex-fill" type="text"
												(click)="$event.stopPropagation();" rows="6"></textarea>
										</div>
										<div *ngIf="isCommentable" class="d-flex flex-fill comment-input-button"
											[class.disabled]="!replyMessage">
											<span class="input-group-addon comment-span ml-auto"
												(click)="onReplyComment(comment);$event.stopPropagation();">
												<i class="fa fa-send fa-fw" aria-hidden="true"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="!comment.parentId && (!previewAnnotation || comment.id === replyCommentId) && !comment.isMessageEdit"
								class="comment-actions mt-3 mb-2 d-flex flex-column">
								<a *ngIf="isCommentable && !comment.isReplying" class="act-reply mr-2"
									(click)="comment.isMessageEdit = false;comment.editingMessage='';comment.isReplying = false;resetComponent();replyComment(comment);$event.stopPropagation();">
									<span class="tooltiptext" directiveCommentMinButtons>{{ 'reply' | translate
										}}</span>
									<span class="reply-comment">{{ 'reply' | translate }}</span>
									<span class="text-uppercase font-weight-bold"></span>
								</a>
								<div class="input-comment mr-4" *ngIf="comment.id === replyCommentId">
									<div class="comment-input-textarea-content mb-2">
										<div class="d-block">
											<mtm-text-editor (keydown.enter)="$event.preventDefault()"
												[relatedCommentId]="comment.id" [(value)]="replyMessage"
												class="flex-fill" (keyup)="$event.stopPropagation()"
												[quillActive]="true" (enterKeyPress)="onReplyComment(comment)"
												(click)="$event.stopPropagation()" [showBottomToolbar]="comment.annotation?.id"
												[projectId]="projectId" [sectionId]="sectionId"
												[subSectionId]="subsectionId" [commentId]="comment.id"
												(uploadFileCallback)="uploadFileCallback($event)"
												(deleteFileCallback)="deleteFileCallback($event, comment)">
											</mtm-text-editor>
											<div class="d-flex align-items-start text-center flex-wrap">
												<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of newFiles">
													<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
														(onTrashFile)="onDeleteNewFile($event, attachment)"></hr-badgebtn-subcmp>
													<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true"
														class="w-100"></hr-grid-subcmp>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="isCommentable" class="d-flex flex-fill comment-input-button"
										[class.disabled]="!replyMessage && (comment.annotation?.id && !newFiles.length)">
										<span class="input-group-addon comment-span ml-auto"
											(click)="onReplyComment(comment);$event.stopPropagation();">
											<i class="fa fa-send fa-fw" aria-hidden="true"></i>
										</span>
									</div>
								</div>
							</div>
							<div class="comment-actions cancel-reply d-flex justify-content-end">
								<a class="cancel-comment-reply mr-4 mb-2" *ngIf="comment.isReplying"
									(click)="comment.isMessageEdit = false;comment.editingMessage='';comment.isReplying = false;resetComponent();$event.stopPropagation();">
									<span class="tooltiptext" directiveCommentMinButtons>{{ 'cancel' |
										translate}}</span>
									<span class="font-weight-bold">{{ 'cancel' | translate}}</span>
								</a>
							</div>
							<div class="comment-actions cancel-reply d-flex justify-content-end">
								<a class="cancel-comment-edit mr-4 mb-2" *ngIf="comment.isMessageEdit && isCommentable"
									(click)="comment.isMessageEdit = false;comment.editingMessage='';comment.isReplying = false;resetComponent();$event.stopPropagation();">
									<span class="tooltiptext" directiveCommentMinButtons>{{ 'cancel' |
										translate}}</span>
									<span class="font-weight-bold">{{ 'cancel' | translate}}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="comment-reply-section ml-5 mt-3" *ngIf="comment.id === replyShowId">
					<div class="container-left-border"></div>
					<div [ngClass]="{ 'highlight': hightLightedComment?.id === reply.id, 'pt-3': hightLightedComment?.id === reply.id }"
						class="comment-section ml-2 mb-4" *ngFor="let reply of comment.replies; trackBy: reply?.id;">
						<div class="d-flex flex-column username-message">
							<div class="d-flex align-items-center reply-user-row">
								<div class="profile-picture">
									<mtm-user-profile-picture [username]="reply.username" [height]="36" [width]="36">
									</mtm-user-profile-picture>
								</div>
								<div class="username ml-3 mr-2" *ngIf="users[reply.username]">
									{{ userService.getUserInfo(reply.username) }}
								</div>
								<div class="timestamp">
									{{reply.datetime | commentDate}}
								</div>
								<div class="comment-actions d-flex ml-auto">
									<a *ngIf="reply.isMessageEdit && isCommentable" class="act-cancel-edit mr-4"
										(click)="reply.isMessageEdit = false;reply.editingMessage='';reply.isReplying = false;resetComponent();$event.stopPropagation();">
										<span class="tooltiptext" directiveCommentMinButtons>{{ 'cancel' | translate
											}}</span>
										<i class="fa fa-times fa-fw" aria-hidden="true"></i>
										<span class="text-uppercase font-weight-bold"></span>
									</a>
									<div *ngIf="!reply.isMessageEdit" class="d-inline-flex align-items-center mr-3">
										<a *ngIf="reply.username === myUser.username && isCommentable"
											class="act-edit mr-2"
											(click)="editComment(reply);$event.stopPropagation();">
											<span class="tooltiptext" directiveCommentMinButtons>{{ 'edit' | translate
												}}</span>
											<i class="fa fa-pencil fa-fw" aria-hidden="true"></i>
											<span class="text-uppercase font-weight-bold"></span>
										</a>
										<a *ngIf="reply.username === myUser.username && isCommentable"
											class="act-delete"
											(click)="confirmDeletion(reply);$event.stopPropagation();">
											<span class="tooltiptext" directiveCommentMinButtons>{{ 'delete' | translate
												}}</span>
											<i class="fa fa-trash fa-fw" aria-hidden="true"></i>
											<span class="text-uppercase font-weight-bold"></span>
										</a>
									</div>
								</div>
							</div>
							<div class="mr-4 mt-2">
								<div class="input-comment">
									<div class="d-block mb-2">
										<span *ngIf="!reply.isMessageEdit" class="d-inline new-lines">
											<mtm-comment-preview [value]="reply.body" [enableAudioTranscription]="true" [subSectionId]="subsectionId"
												[isEdited]="reply.datetime != reply.updatetime"
												[fontSize]="!!previewAnnotation ? '15px' : 'inherit'"
												[textColor]="!!previewAnnotation ? '#000000' : 'inherit'"
												[(attachments)]="reply.files" [dateEdited]="reply.updatetime"
												[truncateText]="!!previewAnnotation" [showDeleteFile]="comment.username === myUser.username"
												(deleteFileCallback)="deleteFileCallback($event, reply)">
											</mtm-comment-preview>
											<div class="d-flex align-items-start text-center flex-wrap" *ngIf="uploadingFiles[reply.id]?.length">
												<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of uploadingFiles[reply.id]">
													<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
														(onTrashFile)="onDeleteNewFile($event, attachment)" [uploading]="true"></hr-badgebtn-subcmp>
													<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true" [uploading]="true"
														class="w-100"></hr-grid-subcmp>
												</div>
											</div>
										</span>
										<div class="comment-input-textarea-content mb-2"
											*ngIf="isCommentable && reply.isMessageEdit">
											<mtm-text-editor (keydown.enter)="$event.preventDefault()"
												[relatedCommentId]="reply.id" [quillActive]="true"
												[(value)]="reply.editingMessage" class="flex-fill"
												(keyup)="$event.stopPropagation()"
												(enterKeyPress)="sendEditedComment(reply, reply.files)"
												(click)="$event.stopPropagation()"
												[projectId]="projectId" [sectionId]="sectionId"
												[subSectionId]="subsectionId" [commentId]="reply.id"
												[(attachments)]="reply.files"
												(uploadFileCallback)="uploadFileCallback($event)"
												(deleteFileCallback)="deleteFileCallback($event, reply)">
											</mtm-text-editor>
											<div class="d-flex align-items-start text-center flex-wrap">
												<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of newFiles">
													<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true" [localFile]="true"
														(onTrashFile)="onDeleteNewFile($event, attachment)"></hr-badgebtn-subcmp>
													<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true"
														class="w-100"></hr-grid-subcmp>
												</div>
											</div>
										</div>
									</div>
									<div class="comment-actions d-flex align-items-start comment-input-button">
										<a *ngIf="isCommentable && reply.username === myUser.username && reply.isMessageEdit"
											class="ml-auto" [class.disabled]="!reply.editingMessage && (reply.annotation?.id && !newFiles.length)"
											(click)="sendEditedComment(reply, reply.files);$event.stopPropagation();">
											<span class="tooltiptext" directiveCommentMinButtons>{{ 'send' | translate
												}}</span>
											<i class="fa fa-send fa-fw" aria-hidden="true"></i>
											<span class="text-uppercase font-weight-bold"></span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="show-previous-comment d-flex align-items-center justify-content-center p-1 mb-0"
					*ngIf="comment.replies.length">
					<a class="show-previous-comment-nav" (click)="showReplies(comment);$event.stopPropagation();">
						<span class="show-replies-label">
							{{ comment.id !== replyShowId ? ('showReplies' | translate) : ('hideReplies' | translate)
							}}</span>
						<span *ngIf="comment.id !== replyShowId"
							class="total-replies ml-2">{{comment.replies.length}}</span>
					</a>
				</div>
			</div>
		</div>

		<button *ngIf="viewMore && comments?.length > commentsToDisplay?.length"
			class="btn w-100 d-block text-uppercase text-center view-more" (click)="viewAllComments()">{{'viewMore' |
			translate }}</button>
	</div>
</div>
