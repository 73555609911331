import { Injectable, EventEmitter } from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
import { environment } from "../../../environments/environment";
import * as moment from 'moment';

export let activeLanguageUpdated: EventEmitter<any> = new EventEmitter<any>();

@Injectable({
	providedIn: 'root'
})
export class TranslateService {
	data: any = {}
	private lang: String
	constructor(private http: HttpClient) { }
	use(lang: string): Promise<{}> {
		this.lang = lang
		const timeFormat = moment().format('DMYYYYHH');
		return new Promise<{}>((resolve, reject) => {
			lang = !lang ? localStorage.getItem('auth_user_language') : lang;
			let storageLangUrl = environment.web.langJsonFiles.replace('%lang%', lang || 'en_us');
			moment.locale(lang);
			activeLanguageUpdated.emit(lang);
			const langPath = `assets/${lang || 'en_us'}.json?v=${timeFormat}`;
			this.http.get(langPath).subscribe(translation => {
				this.data = Object.assign({}, translation || {});
				resolve(this.data);
			},
				error => {
					this.data = {};
					resolve(this.data);
				});
		});
	}

	getLang() {
		return this.lang
	}
}
