<div *ngIf="!!file" class="preview-modal-container" [style.width]="displayWidth ? displayWidth+'px': 'auto'" [style.height]="displayHeight ? displayHeight+'px': 'auto'" [ngClass]="{ 'auto-height': !displayHeight }">
	<div *ngIf="!isUrl && !isLocalFile">
		<img class="card-img card-background min-h-250 h-100" src="{{file}}">
	</div>

	<div class="min-h-250 h-100 preview-player-wrapper" *ngIf="isLocalFile">
		<selector-newupload-files-preview-thumpnail-component [item]="file"></selector-newupload-files-preview-thumpnail-component>
	</div>

	<div *ngIf="isUrl" class="player-wrapper">
		<mtm-media-player [showCloseButton]="true" [item]="file" [enableGeneralComments]="false" [showDownloadButtonIfNotPreviewAble]="true"
        [customPaddingTop]="playerPaddingTop" [directPreview]="directPreview"></mtm-media-player>
	</div>
</div>