import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ApiService } from "app/shared/services/api.service";
import { TransferStatus, MTMCustomFile } from './index';
import { listenerFileUploadCompleted, listenerUploadStatusChanged, listenerStartedUploadingFile, listenerFileUploaded } from './listeners';
import { OverlayService } from '../overlayService';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { LimitReachedPopupComponent } from 'app/subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SUBSCRIPTION_LIMIT } from 'app/subscriptions/models/const';
import { UploadService } from '../upload.service';
import { environment } from 'environments/environment';
import { HelperService } from '../helper.service';

export const supportedFileExtensions: string[] = [
	'aac',
	'avi',
	'bmp',
	'csv',
	'doc',
	'docx',
	'fla',
	'flac',
	'gif',
	'jpeg',
	'jpg',
	'mov',
	'mp3',
	'mp4',
	'mkv',
	'mpeg',
	'ogg',
	'pdf',
	'png',
	'ppt',
	'pptx',
	'rtf',
	'txt',
	'wav',
	'xls',
	'xlsx',
	'zip',
	'tiff',
	'psb',
	'psd'
];


export default class FileUpload {
	public uploadFiles: MTMCustomFile[] = [];

	public files: any[] = [];
	public newFiles: MTMCustomFile[] = [];
	public refFiles: any[] = [];
	private preUploadURL: string = "";
	private postUploadURL: string = "";
	private asReference: boolean = false;
	private parentId: string;

	constructor(
		private translatePipe: TranslatePipe,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private apiService: ApiService,
		files: any[],
		newFiles: MTMCustomFile[],
		preUploadURL: string,
		postUploadURL: string,
		asReference?: boolean,
		fileRef?: any[],
		private uploadService?: UploadService
	) {
		this.files = files;
		this.newFiles = newFiles;
		this.refFiles = fileRef;

		this.preUploadURL = preUploadURL;
		this.postUploadURL = postUploadURL;
		this.asReference = asReference;
	}

	startFileUpload(silent?, parentId?: string, nativeUploadMethod?: boolean): void {
		let fileToUpload = [];
		if (this.asReference) {
			fileToUpload = this.refFiles;
		} else {
			fileToUpload = this.newFiles;
		}
		if (parentId) {
			this.parentId = parentId;
		}
		let filesContentTypes = {};
		let filesSizes = {};
		let fileAddedTime = {};

		fileToUpload.filter(file => file.uploadStatusCode == TransferStatus.NEW || this.asReference).forEach(file => {
			if (!this.asReference) {
				file.removeFromUploadingFiles = (file: MTMCustomFile) => this.removeFromUploadingFiles(file);
				file.onRefreshUploading = (file: MTMCustomFile) => this.refreshFileUload(file);
				file.setTimeToStartUploading();
				file.setUploadStatusCode(TransferStatus.WAITING);
				let fileStringArray = file.fileName.split('.');
				let fileNameArr = (fileStringArray.length > 1) ? file.fileName.split('.').slice(0, -1) : fileStringArray;
				let ext = (fileStringArray.length > 1) ? file.fileName.split('.').pop() : '';
				const originalName = fileNameArr.join('.');
				let fileName = originalName.replace(/[^a-zA-Z0-9_\-]/g, '_') || 'mtm-file';
				const isSameName = originalName == fileName;
				if (!isSameName)
					file.name = fileName + (ext ? ext : '');

				let foundIndex = _.indexOf(supportedFileExtensions, ext) >= 0;
				if (!foundIndex) {
					let contentType = file.fileType || file.type;
					let typeExt = contentType.substr(contentType.indexOf("/") + 1);
					let foundExtIndex = _.indexOf(supportedFileExtensions, typeExt) >= 0;
					if (foundExtIndex) {
						if (typeExt === 'vnd.openxmlformats-officedocument.presentationml.presentation') {
							ext = 'pptx';
						} else if (typeExt === 'vnd.ms-powerpoint') {
							ext = 'ppt';
						} else if (typeExt === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							ext = 'xlsx';
						} else if (typeExt === 'vnd.ms-excel') {
							ext = 'xls';
						} else if (typeExt === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
							ext = 'docx';
						} else if (typeExt === 'msword') {
							ext = 'docx';
						} else {
							ext = typeExt;
						}
						fileName = fileStringArray.join('.');
					} else {
						fileName = fileNameArr.join('.');
					}
				}
				file.fileName = fileName + '_' + file.createTime + (ext ? ('.' + ext) : '');
				// hide for now - MTM-4414
				// if (!isSameName) {
				// 	this.overlayService.showWarning(this.translatePipe.transform('fileNameChanged'));
				// }
				this.uploadFiles.push(file);
				filesContentTypes[file.fileName] = file.fileType;
				filesSizes[file.fileName] = file.fileSize;
				fileAddedTime[file.fileName] = file.createTime;
			} else {
				filesContentTypes[file.displayName] = file.contentType;
				filesSizes[file.displayName] = file.size;
				fileAddedTime[file.displayName] = file.timeCreated;
			}
		});
		this.getUploadSignedURL(filesContentTypes, filesSizes, fileAddedTime, nativeUploadMethod)
	}

	private removeFromUploadingFiles = (file: MTMCustomFile) => {
		try {
			let index: number = -1;

			index = this.newFiles.findIndex(k => k.fileLocalId == file.fileLocalId);
			this.newFiles.splice(index, 1);
			index = this.uploadFiles.findIndex(k => k.fileLocalId == file.fileLocalId);
			this.uploadFiles.splice(index, 1);

			file.cancelUpload(file);
		} catch (error) {
		}
	}

	public refreshFileUload = (file: MTMCustomFile) => {
		let filesContentTypes = {};
		let filesSizes = {};
		let fileAddedTime = {};

		filesContentTypes[file.fileName] = file.fileType;
		filesSizes[file.fileName] = file.fileSize;
		fileAddedTime[file.fileName] = file.createTime;

		file.setUploadStatusCode(TransferStatus.WAITING);
		this.getUploadSignedURL(filesContentTypes, filesSizes, fileAddedTime);
	}

	doNativeFileUpload(url: string, params: string[], mtmFile: MTMCustomFile, fileKeyParam?: any, bodyParams?: any) {
		return new Observable(observer => {
			mtmFile.setUploadStatusCode(TransferStatus.WAITING);
			let formData: FormData = new FormData(),
				xhr: XMLHttpRequest = new XMLHttpRequest();

			if (mtmFile.file instanceof FileList || Array.isArray(mtmFile.file)) {
				for (let i = 0; i < mtmFile.file.length; i++)
					formData.append(fileKeyParam || 'files', mtmFile.file[i], mtmFile.file[i].name);
			} else {
				formData.append(fileKeyParam || 'file', mtmFile.file, mtmFile.file.name);
			}
			if (bodyParams) {
				if (Object.keys(bodyParams).length) {
					Object.keys(bodyParams).forEach(key => {
						formData.append(key, bodyParams[key]);
					})
				}
			}

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200 || xhr.status === 201) {
						// console.log("File Upload ENDED");
						mtmFile.setUploadStatusCode(TransferStatus.UPLOADED);
						observer.next(undefined);
						observer.complete();
					} else {
						console.log("File Upload ERROR");
						mtmFile.setUploadStatusCode(TransferStatus.ERROR);
						observer.error();
					}
				}
			};

			xhr.upload.onprogress = (event) => {
				mtmFile.setUploadStatusCode_and_Progress(
					Math.round(event.loaded / event.total * 100),
					TransferStatus.SENDING
				);

				observer.next(undefined);
			};

			xhr.upload.onerror = (event) => {
				console.log("File Upload ERROR");
				xhr.abort();
				mtmFile.setUploadStatusCode(TransferStatus.ERROR);
				observer.next(undefined);
			};

			xhr.upload.ontimeout = (event) => {
				console.log("File Upload TimeOut");
				xhr.abort();
				mtmFile.setUploadStatusCode(TransferStatus.ERROR);
				observer.next(undefined);
			};

			xhr.open('POST', url, true);
			HelperService.getHttpHeaders().forEach((values, name) => {
				if (name !== 'Content-Type')
					xhr.setRequestHeader(name, values[0]);
			});
			xhr.send(formData);
		});
	}

	private getUploadSignedURL(filesContentTypes, filesSizes, fileAddedTime, nativeUploadMethod?): void {
		if (nativeUploadMethod) {
			this.newFiles.forEach(file => {
				this.FileUpload(file, true);
			});
		} else {
			const data = {
				directory: '/',
				files: null,
				filesContentTypes: filesContentTypes,
				filesSizes: filesSizes,
				fileAddedTime: fileAddedTime,
				message: null,
				parentId: this.parentId || '00000000-0000-0000-0000-000000000000'
			};
			this.apiService.httpPost(this.preUploadURL, data).subscribe(
				(res: any) => {
					if (this.asReference) {
						res.forEach(dbElement => {
							this.FileUpload(dbElement);
						});
					} else {
						res.forEach(dbElement => {
							let index = this.newFiles.findIndex(k => k.fileName == dbElement.displayName);
							if (index < 0) {
								return;
							}
							let refFile = this.newFiles[index];
							refFile.setDbFileObject(dbElement);
							this.FileUpload(refFile);
						});
					}
				}, (err) => {
					let modalRef: NgbModalRef = null;
					if (err.errorCode === 'UPLOAD_TO_STORAGE_ERROR') {
						if (err.message) {
							if (err.message.includes('Number of files exceeded')) {
								modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
								modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.NUMBER_OF_FILES;
								modalRef.componentInstance.forceRedirect = false;
							} else if (err.message.includes('shortageInBytes')) {
								modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
								modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ACTIVE_STORAGE;
								modalRef.componentInstance.forceRedirect = false;
							}
						}
					}
					this.newFiles.forEach(file => this.uploadingError(file));
				});
		}

	}

	private uploadingError(file: MTMCustomFile) {
		file.setUploadStatusCode(TransferStatus.ERROR);
	}

	private FileUpload(file: any, nativeUploadMethod?: boolean): void {
		if (this.asReference) {
			new FileUploadWithSignedURL(this.apiService, this.postUploadURL, file, this.asReference)
				.callPostUploadURL()
				.subscribe((responseFile: MTMCustomFile) => {
					if (!this.asReference) {
						if (responseFile.uploadStatusCode != TransferStatus.COMPLETED) {
							return;
						}

						this.files.splice(0, 0, responseFile.dbFileObject);
						let index = this.newFiles.findIndex(f => f.fileLocalId == responseFile.fileLocalId);
						if (index >= 0) {
							this.newFiles.splice(index, 1);
						}
					}
					listenerUploadStatusChanged.emit(responseFile);
					listenerFileUploadCompleted.emit(responseFile);
				},
					(resFile) => {
						this.uploadingError(resFile);
					});
		} else {
			new FileUploadWithSignedURL(this.apiService, this.postUploadURL, file, null, nativeUploadMethod)
				.doFileUpload()
				.subscribe((responseFile: MTMCustomFile) => {
					if (responseFile.uploadStatusCode != TransferStatus.COMPLETED) {
						return;
					}

					this.files.splice(0, 0, responseFile.dbFileObject);
					let index = this.newFiles.findIndex(f => f.fileLocalId == responseFile.fileLocalId);
					if (index >= 0) {
						this.newFiles.splice(index, 1);
					}
					listenerUploadStatusChanged.emit(responseFile);
					if (!nativeUploadMethod) {
						listenerFileUploadCompleted.emit(responseFile);
					} else {
						listenerFileUploaded.emit(responseFile);
					}
				},
					(resFile) => {
						this.uploadingError(resFile);
					});
		}
	}
}



class FileUploadWithSignedURL {
	private xmlHttpRequest: XMLHttpRequest;
	isUploading = false;
	asReference: boolean;

	constructor(private apiService: ApiService,
		private postUploadURL: string,
		private file: any,
		asReference?: boolean,
		private nativeUploadMethod?: boolean) {
		this.asReference = asReference;
		if (!asReference) {
			file.refFileUploadWithSignedURL = this;
		}
	}

	private setUploadState(status) {
		this.isUploading = status;
	}
	private setUploadingFile() {
		this.setUploadState(true);
	}
	private setNotUploadingFile() {
		this.setUploadState(false);
	}

	public abourtUploading() {
		this.setUploadState(true);
		if (this.xmlHttpRequest)
			this.xmlHttpRequest.abort();
	}

	private doFileUploadSignedURL(): Observable<null> {
		return new Observable(observer => {
			this.file.setUploadStatusCode(TransferStatus.WAITING);
			this.setUploadingFile();

			this.xmlHttpRequest = new XMLHttpRequest();
			let xhr = this.xmlHttpRequest;
			if (!('withCredentials' in xhr)) {
				this.file.setUploadStatusCode(TransferStatus.ERROR);
				observer.error();
				return;
			}

			xhr.onreadystatechange = (res: any) => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200 || xhr.status === 201) {
						// console.log("File Upload ENDED");
						this.file.setUploadStatusCode(TransferStatus.UPLOADED);
						observer.next(this.nativeUploadMethod ? JSON.parse(xhr.responseText) : {});
						observer.complete();
					} else {
						console.log("File Upload ERROR");
						this.file.setUploadStatusCode(TransferStatus.ERROR);
						observer.error();
					}
				}
			};

			xhr.upload.onprogress = (event) => {
				this.file.setUploadStatusCode_and_Progress(
					Math.round(event.loaded / event.total * 100),
					TransferStatus.SENDING
				);

				observer.next(undefined);
			};

			xhr.upload.onerror = (event) => {
				console.log("File Upload ERROR");
				xhr.abort();
				this.file.setUploadStatusCode(TransferStatus.ERROR);
				observer.next(undefined);
			};

			xhr.upload.ontimeout = (event) => {
				console.log("File Upload TimeOut");
				xhr.abort();
				this.file.setUploadStatusCode(TransferStatus.ERROR);
				observer.next(undefined);
			};

			if (this.nativeUploadMethod) {
				let formData: FormData = new FormData();
				let fileName = this.file.file.name.replace(/[^a-zA-Z0-9_\.\-]/g, '_') || 'mtm-file';
				formData.append('file', this.file.file, fileName || 'mtm-file');
				xhr.open('POST', environment.api.baseUrl + '/' + this.postUploadURL, true);
				HelperService.getHttpHeaders().forEach((values, name) => {
					if (name !== 'Content-Type')
						xhr.setRequestHeader(name, values[0]);
				});
				xhr.send(formData);
			} else {
				xhr.open('PUT', this.file.dbFileObject.signedURL, true);
				xhr.setRequestHeader('Content-Type', this.file.file.type);
				xhr.send(this.file.file);
			}
		});
	}

	public doFileUpload(): Observable<MTMCustomFile> {
		return new Observable(observer => {
			this.doFileUploadSignedURL()
				.subscribe((res) => {
					if (this.file.uploadStatusCode != TransferStatus.UPLOADED)
						return;
					this.setNotUploadingFile();
					this.file.setUploadStatusCode(TransferStatus.WAITING);
					if (this.nativeUploadMethod) {
						this.file.setUploadStatusCode(TransferStatus.COMPLETED);
						this.file.setDbFileObject(res);
						observer.next(this.file);
						observer.complete();
					} else {
						this.callPostUploadURL()
							.subscribe((res) => {
								if (this.file.fileAttach) {
									this.file.fileAttach = res;
								}
								observer.next(this.file);
								observer.complete();
							},
								err => {
									this.file.setUploadStatusCode(TransferStatus.ERROR);
									observer.error(this.file);
								});
					}
				},
					err => {
						this.setNotUploadingFile();
						this.file.setUploadStatusCode(TransferStatus.ERROR);
						observer.error(this.file);
					});
		});
	}

	//POST_UPLOAD_API_CALL

	public callPostUploadURL(): Observable<any> {
		return new Observable(observer => {
			let body = this.asReference ? [this.file.id] : (!this.file.fileAttach ? [this.file.dbFileObject.id] : { message: [this.file.dbFileObject.id] });
			this.apiService.httpPost(this.postUploadURL, body)
				.subscribe((res: any) => {
					if (!this.asReference) {
						const fileId = this.file.dbFileObject.id;
						this.file.setUploadStatusCode(TransferStatus.COMPLETED);
						if(res.files?.length){
							const file = res.files.find(file => file.id === fileId);
							if(file) {
								this.file.setDbFileObject(file);
							}
						} else {
							this.file.setDbFileObject(res[0]);
						}
					}
					observer.next(res);
					observer.complete();
				}, err => {
					observer.error();
				});
		});
	}
}
