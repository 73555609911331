<div class="modal-body">
    <div class="row justify-content-between px-3">
        <h5 class="modal-title ml-2">Enter another mobile number</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="row px-4">
        <div class="col text-center">
            <form class="d-flex w-100" [formGroup]="changePhoneForm" (ngSubmit)="onSubmit()">
                <div class="d-flex flex-fill w-100">
                    <div class="form-group flex-fill mr-3"
                        [ngClass]="{'has-danger': submitted && changePhoneForm.controls.phone.invalid}">
                        <selector-mtm-tel-input [cardForm]=changePhoneForm [cardFormKey]="'phone'">
                        </selector-mtm-tel-input>
                        <!-- <input class="form-control" type="tel" id="phone" formControlName="phone" [ngClass]="{'form-control-danger': submitted && (!isValidPhone() || changePhoneForm.controls.phone.invalid)}" value="{{phoneValue}}"> -->
                    </div>
                    <button class="btn btn-lg btn-primary ml-auto" type="submit" [disabled]="sending">Send code</button>
                </div>
            </form>
        </div>
    </div>
</div>