<div class="notification-modal-container" [ngClass]="cssClass">
	<div class="modal-header d-flex flex-column align-items-center ml-auto c-mtm-brand"
		[ngClass]="{ 'justify-content-center': centerHeader, 'no-header-border': noHeaderBorder }">
		<h4 *ngIf="title" class="modal-title ">{{title}}</h4>
		<h4 *ngIf="title2" class="c-mtm-black modal-title2 mb-0 mt-3">{{title2}}</h4>
		<button type="button" [hidden]="isCloseButtonHidden" class="m-0 p-0 close" aria-label="Close"
			(click)="onCancelModal('closedButton')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div *ngIf="icon" class="d-flex align-items-center justify-content-center">
			<div *ngIf="icon === 'warning'" class="modal-icon">
				<img src="../../../../../assets/img/annotations/warning_icon.png" alt="" height="{{iconSize || 24}}">
			</div>
			<div *ngIf="icon === 'file-confirm'" class="modal-icon">
				<img src="../../../../../assets/img/icons/file-confirm.svg" alt="" height="{{iconSize || 24}}">
			</div>
		</div>
		<div class="container">
			<div class="d-block">
				<div *ngIf="subtitle" class="subtitle final-payment">
					{{subtitle}} {{paymentStatus}}
				</div>
				<div *ngIf="description" class="description mb-3 c-mtm-black" [innerHTML]="description"></div>
				<div *ngIf="description3" class="description mb-3 c-mtm-black" [innerHTML]="description3"></div>
				<div *ngIf="description2" class="description mb-3 c-mtm-black" [innerHTML]="description2"></div>
				<div *ngIf="description4" class="description mb-3 c-mtm-black" [innerHTML]="description4"></div>
				<div *ngIf="notificationInfo" class="notification-info mb-3 c-mtm-purple"
					[innerHTML]="notificationInfo"></div>
				<div *ngIf="question" class="notification-question _600 mb-3 c-mtm-black" [innerHTML]="question"></div>
				<ul *ngIf="subDescription || subDescription2 || subDescription3">
					<li *ngIf="subDescription" class="sub-description c-mtm-purple text-left"
						[innerHTML]="subDescription"></li>
					<li *ngIf="subDescription2" class="sub-description c-mtm-purple text-left"
						[innerHTML]="subDescription2"></li>
					<li *ngIf="subDescription3" class="sub-description c-mtm-purple text-left"
						[innerHTML]="subDescription3"></li>
				</ul>
				<div *ngIf="question2" class="notification-question mb-3 c-mtm-black" [innerHTML]="question2"></div>
				<div *ngIf="paymentMade" class="description">
					<span class="final-payment-text-span">Payment Made: </span> {{paymentMade}}
				</div>
				<div *ngIf="paymentMethod" class="description mb-3 c-mtm-black">
					<span class="final-payment-text-span">Payment Method: </span> {{paymentMethod}}
				</div>
				<div *ngIf="amount" class="description mb-3 c-mtm-black">
					<span class="final-payment-text-span">Amount: </span> {{amount}} {{currency}}
				</div>
				<div *ngIf="downpaymentAmount" class="description mb-3 c-mtm-black">
					<span class="final-payment-text-span">Downpayment Amount: </span> {{downpaymentAmount}}
					<span>{{currency}}</span>
				</div>
				<div *ngIf="totalMTM" class="description mb-3 c-mtm-black">
					<span class="final-payment-text-span">MTM Fees Amount: </span> {{totalMTM}}
					<span>{{currency}}</span>
				</div>
				<div *ngIf="currency" class="description mb-3 c-mtm-black">
					<span class="final-payment-text-span">Currency: </span> {{currency}}
				</div>
				<div *ngIf="descriptionMeta" class="description-meta mb-3 c-mtm-black">
					{{descriptionMeta}}
				</div>
				<div *ngIf="descriptionMeta2" class="description-meta mb-3 c-mtm-black">
					{{descriptionMeta2}}
				</div>
				<div *ngIf="descriptionMeta3" class="description-meta mb-3 c-mtm-black">
					{{descriptionMeta3}}
				</div>
				<div *ngIf="note" class="note c-mtm-black">
					{{note}}
				</div>
				<div *ngIf="noteInfo" class="note-info mb-3 c-mtm-black" [innerHTML]="noteInfo">
				</div>
				<div *ngIf="!hideButton" class="d-flex align-items-center justify-content-center mt-4"
					[ngClass]="{ 'flex-row-reverse': reverseButton }">
					<button class="btn b-mtm-grey c-mtm-purple t-uppercase _600" (click)="onCancelModal('cancel')"
						*ngIf="cancelBtn">{{cancelBtn}}</button>
					<button
						[ngClass]="{ 'b-mtm-green': notificationType === 'success', 'b-mtm-red': notificationType === 'danger', 'b-mtm-brand': !notificationType  }"
						class="btn c-full-white t-uppercase _600" (click)="onConfirmModal()">{{confirmBtn}}</button>
				</div>
			</div>
		</div>

	</div>
</div>