import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";

//this is similar to mtm-item-icon, but without encapsulation & width, for easier responsive styling
@Component({
  selector: 'mtm-image',
  templateUrl: './mtm-image.component.html',
  styleUrls: ['./mtm-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MtmImageComponent implements OnInit, OnChanges {
  @Input() name: string = '';
  @Input() imageUrl: string = '';
  @Input() cacheBuster: string = '';
  @Input() imageLoading: 'lazy' | 'eager' = 'lazy';
  @Input() generatedTextColor: boolean = false;
  @Input() generatedBackground: string = '#000';
  @Input() isSquare: boolean = false;
  @Input() borderRadius: string = '0';
  @Input() objectFit: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down' = 'contain';
  @Input() cssClass: string = '';

  initialColor: string = '';
  initialName: string = '';
  finalImageUrl: string = '';

  ngOnInit() {
    this.setValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setValues();
  }

  private getInitial(fullName = '') {
    if(! fullName){
      return '';
    }
    const allNames = fullName.trim().split(' ');
    return allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
  }

  private stringToHslColor(initial: string) {
    if (!initial) return;
    let hash = 0;
    for (let i = 0; i < initial.length; i++) {
      hash = initial.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', 75%, 62%)';
  }


  private setValues(){
    this.initialName = this.getInitial(this.name);
    this.initialColor = this.stringToHslColor(this.initialName);
    this.finalImageUrl = this.cacheBuster ? `${this.imageUrl}?v=${this.cacheBuster}` : this.imageUrl;
  }
}
