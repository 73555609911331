<div class="preview-all-sub-asset">
    <div class="nav-close-times" (click)="activeModal.close()">&times;</div>
    <div class="items-outer">
        <div class="d-flex flex-column flex-lg-row align-items-center flex-lg-wrap items-container">
            <div class="item item-mb" [ngClass]="{'active': item.isActive}" *ngFor="let item of asset.items; let i = index"
                (click)="activeModal.close({index: i, item: item})">
                <div class="preview-image w-100" [assetPreviewDirective]="item"></div>
                <div class="display-name w-100 d-flex align-items-center">
                    <span>{{item.displayName}}</span>
                </div>
            </div>
        </div>
    </div>
</div>