import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
// import { NGValidators } from "ng-validators";
import { ErrorMessageService } from "../../shared/services/error-message.service";
import { AuthService } from "../../shared/services/auth.service";
import { RegistrationService } from './../../shared/services/registration.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	templateUrl: './signup-form.component.html',
	styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit, OnDestroy {

	signupForm: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	responseMsg: string;
	ngUnsubscribe = new Subject();
	isLoading: boolean = false;
	
	constructor(private fb: UntypedFormBuilder, private router: Router, public activeModal: NgbActiveModal,
		private authService: AuthService, private registrationService: RegistrationService) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}


	ngOnInit() {
		// Load form group
		this.loadFormGroup();
	}


	/**
	 * Submit signup form
	 */
	onSubmit() {
		this.submitted = true;

		if (this.signupForm.valid) {
			this.isLoading = true;
			this.registrationService.requestInvitation(this.signupForm.controls['firstName'].value,
				this.signupForm.controls['lastName'].value,
				this.signupForm.controls['company'].value,
				this.signupForm.controls['email'].value,
				this.signupForm.controls['phone'].value,
				this.signupForm.controls['message'].value).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe(
					(data: any) => {
						this.responseMsg = 'Thank you for registering\nWe will contact shortly';
						this.isLoading = false;
					},
					(err: any) => {
						if (err.errorCode === 'EMAIL_EXISTS') {
							this.responseMsg = err.message;
						} else {
							this.responseMsg = 'There was an error trying to process your invitation request.\nPlease, try again';
						}
						this.isLoading = false;
					});
		}
	}


	// ****************************************************************************************************************


	/**
	 * Load form group form signup form
	 */
	private loadFormGroup() {

		// Build FormGroup
		this.signupForm = this.fb.group({
			firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			company: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', [Validators.required, Validators.pattern(/\+[1-9][0-9]{3,14}/)]],
			message: ['', [Validators.maxLength(250)]]
		});

		// Subscribe to changes to get validation errors
		this.signupForm.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.signupForm)
			this.formErrors = ErrorMessageService.getFormErrors(this.signupForm);
	}
}
