import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  templateUrl: './registration-landing.component.html',
  styleUrls: ['./registration-landing.component.scss']
})
export class RegistrationLandingComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  year: string = moment().format('YYYY');
  email: string = '';
  private ngUnsubscribe = new Subject<boolean>();
  private urlParams: Params;

  ngOnInit() {
    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: params => {
        this.email = params['email'] ?? '';
        this.urlParams = params;
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  continueWithGoogle() {
    this.router.navigate(['/entry/auth/google']);
  }

  continueWithMicrosoft() {
    this.router.navigate(['/entry/auth/azure']);
  }

  continueWithEmail() {
    this.router.navigate(['/entry/auth/process'], {
      queryParams: this.urlParams
    })
  }
}
