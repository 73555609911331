import { AfterViewInit, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MTMFileDownload } from "../../../services/signed-url/mtm-file-download";
import {
  FileTransferService,
  listenerDownloadStatusChanged, listenerFileDownloadRemoved,
  listenerFileDownloadLoaded, listenerFileDownloadResumed, TransferStatus, listenerDownloadProgressChanged
} from "../../../services/signed-url";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { TranslatePipe } from "../../../pipes/translate.pipe";

@Component({
  selector: 'mtm-download-popup',
  templateUrl: './download-popup.component.html',
  styleUrls: ['./download-popup.component.scss']
})
export class DownloadPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  transferService = inject(FileTransferService);
  translatePipe = inject(TranslatePipe);

  downloadList: MTMFileDownload[] = [];
  caption: string = '';
  captionParams: any = {};
  isExpanded: boolean = true;
  ngUnsubscribe = new Subject<boolean>();
  inProgressStatusList = [TransferStatus.WAITING, TransferStatus.DOWNLOADING];
  remainingTimeText: string = '';

  ngOnInit() {
    listenerFileDownloadLoaded.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        console.log('download list loaded');
        this.updateDownloadList();
      }
    });

    listenerDownloadStatusChanged.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.updateDownloadList();
      }
    });

    listenerFileDownloadRemoved.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.updateDownloadList();
      }
    });

    listenerFileDownloadResumed.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (silentMode: any) => {
        this.updateDownloadList();
      }
    });

    listenerDownloadProgressChanged.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.syncRemainingTime();
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.transferService.loadActiveDownloads()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => { })
  }

  private updateDownloadList() {
    this.syncDownloadList();
    this.updateCaption();
    this.syncRemainingTime();
  }

  private syncDownloadList() {
    const downloadList = [...this.transferService.filesToDownload];
    downloadList.forEach(file => {
      const fileType = file.contentType;
      let mime = fileType.substring(fileType.lastIndexOf("/"));
      if (!file.iconUrl) {
        file.iconUrl = '/assets/img/icons/' + mime + '.svg';
      }
    });
    this.downloadList = downloadList;
  }

  private updateCaption() {
    this.captionParams = {};
    if (this.downloadList.some(file => this.inProgressStatusList.includes(file.status))) {
      this.caption = 'fileTransfer_downloadInProgress';
      return;
    }
    const statusList = new Set(this.downloadList.map(file => file.status));
    if (statusList.has(TransferStatus.COMPLETED)) {
      const totalCompleted = this.downloadList.filter(file => file.status === TransferStatus.COMPLETED).length;
      this.caption = `fileTransfer_downloadCompleted`;
      this.captionParams = { count: totalCompleted };
    } else if (statusList.has(TransferStatus.PAUSED)) {
      this.caption = 'fileTransfer_downloadPaused';
    } else if (statusList.has(TransferStatus.ERROR)) {
      this.caption = 'fileTransfer_downloadFailed';
    } else if (statusList.has(TransferStatus.INACTIVE)) {
      this.caption = 'fileTransfer_downloadInactive';
    } else if (statusList.has(TransferStatus.CANCELED)) {
      this.caption = 'fileTransfer_downloadCanceled';
    }
    return;
  }

  toggleExpanded(event: MouseEvent) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }

  removeFile(file: MTMFileDownload) {
    this.downloadList = this.downloadList.filter(item => item !== file);
  }

  private syncRemainingTime() {
    const hour = 3600 * 1000;
    const minute = 60000;
    const second = 1000;
    let remaining = 0;
    this.downloadList.forEach(file => {
      if (!file.isResumable && file.status === TransferStatus.DOWNLOADING
        && !!file.extraInfo.remainingTime && file.extraInfo.remainingTime > remaining) {
        remaining = file.extraInfo.remainingTime;
      }
    });
    if (remaining > 0) {
      const duration = moment.duration(remaining);
      if (remaining >= hour) {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_hoursRemaining', { remaining: Math.floor(duration.asHours()) });
      } else if (remaining >= minute) {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_minutesRemaining', { remaining: Math.floor(duration.asMinutes()) });
      } else {
        this.remainingTimeText = this.translatePipe.transform('fileTransfer_minutesRemaining', { remaining: 1 });
      }
    } else {
      this.remainingTimeText = '';
    }
  }
}
