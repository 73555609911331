<div class="card" [ngClass]="{ 'cpd-company': isCpd }" *ngIf="timelineLoaded">
    <div class="card-header mt-4 p-0">
        <h6 class="information-title">{{'generalInformations' | translate}}</h6>
    </div>
    <div class="card-block mb-2">
        <form [formGroup]="generalInfoForm">
            <!-- drive camapaign banner -->
             <!-- Note:- commenting because not in use for now -->
            <!-- <div class="row mb-2">
                <div class="col p-0" id="newProjectBrandFileUpload">
                    <mtm-upload-file class="d-inline-block" [preDefinedFilter]="['IMAGE_FILTER']"
                        [isRoundedCircle]=false [isRedirectImageLoadError]=false buttonTitle="drive_campaign_banner"
                        [imageURL]="banner | cacheBusterImage: cacheBuster" [disabled]="false" [isProjectV2Brand]="true"
                        (files)="brandFileChangeListener($event)" [style.maxWidth]="'100%'"
                        [style.maxHeight]="'60px !important'" [style.minWidth]="'100%'"
                        [style.minHeight]="'60px !important'"
                        [maxSizeMB]="15">
                    </mtm-upload-file>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    <div class="row justify-content-center">
                        <div class="col-lg-2 col-md-6 col-sm-12 file-dropzone" id="newProjectFileUpload">
                            <mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=false
                                [disabled]="false" [isRedirectImageLoadError]=false [isProjectV2]="true"
                                [imageURL]="driveCampaignImage | cacheBusterImage: cacheBuster"
                                (files)="fileChangeListener($event)"
                                [maxSizeMB]="15">
                            </mtm-upload-file>
                        </div>
                        <div class="col-lg-10 col-sm-12 col-md-6 pr-0 w-100 mt-1">
                            <div [ngClass]="isCpd ? 'cpd-project-type-section' : 'normal-project-type-section'">
                                <div class="w-100">
                                    <span class="form-title"> {{'campaignName' | translate}} </span>
                                    <input [ngClass]="{'has-danger': isValidGeneralForm('name')}" class="mt-1 project-name form-control" formControlName="name" type="text">
                                </div>
                                <div *ngIf="!isCpd" class="w-100" >
                                    <span class="form-title"> {{'projectCompanyProductName' | translate}} </span>
                                    <input [ngClass]="{'has-danger': isValidGeneralForm('productName')}" class="mt-1 project-name form-control" formControlName="productName" type="text">
                                </div>
                                <div class="w-100 workspace-dropdown" [style.margin-left]="'10px'">
                                    <span class="form-title">{{'WorkspaceAxe' | translate}}</span>
                                    <span class="form-title"> {{workspaceDisabled}} </span>
                                    <select class="select-workspace mt-1 w-100" formControlName="workspace"
                                        (change)="changeWorkspace()" [ngClass]="{'has-danger': !isworkspaceValid('workspace'), 'disable-workspace':workspaceDisabled}" [disabled]="workspaceDisabled">
                                        <option [value]="''">{{'selectWorkspace' | translate}}</option>
                                        <option *ngFor="let workspace of workspaces" [value]="workspace.name">{{
                                            workspace.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-4">
                                <span class="form-title"> {{'description' | translate}} </span>
                                <input class="mt-1 form-control description" [ngClass]="{'has-danger': isValidGeneralForm('description')}" formControlName="description" type="text">
                            </div>
                            <div [ngClass]="isCpd ? 'cpd-user-dropdown' : 'user-dropdown'">
                                <div class="mt-4 w-100">
                                    <span class="form-title"> {{'campaignLeader' | translate}} </span>
                                    <div class="row">
                                        <div *ngIf="!isParticipantsLoaded"
                                            class="mt-1 ml-3 d-flex justify-content-start align-items-center project-users form-control">
                                            <div class="d-flex loading-dots ">
                                                <div class="loading-dots--dot"></div>
                                                <div class="loading-dots--dot"></div>
                                                <div class="loading-dots--dot"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="isParticipantsLoaded" class="col" formControlName="projectLeaders">
                                            <div class="mt-2">
                                                <div class="form-field col-section mr-2"
                                                    [ngClass]="{ 'has-error': !existingLeaders?.length }" >
                                                    <div class="d-flex align-items-center collaborators-dropdown" container="body"
                                                        ngbDropdown>
                                                        <div class=" mt-auto d-flex align-items-center p-1 w-100"
                                                            ngbDropdownToggle [ngClass]="{'has-danger': !existingLeaders?.length && submitted}">
                                                            <div class="ellipse-text flex-fill mr-1 ">
                                                                <span *ngIf="!existingLeaders?.length" class="c-mtm-full-black">
                                                                    {{'SelectProjectLeader' | translate}}</span>
                                                                <div *ngIf="existingLeaders?.length" class="task-collaborators">
                                                                    <div
                                                                        class="task-collaborator-wrapper d-flex align-items-center">
                                                                        <div class="collaborator-avatar mr-2 d-flex align-items-center"
                                                                            *ngFor="let owner of existingLeaders">
                                                                            <img class="participant-img mr-1"
                                                                                *ngIf="owner.avatarUrl"
                                                                                [src]="owner.avatarUrl" alt="">
                                                                            <span *ngIf="!owner.avatarUrl"
                                                                                class="c-full-white participant-img text-uppercase mr-1"
                                                                                [style.backgroundColor]="timelineService.getColorFromName(owner.fullName)">
                                                                                {{owner.fullName | initialName}}
                                                                            </span>
                                                                            <span>{{owner.fullName}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <i class="fa fa-chevron-down ml-auto pointer mr-1"></i>
                                                        </div>
                                                        <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                                                            <div class="p-1 w-100">
                                                                <input type="text" placeholder="Search..."
                                                                    class="search-text"
                                                                    (keyup)="searchParticipants($event)" />
                                                            </div>
                                                            <div *ngFor="let participant of (!!selectedWorkspace ? leaderParticipantsBySelectedWorkspace : leaderParticipantsByBrand)"
                                                                class="d-flex align-items-center pointer"
                                                                (click)="ownerSelected($event, participant)"
                                                                ngbDropdownItem>
                                                                <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                                                                    [ngClass]="{ 'selected': participant.selected }">
                                                                    <i class="fa fa-check"></i>
                                                                </div>
                                                                <img class="participant-img" *ngIf="participant.avatarUrl"
                                                                    [src]="participant.avatarUrl" alt="">
                                                                <span *ngIf="!participant.avatarUrl"
                                                                    class="c-full-white participant-img text-uppercase"
                                                                    [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                                                                    {{participant.fullName | initialName}}
                                                                </span>
                                                                <span class="ml-2 _600">{{participant.fullName}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <input [disabled]="!canEditOwner" class="mt-1 project-users form-control" formControlName="projectLeaders" type="text"> -->
                                </div>
                                <div *ngIf="!isCpd" class="mt-4 w-100">
                                    <span class="form-title"> {{'projectManager' | translate}} </span>
                                    <div class="row">
                                        <div *ngIf="!isParticipantsLoaded"
                                            class="mt-1 ml-3 d-flex justify-content-start align-items-center project-users form-control">
                                            <div class="d-flex loading-dots ">
                                                <div class="loading-dots--dot"></div>
                                                <div class="loading-dots--dot"></div>
                                                <div class="loading-dots--dot"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="isParticipantsLoaded && !isCpd" class="col" formControlName="cncManager">
                                            <div class="mt-2">
                                                <div class="form-field col-section"
                                                    [ngClass]="{ 'has-error': !existingManagers?.length }" >
                                                    <div class="d-flex align-items-center collaborators-dropdown" container="body"
                                                        ngbDropdown>
                                                        <div class=" mt-auto d-flex align-items-center p-1 w-100"
                                                            ngbDropdownToggle [ngClass]="{'has-danger': !existingManagers?.length && submitted}">
                                                            <div class="ellipse-text flex-fill mr-1">
                                                                <span *ngIf="!existingManagers?.length" class="c-mtm-full-black">
                                                                    {{'SelectProjectManager' | translate}}</span>
                                                                <div *ngIf="existingManagers?.length" class="task-collaborators">
                                                                    <div
                                                                        class="task-collaborator-wrapper d-flex align-items-center">
                                                                        <div class="collaborator-avatar mr-2 d-flex align-items-start"
                                                                            *ngFor="let owner of existingManagers">
                                                                            <img class="participant-img mr-1"
                                                                                *ngIf="owner.avatarUrl"
                                                                                [src]="owner.avatarUrl" alt="">
                                                                            <span *ngIf="!owner.avatarUrl"
                                                                                class="c-full-white participant-img text-uppercase mr-1"
                                                                                [style.backgroundColor]="timelineService.getColorFromName(owner.fullName)">
                                                                                {{owner.fullName | initialName}}
                                                                            </span>
                                                                            <span>{{owner.fullName}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <i class="fa fa-chevron-down ml-auto pointer mr-1"></i>
                                                        </div>
                                                        <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                                                            <div class="p-1 w-100">
                                                                <input type="text" placeholder="Search..."
                                                                    class="search-text"
                                                                    (keyup)="searchManagerParticipants($event)" />
                                                            </div>
                                                            <div *ngFor="let participant of (!!selectedWorkspace ? managerParticipantsBySelectedWorkspace : managerParticipantsByBrand)"
                                                                class="d-flex align-items-center pointer"
                                                                (click)="managerSelected($event, participant)"
                                                                ngbDropdownItem>
                                                                <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                                                                    [ngClass]="{ 'selected': participant.selectedAsManager }">
                                                                    <i class="fa fa-check"></i>
                                                                </div>
                                                                <img class="participant-img" *ngIf="participant.avatarUrl"
                                                                    [src]="participant.avatarUrl" alt="">
                                                                <span *ngIf="!participant.avatarUrl"
                                                                    class="c-full-white participant-img text-uppercase"
                                                                    [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                                                                    {{participant.fullName | initialName}}
                                                                </span>
                                                                <span class="ml-2 _600">{{participant.fullName}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <input [disabled]="!canEditOwner" class="mt-1 project-users form-control" formControlName="projectLeaders" type="text"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <form [formGroup]="criteriaForm">
        <div class="p-0 tabs">
            <div class="project-type-section">
                <div class="col">
                    <h6 class="information-title m-0">{{'WhatsYourTypeOfCampaign' | translate}}</h6>
                </div>
                <div *ngIf="isProjectSelected" class="col justify-content-center d-flex flex-wrap align-content-end flex-column-reverse"
                    (click)="resetCriteriaForm(); changeProjectType()">
                    <button class="text-uppercase change-project-type">
                        <img class="mr-1" src=".../../../../../assets/img/project-v2/fa-chevron-left.svg" />
                        {{'ChangeTypeOfCampaign' | translate}}
                    </button>
                </div>
            </div>
            <div *ngIf="!isProjectSelected && !isCpd" class="project-type-section mt-3  justify-content-between align-items-center"
                [ngClass]="{'campaign-select-error': isValid('Project Type')}">
                <div class="col p-0 campaign-image-section d-flex align-items-center justify-content-center"
                    (click)="selectProjectType(productCampaign); removeObjectFromCriteriaForm(['Set Type', 'POSM Type', 'Include for China', 'Hallmarks Type',  'Collector', 'Set'])">
                    <span class="project-type-title">{{'productCampaign' | translate}}</span>
                </div>
                <div class="p-3">
                    <span class="d-flex justify-content-center align-items-center _500 partition-title"> or </span>
                </div>
                <div class="col p-0 hallmark-image-section d-flex align-items-center justify-content-center"
                    (click)="selectProjectType(hallmarks); removeObjectFromCriteriaForm(['Product Launch Type', 'DMI Produce Type', 'Campaign Type'])">
                    <span class="project-type-title">{{'hallmarks' | translate}}</span>
                </div>
            </div>
            <div *ngIf="!isProjectSelected && isCpd" class="row mt-3 d-flex justify-content-between align-items-center"
                [ngClass]="{'campaign-select-error': isValid('Project Type')}">
                <div class="col ml-3 p-0 product-campaign-image-section d-flex align-items-center justify-content-center"
                    (click)="selectCpdProjectType(cpdProductCampaign); removeObjectFromCriteriaForm(['priority'])">
                </div>
                <div class="p-3">
                    <span class="d-flex justify-content-center align-items-center _500 partition-title"> or </span>
                </div>
                <div class="col mr-3 p-0 brand-campaign-image-section d-flex align-items-center justify-content-center"
                    (click)="selectCpdProjectType(cpdBrandCampaign); removeObjectFromCriteriaForm(['Campaign Leading', 'Campaign Type', 'Repush Type', 'Product Category', 'Product Subcategory', 'DMI Based', 'priority'])">
                </div>
            </div>
            <div *ngIf="!isCpd">
                <div *ngIf="isProjectSelected && isProductCampaign" class="mt-3">
                    <div class="p-0 productCampaign-project-selected d-flex align-items-center justify-content-center">
                        <span class="project-type-title">{{'productCampaign' | translate}}
                            <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                        </span>
                    </div>
                    <div class="row w-100 mt-3">
                        <div class="row w-100 mt-3 align-items-center project-type-section">
                            <div class="col-2 mw-100">
                                <span class="seleted-project-title">{{'ProductCategory' | translate}}</span>
                            </div>
                            <div class="col-4 mw-100">
                                <div class="col p-0">
                                    <div class="select-wrapper">
                                        <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Product Launch Type')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!selectedProductCategory && !cf['Product Launch Type']?.value"
                                                        class="c-mtm-full-black">{{'selectedProductCategory' | translate
                                                        }}</span>
                                                    <div *ngIf="selectedProductCategory || cf['Product Launch Type']?.value " class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex">
                                                                <span
                                                                    class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">{{(selectedProductCategory ? selectedProductCategory  : cf['Product Launch Type'].value
                                                                    )| translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class=" w-100" ngbDropdownMenu>
                                                <div *ngFor="let category of productCategory"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getProductCategory(category)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ category.name | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Launch Type']?.value && categoryComplexity.length">
                        <div *ngIf="cf['Product Launch Type']?.value && categoryComplexity.length" class="row w-100 mt-3 align-items-center project-type-section">
                            <div class="col-2 mw-100">
                                <span class="seleted-project-title">{{'complexity' | translate}}</span>
                            </div>
                            <div class="col-3 mw-100">
                                <div class="col p-0">
                                    <div class="select-wrapper">
                                        <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Complexity')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!selectedComplexityCategory && !cf['Complexity']?.value"
                                                        class="c-mtm-full-black">{{'selectedComplexityCategory' | translate
                                                        }}</span>
                                                    <div *ngIf="selectedComplexityCategory || cf['Complexity']?.value" class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex">
                                                                <span
                                                                    class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">{{(selectedComplexityCategory ? selectedComplexityCategory : cf['Complexity'].value)
                                                                    | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class="w-100" ngbDropdownMenu>
                                                <div *ngFor="let category of categoryComplexity"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getComplexity(category)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ category.name | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Complexity']?.value || cf['Product Launch Type']?.value == 'No Product Launch Only Campaign' || cf['Product Launch Type']?.value =='No Product Launch Only Campaign'">
                        <div class="row w-100 mt-3 align-items-center project-type-section" *ngIf="cf['Complexity']?.value || cf['Product Launch Type']?.value == 'No Product Launch Only Campaign' || cf['Product Launch Type']?.value =='No Product Launch Only Campaign'">
                            <div class="col-2 mw-100">
                                <span class="seleted-project-title">
                                    <img class="mr-2" src="../../../assets/img/selection-grid-edit/questionmarkicon.svg"
                                        [ngbTooltip]="campaignTooltip" alt="">
                                    {{'CampaignType' | translate}}</span>
                            </div>
                            <ng-template #campaignTooltip>
                                <div [style.minWidth]="'175px'" class="p-1">
                                    <div class="text-left">{{'CampaignTypeTooltip' | translate}}</div>
                                </div>
                            </ng-template>
                            <div class="col-3 mw-100">
                                <div class="col p-0">
                                    <div class="select-wrapper">
                                        <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Campaign Type')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!selectedLCampaignTypeCategory && !cf['Campaign Type'].value"
                                                        class="c-mtm-full-black">{{'selectedLCampaignTypeCategory' | translate }}</span>
                                                    <div *ngIf="selectedLCampaignTypeCategory || cf['Campaign Type'].value" class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex ">
                                                                <span
                                                                    class="c-mtm-full-black _400">{{'campaignType_' + (selectedLCampaignTypeCategory ? selectedLCampaignTypeCategory : cf['Campaign Type'].value) | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class="w-100" ngbDropdownMenu>
                                                <div *ngFor="let category of ( cf['Product Launch Type']?.value =='No Product Launch Only Campaign' ? campaignOnlyTypeCategory : campaignTypeCategory)"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getCampaignType(category)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ 'campaignType_' + category.name | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 mt-4 align-items-center project-type-section" *ngIf="cf['Campaign Type'].value=='Standard'||cf['Campaign Type'].value=='New Territory'">
                            <div class="col-2 mw-100" *ngIf="cf['POSM']">
                                <span class="seleted-project-title">{{'posm' | translate}}</span>
                            </div>
                            <div class="col-3 mw-100" *ngIf="cf['POSM']">
                                <div class="col p-0 d-flex align-items-center">
                                    <input class="custom-radio-btn" formControlName="POSM" type="radio" value="Yes" [style.height]="'15px'" (click)="addObjectInCriteriaForm(['DMI Produce Type'])">
                                    <label class="custom-radio-label _700 my-0 ml-0 mr-5"
                                        [ngClass]="{'text-danger': isValid('POSM')}">{{'Yes' | translate}}</label>
                                    <input class="custom-radio-btn" [ngClass]="{'has-danger p-2': isValid('POSM')}" [style.height]="'15px'" (click)="removeObjectFromCriteriaForm(['DMI Produce Type'])"
                                        formControlName="POSM" type="radio" value="No">
                                    <label class="custom-radio-label _700 my-0 ml-0 mr-5"
                                        [ngClass]="{'text-danger': isValid('POSM')}">{{'No' | translate}}</label>
                                </div>
                            </div>
                            <div class="col 7 mw-100" *ngIf="cf['POSM'] && cf['POSM'].value == 'Yes'">
                                <div class="col p-0 d-flex align-items-center">
                                    <input class="custom-radio-btn" formControlName="DMI Produce Type" type="radio" value="Yes" [style.height]="'15px'">
                                    <label class="custom-radio-label _700 my-0 ml-0 mr-5" [ngClass]="{'text-danger': isValid('DMI Produce Type')}">{{'DMI Produced' | translate}}</label>
                                    <input class="custom-radio-btn" formControlName="DMI Produce Type" type="radio" value="No" [style.height]="'15px'">
                                    <label class="custom-radio-label _700 my-0 ml-0 mr-5" [ngClass]="{'text-danger': isValid('DMI Produce Type')}">{{'Locally Produced' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isProjectSelected && !isProductCampaign " class="mt-3">
                    <div class="w-100 p-0 animation-project-selected d-flex align-items-center justify-content-center">
                        <span class="project-type-title">{{'hallmarks' | translate}}
                            <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                        </span>
                    </div>
                    <div class="row w-100 mt-4 align-items-center h-100 project-type-section" [style.min-height]="'35px'">
                        <div class="col-3 mw-100">
                            <span class="seleted-project-title">{{'What is the type of Hallmarks?' | translate}}</span>
                        </div>
                        <div class="col-9 p-0 mw-100">
                            <div class="row justify-content-evenly d-flex align-items-center project-type-section ">
                                <div class="d-flex align-items-center" [style.max-width]="'280px'" [style.width]="'100%'">
                                    <input class="custom-radio-btn" formControlName="Hallmarks Type" type="radio" value="Global Hallmarks" (click)="addObjectInCriteriaForm(['Include for China'])">
                                    <label class="custom-radio-label _700 m-0"
                                        [ngClass]="{'text-danger': isValid('Hallmarks Type')}">{{'Global Hallmark (Not Holiday)' | translate}}</label>
                                </div>
                                <div class="d-flex align-items-center" [style.max-width]="'200px'" [style.width]="'100%'">
                                    <input class="custom-radio-btn" formControlName="Hallmarks Type" type="radio" value="Holiday" (click)="hallmarkHolidaySelected();addObjectInCriteriaForm(['Include for China'])">
                                    <label class="custom-radio-label _700 m-0"
                                        [ngClass]="{'text-danger': isValid('Hallmarks Type')}">{{'Holiday' | translate}}</label>
                                </div>
                                <div class="d-flex align-items-center" [style.max-width]="'479px'" [style.width]="'100%'">
                                    <input class="custom-radio-btn" formControlName="Hallmarks Type" (click)="removeObjectFromCriteriaForm(['Include for China'])" type="radio" value="China Specific Hallmarks">
                                    <label class="custom-radio-label _700 m-0"
                                        [ngClass]="{'text-danger': isValid('Hallmarks Type')}">{{'China Specific Hallmarks' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-3" *ngIf="cf['Hallmarks Type']?.value">
                    <div class="row w-100 mt-3 align-items-center project-type-section h-100 flex-nowrap" *ngIf="cf['Hallmarks Type']?.value" [style.min-height]="'35px'">
                        <div class="col-3 mw-100">
                            <span class="seleted-project-title">{{'collector' | translate}}</span>
                        </div>
                        <div class="col-9 p-0 mw-100">
                            <div class="row align-items-center project-type-section flex-nowrap">
                                <div class="col d-flex flex-row" [style.max-width]="'280px'" [style.width]="'100%'">
                                    <div class="row justify-content-evenly d-flex align-items-center">
                                        <input class="custom-radio-btn" formControlName="Collector" type="radio" value="Yes" (click)="addObjectInCriteriaForm(['Include for China', 'Complexity'])">
                                        <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Collector')}">{{'Yes' | translate}}</label>
                                        <input class="custom-radio-btn" formControlName="Collector" type="radio" value="No" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'" (click)="removeObjectFromCriteriaForm(['Include for China', 'Complexity'])">
                                        <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Collector')}" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">{{'No' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col" *ngIf="cf['Collector']?.value == 'Yes' && cf['Hallmarks Type']?.value != 'China Specific Hallmarks'">
                                    <div class="row justify-content-evenly flex-nowrap" *ngIf="cf['Collector']?.value == 'Yes'">
                                        <div class="d-flex align-items-center" [style.max-width]="'200px'" [style.width]="'100%'">
                                            <input class="custom-radio-btn" formControlName="Include for China" type="radio" value="Yes">
                                            <label class="custom-radio-label _700 m-0"
                                                [ngClass]="{'text-danger': isValid('Include for China')}">{{'Include for China' | translate}}</label>
                                        </div>
                                        <div class="d-flex align-items-center " [style.max-width]="'200px'" >
                                            <input class="custom-radio-btn" formControlName="Include for China" type="radio" value="No">
                                            <label class="custom-radio-label _700 m-0"
                                                [ngClass]="{'text-danger': isValid('Include for China')}">{{'Not for China' | translate}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center project-type-section" *ngIf="cf['Collector']?.value == 'Yes' && (cf['Include for China']?.value || cf['Hallmarks Type']?.value=='China Specific Hallmarks')"  [style.max-width]="'279px'" [style.width]="'100%'">
                                    <div class="col-4">
                                        <span class="seleted-project-title">{{'complexity' | translate}}</span>
                                    </div>
                                    <div class="col p-0">
                                        <div class="select-wrapper">
                                            <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                                <div [ngClass]="{'has-danger p-2': isValid('Complexity')}"
                                                    class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                    <div class="ellipse-text flex-fill mr-1">
                                                        <span *ngIf="!hallmarkSelectedComplexityCategory && !cf['Complexity']?.value"
                                                            class="c-mtm-full-black">{{'hallmarkSelectedComplexityCategory' | translate }}</span>
                                                        <div *ngIf="hallmarkSelectedComplexityCategory || cf['Complexity']?.value" class="task-collaborators">
                                                            <div class="task-collaborator-wrapper d-flex align-items-center">
                                                                <div class="collaborator-avatar d-flex">
                                                                    <span class="c-mtm-full-black _400">{{(hallmarkSelectedComplexityCategory ? hallmarkSelectedComplexityCategory : cf['Complexity'].value
                                                                        )| translate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                                </div>
                                                <div class="w-100" ngbDropdownMenu>
                                                    <div *ngFor="let category of hallmarksComplexicity"
                                                        class="d-flex align-items-center pointer"
                                                        (click)="getHallmarkComplexity(category)" ngbDropdownItem>
                                                        <span class="ml-2 _400">{{ category.name | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-3" *ngIf="cf['Complexity']?.value || cf['Collector'].value=='No'">
                    <div class="row w-100 mt-3 align-items-center h-100 project-type-section" *ngIf="cf['Complexity']?.value || cf['Collector'].value=='No'" [style.min-height]="'35px'">
                        <div class="col-3">
                            <span class="seleted-project-title">{{'set' | translate}}</span>
                        </div>
                        <div class="col" [style.max-width]="'280px'" [style.width]="'100%'">
                            <div class="row justify-content-evenly">
                                <div class="col p-0 justify-content-evenly d-flex align-items-center">
                                    <input class="custom-radio-btn" formControlName="Set" type="radio" value="Yes" (click)="addObjectInCriteriaForm(['Set Type'])">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Set')}">{{'Yes' | translate}}</label>
                                    <input class="custom-radio-btn" formControlName="Set" type="radio" value="No" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'" (click)="removeObjectFromCriteriaForm(['Set Type'])">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Set')}" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">{{'No' | translate}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col" *ngIf="cf['Set']?.value=='Yes'">
                            <div class="row justify-content-evenly flex-nowrap">
                                <div class="d-flex align-items-center w-100" [style.max-width]="'200px'">
                                    <input class="custom-radio-btn" formControlName="Set Type" type="radio" value="Yes">
                                    <label class="custom-radio-label _700 mb-0" [ngClass]="{'text-danger': isValid('Set Type')}">{{'DMI Produced' | translate}}</label>
                                </div>
                                <div class="d-flex align-items-center" [style.max-width]="'200px'">
                                    <input class="custom-radio-btn" formControlName="Set Type" type="radio" value="No" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">
                                    <label class="custom-radio-label _700 mb-0" [ngClass]="{'text-danger': isValid('Set Type')}" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">{{'Locally Produced' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-3" *ngIf="(cf['Set Type']?.value && cf['Set']?.value=='Yes') || cf['Set']?.value == 'No'">
                    <div class="row w-100 mt-3 align-items-center project-type-section" *ngIf="(cf['Set Type']?.value && cf['Set']?.value=='Yes') || cf['Set']?.value == 'No'" [style.min-height]="'35px'">
                        <div class="col-3">
                            <span class="seleted-project-title">{{'posm' | translate}}</span>
                        </div>
                        <div class="col" [style.max-width]="'280px'" [style.width]="'100%'">
                            <div class="row justify-content-evenly">
                                <div class="col p-0 justify-content-evenly d-flex align-items-center">
                                    <input class="custom-radio-btn" formControlName="POSM" type="radio" value="Yes" (click)="addObjectInCriteriaForm(['POSM Type'])">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('POSM')}">{{(cf['Hallmarks Type']?.value != 'China Specific Hallmarks' ? 'Yes' : 'Yes, Locally Produced') | translate}}</label>
                                    <input class="custom-radio-btn" formControlName="POSM" type="radio" value="No" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'" (click)="removeObjectFromCriteriaForm(['POSM Type'])">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('POSM')}" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">{{'No' | translate}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col" *ngIf="cf['POSM']?.value=='Yes' && cf['Hallmarks Type']?.value != 'China Specific Hallmarks'">
                            <div class="row justify-content-evenly flex-nowrap">
                                <div class="d-flex align-items-center" [style.max-width]="'200px'" [style.width]="'100%'">
                                    <input class="custom-radio-btn" formControlName="POSM Type" type="radio" value="Yes">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('POSM Type')}">{{'DMI Produced' | translate}}</label>
                                </div>
                                <div class="d-flex align-items-center" [style.max-width]="'200px'" [style.width]="'100%'">
                                    <input class="custom-radio-btn" formControlName="POSM Type" type="radio" value="No" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('POSM Type')}" *ngIf="cf['Hallmarks Type']?.value != 'Holiday'">{{'Locally Produced' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isCpd">
                <div *ngIf="isProjectSelected && isCpdProductCampaign" class="mt-3">
                    <div class="p-0 cpd-productCampaign-project-selected d-flex align-items-center justify-content-center">
                        <span class="project-type-title">{{'productCampaign' | translate}}
                            <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                        </span>
                    </div>
                    <div class="row w-100 mt-3 ml-0">
                        <div class="cpd-project-type-section w-100 mt-3 align-items-center">
                            <div class="col-lg-4 col-md-4 col-sm-12 p-0">
                                <span class="seleted-project-title">{{'Who is leading the campaign?' | translate}}</span>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12 p-0">
                                <div class="pl-3 d-flex align-items-center">
                                    <input class="custom-radio-btn"
                                        formControlName="Campaign Leading" type="radio" value="Master" (click)="setDMIOwnershipBased('Master')">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Campaign Leading')}">{{'Master' | translate}}</label>
                                    <input class="custom-radio-btn"
                                        formControlName="Campaign Leading" type="radio" value="Emerging" (click)="setDMIOwnershipBased('Emerging')">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Campaign Leading')}">{{'Emerging' | translate}}</label>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Campaign Leading']?.value">
                        <div *ngIf="cf['Campaign Leading']?.value" class="cpd-project-type-section  mt-3 w-100 mr-2">
                            <div class="d-flex align-items-center col-lg-4 col-md-4 col-sm-12 p-0 ml-1">
                                <span class="seleted-project-title">{{'Where is then leading DMI based?' | translate}}</span>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12 ">
                                <div class="select-wrapper">
                                    <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                        <div [ngClass]="{'has-danger p-2': isValid('DMI Based')}"
                                            class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                            <div class="ellipse-text flex-fill mr-1">
                                                <span *ngIf="!cf['DMI Based']?.value"
                                                    class="c-mtm-full-black">{{'--Select--' | translate }}</span>
                                                <div *ngIf="cf['DMI Based']?.value" class="task-collaborators">
                                                    <div class="task-collaborator-wrapper d-flex align-items-center">
                                                        <div class="collaborator-avatar d-flex">
                                                            <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                                                {{cf['DMI Based'].value | translate }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                        </div>
                                        <div class=" w-100" ngbDropdownMenu>
                                            <div *ngFor="let dmiOwnership of dmiOwnerships"
                                                class="d-flex align-items-center pointer"
                                                (click)="getDmiOwnership(dmiOwnership)" ngbDropdownItem>
                                                <span class="ml-2 _400">{{ dmiOwnership.label | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['DMI Based']?.value">
                        <div *ngIf="cf['DMI Based']?.value" class="cpd-project-type-section w-100 mt-3 flex-wrap">
                            <div class="col-lg-4 col-md-4 col-sm-12 d-flex p-0">
                                <span class="seleted-project-title">{{'What is the product category?' | translate}}</span>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12 ">
                                <div class="cpd-project-type-section">
                                    <div class="select-wrapper w-100">
                                        <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Product Category')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!cf['Product Category']?.value"
                                                        class="c-mtm-full-black">{{'--Select--' | translate }}</span>
                                                    <div *ngIf="cf['Product Category']?.value" class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex">
                                                                <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                                                    {{cf['Product Category'].value | translate }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class=" w-100" ngbDropdownMenu>
                                                <div *ngFor="let productCategory of cpdProductCategory"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getCpdProductCategory(productCategory)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ productCategory.label | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 p-0 col-md-4 col-sm-12 d-flex align-items-center"  *ngIf="cf['Product Category']?.value && productSubCategories?.length">
                                    <div class="">
                                        <span class="seleted-project-title text-nowrap">{{'What is the product subcategory?' | translate}}</span>
                                    </div>
                            </div>
                            <div class="col-lg-2 col-md-7 col-sm-12 "  *ngIf="cf['Product Category']?.value && productSubCategories?.length">
                                <div class="select-wrapper w-100" [style.min-width]="'250px'">
                                    <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                        <div [ngClass]="{'has-danger p-2': isValid('Product Subcategory')}"
                                            class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                            <div class="ellipse-text flex-fill mr-1">
                                                <span *ngIf="!cf['Product Subcategory']?.value"
                                                    class="c-mtm-full-black">{{'--Select--' | translate }}</span>
                                                <div *ngIf="cf['Product Subcategory']?.value" class="task-collaborators">
                                                    <div class="task-collaborator-wrapper d-flex align-items-center">
                                                        <div class="collaborator-avatar d-flex">
                                                            <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                                                {{cf['Product Subcategory'].value | translate }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                        </div>
                                        <div class="w-100" ngbDropdownMenu>
                                            <div *ngFor="let productSubCategory of productSubCategories"
                                                class="d-flex align-items-center pointer"
                                                (click)="getCpdProductSubCategory(productSubCategory)" ngbDropdownItem>
                                                <span class="ml-2 _400">{{ productSubCategory.label | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex">
                                    <div class="col p-0">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Category']?.value && productSubCategories?.length">
                        <div *ngIf="cf['Product Category']?.value && productSubCategories?.length" class="row w-100 mt-3 align-items-center" style="z-index: 99999 !important;">
                            <div class="col-4">
                                <span class="seleted-project-title">{{'What is the product subcategory?' | translate}}</span>
                            </div>
                            <div class="col-8">
                                <div class="col p-0">
                                    <div class="select-wrapper w-100 mr-3">
                                        <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Product Subcategory')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!cf['Product Subcategory']?.value"
                                                        class="c-mtm-full-black">{{'--Select--' | translate }}</span>
                                                    <div *ngIf="cf['Product Subcategory']?.value" class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex">
                                                                <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                                                    {{cf['Product Subcategory'].value | translate }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class=" w-100" ngbDropdownMenu>
                                                <div *ngFor="let productSubCategory of productSubCategories"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getCpdProductSubCategory(productSubCategory)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ productSubCategory.label | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Category']?.value">
                        <div class="cpd-project-type-section w-100 mt-3 align-items-center" *ngIf="cf['Product Category']?.value">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <span class="seleted-project-title">{{'What is the project type?' | translate}}</span>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12 pl-0">
                                <div class="pl-3 d-flex align-items-center">
                                    <input class="custom-radio-btn"
                                        formControlName="Campaign Type" type="radio" value="Product Launch">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Campaign Type')}">{{'Product Launch' | translate}}</label>
                                    <input class="custom-radio-btn"
                                        formControlName="Campaign Type" type="radio" value="Product Repush">
                                    <label class="custom-radio-label mb-0 _700" [ngClass]="{'text-danger': isValid('Campaign Type')}">{{'Product Repush' | translate}}</label>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Campaign Type']?.value=='Product Repush'">
                        <div *ngIf="cf['Campaign Type']?.value=='Product Repush'" class="cpd-project-type-section w-100 mt-3 align-items-center">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <span class="seleted-project-title">{{'What kind of launch?' | translate}}</span>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <div class="col p-0">
                                    <div class="select-wrapper">
                                        <div class="d-flex align-items-center collaborators-dropdown" container="body" ngbDropdown>
                                            <div [ngClass]="{'has-danger p-2': isValid('Repush Type')}"
                                                class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                                <div class="ellipse-text flex-fill mr-1">
                                                    <span *ngIf="!cf['Repush Type']?.value"
                                                        class="c-mtm-full-black">{{'--Select--' | translate }}</span>
                                                    <div *ngIf="cf['Repush Type']?.value" class="task-collaborators">
                                                        <div class="task-collaborator-wrapper d-flex align-items-center">
                                                            <div class="collaborator-avatar d-flex">
                                                                <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                                                    {{cf['Repush Type'].value | translate }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                            </div>
                                            <div class=" w-100" ngbDropdownMenu>
                                                <div *ngFor="let kindOfLaunch of kindOfLaunches"
                                                    class="d-flex align-items-center pointer"
                                                    (click)="getKindOfLaunch(kindOfLaunch)" ngbDropdownItem>
                                                    <span class="ml-2 _400">{{ kindOfLaunch.label | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal-line mt-4 mr-5" *ngIf="(cf['Campaign Type']?.value=='Product Launch') || cf['Repush Type']?.value">
                        <div class="cpd-project-type-section align-items-center mt-3 w-100 mr-2 align-items-center" [ngClass]="{'has-danger p-2': !cf['Asset Bundle']?.value && submitted}" 
                            *ngIf="(cf['Campaign Type']?.value=='Product Launch') || cf['Repush Type']?.value">
                            <div class="col-lg-4 col-md-4 col-sm-12 p-0 ml-1">
                                <span class="seleted-project-title">{{'What bundle of assets do you need to develop?' | translate}}</span>
                            </div>
                            <div *ngIf="(cf['Campaign Type']?.value=='Product Launch') || (cf['Repush Type']?.value=='Reno')" class="col-lg-4 col-md-4 col-sm-12 asset-bundle prioritylevel-btn" (click)="setAssetBundle('1 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'1' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'gray-star' : 'gray-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'gray-star' : 'gray-star'"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(cf['Campaign Type']?.value=='Product Launch') || (cf['Repush Type']?.value=='Reno')" class="col-lg-4 col-md-4 col-sm-12 asset-bundle prioritylevel-btn" (click)="setAssetBundle('2 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'2' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'green-star' : 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'gray-star' : 'gray-star'"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="cf['Campaign Type']?.value" class="col-lg-4 col-md-4 col-sm-12 asset-bundle prioritylevel-btn" (click)="setAssetBundle('3 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'3' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star' : 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star' : 'black-star'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isProjectSelected && !isCpdProductCampaign " class="mt-3">
                    <div class="p-0 cpd-brandCampaign-project-selected d-flex align-items-center justify-content-center">
                        <span class="project-type-title">{{'brandCampaign' | translate}}
                            <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                        </span>
                    </div>
                    <div class="w-100 mt-3 ml-0">
                        <div class="align-items-center cpd-project-type-section mr-2 mt-3 w-100" [ngClass]="{'has-danger p-2': !cf['Asset Bundle']?.value && submitted}">
                            <div class="col-lg-3 col-md-3 col-sm-12 d-flex ml-1 p-0 d-flex ml-1 p-0 ml-1">
                                <span class="seleted-project-title">{{'What bundle of assets do you need to develop?' | translate}}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 asset-bundle prioritylevel-btn mr-2" (click)="setAssetBundle('1 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'1' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'gray-star' : 'gray-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '1 Star' ? 'gray-star' : 'gray-star'"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 asset-bundle prioritylevel-btn mr-2" (click)="setAssetBundle('2 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'2' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'green-star' : 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '2 Star' ? 'gray-star' : 'gray-star'"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 asset-bundle prioritylevel-btn" (click)="setAssetBundle('3 Star')"
                                [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="mr-1 _400 f-12">{{'3' | translate}}</div>
                                    <div class="">
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star': 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star' : 'black-star'"></i>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            [ngClass]="cf['Asset Bundle']?.value == '3 Star' ? 'green-star' : 'black-star'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="horizontal-line mt-4">
        <div class="row mt-3">
            <div class="row w-100 ml-3 align-items-center project-type-section">
                <div class="col-2 p-0" [style.max-width]="'100%'">
                    <span class="information-title">{{'whatsYourExpectedMAD' | translate}}</span>
                </div>
                <div class="col-3 pl-0" [style.max-width]="'100%'">
                    <div class="input-group h-100 mt-1" [ngClass]="{'has-danger': isValid('expectedDate')}">
                        <input class="form-control h-100 date-picker" formControlName="expectedDate" ngbDatepicker
                            #date="ngbDatepicker" firstDayOfWeek="1" (click)="date.toggle()"
                            [(ngModel)]="expectedMadDate" (ngModelChange)="filterDateChanged()">
                        <div class="input-group-addon d-flex flex-column justify-content-center"
                            (click)="date.toggle()">
                            <img src="'../../../../../assets/img/square-calendar.svg" alt="">
                        </div>
                    </div>
                </div>
                <div *ngIf="!isCpd" class="col-2" [style.max-width]="'100%'">
                    <span class="information-title">{{'isIt3StarProject' | translate}}</span>
                </div>
                <div *ngIf="!isCpd" class="col-3" [style.max-width]="'100%'">
                    <div class="d-flex align-items-center col p-0">
                        <input class="custom-radio-btn" formControlName="Is Three Star" type="radio" [value]="'true'" [style.height]="'15px'">
                        <label class="custom-radio-label _700 text-uppercase my-0 ml-0 mr-5" [ngClass]="{'text-danger': isValid('Is Three Star')}">{{'Yes' | translate}}</label>
                        <input class="custom-radio-btn" formControlName="Is Three Star" type="radio" [value]="'false'" [style.height]="'15px'">
                        <label class="custom-radio-label _700 text-uppercase my-0 ml-0 mr-5" [ngClass]="{'text-danger': isValid('Is Three Star')}">{{'No' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>
        <hr class="horizontal-line mt-3">
        <div class="my-3 project-type-section-btn">
            <div class="col d-flex" *ngIf="this.projectV2ServiceService.timelineId">
                <button (click)="returnToProject()" class="bg-white return-to-project-button" type="button">
                    <span class="_700 f-12 text-uppercase">{{'returnToProjects' | translate}}</span>
                </button>
            </div>
            <div class="col d-flex next-btn">
                <button class="next-button" type="button" (click)="onContinue()" [disabled]="isLoading">
                    <span class="_700 f-12 text-light text-uppercase">{{'next' | translate}}</span>
                </button>
            </div>
        </div>
    </form>
</div>