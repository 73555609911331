
import { interval as observableInterval, Observable, Subject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
// import { NGValidators } from "ng-validators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../shared/services/auth.service";
import { RecoveryPasswordComponent } from './../recovery-password/recovery-password.component';
import { LoginWithEmailComponent } from './../login-with-email/login-with-email.component';
import { NotificationService } from './../../shared/services/notification.service';
import { ProposalInvitationService } from "../../shared/services/proposal-invitation.service";
import { ProjectService } from "../../shared/services/project.service";
import { RegistrationService } from "../../shared/services/registration.service";
import { UserService } from "../../shared/services/user.service";
import { CountryCodes } from "../../shared/components/mtm-tel-input/models/country-codes.model";
import { OverlayService } from "../../shared/services/overlayService";
import { ValidatorService } from "../../shared/services/validator.service";
import { Authority, PermissionService } from "../../shared/services/permissions.service";
import { takeUntil } from "rxjs/operators";
import { PhoneVerificationComponent } from "../../on-boarding/phone-verification/phone-verification.component";
import { Verify2faComponent } from "../verify-2fa/verify-2fa.component";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { TranslateService } from 'app/shared/services/translate.service';
import DOMPurify from 'dompurify';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountChangePasswordComponent } from 'app/account/change-password/account-change-password.component';
import { EmitterService } from 'app/shared/services/emitter.service';
import { BroadcastService } from 'app/shared/services/broadcast.service';

@Component({
	selector: 'mtm-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

	private loginForm: UntypedFormGroup;
	private registrationForm: UntypedFormGroup;
	submitted: boolean = false;
	private returnUrl: string = null;
	private submitBtn = 'login_signIn';
	showRegistrationFields: boolean = false;
	private sub: Subscription;
	private viewValidationResponse: any;
	private isTaxIdValid: boolean = false;
	listCountryCodes: any[] = new CountryCodes().allCountries;
	private isMatchDomainNameWithEmail: boolean = false;
	selectedCountry: string;
	private vatPlaceHolder: string;
	ngUnsubscribe = new Subject();

	@Input() projectId: string;
	@Input() projectType: string;
	@Input() companyType: string;
	@Input() fromOneShot: boolean;
	@Input() redirectLoginFormOneShot: boolean;
	@Input() callbackRegistrationSuccess: Function;
	@Input() callbackLoginSuccess: Function;

	@Input() modalREF: any;
	private mfaModalRef: NgbModalRef;

	isTermsCondition: boolean = false;
	authUser: any;
	workSpaces = [];
	hasLtgPermission: boolean = false;
	loading: boolean = false;

	constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private authService: AuthService, private router: Router,
		private notificationService: NotificationService, private route: ActivatedRoute, private overlayService: OverlayService,
		private proposalInvitationService: ProposalInvitationService, private projectService: ProjectService,
		private broadcastService: BroadcastService,
		private registrationService: RegistrationService, public userService: UserService, private validatorService: ValidatorService,
		public permissionService: PermissionService, private translatePipe: TranslatePipe, private workspaceService: WorkspaceService,
		private translateService: TranslateService, private sanitizer: DomSanitizer) {

		// Load form group
		this.loadFormGroup();
	}


	ngOnInit() {
		// Get returnUrl
		if (this.projectId)
			this.showRegistrationFields = true;
		if (this.redirectLoginFormOneShot)
			this.showRegistrationFields = false;

		this.returnUrl = this.returnUrl ? this.returnUrl : this.route.snapshot.queryParams['returnUrl'];
		if (this.returnUrl)
			sessionStorage.setItem('returnUrl', this.returnUrl);
	}

	// getWorkspaces() {
	// 	this.authUser = this.authService.getAuthUser();
	// 	this.workspaceService.getWorkspaces(this.authUser.companyId)
	// 	  .subscribe(result => {
	// 		this.workSpaces = result;
	// 	  });
	//   }
	// Validate and sanitize the returnUrl
	private isValidReturnUrl(returnUrl: string): boolean {
		// Implement your validation and sanitization logic here.
		// Ensure that the returnUrl is safe and points to a trusted destination.
		// You can use regular expressions or other checks to verify its safety.

		// For example, you might allow returnUrl to start with '/' and disallow any other values.
		// Modify this logic based on your application's specific requirements.
		const safePattern = /^\/[a-zA-Z0-9_-]+$/;
		return safePattern.test(returnUrl);
	}

	isValidURL(url) {
		try {
			new URL(url);
			return true;
		} catch (error) {
			return false;
		}
	}
	private proceedLogin() {
		try {
			this.loading = true;
			if (this.returnUrl) {
				// Validate and sanitize the returnUrl before using it
				// const isSafeReturnUrl = this.isValidReturnUrl(this.returnUrl);

				// if (isSafeReturnUrl) {
				this.closeActiveModal();
				this.closeMfaModal();
				let safeURL = DOMPurify.sanitize(this.returnUrl);
				this.router.navigateByUrl(encodeURI(safeURL)).then(() => {
					if (typeof this.callbackLoginSuccess === 'function') {
						this.callbackLoginSuccess();
					}
				});
			} else {
				this.userService.getUserProfileSettings().subscribe(
					(profile_settings: any) => {
						const lang = this.authService.getAuthLanguage(false) || profile_settings.language || 'en_us';
						this.authService.setAuthLanguage(lang);
						this.translateService.use(lang);
						/*
						this.userService.changeUserProfileSettingLang(profile_settings.language || 'en_us').pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe((userProfileSettings) => {
							//console.log(userProfileSettings);
						});
						*/
						// this.getWorkspaces();

						// set Ltg permission

						if (this.permissionService.hasAuthority(Authority.I, null)) {
							this.projectService.getProjects().pipe(
								takeUntil(this.ngUnsubscribe)
							).subscribe(projects => {
								this.closeMfaModal();
								this.authUser = this.authService.getAuthUser();

								// below condition is for loreal users
								if (this.hasLtgPermission) {
									this.navigateWithArray(['/workspaces']);
								} else {
									this.navigateWithArray(['/projects']);
								}
								this.loading = false;
								//workspace dashboard replaces project dashboard
								/*
								if (projects.length > 0) {
									this.navigateWithArray(['/projects']);
								} else {
									this.proposalInvitationService.getProposals().pipe(
										takeUntil(this.ngUnsubscribe)
									).subscribe(proposals => {
										let numberOfCreatedProposal;
										if (proposals.length > 0) {
											numberOfCreatedProposal = proposals.filter(p => p.status != "ACCEPTED").length;
											if (numberOfCreatedProposal > 0) {
												this.navigateWithArray(['projects', 'project-proposals']);
											} else
												this.navigateWithArray(['projects', 'project-invitations']);

										} else {
											this.proposalInvitationService.getInvitedCompanies().pipe(
												takeUntil(this.ngUnsubscribe)
											).subscribe(invitations => {
												this.navigateWithArray(['projects', 'project-invitations']);
											})
										}
									})
								}
								*/
							});
						} else {
							this.closeMfaModal();
							if (this.hasLtgPermission) {
								this.navigateWithArray(['/workspaces']);
							} else {
								this.navigateWithArray(['/projects']);
							}
						}
						if (typeof this.callbackLoginSuccess === 'function') {
							this.callbackLoginSuccess();
						}
					}
				);
			}
		} catch (error) {
			this.submitBtn = 'login_signIn';
			this.submitted = false;
			this.loading = false;
		}
	}

	openAccountActivationStatus(isDelete: boolean) {
		this.notificationService.open({
			title: this.translatePipe.transform(isDelete ? 'accountDeleted' : 'accountDeactivated'),
			title2: isDelete ? void 0 : this.translatePipe.transform('accessRevoked'),
			notificationType: 'danger',
			centerHeader: true,
			question: this.translatePipe.transform(isDelete ? 'accountDeletedQuestion' : 'accountDeactivatedQuestion'),
			confirmBtn: "OK"
		}).subscribe(confirm => {
			if (confirm) {
				this.authService.logout();
			}
			this.loading = false;
		});
	}

	loginData: any;
	onNewSubmit() {
		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}

    this.submitBtn = 'login_signingIn';
		this.loading = true;
		this.authService.newLogin(
			this.loginForm.controls['email'].value,
			this.loginForm.controls['password'].value,
			this.loginForm.controls['remember_me'].value,
			this.projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((data: any) => {
				const { challenge = false, mechanism = '', verificationNeed, auth, phone } = data;
				this.hasLtgPermission = data.hasLtgPermission;
				if (!challenge) {
					this.loginData = data;
					this.authUser = this.authService.getAuthUser();
				} else {
					const modalRef = this.modalService.open(Verify2faComponent, { size: 'sm', backdrop: 'static',
            windowClass: 'verify-2fa-window',
            keyboard: false});
					this.mfaModalRef = modalRef;
					this.submitted = false;
					const compEl = modalRef.componentInstance as Verify2faComponent;
					if (compEl != null) {
						compEl.email = this.loginForm.controls['email'].value;
						compEl.mechanism = mechanism;
						compEl.timeoutEnabled = true;
					}

					const clearData = () => {
						localStorage.clear();
						sessionStorage.clear();
						modalRef.dismiss();
						this.submitBtn = 'login_signIn';
					};

					modalRef.result.then((result: boolean) => {
						this.loading = false;
						if (result) {
							this.loginData = data;
							this.authUser = this.authService.getAuthUser();
						} else {
							clearData();
						}
					}).catch(() => {
						this.loading = false;
						clearData();
					});
				}
			},
				err => {
					this.submitted = false;
					this.submitBtn = 'login_signIn';
					// User not active
					if (err.errorCode == 'USER_NOT_ACTIVE') {
						// this.notificationService.open({
						// 	title: 'MTM',
						// 	description: `Your account is not active yet. Check your email address and click on invitation's link.`,
						// 	confirmBtn: 'Accept'
						// })
						this.openAccountActivationStatus(false);
					}
					else if (err.errorCode == 'ADMIN_ACCESS_ONLY_ACCESS_DENIED') {
						this.notificationService.open({
							title: 'MTM',
							description: this.translatePipe.transform('maintenanceText'),
							confirmBtn: 'Accept'
						}).subscribe(res => {
							this.loading = false;
						});
					}

					// Display message error
					else {
						this.loading = false;
						if (err.errorCode === "NOT_FOUND") {
							// this.openAccountActivationStatus(false);
							// } else {
							this.notificationService.open({
								title: this.translatePipe.transform('bad_credentials'),
								description: `${this.translatePipe.transform('user_not_found').replace('<email>', this.loginForm.controls['email'].value)}`,
								confirmBtn: 'OK'
							});
						} else if (err.errorCode === "USER_DELETED") {
							this.notificationService.open({
								title: this.translatePipe.transform('bad_credentials'),
								description: `${this.translatePipe.transform('user_not_found').replace('<email>', this.loginForm.controls['email'].value)}`,
								confirmBtn: 'OK'
							});
						} else if (err.errorCode === "UNEXPECTED_ERROR") {
							this.notificationService.open({
								title: this.translatePipe.transform('bad_credentials'),
								description: this.translatePipe.transform('invalid_email_password'),
								confirmBtn: 'OK'
							});
						} else if (err.errorCode === 'IP_BLOCKED') {
							this.notificationService.open({
								title: this.translatePipe.transform('login_IPBlocked_title'),
								description: this.translatePipe.transform('login_IPBlocked_description'),
								confirmBtn: 'OK'
							});
						} else if (err.errorCode === 'USER_BLOCKED') {
							this.notificationService.open({
								title: this.translatePipe.transform('user_account_blocked'),
								description: this.translatePipe.transform('user_account_blocked_description'),
								confirmBtn: 'OK'
							});
						}

					}
				});
	}

	/**
	 * Submit login form
	 */
	onSubmit() {

		this.submitBtn = 'login_signingIn';

		if (this.loginForm.valid) {

			this.submitted = true;

			// If login is successfully, redirect to Main layout
			this.authService.login(this.loginForm.controls['email'].value,
				this.loginForm.controls['password'].value,
				this.loginForm.controls['remember_me'].value, this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe(() => {
					this.proceedLogin();

				}, (err: any) => {
					this.submitted = false;
					this.submitBtn = 'login_signIn';

					// User not active
					if (err.errorCode == 'USER_NOT_ACTIVE') {
						// this.notificationService.open({
						// 	title: 'MTM',
						// 	description: `Your account is not active yet. Check your email address and click on invitation's link.`,
						// 	confirmBtn: 'Accept'
						// })

						this.openAccountActivationStatus(false);
					}

					// Display message error
					else {
						if (err.errorCode === "NOT_FOUND") {
							this.openAccountActivationStatus(true);
						} else {
							this.notificationService.open({
								title: 'MTM',
								description: `${err.message}\nPlease, try again.`,
								confirmBtn: 'Accept'
							});
						}
					}

				});
		}
	}


	filterCountryCode(value: string): string {
		return this.listCountryCodes.find((country: any) => value.startsWith(country.regionCode)).regionCode;
	}

	checkDomainNameMatch() {
		if (!this.registrationForm.controls['email'].touched)
			return;
		this.isMatchDomainNameWithEmail = this.registrationForm.controls['email'].value.toString().toLowerCase().endsWith("@" + this.registrationForm.controls['companyUrl'].value.toString().toLowerCase());
	}

	IsMatchDomainNameWithEmailOnChange() {
		this.isMatchDomainNameWithEmail = this.registrationForm.controls['email'].value.toString().toLowerCase().endsWith("@" + this.registrationForm.controls['companyUrl'].value.toString().toLowerCase());
		if (!this.isMatchDomainNameWithEmail)
			this.overlayService.showWarning("Company domain name does not match with the company email address. Please try again.", "Email validation warning!");
	}

	//disable tax id validation
	/*
	checkTaxValidation() {
		let companyTaxId = this.registrationForm.controls['companyTaxId'].value;
		let regionCode = this.filterCountryCode(companyTaxId);
		let taxValue = companyTaxId.toString().replace(regionCode, "");

		if (regionCode !== this.selectedCountry) {
			this.isTaxIdValid = false;
			this.overlayService.showWarning("Country and VAT id is not match", "VAT Validation warning!");
			return;
		}

		this.validatorService.checkTaxValidation(regionCode, taxValue).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(value => {
			this.viewValidationResponse = value;
			this.isTaxIdValid = value.valid;
			if (value.valid === false) {
				this.overlayService.showWarning("Please enter correct TAX Id", "VAT Validation warning!");
			}
		}, error => {
			if (error) {
				this.isTaxIdValid = false;
				this.overlayService.showError(error.message, "VAT Validation error!");
			}
		});
	}
	*/

	/**
	 * On Facebook login
	 */
	onFacebookLogin(): void {
		this.navigateWithArray(['/entry/auth/facebook']);
	}


	/**
	 * On Google login
	 */
	onGoogleLogin() {
		this.navigateWithArray(['/entry/auth/google']);
	}


	/**
	 * On Linkedin login
	 */
	onLinkedinLogin() {
		this.navigateWithArray(['/entry/auth/linkedin']);
	}

	/**
	 * On Azure login
	 */
	onAzureLogin() {
		this.navigateWithArray(['/entry/auth/azure']);
	}


	/**
	 * Open Recovery password modal
	 */
	onOpenForgotPasswordModal() {
		const modalRef = this.modalService.open(RecoveryPasswordComponent, { size: 'sm', windowClass: 'recover-password-window' });
		return false;
	}


	/**
	 * Open Login with email modal
	 */
	onLoginWithEmailModal() {
		const modalRef = this.modalService.open(LoginWithEmailComponent, { size: 'sm' });
		return false;
	}


	// *****************************************************************************************************************


	/**
	 * Load form group
	 */
	private loadFormGroup() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
			remember_me: ['', []]
		});

		this.registrationForm = this.fb.group({
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			company: ['', [Validators.required]],
			country: ['', [Validators.required]],
			companyUrl: ['', [Validators.required]],
			companyTaxId: ['', [Validators.required]],
			email: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50),
			Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?).+[a-z]*$/i)])],
			phone: ['', [Validators.required, Validators.pattern(/\+[1-9][0-9]{3,14}/)]]
		});
	}


	/**
	 * Save registration
	 */
	saveRegistration() {

		let formValue = this.registrationForm.value;
		let user = Object.assign({}, formValue);
		user.username = formValue.email;
		user.globalRole = 'COMPANY_PRINCIPAL';

		this.registrationService.saveRegistration(user, this.projectId, this.projectType, this.companyType, formValue.companyUrl,
			formValue.companyTaxId, user.globalRole, this.selectedCountry).pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(
				response => {
					if (!this.projectId || this.projectId == '') {
						this.fromOneShot = true;
						this.callbackRegistrationSuccess();
					}
					else {
						this.fromOneShot = true;
						this.callbackRegistrationSuccess();
					}
				},
				error => {
					if (error.errorCode == 'ADMIN_ACCESS_ONLY_ACCESS_DENIED') {
						this.notificationService.open({
							title: 'MTM',
							description: this.translatePipe.transform('maintenanceText'),
							confirmBtn: 'Accept'
						});
						return;
					}

					this.notificationService.open({
						size: 'lg',
						title: 'MTM Registration',
						description: 'An error occurred while saving registration' + error && error.message ? error.message : '',
						confirmBtn: this.makeConfirmButtonText(error),
						cancelBtn: this.makeCancelButtonText(error),
					}).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe((res) => {
						if (this.sub) {
							this.sub.unsubscribe();
						}
						if (res && error.errorCode == 'EMAIL_EXISTS') {
							this.showRegistrationFields = false;
							this.loginForm.controls['email'].setValue(user.email);
							this.sub = observableInterval(1000).pipe(
								takeUntil(this.ngUnsubscribe)
							)
								.subscribe((val) => {
									if (this.showRegistrationFields == true) {
										this.loginForm.controls['email'].setValue('');
									}
								});
						}
						if (res && error.errorCode == 'PENDING_INPROGRESS') {
							this.registrationService.resendReqistrationEmail(user).pipe(
								takeUntil(this.ngUnsubscribe)
							).subscribe(() => {
								this.notificationService.open({
									size: 'lg',
									title: 'MTM Registration',
									description: 'Email is resended!',
									confirmBtn: 'Ok',
									cancelBtn: null
								});
							});
						}
					});
				}
			);

	}

	/**
	 * Registration info  modal
	 */
	registrationSuccess() {
		this.notificationService.open({
			size: 'lg',
			title: 'MTM Registration',
			description: `You have registered successfully.`,
			confirmBtn: 'SIGN IN',
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.navigateLoginPage();
		}, err => {
			console.log(err);
		});
	}

	private navigateLoginPage() { //PROJECT TYPE AND COMPANY TYPE WILL BE ADDED
		this.closeActiveModal();
		this.router.navigate(['entry/login', this.projectId, this.projectType, this.companyType], { skipLocationChange: true });
	}

	private navigateWithArray(routeArr: string[]) {
		this.closeActiveModal();
		this.router.navigate(routeArr);
	}

	closeActiveModal() {
		try {
			if (this.modalREF)
				this.modalREF.dismiss();
		} catch (error) {

		}
	}

	private closeMfaModal() {
		try {
			if (this.mfaModalRef)
				this.mfaModalRef.close();
		} catch (error) {

		}
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private makeConfirmButtonText(error: any): string {
		if (error.errorCode == 'EMAIL_EXISTS') {
			return 'Sign In';
		} else if (error.errorCode == 'PENDING_INPROGRESS') {
			return 'Resend';
		} else {
			return 'Ok';
		}
	}

	private makeCancelButtonText(error: any): string {
		if (error.errorCode == 'EMAIL_EXISTS' || error.errorCode == 'PENDING_INPROGRESS') {
			return 'Cancel';
		} else {
			return null;
		}
	}

	onSelectCountry(event: any) {
		this.selectedCountry = event.target.value;
		if (this.selectedCountry === "US")
			this.vatPlaceHolder = "e.g US91-1144442"
		else
			this.vatPlaceHolder = "e.g FR12345678900"
	}

	isCountryValid() {
		return this.selectedCountry == "" || this.selectedCountry === undefined;
	}

	passwordValidated(val) {
		if (val) {
			this.continueLogin();
		} else {
			const modalRef = this.modalService.open(AccountChangePasswordComponent, { size: 'lg', windowClass: 'account-change-password-modal', keyboard: false, backdrop: 'static' });
			modalRef.componentInstance.asModal = true;
			modalRef.result.then(this.cleanUpLoginInfo.bind(this), this.cleanUpLoginInfo.bind(this));
		}
	}

	cleanUpLoginInfo() {
		if (this.authUser) {
			this.authUser = null;
		}
		// Call service to remove token from BE and clean localStorage
		this.authService.logout();

		// Emit event and redirect to Login page
		EmitterService.get('user.logout').emit();
		this.broadcastService.send({ action: 'user.logout' });
		this.router.navigate(['/entry']);
	}

	continueLogin() {
		const { challenge = false, mechanism = '', verificationNeed, auth, phone } = this.loginData;
		if (!challenge) {
			if (!verificationNeed) {
				this.proceedLogin();
			} else {
				this.notificationService.open({
					title: 'Verification Pending',
					description: `Please verify your email and mobile number to continue.`,
					confirmBtn: 'Verify now',
					cancelBtn: 'Cancel'
				}).subscribe(
					confirm => {
						if (confirm) {
							this.router.navigate(['/verification'], {
								queryParams: { token: auth.token, email: this.loginForm.controls['email'].value, phone: phone }
							});
						}
						this.submitted = false;
					}, err => {
						console.error(err);
					});
			}
		} else {
			this.proceedLogin();
		}
	}
}
