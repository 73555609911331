import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { UserRole, ProjectSection, Team, User } from "app/shared/interfaces";
import { UserService } from "app/shared/services/user.service";
import { AuthService } from "app/shared/services/auth.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from "app/shared/services/helper.service";
import { TeamService } from "app/shared/services/team.service";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { Observable, Subject, forkJoin, concat, last } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProjectService } from "app/shared/services/project.service";

export const TeamEditorTypes = {
  Project: 'project',
  Workspace: 'workspace',
  Brand: 'brand'
};


@Component({
  templateUrl: './team-editor.component.html',
  styleUrls: ['./team-editor.component.scss']
})
export class TeamEditorComponent implements OnInit, OnDestroy {
  @Input() workspaceId: string = '';
  @Input() projectId: string = HelperService.getEmptyUUID();
  @Input() teamId: string = '';
  @Input() brandId: string = HelperService.getEmptyUUID();
  @Input() level: string = TeamEditorTypes.Project;
  @Input() availableUsernames: string[] = []; //used for workspace/brand team for a list of users to choose from

  availableUsers: User[] = []; //at the moment backend can't add user outside the scope
  selectedMembers: User[] = [];
  authUser: User;
  availableRoles: UserRole[] = [];
  existingMembers: string[] = []; //for usernames comparison during update
  isCreator: boolean = false;
  creator: User | null = null;
  isLoading: boolean = false;
  teamForm = this.fb.group({
    id: new FormControl<string>(''),
    companyId: new FormControl<string | null>('', [Validators.required]),
    creator: new FormControl<string>(''),
    name: new FormControl<string>('', [Validators.required]),
    //brandId: new FormControl<string|null>(null),
    workspaceId: new FormControl<string | null>(this.workspaceId),
    projectId: new FormControl<string>(''), //need to check why need projectId & projects
    projects: new FormControl<string[]>([]),
    members: new FormControl<string[]>([], [Validators.required]),
    overrideRoles: new FormControl<boolean>(false),
    roles: new FormControl<string[]>([])
  })
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private projectService: ProjectService,
    private overlayService: OverlayService,
    private translatePipe: TranslatePipe,
    private activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private loadData() {

    const requests = [this.userService.getUsersByCompanyId(this.authUser.companyId),
    //TODO: confirms if we need to workspace role for workspace team
    this.projectService.getRoles(true)];
    if (this.teamId) {
      requests.push(this.teamService.getTeamById({
        projectId: this.projectId,
        workspaceId: this.workspaceId,
        companyId: this.authUser.companyId,
        brandId: this.brandId,
        id: this.teamId
      }));
    }

    this.isLoading = true;

    forkJoin(requests).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results: any[]) => {
          const users = results[0] as User[];
          users.sort((a, b) => a.fullName.localeCompare(b.fullName));
          //TODO: comment out the following line if you want to enable company wide user
          //this.availableUsers = users;

          //TODO: comment the following lines if you want to disable user filter by scope
          if (this.level == TeamEditorTypes.Project) {
            this.availableUsers = this.projectService.project.participants;
          } else {
            this.availableUsers = users.filter(u => this.availableUsernames.includes(u.username));
          }

          this.availableRoles = results[1].filter(l => !l.id.startsWith("ADMIN"));

          if (requests.length > 2) {
            const team = results[2] as Team;
            this.teamForm.patchValue(team);
            this.existingMembers = [...team.members];
            this.selectedMembers = this.availableUsers.filter(user => team.members.includes(user.username));
            this.initializeFormValues(team);
          } else {
            this.initializeFormValues(null);
          }
          this.isLoading = false;
        }, error: (err: any) => {
          console.error(err);
          this.isLoading = false;
        }
      })
  }

  private initializeFormValues(team: Team | null) {
    if (team) {
      const values = {
        id: team.id,
        companyId: team.companyId,
        creator: team.creator,
        name: team.name,
        workspaceId: team.workspaceId,
        projectId: this.projectId,
        projects: team.projects,
        members: team.members
      };
      this.teamForm.patchValue(values);
      this.isCreator = this.authUser.username === team.creator;
      this.creator = this.availableUsers.find(u => u.username === team.creator);
    } else {
      this.teamForm.patchValue({
        companyId: this.authUser.companyId,
        creator: this.authUser.username,
        projectId: this.projectId,
        workspaceId: this.workspaceId,
        projects: [this.projectId]
      });
      this.isCreator = true;
      this.creator = this.availableUsers.find(u => u.username === this.authUser.username);
    }
    
  }

  submit() {
    HelperService.checkFormFields(this.teamForm);

    if (!this.teamForm.valid) {
      return;
    }

    const team = this.teamForm.value as Team;
    team.brandId = this.brandId;
    console.log('team to save', team);
    let membersToRemove = [];
    let membersToAdd = [];

    const requests$ = [this.teamService.saveTeam(team)];

    if (team.id) {
      membersToRemove = this.existingMembers.filter(m => !team.members.includes(m));
      if (membersToRemove.length > 0) {
        requests$.push(this.teamService.removeMember({
          ...team,
          members: membersToRemove
        }));
      }
      membersToAdd = team.members.filter(m => !this.existingMembers.includes(m));
      if (membersToAdd.length > 0) {
        requests$.push(this.teamService.addMember({
          ...team,
          members: membersToAdd
        }));
      }
    }

    this.isLoading = true;
    concat(...requests$).pipe(last())
      .subscribe({
        next: (team: any) => {
          this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
          this.activeModal.close(team);
          this.isLoading = false;
        },
        error: (err: any) => {
          this.isLoading = false;
          console.error(err);
          this.overlayService.showError(this.translatePipe.transform('overlaySaveError'), 'Error');
        }
      });
  }

  onSelectionChange() {
    this.selectedMembers = this.availableUsers.filter(user => this.teamForm.value.members.includes(user.username));
  }

  onUserRemoved(userName: string) {
    const newIds = (<string[]>this.teamForm.value.members).filter(u => u !== userName);
    this.teamForm.patchValue({
      members: newIds
    });
    this.selectedMembers = this.availableUsers.filter(user => this.teamForm.value.members.includes(user.username));
  }

  userTrackBy(index: number, user: User): string {
    return user.username;
  }

  adjustRoles() {
    if (!this.teamForm.value.overrideRoles) {
      this.teamForm.patchValue({
        roles: []
      });
    }
  }

}
