import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { DateHelperService } from "../../../shared/services/date-helper.service";
import { CheckProjectDates } from "../../../shared/services/check-project-dates";
import { checkProjectDates } from 'app/shared/base-functions/check-project-dates.base.function';
import { ProjectService } from 'app/shared/services/project.service';

@Component({
    selector: 'mtm-users-permissions',
    templateUrl: './users-permissions.component.html',
    styleUrls: ['./users-permissions.component.scss']
})
export class UsersPermissionsComponent implements OnInit {
    isProposal: boolean = false;
    date: any = {
        startDate: null,
        dueDate: null,
        airDate: null
    };
    proposalAccepted: boolean = false;
    isOpen: boolean = false;
    isProjectDropDownOpen: boolean = false;
    isProjectTemplateDropDown: boolean = false;
    selectedProjectType: string;
    selectedProjectTypeTemplate: string;
    decision: boolean = true;

    typeOfProject = [
        { name: 'video' },
        { name: 'photo' },
    ]

    templateType = [
        { name: 'Story Telling' },
        { name: 'Product Shots' },
        { name: 'Interviews' },
        { name: 'Tutorials' },
        { name: 'Documentary' },
    ]

    timelineOption = [
        { name: 'Marketing' },
        { name: '360' },
        { name: 'RDD PLV' },
        { name: 'Staging' },
        { name: 'PR/COMMS' },
        { name: 'Digital' },
    ]

    projectSubSection = [
        { name: "Upload Brief" },
        { name: "Q&a" },
        { name: "Editing" },
        { name: "Color Grading" },
    ]

    users = [
        {
            "username": "punit.seelam@mtm.video",
            "firstName": "Punit",
            "lastName": "Seelam",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "punit.seelam@mtm.video",
            "phone": "+33782554849",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_PROJECT_OWNER",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "adi.winata@mtm.video",
            "jobTitle": "Project Owner",
            "termsAndConditionsAcceptedDate": 1605861263338,
            "lastSeen": 1674836264666,
            "lastauthattempt": 1674547981253,
            "lastauthsuccess": 1674547981476,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Punit Seelam",
            "storagePath": "punit.seelam@mtm.video/"
        },
        {
            "username": "b@b.com",
            "firstName": "Bob",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "b@b.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1634658157917,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "b@b.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Bob ",
            "storagePath": "b@b.com/"
        },
        {
            "username": "dennis.mwangi@mtm.video",
            "firstName": "dennis",
            "lastName": "mwangi",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "dennis.mwangi@mtm.video",
            "phone": "+254722956791",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1650608448053,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_PRINCIPAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "Dev",
            "termsAndConditionsAcceptedDate": 1606381872572,
            "lastSeen": 1672411326755,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "dennis mwangi",
            "storagePath": "dennis.mwangi@mtm.video/"
        },
        {
            "username": "david@sentai.eu",
            "firstName": "David",
            "lastName": "Senouf",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "david@sentai.eu",
            "phone": "+212610680242",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/david@sentai.eu/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "0abc11e5-206f-4315-ba9a-3729a4e732e5",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "CEO",
            "termsAndConditionsAcceptedDate": 1607532326965,
            "lastSeen": 1671016373806,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "David Senouf",
            "storagePath": "david@sentai.eu/"
        },
        {
            "username": "admin@mtm.video",
            "firstName": "Admin",
            "lastName": "Istrator",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "admin@mtm.video",
            "phone": "+15005550006",
            "message": "Super Admin",
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_REGULAR",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": null,
            "jobTitle": "Super Admin",
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1672908756654,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Admin Istrator",
            "storagePath": "admin@mtm.video/"
        },
        {
            "username": "samuel.ohayon@mtm.video",
            "firstName": "Samuel",
            "lastName": "Ohayon",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "samuel.ohayon@mtm.video",
            "phone": "+33612783845",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1656055995896,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "krishna.gupta@mtm.video",
            "jobTitle": "PM junior",
            "termsAndConditionsAcceptedDate": 1652695610053,
            "lastSeen": 1670402701810,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Samuel Ohayon",
            "storagePath": "samuel.ohayon@mtm.video/"
        },
        {
            "username": "punit9779@gmail.com",
            "firstName": "Punit",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "punit9779@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629114176350,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "punit9779@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1663593436881,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Punit ",
            "storagePath": "punit9779@gmail.com/"
        },
        {
            "username": "joli@gmail.com",
            "firstName": "name",
            "lastName": "name",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "joli@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629123892475,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "joli@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "name name",
            "storagePath": "joli@gmail.com/"
        },
        {
            "username": "prince.diop@mtm.video",
            "firstName": "Prince",
            "lastName": "Diop",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "prince.diop@mtm.video",
            "phone": "+33782751748",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1641204353328,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "4788fc63-bade-4e7c-afff-4e698795aa3f",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "krishna.gupta@mtm.video",
            "jobTitle": "Product owner",
            "termsAndConditionsAcceptedDate": 1641204341221,
            "lastSeen": 1674825150499,
            "lastauthattempt": 1674815422898,
            "lastauthsuccess": 1674815422989,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "Prince Diop",
            "storagePath": "prince.diop@mtm.video/"
        },
        {
            "username": "nour@34434.fr",
            "firstName": "nour",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "nour@34434.fr",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1672919797998,
            "activationStatus": "PENDING_INPROGRESS",
            "globalRole": "COMPANY_REGULAR",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "nour@34434.fr",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1659601580517,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "nour ",
            "storagePath": "nour@34434.fr/"
        },
        {
            "username": "annick@mtm.video",
            "firstName": null,
            "lastName": null,
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "annick@mtm.video",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1628784373288,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": null,
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": " ",
            "storagePath": "annick@mtm.video/"
        },
        {
            "username": "dcsdcsd",
            "firstName": "cdsdc",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "dcsdcsd",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629106497483,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "dcsdcsd",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "cdsdc ",
            "storagePath": "dcsdcsd/"
        },
        {
            "username": "mohit.bumb@mtm.video",
            "firstName": "Mohit",
            "lastName": "Bumb",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "mohit.bumb@mtm.video",
            "phone": "+918839612525",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/mohit.bumb@mtm.video/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1641198274077,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "Frontend Developer",
            "termsAndConditionsAcceptedDate": 1641197654258,
            "lastSeen": 1674836617326,
            "lastauthattempt": 1674818223865,
            "lastauthsuccess": 1674818223946,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "Mohit Bumb",
            "storagePath": "mohit.bumb@mtm.video/"
        },
        {
            "username": "nour1@nicoric.com",
            "firstName": "nour1",
            "lastName": "BA",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "nour1@nicoric.com",
            "phone": "+33123456789",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1654500287940,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "nour.benammar@mtm.video",
            "jobTitle": "nicoric",
            "termsAndConditionsAcceptedDate": 1654500275106,
            "lastSeen": 1654500766319,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "nour1 BA",
            "storagePath": "nour1@nicoric.com/"
        },
        {
            "username": "nour.benammar@gmail.com",
            "firstName": "nour",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "nour.benammar@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629369538580,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "nour.benammar@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "nour ",
            "storagePath": "nour.benammar@gmail.com/"
        },
        {
            "username": "john@abcdeftest33.com",
            "firstName": "John",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "john@abcdeftest33.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1638337935784,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "john@abcdeftest33.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "John ",
            "storagePath": "john@abcdeftest33.com/"
        },
        {
            "username": "david.senouf@mtm.video",
            "firstName": "David",
            "lastName": "Senouf",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "david.senouf@mtm.video",
            "phone": "+33675542751",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/david.senouf@mtm.video/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "9c1e0c3c-c311-4019-9708-dc5023866c7c",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "admin@mtm.video",
            "jobTitle": "CTO",
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1674836591858,
            "lastauthattempt": 1674818499109,
            "lastauthsuccess": 1674818499181,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "David Senouf",
            "storagePath": "david.senouf@mtm.video/"
        },
        {
            "username": "anton.setiawan@mtm.video",
            "firstName": "Anton",
            "lastName": "Setiawan",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "anton.setiawan@mtm.video",
            "phone": "+6281331043950",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/anton.setiawan@mtm.video/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1628521312964,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "Front End Developer",
            "termsAndConditionsAcceptedDate": 1628521244117,
            "lastSeen": 1674808246007,
            "lastauthattempt": 1674796811260,
            "lastauthsuccess": 1674796811339,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Anton Setiawan",
            "storagePath": "anton.setiawan@mtm.video/"
        },
        {
            "username": "er@mtm.video",
            "firstName": "er",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "er@mtm.video",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1635251193844,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "er@mtm.video",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "er ",
            "storagePath": "er@mtm.video/"
        },
        {
            "username": "punit7997@gmail.com",
            "firstName": "Punit",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "punit7997@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629300586022,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "punit7997@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Punit ",
            "storagePath": "punit7997@gmail.com/"
        },
        {
            "username": "xyz@gmail.com",
            "firstName": "annick",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "xyz@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1628862044336,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "xyz@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "annick ",
            "storagePath": "xyz@gmail.com/"
        },
        {
            "username": "david.ohana@mtm.video",
            "firstName": "David ",
            "lastName": "Ohana",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "david.ohana@mtm.video",
            "phone": "+33665042193",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1647429386283,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "e4447adb-a472-400a-92d4-c583367a0b5b",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "prince.diop@mtm.video",
            "jobTitle": "Design Lead",
            "termsAndConditionsAcceptedDate": 1647429375933,
            "lastSeen": 1674736235469,
            "lastauthattempt": 1674719178711,
            "lastauthsuccess": 1674719178783,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "David  Ohana",
            "storagePath": "david.ohana@mtm.video/"
        },
        {
            "username": "shubham.jain@mtm.video",
            "firstName": "Shubham",
            "lastName": "Jain",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "shubham.jain@mtm.video",
            "phone": "+918602261856",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1656324734272,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_REGULAR",
            "userConnectionId": "e648881d-e917-4afc-babb-8a2dec10c605",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "nour.benammar@mtm.video",
            "jobTitle": "DevOps Engineer",
            "termsAndConditionsAcceptedDate": 1629100787340,
            "lastSeen": 1674640317679,
            "lastauthattempt": 1674639962246,
            "lastauthsuccess": 1674639962328,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Shubham Jain",
            "storagePath": "shubham.jain@mtm.video/"
        },
        {
            "username": "cdcddcd",
            "firstName": "qecdsc",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "cdcddcd",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629106638692,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "cdcddcd",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "qecdsc ",
            "storagePath": "cdcddcd/"
        },
        {
            "username": "elie.ohayon@mtm.video",
            "firstName": "Elie",
            "lastName": "Ohayon",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "elie.ohayon@mtm.video",
            "phone": "+33673708865",
            "message": "MTM CEO",
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_PRINCIPAL",
            "userConnectionId": "5e19eacf-57fe-4e3d-96e0-924960561875",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": null,
            "jobTitle": "MTM CEO",
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1671110932868,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Elie Ohayon",
            "storagePath": "elie.ohayon@mtm.video/"
        },
        {
            "username": "kealea@le.cie",
            "firstName": "nour",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "kealea@le.cie",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1657185874310,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "kealea@le.cie",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1657185974927,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "nour ",
            "storagePath": "kealea@le.cie/"
        },
        {
            "username": "dennis.mwangi+test@mtm.video",
            "firstName": null,
            "lastName": null,
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "dennis.mwangi+test@mtm.video",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1672384445203,
            "activationStatus": "PENDING_INPROGRESS",
            "globalRole": "COMPANY_PRINCIPAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "dennis.mwangi@mtm.video",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": " ",
            "storagePath": "dennis.mwangi+test@mtm.video/"
        },
        {
            "username": "ardiansyah@mtm.video",
            "firstName": "Ardiansyah",
            "lastName": "‎‎",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "ardiansyah@mtm.video",
            "phone": "+6289679109393",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/ardiansyah@mtm.video/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1643800130584,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "71960d55-015e-4ed1-9248-bb782aaa2280",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "krishna.gupta@mtm.video",
            "jobTitle": "Developer",
            "termsAndConditionsAcceptedDate": 1643800109546,
            "lastSeen": 1674729375735,
            "lastauthattempt": 1674639670478,
            "lastauthsuccess": 1674639670550,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "Ardiansyah ‎‎",
            "storagePath": "ardiansyah@mtm.video/"
        },
        {
            "username": "master@gmail.com",
            "firstName": "name",
            "lastName": "name",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "master@gmail.com",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629123864997,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "master@gmail.com",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "name name",
            "storagePath": "master@gmail.com/"
        },
        {
            "username": "margie.64893b08@nicoric.com",
            "firstName": "iufz",
            "lastName": "ijbdf",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "margie.64893b08@nicoric.com",
            "phone": "+33612783845",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1657542713264,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_REGULAR",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "samuel.ohayon@mtm.video",
            "jobTitle": "miojfse",
            "termsAndConditionsAcceptedDate": 1657542693449,
            "lastSeen": 1664895825162,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "iufz ijbdf",
            "storagePath": "margie.64893b08@nicoric.com/"
        },
        {
            "username": "nour.benammar@mtm.video",
            "firstName": "Nour",
            "lastName": "Ben Ammar",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "nour.benammar@mtm.video",
            "phone": "+21653612785",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": "951388c9-f445-4e04-a4d2-eb0bc861f84d",
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "Dev",
            "termsAndConditionsAcceptedDate": 1606381872572,
            "lastSeen": 1674484618386,
            "lastauthattempt": 1674473937234,
            "lastauthsuccess": 1674473937831,
            "oneshotaccess": true,
            "subscriptionaccess": true,
            "fullName": "Nour Ben Ammar",
            "storagePath": "nour.benammar@mtm.video/"
        },
        {
            "username": "br@di.fr",
            "firstName": "br",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "br@di.fr",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1669969633793,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "br@di.fr",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "br ",
            "storagePath": "br@di.fr/"
        },
        {
            "username": "riwop17863@aethiops.com",
            "firstName": "Ta",
            "lastName": "Ea",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "riwop17863@aethiops.com",
            "phone": "+628175005221",
            "message": null,
            "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/riwop17863@aethiops.com/avatar",
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1633333589454,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "PROJECT_STAFF",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "adi.winata@mtm.video",
            "jobTitle": "test",
            "termsAndConditionsAcceptedDate": 1633333561771,
            "lastSeen": 1653404092320,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Ta Ea",
            "storagePath": "riwop17863@aethiops.com/"
        },
        {
            "username": "f@fe.fr",
            "firstName": "fe",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "f@fe.fr",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1667556231143,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "f@fe.fr",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1667556348451,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "fe ",
            "storagePath": "f@fe.fr/"
        },
        {
            "username": "adri3@hotmail.fr",
            "firstName": "adri3",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "adri3@hotmail.fr",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629296784773,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "adri3@hotmail.fr",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "adri3 ",
            "storagePath": "adri3@hotmail.fr/"
        },
        {
            "username": "https://dev.mtm.video/review/fa01313a-4e39-da09-6f06-5c015aeaaac1",
            "firstName": "rere",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "https://dev.mtm.video/review/fa01313a-4e39-da09-6f06-5c015aeaaac1",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1629361511300,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "https://dev.mtm.video/review/fa01313a-4e39-da09-6f06-5c015aeaaac1",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": null,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "rere ",
            "storagePath": "https://dev.mtm.video/review/fa01313a-4e39-da09-6f06-5c015aeaaac1/"
        },
        {
            "username": "annick.cesbron@mtm.video",
            "firstName": "Annick",
            "lastName": "",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "annick.cesbron@mtm.video",
            "phone": null,
            "message": null,
            "avatarUrl": null,
            "verified": false,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": 1628860879906,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "COMPANY_OCCASIONAL",
            "userConnectionId": null,
            "termsAndConditionsAgree": false,
            "deleted": false,
            "inviter": "annick.cesbron@mtm.video",
            "jobTitle": null,
            "termsAndConditionsAcceptedDate": null,
            "lastSeen": 1654258769666,
            "lastauthattempt": null,
            "lastauthsuccess": null,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Annick ",
            "storagePath": "annick.cesbron@mtm.video/"
        },
        {
            "username": "gustavo.cuellar@mtm.video",
            "firstName": "Gustavo",
            "lastName": "Cuellar",
            "company": {
                "id": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
                "name": "MTM - Master the Monster",
                "description": "MTM - Master the Monster1",
                "companyType": "ADVERTISER"
            },
            "email": "gustavo.cuellar@mtm.video",
            "phone": "+33610523139",
            "message": null,
            "avatarUrl": null,
            "verified": true,
            "blocked": false,
            "companyId": "258d26b8-7f13-4ea0-bbff-2539a00f52e4",
            "activationDate": null,
            "activationStatus": "ACTIVE_COMPLETED",
            "globalRole": "ADMIN",
            "userConnectionId": null,
            "termsAndConditionsAgree": true,
            "deleted": false,
            "inviter": "david.senouf@mtm.video",
            "jobTitle": "Business Dev",
            "termsAndConditionsAcceptedDate": 1608214845465,
            "lastSeen": 1674818271554,
            "lastauthattempt": 1674659312049,
            "lastauthsuccess": 1674659312121,
            "oneshotaccess": true,
            "subscriptionaccess": false,
            "fullName": "Gustavo Cuellar",
            "storagePath": "gustavo.cuellar@mtm.video/"
        }
    ]

    dropSubSection = [
        { name: "sub section" },
        { name: "sub section" },
        { name: "sub section" },
        { name: "sub section" },
        { name: "sub section" }
    ]

    constructor(
        private projectService: ProjectService,
    ) { }

    ngOnInit(): void {
        this.selectedProjectType = this.typeOfProject[0].name;
        this.selectedProjectTypeTemplate = this.templateType[0].name;
    }

    onChangedProjectDate() {
        let newData = {
            startDate: DateHelperService.fromDatePickerToDate(this.date.startDate),
            plannedEndDate: DateHelperService.fromDatePickerToDate(this.date.dueDate),
            airDate: DateHelperService.fromDatePickerToDate(this.date.airDate)
        }

        this.changeProjectDates(newData);
    }

    changeProjectDates(date) {
        this.projectService.project = {
            ...this.projectService.project,
            startDate: date.startDate,
            plannedEndDate: date.plannedEndDate,
            airDate: date.airDate
        }

        if (this.projectService.projectOnloaded)
            this.projectService.emitProjectOnloaded();
    }

    getDateLabel(date) {
        if (date)
            return moment(new Date())
                .year(date.year)
                .month(date.month - 1)
                .date(date.day)
                .utcOffset(0)
                .format("MMMM D, YYYY");
        return "-";
    }

    isValidProjectDates(controlValue, isStartDate?): boolean {
        if (!controlValue || controlValue == '')
            return true;

        if (isStartDate)
            return false;

        return !this.compareProjectDates();
    }

    compareProjectDates(): boolean {
        return checkProjectDates(
            this.date.startDate,
            this.date.dueDate,
            this.date.airDate
        );
    }

    dropDown(value) {
        this.isOpen = value;
        this.isOpen = !this.isOpen
    }

    projectTypeDropDown(value) {
        this.isProjectDropDownOpen = value;
        this.isProjectDropDownOpen = !this.isProjectDropDownOpen;
    }

    selectProjectType(value) {
        this.selectedProjectType = value;
    }

    selectProjectTemplate(value) {
        this.selectedProjectTypeTemplate = value;
    }

    projectTypeTemplateDropDown(value) {
        this.isProjectTemplateDropDown = value;
        this.isProjectTemplateDropDown = !this.isProjectTemplateDropDown;
    }

    changeDecision(value) {
        this.isProposal = value;
        this.isProposal = !this.isProposal;
        this.decision = value;
        this.decision = !this.decision;
    }
}
