<ng-template ngbModalContainer></ng-template>
<div class="container mt-1">
    <div class="col">
        <div class="row">
            <img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
        </div>
        <div class="row text-center justify-content-center">
            <h3>Welcome to Master the Monster,<br>a collaborative platform.</h3>
        </div>
        <div class="row justify-content-center mt-2">
            <div class="card card-verification px-5 py-5 mt-4">
                <form class="verification-form" [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
                    <p class="h5 text-center">To confirm your account, enter the verification code sent to your phone
                        number ending in {{ lastTwoDigits
                        }}.
                    </p>
                    <p class="h6 mt-2">Entered the wrong phone number? <a class="text-primary font-weight-bold" href="#"
                            (click)="openChangePhoneModal()">Use another phone number</a></p>
                    <div class="row mt-4 mx-auto">
                        <label class="form-group has-float-label"
                            [ngClass]="{'has-danger': verificationForm.controls.code.invalid && submitted}">
                            <input class="form-control form-control-lg" formControlName="code" type="text" maxlength="4"
                                placeholder=" "
                                [ngClass]="{'form-control-danger': verificationForm.controls.code.invalid && submitted}"
                                (keypress)="isNumberKey($event)">
                            <span *ngIf="!(submitted && verificationForm.controls.code.invalid)">Verification
                                Code</span>
                            <span *ngIf="submitted && verificationForm.controls.code.invalid" class='text-danger'>Code
                                must be 4 digits long</span>
                        </label>
                        <button class="btn btn-lg btn-primary" type="submit" [disabled]="sending">Submit</button>
                    </div>
                    <div class="row justify-content-between mx-auto">
                        <label class="form-check-label font-weight-bold">
                            <input type="checkbox" formControlName="trust" class="form-check-input"> Trust this
                            computer<br>
                            <small class="text-muted">We won't ask you to verify your<br>account again on this
                                device.</small>
                        </label>
                        <p class="text-right resend">Didn't get your code? <a href="#"
                                class="text-primary font-weight-bold" (click)="resend()"
                                [class.resend-disabled]="sending || sent">
                                <span *ngIf="!(sent || sending)">Resend!</span>
                                <span *ngIf="sent">Sent!</span>
                                <span *ngIf="sending">Sending...</span>
                            </a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
        <div class="row justify-content-center d-none">
            <div class="card-verification">
                <a class="nav-link text-nowrap mt-3 text-danger" (click)="onBack()">
                    <i aria-hidden="true" class="fa fa-long-arrow-left"></i>
                    Back
                </a>
            </div>
        </div>
    </div>
</div>