<div class="recovery-pwd-container">
	<div class="modal-header">
		<h4 class="modal-title">{{'login_forgotPassword_title' | translate }}</h4>
		<button id="forgot-password-close-btn" type="button" class="close" aria-label="Close"
			(click)="activeModal.close(responseMsg)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<form class="signup-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="!emailSent">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="form-group row"
							[ngClass]="{'has-danger': formGroup.controls.email.invalid && submitted}">
							<label for="forgot-password-email-tb">{{'signInEmail' | translate }}</label>
							<input id="forgot-password-email-tb" class="form-control" type="text"
								formControlName="email" placeholder=" "
								[ngClass]="{'form-control-danger': formGroup.controls.email.invalid && submitted}">
							<span class="text-danger" *ngIf="submitted && formErrors.email">{{login_error_emailRequired | translate}}</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="row btn-footer justify-content-center">
							<button id="forgot-password-submit-btn" type="submit" class="btn btn-lg btn-send"
								[disabled]="sending">
								{{'login_forgotPassword_submit' | translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="row mt-2 response-msg" *ngIf="emailSent">
			<div class="col text-center">
				<h5>{{'login_forgotPassword_emailSent' | translate }}</h5>
				<button id="forgot-password-msg-close" class="btn btn-primary btn-lg"
					(click)="activeModal.close(responseMsg)">{{'close' | translate }}</button>
			</div>
		</div>
	</div>
</div>