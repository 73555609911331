import { Injectable } from '@angular/core';

/**
 * ComponentStateService
 * 
 * This service allows direct iteraction between services and components without being coupled together.
 * It provides a centralized way to access and manipulate component-specific functionalities
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class ComponentStateService {
  private mainLayoutComponent: any;

  constructor() { }

  setMainLayoutComponent(component: any) {
    this.mainLayoutComponent = component;
  }

  clearIdleWatcher() {
    if (this.mainLayoutComponent && this.mainLayoutComponent.clearIdleWatcher) {
      this.mainLayoutComponent.clearIdleWatcher();
    } else {
      console.error('MainLayoutComponent or clearIdleWatcher not available');
    }
  }

}