<div class="card card-position pb-5">
  <div class="d-flex justify-content-center align-items-center">
    <div *ngIf="showSideNav">
      <div class="row">
        <div class="col pl-5" [style.max-width]="'255px'" [style.min-width]="'255px'" *ngIf="false">
          <div class="sidebar-card ">
            <div class="col my-3 pt-3">
              <div *ngIf="canCreateProject" class="sidebar-btn d-flex align-items-center" [style.background]="'#000'" (click)="createNew()"
                [ngClass]="{'active-create-btn' : isCreateActive }">
                <div class="circle-div ml-1">
                  <i [style.color]="'#fff'" class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <span [style.color]="'#fff'" class="sidebar-btn-text text-uppercase f-14 _700 ml-2 ">{{'loreal_dashboard_createNew' | translate}}</span>
              </div>
              <div class="create-btn-dropdown pointer" *ngIf="isCreateActive">
                <div class="col create-btn-text" (click)="createNewItem(item)" *ngFor="let item of createBtnRouts">
                  {{item}}
                </div>
              </div>
            </div>
            <div class="col my-3">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Workspaces','workspaceComponent')"
                [routerLink]="'/workspaces'"
                [ngStyle]="{ 'background-color': isActive('/workspaces') || isWorkspaceActive ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/workspace-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'workspaces' | translate }}</span>
              </div>
            </div>
            <div class="col my-3" *ngIf="showDriveCampaign">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Drive Campaigns','campaignDriveComponent')"
                [routerLink]="'/workspaces/campaign-drive'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/campaign-drive') || isDriveCampaignComponet ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/drive-campaign-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{(isCpd ? 'loreal_campaigns' : 'loreal_driveCampaigns') | translate }}</span>
              </div>
            </div>
            <div class="col my-3" *ngIf="showCampaign && !isCpd">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Campaigns','campaignsComponent')"
                [routerLink]="'/workspaces/campaigns'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/campaigns') || isCampaignComponent ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/campaign-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'loreal_campaigns' | translate}}</span>
              </div>
            </div>
            <div class="col my-3">
              <div class="sidebar-btn d-flex align-items-center" [routerLink]="'/workspaces/projects'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/projects') || isProjectComponent ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/project-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'projects' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col p-0">
          <div *ngIf="showSideNav" class="px-3">
            <router-outlet></router-outlet>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col p-0">
      <div *ngIf="showSideNav" class="px-3">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div *ngIf="!showSideNav" class="w-100 px-3 create-campaign">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>