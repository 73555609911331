import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'mtm-commingsoon',
  templateUrl: './commingsoon.component.html',
  styleUrls: ['./commingsoon.component.scss']
})
export class CommingsoonComponent implements OnInit {
  @Input() top: string = '0';
  @Input() left: string = '0';
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() zindex: string = '99999';

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    // Add disable-scroll class to body when the overlay is initialized
    // this.renderer.addClass(document.body, 'disable-scroll');
  }

  ngOnDestroy(): void {
    // Remove disable-scroll class from body when the overlay is destroyed
    // this.renderer.removeClass(document.body, 'disable-scroll');
  }
}
