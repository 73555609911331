<div class="container-fluid main-layout-container">
  <div class="row main-row">
    <div class="col menubar mx-0">
      <mtm-left-sidebar *ngIf="!isPublicPage && authUser"></mtm-left-sidebar>
    </div>

    <div class="col mainarea pb-0 mx-0 flex-grow-1 px-0">
      <div class="d-flex">
        <div class="main-container flex-grow-1"
             [class.has-right-pane]="themeOptions.rightPane"
             [class.has-crisp]="userProfile?.crispChatEnable"
         [ngClass]="themeOptions.mainContainerClasses">
          <header [ngClass]="{
						'has-custom-header': themeOptions.header,
						'full-width-content': themeOptions.fullWidthContent,
						'position-relative': themeOptions?.header,
					  }" [style.background-image]="headerBgImage">


            <div class="d-flex flex-column flex-md-row-reverse justify-content-md-between py-md-3">
              <div class="d-flex top-header align-items-center align-self-md-start">
                <!-- for kanban -->
                <!-- <div class="project-btn" *ngIf="isKanban">
                  <button [style.background-color]="'c-mtm-brand'" class="btn btn-sm btn-danger"
                          (click)="goToProject()"> {{'workspace_edit_goToProject'|translate}}</button>
                </div> -->
                <top-nav></top-nav>
              </div>

              <section class="d-flex flex-row align-items-center py-3 px-4 py-md-0 header-info-container"
                       *ngIf="themeOptions.hasHeaderInfo">
                <div class="mr-3 image-container" *ngIf="headerInfo.avatarImage || headerInfo.avatarName">
                  <mtm-image [name]="headerInfo.avatarName" [isSquare]="true"
                             [imageUrl]="headerInfo.avatarImage" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h2 class="mb-0 text-capitalize header-title">{{headerInfo.title | translate}}</h2>
                  <div class="header-subtitle" *ngIf="headerInfo.subtitle"
                       [innerHTML]="headerInfo.subtitle | translate: headerInfo.subtitleParams">
                  </div>
                </div>
              </section>
            </div>


            <div *ngIf="themeOptions?.header">
              <ng-container [ngTemplateOutlet]="themeOptions.header"></ng-container>
            </div>
          </header>
          <section
            class="d-flex flex-column flex-xl-row align-items-center justify-content-xl-between p-3 breadcrumb-container"
            [class.fixed-breadcrumb-container]="!themeOptions.fullWidthContent">
            <div class="breadcrumb-wrapper">
              <mtm-breadcrumb-component></mtm-breadcrumb-component>
            </div>

            <ng-container [ngTemplateOutlet]="themeOptions.searchPanel"></ng-container>

          </section>

          <section>
            <ng-container></ng-container>
          </section>

          <div #rootLayoutContainer class="" [ngClass]="{'top-shift': false}">
            <router-outlet></router-outlet>
          </div>
          <div class="bottom-container">
            <div class="d-flex align-items-end">
              <ng-container [ngTemplateOutlet]="themeOptions.bottomPanel"></ng-container>
              <mtm-download-popup *ngIf="authUser"></mtm-download-popup>
              <mtm-upload-popup *ngIf="authUser"></mtm-upload-popup>
            </div>
          </div>
        </div>
        <div *ngIf="themeOptions?.rightPane">
          <ng-container [ngTemplateOutlet]="themeOptions.rightPane"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
