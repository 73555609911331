<div class="header-right-side-container" [ngClass]="{ 'public-link': publicReviewLink || publicPage }">
	<button *ngIf="hasDebt" class="btn payButton" (click)="openPaymentModal()">
		{{'billing_overview_label_subscribeNow' | translate}}
	</button>

	<selector-activity-log *ngIf="!publicReviewLink && !publicPage && authUser &&
	hasActivityLogAccess"></selector-activity-log>

	<selector-notification-dropdown *ngIf="!publicReviewLink && !publicPage && authUser">
	</selector-notification-dropdown>
	
	<button class="btn language-button" [style.white-space]="'nowrap'" (click)="backToAdmin()" *ngIf="onBehalf()">Logout
		on behalf</button>

	<!--
	<div ngbDropdown class="d-inline-block language-picker" [class.pe-none]="isPending">
		<div class="toggle" ngbDropdownToggle>
			<i class="flag-icon {{selectedFlag}}"></i>
		</div>
		<div ngbDropdownMenu>
			<div ngbDropdownItem class="btn flag-icon flag-icon-us" (click)="changeLang('en_us')"></div>
			<div ngbDropdownItem class="btn flag-icon flag-icon-fr" (click)="changeLang('fr')"></div>
			<div ngbDropdownItem class="btn flag-icon flag-icon-es" (click)="changeLang('es')"></div>
			<div ngbDropdownItem class="btn flag-icon flag-icon-pt" (click)="changeLang('pt')"></div>
			<div ngbDropdownItem class="btn flag-icon flag-icon-cn" (click)="changeLang('zh')"></div>
		</div>
	</div>
	-->
</div>
