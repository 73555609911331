import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { UserService } from "app/shared/services/user.service";
import { AuthService } from "app/shared/services/auth.service";
import { MtmCurrencyPipe } from "app/shared/pipes/mtm-currency.pipe";
import * as moment from "moment";
import "moment-timezone";
import { CommonService } from "../../../shared/services/common.service";
import { ProdPartnerService } from "../../../shared/services/prod-partner.service";
import { DateHelperService } from "../../../shared/services/date-helper.service";
import { NotificationService } from "app/shared/services/notification.service";

import { checkProjectDates } from 'app/shared/base-functions/check-project-dates.base.function';
import { EmitterService } from "../../../shared/services/emitter.service";
import { DownpaymentService } from "../../../shared/services/downpayment.service";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "../../../shared/services/datepicker-adapter";
import { CheckProjectDates } from "../../../shared/services/check-project-dates";
import { OverlayService } from "../../../shared/services/overlayService";
import { CurrencyService } from "app/shared/services/currency.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { WorkspaceService } from "app/shared/services/workspace.service";

@Component({
	selector: "mtm-schedule-partner-selection",
	templateUrl: "./schedule-partner-selection.component.html",
	styleUrls: ["./schedule-partner-selection.component.scss"],
	providers: [MtmCurrencyPipe, { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
	host: {
		"(document:click)": "onClick($event)"
	}
})
export class SchedulePartnerSelectionComponent implements OnInit, OnDestroy {
	@Input()
	projectId: string;
	@Input()
	sectionId: string;
	@Input()
	subsectionId: string;
	@Input()
	isOneShotProject: boolean;
	@Output()
	onSubmitScheduleAndPrice = new EventEmitter<boolean>();

	@ViewChild("plannedBudgetElement", { static: true })
	plannedBudgetElement: ElementRef;

	projectBudget: any;
	sending: boolean = false;
	currencies: any[];
	currency: string | number;
	sectionName: string;
	timezones: any[] = [];
	selectedLocatedType: any = "GLOBAL";
	selectedLocated: any = "GLOBAL";
	consultant: boolean;
	consultantOption: any;
	languages: any[] = [];
	langSelected: any[] = [];
	subTitleLanguagesSelected: any[] = [];
	dubbingLanguagesSelected: any[] = [];
	proposalAccepted: boolean = false;
	currencyFocused: boolean;
	videoLength: any = {
		hour: '',
		minutes: '',
		seconds: ''
	};

	referenceDate: any = {
		startDate: null,
		dueDate: null,
		airDate: null
	};
	date: any = {
		startDate: null,
		dueDate: null,
		airDate: null
	};
	authUser;

	isValidVideoLenght: boolean = false;
	isValidLang: boolean = false;

	budgetMenu: boolean;
	budgetRange = [];

	creativeObject: any;

	consultantOptions = [];

	knowbudget: any;
	@ViewChild("d", { static: true })
	datePicker;
	@ViewChild("d2", { static: true })
	datePicker2;
	@ViewChild("d3", { static: true })
	datePicker3;

	userCurrency: any;
	ngUnsubscribe = new Subject();

	constructor(private elementRef: ElementRef,
		private router: Router,
		private projectService: ProjectService,
		private authService: AuthService,
		public service: ProdPartnerService,
		public userService: UserService,
		private route: ActivatedRoute,
		private workspaceService: WorkspaceService,
		private currencyPipe: MtmCurrencyPipe,
		private commonService: CommonService,
		private serviceNotification: NotificationService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private currencyService: CurrencyService
	) {
		this.creativeObject = {
			title: "CREATIVE_BRIEF",
			label: "creative",
			selected: false,
			reflesh: false
		};

		this.consultantOptions.push({
			title: "CREATIVE_BRIEF",
			label: "creative",
			selected: false,
			reflesh: false
		});
		this.consultantOptions.push({
			title: "BIDDING",
			label: "bidding",
			selected: false,
			reflesh: false
		});
		this.consultantOptions.push({
			title: "PRODUCTION",
			label: "production",
			selected: false,
			reflesh: false
		})
		this.consultantOptions.push({
			title: "DONTHELP",
			label: "dontHelp",
			selected: true,
			reflesh: true
		});
	}

	initialize() {
		this.currencies = this.userService.getCurrencies();
		this.authUser = this.authService.getAuthUser();
		if (this.authService && this.authUser && !this.authUser.isAdmin && this.proposalAccepted == false) {
			this.checkProposalAccepted()
		}
		EmitterService.get("scriptChange").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(script => {
			if (script)
				this.removeConsultantBrief();
			else {
				this.addConsultantBrief();
			}
		});
		this.loadFormOptions();
		this.setUpLanguages();
		this.getProductionCompanyCountries();
		if (!this.isOneShotProject) {
			this.route.pathFromRoot[1].params.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(params => {
				this.projectId = params["projectId"];
				this.service.getProdPartnerContent(this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(content => {
					if (content.script)
						this.removeConsultantBrief();
					else
						this.addConsultantBrief();
				});
				this.service.getProdPartnerScheduleAndPricing(this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(secheduleAndPricing => {
					let dontHelp = this.consultantOptions.find(c => c.title === "DONTHELP")
					dontHelp.selected = true
					this.consultantOptions.forEach(c => {
						if (secheduleAndPricing.consultantValues.indexOf(c.title) >= 0) {
							dontHelp.selected = false
							c.selected = true;
						}
					});
				});
				this.route.params.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(params => {
					if (params["sectionId"] && params["subsectionId"]) {
						this.sectionId = params["sectionId"];
						this.subsectionId = params["subsectionId"];
						this.service
							.getProdPartnerScheduleAndPricing(this.projectId).pipe(
								takeUntil(this.ngUnsubscribe)
							).subscribe(s => {
								this.langSelected = s.languages;
								this.subTitleLanguagesSelected = s.subTitleLanguages;
								this.dubbingLanguagesSelected = s.dubbingLanguages;
								this.videoLength.hour = s.hours;
								this.videoLength.minutes = s.minutes;
								this.videoLength.seconds = s.seconds;
								this.date.startDate = DateHelperService.fromDateToDatepicker(
									DateHelperService.fromTimestampToDate(
										s.startDate
									)
								);
								this.date.dueDate = DateHelperService.fromDateToDatepicker(
									DateHelperService.fromTimestampToDate(s.dueDate)
								);
								this.date.airDate = DateHelperService.fromDateToDatepicker(
									DateHelperService.fromTimestampToDate(s.airDate)
								);
								this.referenceDate = {
									...this.referenceDate,
									startDate: DateHelperService.fromTimestampToDate(s.startDate),
									plannedEndDate: DateHelperService.fromTimestampToDate(s.dueDate),
									airDate: DateHelperService.fromTimestampToDate(s.airDate),
								}
								this.countBudget(s);
								this.budgetMenu = this.proposalAccepted || !!this.knowbudget;
								this.onSelectLocated(s.country, s.location);
								this.setValuesOfConsultant(this.consultantOptions, s.consultantValues);
							});
					}
				})
					.unsubscribe();
			});
		}
	}

	ngOnInit() {
		this.currencyService.getMyCountryDetail()
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((data: any) => {
				if (!this.projectService.project.id) {
					this.projectService.project.currency = (data.currency.code !== 'EUR' ? 'USD' : 'EUR');
				}
				this.initializeBudgetRanges();
				this.initialize();
			});
	}

	ngOnDestroy() {
		this.changeProjectDates(this.referenceDate);
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	onCurrencyChange(e) {
		if (this.projectService.project.id) {
			this.projectService.saveProject(this.projectService.project)
				.subscribe(() => {
					this.initializeBudgetRanges();
					this.onAmountBlur(e);
				}, (err) => console.log(err));
		} else {
			this.currency = this.projectService.project.currency;
			this.initializeBudgetRanges();
			this.onAmountBlur(e);
		}
	}

	initializeBudgetRanges() {
		this.budgetRange = [];
		this.budgetRange.push({
			title: "MINIMAL",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "SMALL",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "MEDIUM",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "NORMAL",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "BIG",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "HUGE",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
		this.budgetRange.push({
			title: "ENTERPRISE",
			selected: false,
			currency: this.projectService.project.currency !== 'EUR' ? 'USD' : 'EUR'
		});
	}

	private onClick(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.datePicker.close();
			this.datePicker2.close();
			this.datePicker3.close();
		}
	}

	private removeConsultantBrief() {
		this.consultantOptions.forEach(consultant => {
			if (consultant.title == "CREATIVE_BRIEF") {
				const index = this.consultantOptions.indexOf(consultant);
				this.consultantOptions.splice(index, 1);
			}
		})
	}

	private addConsultantBrief() {
		if (this.consultantOptions.length < 4) {
			if (this.consultantOptions.filter(option => option.title === this.creativeObject.title).length == 0) {
				this.consultantOptions.unshift(this.creativeObject);
			}
		}
	}

	private countBudget(s: any) {
		let index = this.budgetRange.findIndex(k => k.title == s.budgetRange);
		if (index >= 0 && s.budget && !this.proposalAccepted) {
			this.budgetRange[index].selected = true;
		} else {
			this.knowbudget = (s.budget ? ((this.projectService.project.currency === 'EUR' ? '€' : '$') + s.budget) : '');
		}
	}

	private loadFormOptions() {
		this.currencies = this.userService.getCurrencies();
		this.currency = this.authService.getUserSettings("currency");
	}

	getDateLabel(date) {
		if (date)
			return moment(new Date())
				.year(date.year)
				.month(date.month - 1)
				.date(date.day)
				.utcOffset(0)
				.format("MMMM D, YYYY");
		return "-";
	}

	selectedBudgetMenu(which: boolean) {
		this.budgetMenu = which;
	}

	onSelectLocated(country: any, located) {
		this.selectedLocated = country;
		this.selectedLocatedType = located;
	}

	toggleOption(selectedToogle: any, isknowBudget: boolean) {
		setTimeout(() => {
			this.budgetRange.map(k => (k.selected = false));
			if (!isknowBudget) {
				selectedToogle.selected = true;
				this.knowbudget = null;
			}
		}, 0);
	}

	consultantButtonOption(selectedToogle: any) {
		if (selectedToogle.reflesh) {
			this.consultantOptions.forEach(c => {
				if (c.reflesh)
					c.selected = !c.selected;
				else
					c.selected = false;
			})
		} else {
			selectedToogle.selected = !selectedToogle.selected;
			this.consultantOptions.forEach(c => {
				if (c.reflesh)
					c.selected = false;
			})
		}
	}

	private setUpLanguages() {

		if (this.isOneShotProject) {
			this.commonService.getOneShotLanguages().pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.languages = data;
				});
		} else {
			this.commonService.getLanguages().pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.languages = data;
				});
		}

	}

	onSubmit(): void {

		if (!CheckProjectDates.checkProjectStartDateEndDate(this.date.startDate, this.date.dueDate)) {
			this.overlayService.showError(this.translatePipe.transform("overlaySubsectionDateError"));
		} else if (!CheckProjectDates.checkProjectEndDateAirDate(this.date.dueDate, this.date.airDate)) {
			this.overlayService.showError(this.translatePipe.transform("overlaySubsectionDateError2"));
		}
		let budget = null;

		if (this.isValidLang || !this.compareProjectDates() || this.isValidBudget()) return;

		if (this.budgetMenu) {
			budget = this.knowbudget ? parseInt(this.knowbudget.replace(/[^\d.-]/g, '')) : 0;
		} else {
			let budgetObj = this.budgetRange.find(k => k.selected == true);
			if (budgetObj)
				budget = budgetObj.title;
			else
				budget = null;
		}


		let DTO: any = {
			projectId: this.projectId,
			startDate: DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(this.date.startDate)
			),
			dueDate: DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(this.date.dueDate)
			),
			airDate: DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(this.date.airDate), DateHelperService.END_OF_DAY
			),
			currency: this.projectService.project.currency,
			knowBudget: this.budgetMenu,
			budget: budget,
			location: this.selectedLocatedType == 'LOCAL' ? "LOCAL" : "GLOBAL",
			country:
				this.selectedLocatedType == "GLOBAL"
					? null
					: this.selectedLocated,
			consultantValues: this.getValueOfConsultant(this.consultantOptions),
			languages: this.langSelected,
			subTitleLanguages: this.subTitleLanguagesSelected,
			dubbingLanguages: this.dubbingLanguagesSelected,
			hours: this.videoLength.hour || 0,
			minutes: this.videoLength.minutes || 0,
			seconds: this.videoLength.seconds || 0
		};
		this.save(DTO);
	}

	private save(DTO: any) {
		this.service
			.saveProdPartnerScheduleAndPricing(this.projectId, DTO, this.isOneShotProject).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				res => {
					this.referenceDate = {
						...this.referenceDate,
						startDate: DateHelperService.fromTimestampToDate(res.startDate),
						plannedEndDate: DateHelperService.fromTimestampToDate(res.dueDate),
						dueDate: DateHelperService.fromTimestampToDate(res.dueDate),
						airDate: DateHelperService.fromTimestampToDate(res.airDate)
					};

					if (this.projectService.project.id) {
						this.projectService.project.budget = res.budget;
						this.projectService.saveProject(this.projectService.project);
					}


					const activeWorkspace = this.workspaceService.getActiveWorkspace();

					//if this is new workspace one shot project
					if (!this.projectService.project.id && activeWorkspace && this.isOneShotProject && this.authUser && this.authUser.company.companyType == 'ADVERTISER') {
						this.projectService.createOneShotForWorkspace(activeWorkspace.id, DTO.projectId).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe((response) => {
							this.router.navigate(['/projects', DTO.projectId]);
						});

					} else {
						this.nextPage();
					}



				},
				err => {
					console.log('Something went wrong while saving schedule & pricing !!!');
				}
			);
	}

	private nextPage(): void {
		if (this.projectService.subsectionsSelected && !this.isOneShotProject) {
			let current = false;
			this.projectService.subsectionsSelected.forEach(sub => {
				if (current) {
					this.router.navigate([
						"projects",
						this.projectId,
						this.sectionId,
						sub.id,
						'preview'
					]);
					current = false;
				}
				if (sub.id === this.subsectionId) current = true;
			});
		} else if (this.isOneShotProject) {
			this.onSubmitScheduleAndPrice.emit(true)
		}
	}

	isValidProjectDates(controlValue, isStartDate?): boolean {
		if (!controlValue || controlValue == '')
			return true;

		if (isStartDate)
			return false;

		return !this.compareProjectDates();
	}

	compareProjectDates(): boolean {
		return checkProjectDates(
			this.date.startDate,
			this.date.dueDate,
			this.date.airDate
		);
	}

	onChangedProjectDate() {
		let newData = {
			startDate: DateHelperService.fromDatePickerToDate(this.date.startDate),
			plannedEndDate: DateHelperService.fromDatePickerToDate(this.date.dueDate),
			airDate: DateHelperService.fromDatePickerToDate(this.date.airDate)
		}

		this.changeProjectDates(newData);
	}

	changeProjectDates(date) {
		this.projectService.project = {
			...this.projectService.project,
			startDate: date.startDate,
			plannedEndDate: date.plannedEndDate,
			airDate: date.airDate
		}

		if (this.projectService.projectOnloaded)
			this.projectService.emitProjectOnloaded();
	}

	private getValueOfConsultant(inputs: any[]) {

		let values: any[] = [];
		inputs.filter(k => k.selected == true).map(l => {
			values.push(l.title)
		});

		return values;
	}

	private setValuesOfConsultant(consultantKey: any[] = [], consultantList: any[] = []) {
		try {
			consultantList.map(k => {
				let obj = consultantKey.find(l => l.label == k);
				obj.selected = true;
			})
		} catch (error) {

		}
	}


	getProductionCompanyCountries() {
		this.projectService.getProductionCompanyCountries().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.timezones = res.filter(dt => dt.code != 'CH');
		}, error2 => {
			console.log(error2)
		});
	}


	private checkProposalAccepted() {
		this.projectService.checkProposalAccepted(this.projectService.project.id).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.proposalAccepted = res;
		});
	}

	private isValidBudget() {
		if (this.budgetMenu) {
			if (this.knowbudget == null) {
				return true;
			} else {
				return false;
			}
		} else {
			if (this.budgetRange.filter(purpose => purpose.selected === true).length == 0) {
				return this.knowbudget == null;
			} else {
				return false;
			}
		}

	}

	onAmountBlur(e) {
		this.onAmountFocused(e);
		setTimeout(() => {
			this.currencyFocused = false;
			this.knowbudget = (this.knowbudget ? ((this.projectService.project.currency === 'EUR' ? '€' : '$') + (this.knowbudget || '')) : '');
		}, 0);
	}

	onAmountFocused(e) {
		this.currencyFocused = true;
		this.knowbudget = this.knowbudget ? parseInt((this.knowbudget.toString().replace(/[^\d.-]/g, ''))) : '';
	}
}
