<div class="mtm-user-profile-picture"
	[ngClass]="{ 'square-icon': squareIcon, 'circle-icon': !squareIcon, 'disabled': disabled }">
	<div class="icon-container-img d-flex align-items-center justify-content-center" *ngIf="isHasProfilePicture()"
		[ngClass]="{'show-border': showBorder}">
		<img class="icon-image" [src]="usersList[username]?.blobPicture | safeResourceUrl" [height]="height"
			[width]="width">
	</div>
	<div class="icon-container-color d-flex justify-content-center align-items-center" *ngIf="!isHasProfilePicture()"
		[ngClass]="{'show-border': showBorder}">
		<span *ngIf="!avatarUrl || !avatarLoaded" class="icon-color" [style.height]="height + 'px'" [style.width]="width + 'px'"
			[style.fontSize]="fontSize" [style.backgroundColor]="nameColor">
			{{initialName}}
		</span>
    <!--
		<span *ngIf="avatarUrl" class="icon-color" [style.height]="height + 'px'" [style.width]="width + 'px'"
			[style.background-image]="'url(' + avatarUrl + ')'" [style.background-repeat]="'no-repeat'"
			[style.background-position]="'center'">
		</span>
		-->
    <img *ngIf="avatarUrl" class="icon-avatar" [class.d-none]="!avatarLoaded" [style.height]="height + 'px'" [style.width]="width + 'px'"
         [src]="avatarUrl"
         (load)="onAvatarLoaded()" />

	</div>
</div>
