<div class="hierarchical-files-component">
  <div *ngIf="label && label!=''" class="row m-0 mb-2 pt-4 pr-2 text-right" style="border-bottom: 1px solid #f1f1f1;">
    <strong>{{label}}</strong>
  </div>
  <div *ngIf="isSearchDisplay" class="d-flex align-items-center pt-3 pb-3 pl-5 pr-5 search-box controllersContainer">
    <div class="d-flex align-items-center folder-location-info">
      <i title="back" *ngIf="hierarcicalMenu.length > 1" class="mtm-icon-back pointer" (click)="backFolder()"></i>
      <span class="_800">{{getFolderDisplayName((hierarcicalMenu.length ? hierarcicalMenu[hierarcicalMenu.length - 1].name : '')) |
        translate}}</span>
    </div>
    <label class="form-group mr-4 ml-auto">
      <input class="form-control pl-3 pr-1 pt-2 pb-2" type="search" placeholder="{{'search'|translate}}"
             [(ngModel)]="fileSearchName" (ngModelChange)="searchFiles()" [style.height]="'auto'">
      <button class="btn" (click)="searchFiles()">
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </label>
    <div class="d-flex flex-row align-items-center">
      <div *ngIf="viewMode=='PREVIEW' && currentRoot.files.length>0"
           class="w-100 d-flex flex-row justify-content-end">
        <button class="btn btn-sm btn-outline-danger" (click)="changeSlideValue('ZOOM-OUT')">
          <i class="fa fa-search-minus"></i>
        </button>
        <span class="zoom-value">
					<strong>{{slideValue}}%</strong>
				</span>
        <button class="btn btn-sm btn-outline-danger" (click)="changeSlideValue('ZOOM-IN')">
          <i class="fa fa-search-plus fa-danger"></i>
        </button>
      </div>
      <ul class="d-flex align-items-center m-0">
        <li class="d-inline-block mr-3">
          <a class="nav-link p-0 pointer" [class.view-selected]="viewMode=='GRID'"
             (click)="selectViewChange($event, 'GRID')">
            <img type="image/svg+xml" height="24" width="24"
                 src="/assets/img/{{viewMode=='GRID' ? 'grid-view' : 'unselected-grid-view-icon'}}.svg"
                 class="grid-icon">
          </a>
        </li>
        <li class="d-inline-block">
          <a class="nav-link p-0 pointer" [class.view-selected]="viewMode=='LIST'"
             (click)="selectViewChange($event, 'LIST')">
            <img type="image/svg+xml" height="24" width="24"
                 src="/assets/img/{{viewMode=='LIST' ? 'selected-list-view-icon' : 'list-view'}}.svg"
                 class="list-icon">
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-end files-archive-box pt-3 pb-3 pl-5 pr-5">
    <!--
    <button class="btn mtm-btn b-mtm-black c-full-white _700 pt-2 pb-2 pl-3 pr-5"
      [disabled]="archivingFilesInProgress || !isHasAccess"
      [ngClass]="{ 'pr-5': !archivingFilesInProgress, 'pr-4': archivingFilesInProgress }"
      (click)="openFolderModal($event)">
      <div class="d-flex align-items-center justify-content-center">
        <i class="mtm-icon-archive"
          [ngClass]="{ 'mtm-icon-archive': !archivingFilesInProgress, 'fa fa-spinner fa-pulse': archivingFilesInProgress }"></i>
        <span class="t-uppercase">{{ archivingFilesInProgress ? 'BUILDING ARCHIVE...' : 'CREATE ARCHIVE'
          }}</span>
      </div>
    </button>
    -->
    <div class="d-flex" *ngIf="currentRoot?.canAdd">
      <div class="create-new-folder-button d-flex flex-column align-items-center justify-content-center"
           (click)="$event.stopPropagation(); createDirectory();">
        <i class="d-flex align-items-center justify-content-center fa fa-plus"></i>
        <span class="mt-1">{{'createNewFolder' | translate }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-center upload-file-button ml-3"
           (click)="$event.stopPropagation(); uploadFile();">
        <div class="upload-file-icon"></div>
        <span class="ml-4">{{'uploadFile' | translate }}</span>
      </div>
    </div>

  </div>
  <div *ngIf="folders?.length">
    <div *ngFor="let folder of folders" class="d-flex align-items-center mb-1 pointer" (drop)="handleDrop($event, folder)" (dragover)="allowDrop($event)"
         [style.border-bottom]="'1px solid rgba(167, 178, 210, 0.5)'" (click)="openDirectory(folder)" >
      <img height="34" width="46" src="assets/img/black_folder.svg" class="file-icon mr-2"/>
      <h6 class="nameFileFolder m-0 _600 c-mtm-full-black text-left">{{getFolderDisplayName(folder.displayName)}}
      </h6>
      <div class="trash-button ml-auto" *ngIf="isDisplayTrashBtn" [ngClass]="{'rightbar-trash': isSmallDisplay}">
				<span (click)="$event.stopImmediatePropagation();onTrashFile(folder, $event)">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</span>
      </div>
    </div>
  </div>
  <div class="file-list hierarcicalFilesDisplayContainer pb-3" #hierarchicalFilesDisplayContainer
       [ngClass]="{'px-4': !isSmallDisplay}">
    <div *ngIf="isMenuDisplay" class="w-100 folder-list pt-2 pb-2 pl-3 pr-3 d-flex">
			<span class="folder-item d-inline-flex align-items-center justify-content-center mr-2">
				<span class="menuElement menuBackButton d-inline-block p-2" (click)="backFolder()">{{'back' |
          translate}}</span>
			</span>
      <span *ngFor="let menu of hierarcicalMenu;let i=index" (click)="preFolder(i)"
            class="folder-item d-inline-flex align-items-center justify-content-center mr-2">
				<span class="menuElement d-inline-block p-2" [ngClass]="{'menu-font': i == hierarcicalMenu.length - 1}">
					{{(menu?.name || '') | translate}}</span>
			</span>
    </div>
    <hr class="m-0" *ngIf="isMenuDisplay">
    <h3 *ngIf="fileSearchName && fileSearchName!='' && (!currentRoot.files || currentRoot.files.length<=0)"
        class="w-100 text-center text-danger mt-2 mb-2">
      Not found file
    </h3>

    <!-- GRID -->

    <div class="grid-view" *ngIf="viewMode=='GRID'">
      <div class="mb-3 mt-2" [style.height]="'24px'" [style.position]="'relative'">
        <label [style.left]="'0'" [style.right]="'auto'" *ngIf="canDownloadFiles"
               class="mtm-checkbox mtm-checkbox-green m-0">
          <input class="form-check-input" type="checkbox" [(ngModel)]="isAllAssetsSelected"
                 (change)="selectAllAssets($event)">
          <span class="checkmark"></span>
          <span class="_600 ml-3" [style.font-size]="'14px'">Select All</span>
        </label>
      </div>
      <div class="d-flex flex-column flex-md-row flex-wrap" [style.gap]="'1rem'">
        <div class="d-flex flex-column text-center pointer folder-content"
             *ngFor="let child of currentRoot.children" (click)="canDownloadFiles ? true : nextFolder(child)">
          <div class="d-flex align-items-center folder-info">
            <img height="34" width="46" src="assets/img/black_folder.svg" class="file-icon"/>
            <h6 class="nameFileFolder _600 c-mtm-full-black text-left"> {{(child?.name || '') | translate}}
            </h6>
          </div>
          <div class="d-flex align-items-center folder-meta">
						<span class="c-mtm-purple _500" *ngIf="! child.hideCount">{{child.displayLengthOfFiles || 0}}
              {{'files' | translate}}
						</span>
            <div *ngIf="child.lastUpdated" class="d-flex align-items-center" style="flex: 1;">
              <i [style.font-size]="'6px'" class="c-mtm-purple fa fa-circle mr-2 ml-2"
                 *ngIf="! child.hideCount"></i>
              <span class="c-mtm-purple _500 grid-updated-date" title="{{'filesUpdated' | translate}} {{child.lastUpdated | mtmDate }}"
              >{{'filesUpdated' | translate}} {{child.lastUpdated | mtmDate }}</span>
            </div>
          </div>
          <label *ngIf="canDownloadFiles" class="mtm-checkbox mtm-checkbox-green m-0">
            <input class="form-check-input" (change)="selectAsset($event)" type="checkbox"
                   [(ngModel)]="child.selected">
            <span class="checkmark"></span>
          </label>
          <div ngbDropdown class="dropdown" container="body" (click)="$event.stopImmediatePropagation()"
               placement="bottom-right" *ngIf="child.canDelete && child.type == 'DIR'">
						<span ngbDropdownToggle class="edit-button">
							<img src="/assets/img/selection-grid-edit/three-dot-menu.svg" alt="">
						</span>
            <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownMenuLink">
              <a class="font-weight-bold dropdown-item" (click)="deleteDirectory(child)">
                Delete
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column text-center pointer folder-content"
             *ngFor="let file of currentRoot.files" (click)="onFileSelected($event, file)"
             [ngClass]="{ 'selected': file.selected }">
         
          <div [hidden]="true" [style.position]="'absolute'" [style.right]="'0'" *ngIf="selectable">
            <input type="checkbox" [(ngModel)]="file.selected" (change)="onFileSelected($event, file)">
          </div>
          <hr-grid-subcmp [file]="file" (iconClick)="handleListIconClick(file)"
                          [tightDateMargin]="currentRoot.canMove"
                          (nameClick)="handleListNameClick(file)"></hr-grid-subcmp>
          <hr-badgebtn-subcmp *ngIf="!disableAction" [file]="file" [isDisplayTrashBtn]="isDisplayTrashBtn"
                          class="position-relative mx-auto action-buttons"
                          (onTrashFile)="onTrashFile($event)" [class.rightbadge]="isSmallDisplay"
                          [moveButtonShown]="currentRoot.canMove" [localFile]="localFile"
                          (fileMove)="handleFileMovePrompt($event)" ></hr-badgebtn-subcmp>
        </div>
        <div *ngIf="stillLoading && !fileSearchName"
             class="loader d-flex flex-column align-items-center justify-content-center text-center pointer">
          <div class="capsul">
            <div class="content-area m-0">
              <div class="d-flex align-items-center justify-content-center flex-column">
                <div class="d-block">
                  <i class="fa fa-spinner fa-pulse"
                     [ngStyle]="{ 'font-size': '32px', 'color': 'red' }"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="viewMode=='PREVIEW'" class="row w-100 ml-0 mr-0">
      <div class="col-sm-4 text-center mb-1" *ngFor="let child of currentRoot.children"
           (click)="canDownloadFiles ? true : nextFolder(child)">
        <img src="assets/img/icons/blue_folder.svg" class="file-icon mr-1 preview-container"/>
        <h6 class="nameFileFolder"> {{child.name}}</h6>
        <h6 class="nameFileFolder">
          <strong>{{child.displayLengthOfFiles || 0}} files</strong>
        </h6>
      </div>
      <hr class="w-100"
          *ngIf="currentRoot.children && currentRoot.children.length && currentRoot.files && currentRoot.files.length">
      <div class="text-center file-preview-container mb-1" style="position: relative"
           *ngFor="let file of currentRoot.files" (click)="onFileSelected($event, file)"
           [ngClass]="{ 'selected': file.selected }">
        <hr-badgebtn-subcmp *ngIf="!disableAction" [file]="file" [isDisplayTrashBtn]="isDisplayTrashBtn" [localFile]="localFile"
                            (onTrashFile)="onTrashFile($event)"></hr-badgebtn-subcmp>
        <div [hidden]="true" [style.position]="'absolute'" [style.right]="'0'" *ngIf="selectable">
          <input type="checkbox" [(ngModel)]="file.selected" (change)="onFileSelected($event, file)">
        </div>
        <div class="file-icon preview-container" style="position: sticky;"
             [style.width]="widthForPreviewMode + 'px'" [style.height]="heightForPreviewMode + 'px'">
          <hr-preview-subcmp [style.display]="'block'" [style.height]="'100%'" [file]="file"
                             [height]="heightForPreviewMode+'px'"></hr-preview-subcmp>
        </div>
      </div>
      <div *ngIf="stillLoading && !fileSearchName" class="col-sm-4 text-center m-0">
        <div class="capsul">
          <div class="content-area m-0">
            <div class="d-flex align-items-center justify-content-center flex-column">
              <div class="d-block">
                <i class="fa fa-spinner fa-pulse"
                   [ngStyle]="{ 'font-size': '32px', 'color': 'red' }"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- list view -->
    <div *ngIf="viewMode=='LIST'" class="w-100 list-view">
      <div class="d-flex flex-column w-100">
        <div class="folder-table-header folder-table-row d-flex flex-wrap align-items-center flex-fill"
             *ngIf="currentRoot.children?.length || !isSmallDisplay">
          <div class="d-flex align-items-center folder-table-cell flex-fill c-mtm-purple _600">
            <div class="ml-1 mr-2">
              <label *ngIf="canDownloadFiles" class="mtm-checkbox mtm-checkbox-green m-0">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isAllAssetsSelected"
                       (change)="selectAllAssets($event)">
                <span class="checkmark" [style.top]="'-10px'"></span>
              </label>
            </div>
            <div class="pointer d-flex align-items-center" (click)="sortData('name')">
              <span class="mr-2">{{'name'|translate}}</span>
              <i *ngIf="sortBy==='name'" class="fa fa-sort-asc"
                 [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
            </div>
          </div>
          <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay && showNumberOfFiles"
               (click)="sortData('displayLengthOfFiles')">
            <span class="mr-2">{{'numberOfFiles'|translate}}</span>
            <i *ngIf="sortBy==='displayLengthOfFiles'" class="fa fa-sort-asc"
               [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
          </div>
          <!-- TODO:- content section remove after confirmation -->
          <!-- <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay"
               (click)="sortData('content')">
            <span class="mr-2">{{'content'|translate}}</span>
            <i *ngIf="sortBy==='content'" class="fa fa-sort-asc"
               [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
          </div> -->
          <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay"
               (click)="sortData('lastUpdate')">
            <span class="mr-2">{{'lastUpdate'|translate}}</span>
            <i *ngIf="sortBy==='lastUpdate'" class="fa fa-sort-asc"
               [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
          </div>
          <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay"
               (click)="sortData('size')">
            <span class="mr-2">{{'size'|translate}}</span>
            <i *ngIf="sortBy==='size'" class="fa fa-sort-asc"
               [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
          </div>
          <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay && showUploadedBy"
               (click)="sortData('size')">
            <span class="mr-2">{{'uploadedBy'|translate}}</span>
            <i *ngIf="sortBy==='size'" class="fa fa-sort-asc"
               [ngClass]="{ 'fa-sort-asc': dir==='asc', 'fa-sort-desc': dir==='desc' }"></i>
          </div>
          <div class="folder-table-cell flex-fill c-mtm-purple _600 pointer" *ngIf="! isSmallDisplay">
            <span class="mr-2"></span>
          </div>
        </div>
        <div class="folder-table-row d-flex flex-wrap align-items-center flex-fill pointer"
             *ngFor="let child of currentRoot.children" (click)="canDownloadFiles ? true : nextFolder(child)"
             (drop)="handleDrop($event, child)" (dragover)="allowDrop($event)">
          <div class="folder-table-cell d-flex align-items-center folder-info">
            <div class="ml-1 mr-2">
              <label *ngIf="canDownloadFiles" class="mtm-checkbox mtm-checkbox-green m-0">
                <input class="form-check-input" type="checkbox" (change)="selectAsset($event)"
                       [(ngModel)]="child.selected">
                <span class="checkmark" [style.top]="'-10px'"></span>
              </label>
            </div>
            <img height="18" width="24" src="assets/img/black_folder.svg" class="file-icon"/>
            <h6 class="m-0 nameFileFolder _600 c-mtm-full-black text-left"> {{getFolderDisplayName(child?.name || '') |
              translate}}
            </h6>
          </div>
          <div class="folder-table-cell d-flex align-items-center folder-info" *ngIf="! isSmallDisplay && showNumberOfFiles">
						<span class="c-mtm-purple _500" *ngIf="! child.hideCount">{{child.displayLengthOfFiles || 0}}
              {{'files'|translate}}</span>
          </div>
          <div class="folder-table-cell d-flex align-items-center folder-info" *ngIf="! isSmallDisplay">
						<span *ngIf="child.lastUpdated" class="c-mtm-purple _500">{{child.lastUpdated | mtmDate }}</span>
          </div>
          <div class="folder-table-cell d-flex align-items-center folder-info" *ngIf="! isSmallDisplay">
            <span class="c-mtm-purple _500" *ngIf="! child.hideSize">{{formatSizeUnits(child.size)}}</span>
          </div>
          <div class="trash-button d-flex align-items-center justify-content-center ml-auto mr-2">
						<span class="d-flex align-items-center justify-content-center">
							<i class="fa fa-trash" aria-hidden="true" *ngIf="child.canDelete && child.type == 'DIR'"
                 (click)="$event.stopPropagation(); deleteDirectory(child);"></i>
						</span>
          </div>
        </div>
        <div class="folder-table-row d-flex flex-wrap align-items-center flex-fill pointer"
             [style.position]="'relative'" *ngFor="let file of currentRoot.files"
             (click)="onFileSelected($event, file)" [ngClass]="{ 'selected': file.selected }"
             [draggable]="false" (dragstart)="handleDrag($event, file)">
          <hr-badgebtn-subcmp *ngIf="!disableAction" [file]="file" [isDisplayTrashBtn]="isDisplayTrashBtn"
                              [class.rightbadge]="isSmallDisplay" (onTrashFile)="onTrashFile($event)"
                              [moveButtonShown]="currentRoot.canMove" [localFile]="localFile"
                              (fileMove)="handleFileMovePrompt($event)"></hr-badgebtn-subcmp>
          <div [hidden]="true" [style.position]="'absolute'" [style.right]="'0'" *ngIf="selectable">
            <input type="checkbox" [(ngModel)]="file.selected" (change)="onFileSelected($event, file)">
          </div>
          <hr-list-subcmp class="flex-fill" [class.leftbadge]="isSmallDisplay" [showNumberOfFiles]="showNumberOfFiles"
                          [isSmallDisplay]="isSmallDisplay" [file]="file" (iconClick)="handleListIconClick($event)"
                          (nameClick)="handleListNameClick($event)" [allowFilenameEdit]="true" [showUploadedBy]="showUploadedBy"></hr-list-subcmp>
        </div>
        <div *ngIf="stillLoading && !fileSearchName"
             class="w-100 mt-3 m-0 d-flex flex-row justify-content-center">
          <div class="capsul">
            <div class="content-area m-0">
              <div class="d-flex align-items-center justify-content-center flex-column">
                <div class="d-block">
                  <i class="fa fa-spinner fa-pulse"
                     [ngStyle]="{ 'font-size': '32px', 'color': 'red' }"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recent-upload-assets mt-4" *ngIf="showRecentUploadedAssets && recentUploadedAssets.length">
      <label class="mt-4 mb-4">{{'recentUploadedFiles'|translate}}</label>
      <div *ngIf="viewMode=='LIST'" class="w-100 list-view">
        <div class="d-flex flex-column w-100" *ngFor="let asset of recentUploadedAssets">
          <div class="folder-table-row d-flex flex-wrap align-items-center flex-fill pointer"
               [style.position]="'relative'" (click)="onFileSelected($event, asset)"
               [ngClass]="{ 'selected': asset }">
            <hr-badgebtn-subcmp *ngIf="!disableAction" [file]="asset" [isDisplayTrashBtn]="true"
                                [class.rightbadge]="isSmallDisplay" [localFile]="localFile"
                                [moveButtonShown]="currentRoot.canMove"
                                (onTrashFile)="onTrashFile($event)"></hr-badgebtn-subcmp>
            <hr-list-subcmp class="flex-fill" [class.leftbadge]="isSmallDisplay"
                            [file]="asset" [allowFilenameEdit]="true"></hr-list-subcmp>
          </div>
        </div>
      </div>
      <div *ngIf="viewMode !='LIST'" class="d-flex flex-wrap">
        <div class="recent-uploaded-asset-item" *ngFor="let asset of recentUploadedAssets">
          <mtm-preview-asset *ngIf="asset?.files?.length" [asset]="asset" [showFileIcon]="true">
          </mtm-preview-asset>
        </div>
      </div>
    </div>
  </div>
</div>
