<div class="campaign-overview-component flex-column py-4">
  <div class="d-flex flex-column mx-auto">
    <div class="d-block">
      <div class="d-none d-md-block">
        <div class="d-flex align-items-center page-tab-control">
          <div class="section-title d-flex align-items-center" [ngClass]="{ 'collapse': isCampaignCollapse }">
            <div class="d-flex align-items-center pointer" (click)="isCampaignCollapse=!isCampaignCollapse"
              [attr.aria-expanded]="isCampaignCollapse" aria-controls="project-collapse-control">
              <i class="mtm-icon mtm-icon-right-triangle-black"></i>
              <span class="px-3 capital-1st-latter">{{ headerLabel | translate}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center ml-auto project-nav-tabs">
            <div class="tab-item pointer text-center" (click)="allItems();activeTab='all'"
              [ngClass]="{ 'active': isAll }">
              <span>{{ 'All' | translate }}</span>
            </div>
            <div class="tab-item pointer text-center" (click)="favoriteItems();activeTab='favorites'"
              [ngClass]="{ 'active': isFavorites }">
              <span>{{ 'Favorites' | translate }}</span>
            </div>
            <div class="tab-item pointer text-center" (click)="getArchiveCampaign();activeTab='archived'"
              [ngClass]="{ 'active': isArchived }">
              <span>{{ 'Archived' | translate }}</span>
            </div>
            <div class="tab-item p-0" *ngIf="canCreateDriveCampaign">
              <button class="btn btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
                (click)="createCampaignDrive()">
                <i class="p-1 fa fa-plus" aria-hidden="true"></i>
                <span class="ml-2">{{'newCampaign' | translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-md-none">
        <div class="d-flex flex-column page-tab-control">
          <div class="section-title section-title-mobile pointer d-flex align-items-center w-100 mb-2"
            [ngClass]="{ 'collapse': isCampaignCollapse }">
            <div class="d-flex align-items-center pointer" (click)="isCampaignCollapse=!isCampaignCollapse"
              [attr.aria-expanded]="isCampaignCollapse" aria-controls="project-collapse-control">
              <i class="mtm-icon mtm-icon-right-triangle-black"></i>
              <span class="px-3 capital-1st-latter">{{ headerLabel | translate }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center project-nav-tabs mb-3">
            <div class="tab-item pointer w-100 text-center" (click)="allItems();activeTab='all'"
              [ngClass]="{ 'active': isAll }">
              <span>{{ 'All' | translate }}</span>
            </div>
            <div class="tab-item pointer w-100 text-center" (click)="favoriteItems();activeTab='favorites'"
              [ngClass]="{ 'active': isFavorites }">
              <span>{{ 'Favorites' | translate }}</span>
            </div>
            <div class="tab-item pointer w-100 text-center" (click)="getArchiveCampaign();activeTab='archived'"
              [ngClass]="{ 'active': isArchived }">
              <span>{{ 'Archived' | translate }}</span>
            </div>
          </div>
          <div class="d-block" *ngIf="canCreateDriveCampaign">
            <button
              class="btn btn-block btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
              (click)="createCampaignDrive()">
              <i class="p-1 fa fa-plus" aria-hidden="true"></i>
              <span class="ml-2">{{ 'newCampaign' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4" *ngIf="isCampaignCollapse" (scroll)="handleItemsScroll()">

      <div *ngIf="!isLoaded && !isError" class="skeleton">
        <mtm-grid-skeleton [borderRadius]="6" [gridCount]="5" [widthSizeInPixels]="320"
          [HeightSizeInPixels]="240"></mtm-grid-skeleton>
      </div>
      <div class="workspace-list " #collapse="ngbCollapse" [(ngbCollapse)]="!isCampaignCollapse">
        <div *ngIf="canCreateDriveCampaign && DriveCampaigns.length == 0 " class="pointer workspace">
          <div class="campaign-drive-btn d-flex justify-content-center align-items-center w-100">
            <button class="btn btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
              [style.min-width]="'100px'" [style.width]="'auto'" (click)="createCampaignDrive()">
              <i class="p-1 fa fa-plus" aria-hidden="true"></i>
              <span class="ml-2">{{'newCampaign' | translate}}</span>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center w-75" *ngIf="isError">
          <span class="_600 f-12 ">{{'loreal_dashboard_somethingWentWrong' | translate}}</span>
        </div>
        <div class="pointer workspace" *ngFor="let item of DriveCampaigns" (click)="redirectToDashboard(item)">
          <div class="card-img">
            <img *ngIf="item.logo" class="image-style" [src]="item.logo" alt="">
            <span *ngIf="!item.logo" class="c-full-white profile-text text-uppercase h-100 w-100"
              [style.backgroundColor]="timelineService.getColorFromName(item.name)">
              {{item.name | slice:0:2}}
            </span>
            <span class="badge badge-edit-button">
              <i (click)="$event.stopImmediatePropagation();markAsFavorite(item)" *ngIf="!item.isFavorite"
                class="fa fa-star-o" aria-hidden="true"></i>
              <i (click)="$event.stopImmediatePropagation();removeFavorite(item)" *ngIf="item.isFavorite"
                class="fa fa-star" [style.color]="'#ffca4c'" aria-hidden="true"></i>
            </span>
          </div>
          <div class="card-block d-flex">
            <div class="col pl-1">
              <div class="row">
                <div class="col">
                  <span class="ellipse-text f-10 _700 brand-name" [style.width]="'220px'"
                    [style.color]="'#898C98'">{{item.workspaceName}}</span>
                </div>
              </div>

              <div class="row" [style.height]="'45px'">
                <div class="col">
                  <span class="ellipse-text f-15 _700 c-mtm-full-black text-uppercase">{{item.name | titlecase}}</span>
                </div>
              </div>

              <div class="d-flex flex-row mt-1">
                <div class=" d-flex" [style.max-width]="'max-content'">
                  <div class="item-count-circle d-flex justify-content-center align-items-center">
                    <span class="f-8 c-full-white _700">{{item.numberOfProject}}</span>
                  </div>
                  <div class="ml-2">
                    <span class="f-10 _400 ">{{'projects' | translate}}</span>
                  </div>
                </div>
                <div class=" pl-4 d-flex " [style.max-width]="'max-content'">
                  <div class="item-count-circle d-flex justify-content-center align-items-center">
                    <span class="f-8 c-full-white _700">{{item.numberOfTask}}</span>
                  </div>
                  <div class="ml-2">
                    <span class="f-10 _400">{{'tasks' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center flex-row flag-position" [style.gap]="'5px'">
                <div class="d-flex" [style.max-width]="'max-content'">
                  <span class="f-10 _700 c-mtm-full-black text-uppercase">mad {{item.expectedMad | date:'dd.MM.YYYY':'UTC'}}</span>
                </div>
                <div class="p-0" [style.max-width]="'max-content'">
                  <div class="top-content d-flex align-items-center" (click)="$event.stopImmediatePropagation()">
                    <div ngbDropdown class="d-flex align-items-center" [autoClose]="true" #dropdownProjectStatus>
                      <button [disabled]="!canEditStatus" (click)="$event.stopImmediatePropagation()" type="button"
                        class="d-flex align-items-center btn project-status-dropdown" ngbDropdownToggle>
                        <div class="d-flex align-items-center project-status {{item.status}}">
                          <div class="project-union-icon-container">
                            <i class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[item.status]}}"></i>
                          </div>
                          <span class="mr-2">{{'task_'+item.status|translate}}</span>
                          <i class="fa fa-chevron-down ml-auto pointer"></i>
                        </div>
                      </button>
                      <div class="project-dropdown-menu" ngbDropdownMenu>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.Completed||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.InProgress)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display IN_PROGRESS">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_IN_PROGRESS'|translate}}</span>
                          </div>
                        </div>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Completed)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display COMPLETED">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_COMPLETED'|translate}}</span>
                          </div>
                        </div>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Completed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Delayed)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display DELAYED">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_DELAYED'|translate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pointer" [style.height]="'20px'" style="position: relative; top: -5px;"
              (click)="$event.stopImmediatePropagation()">
              <div *ngIf="canCreateDriveCampaign" ngbDropdown class="dropdown" container="body"
                placement="bottom-right">
                <button ngbDropdownToggle class="edit-button">
                  <i class="fa fa-cog fa-lg" [style.color]="'#898D98'" aria-hidden="true"></i>
                </button>

                <div ngbDropdownMenu class="dropdown-menu-left arrow_box" aria-labelledby="dropdownMenuLink">
                  <div class="col options hover-text">
                    <div *ngIf="!inWorkspace"   class="col pointer p-0 d-flex align-items-center my-3" (click)="gotoWorkspace(item) ">
                      <i class="_500 fa fa-home fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                      <span class="ml-2 f-10 _700 text-uppercase"> {{ 'gotoWorkspace' | translate }}</span>
                    </div>
                    <div class="col pointer p-0 d-flex align-items-center my-3" (click)="redirectToDashboard(item)">
                      <i class="_500 fa fa-rocket fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                      <span class="ml-2 f-10 _700 text-uppercase"> {{ 'gotoCampaign' | translate }}</span>
                    </div>
                    <div class="col pointer p-0 d-flex align-items-center my-3 " (click)="editCampaignDrive(item)">
                      <i class="_500 fa fa-pencil fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                      <span class="ml-2 f-10 _700"> {{'settings' | translate}}</span>
                    </div>
                    <div class="col pointer p-0 d-flex align-items-center my-3"
                      (click)="!item.isFavorite ? markAsFavorite(item) : removeFavorite(item)">
                      <i *ngIf="!item.isFavorite" class="_500 fa fa-star-o fa-lg" [style.color]="'#181E39'"
                        aria-hidden="true"></i>
                      <i *ngIf="item.isFavorite" class="_500 fa fa-star fa-lg " [style.color]="'#ffca4c'"
                        aria-hidden="true"></i>
                      <span *ngIf="!item.isFavorite" class="ml-2 f-10 _700 text-uppercase"> {{ 'markAsFavorite' |
                        translate }}</span>
                      <span *ngIf="item.isFavorite" class="ml-2 f-10 _700 text-uppercase"> {{ 'removeFavorite' |
                        translate
                        }}</span>
                    </div>
                    <div class="col pointer p-0 d-flex align-items-center my-3" (click)="archiveDriveCampaign(item)">
                      <img [style.color]="'#181E39'" width="15" height="12" src="../../../assets/svg/archive-svg.svg"
                        alt="">
                      <span *ngIf="!item.archived" class="ml-2 f-10 _700 text-uppercase"> {{ 'archive' |
                        translate}}</span>
                      <span *ngIf="item.archived" class="ml-2 f-10 _700 text-uppercase"> {{ 'unArchive' |
                        translate}}</span>
                    </div>
                    <div class="col pointer p-0 d-flex align-items-center my-3" (click)="deleteDriveCampaign(item)">
                      <i class="_500 fa fa-trash-o fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                      <span class="ml-2 f-10 _700"> {{'deleteReviewLink' | translate}} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="!isInit" class="w-100" style="height: 30px;" #resultObserver observeThis
          (visibilityChange)="loadMore($event)">
        </div>

      </div>
    </div>
    <div class="mt-5">
      <div class="d-block">
        <div class="d-none d-md-block">
          <div class="d-flex align-items-center page-tab-control">
            <div class="section-title d-flex align-items-center w-100" [ngClass]="{ 'collapse': isProjectCollapse}">
              <div class="d-flex align-items-center pointer" (click)="isProjectCollapse=!isProjectCollapse"
                [attr.aria-expanded]="isProjectCollapse" aria-controls="project-collapse-control">
                <i class="mtm-icon mtm-icon-right-triangle-black"></i>
                <span class="px-3 no-wrap">{{ 'Stand-alone Projects' | translate}}</span>
              </div>
            </div>
            <div class="d-flex align-items-center ml-auto project-nav-tabs">
              <div class="tab-item pointer text-center" (click)="allProjectItems();activeTab='all'"
                [ngClass]="{ 'active': isAllProject }">
                <span>{{ 'All' | translate }}</span>
              </div>
              <div class="tab-item pointer text-center " (click)="favoriteProjectItems();activeTab='favorites'"
                [ngClass]="{ 'active': isFavoritesProject }">
                <span>{{ 'Favorites' | translate }}</span>
              </div>
              <div class="tab-item pointer text-center " (click)="getArchiveProjects();activeTab='archived'"
                [ngClass]="{ 'active': isArchivedProject }">
                <span>{{ 'Archived' | translate }}</span>
              </div>
              <div class="tab-item p-0" *ngIf="canCreateProject">
                <button class="btn btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
                  (click)="createProject()">
                  <i class="p-1 fa fa-plus" aria-hidden="true"></i>
                  <span class="ml-2">{{'newProject' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-md-none">
          <div class="d-flex flex-column page-tab-control">
            <div class="section-title section-title-mobile pointer d-flex align-items-center w-100 mb-2"
              [ngClass]="{ 'collapse': isProjectCollapse }">
              <div class="d-flex align-items-center pointer" (click)="isProjectCollapse=!isProjectCollapse"
                [attr.aria-expanded]="isProjectCollapse" aria-controls="project-collapse-control">
                <i class="mtm-icon mtm-icon-right-triangle-black"></i>
                <span class="px-3 no-wrap">{{ 'Stand-alone Projects' | translate }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center project-nav-tabs mb-3">
              <div class="tab-item pointer w-100 text-center" (click)="allProjectItems();activeTab='all'"
                [ngClass]="{ 'active': isAllProject }">
                <span>{{ 'All' | translate }}</span>
              </div>
              <div class="tab-item pointer w-100 text-center" (click)="favoriteProjectItems();activeTab='favorites'"
                [ngClass]="{ 'active': isFavoritesProject }">
                <span>{{ 'Favorites' | translate }}</span>
              </div>
              <div class="tab-item pointer w-100 text-center" (click)="getArchiveProjects();activeTab='archived'"
                [ngClass]="{ 'active': isArchivedProject }">
                <span>{{ 'Archived' | translate }}</span>
              </div>
            </div>
            <div class="d-block" *ngIf="canCreateProject">
              <button
                class="btn btn-block btn-orange text-uppercase position-relative btn-new-project text-nowrap py-1 px-4"
                (click)="createProject()">
                <i class="p-1 fa fa-plus" aria-hidden="true"></i>
                <span class="ml-2">{{'newProject' | translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" #collapse="ngbCollapse" [(ngbCollapse)]="!isProjectCollapse">
      <mtm-projects [workspaceId]="workspaceId" [isProjectCollapse]="isProjectCollapse"></mtm-projects>
    </div>
  </div>
</div>
<!-- breadcrumb section  -->
<ng-template #searchPanel>
  <div class="d-flex flex-column flex-md-row align-items-center search-panel">
    <div class="position-relative search-container">
      <input [(ngModel)]="searchDriveCampaign" class="search-box pl-2 pr-4" (ngModelChange)="searchDriveCampaigns()"
        type="text" placeholder="{{'search' | translate}}" />
      <i class="fa fa-search search-icon" aria-hidden="true"></i>
    </div>
    <div class="calendar-block d-flex">
      <div class="d-flex align-items-center w-100">
        <div #monthYearDropdown class="d-flex align-items-center calendar-dropdown ml-auto" ngbDropdown>
          <div class="dropdown-toggle w-100 mt-auto d-flex align-items-center p-0 pointer" ngbDropdownToggle>
            <div class="_400 ml-2 mr-3">{{calendarViewMode | translate}}
            </div>
            <div class="calendar-icon-div pointer ml-auto">
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </div>
          <div class="calendar-dropdown-menu w-100" ngbDropdownMenu>
            <div ngbDropdownItem (click)="selectCalendarMode('last_updated')">{{'last_updated' | translate }}</div>
            <div ngbDropdownItem (click)="selectCalendarMode('week')">{{'week' | translate}}</div>
            <div ngbDropdownItem (click)="selectCalendarMode('3_months')">{{'calendarView_3_months' | translate}}</div>
            <div ngbDropdownItem (click)="selectCalendarMode('6_months')">{{'calendarView_6_months' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template>