import { EventEmitter, Injectable, Output, TemplateRef } from "@angular/core";
import { HeaderInfo, ThemeOptions } from "../interfaces";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private headerInfo: HeaderInfo = {
    title: '',
    subtitle: '',
    avatarName: '',
    avatarImage: '',
    subtitleParams: {}
  };
  private contentInfo: ThemeOptions = {
    rightPane: null,
    backgroundImage: null,
    header: null,
    fullWidthContent: false,
    hasHeaderInfo: true,
    searchPanel: null,
    bottomPanel: null,
    mainContainerClasses: [],
    dynamicContent: {}
  };

  private contentInfo$ = new BehaviorSubject<ThemeOptions>(this.contentInfo);
  private headerInfo$ = new BehaviorSubject<HeaderInfo>(this.headerInfo);

  contentParams = this.contentInfo$.asObservable();
  headerParams = this.headerInfo$.asObservable();

  setContent(update: ThemeOptions) {
    this.contentInfo = update;
    if (!this.contentInfo.mainContainerClasses) {
      this.contentInfo.mainContainerClasses = [];
    }
    if (!this.contentInfo.dynamicContent) {
      this.contentInfo.dynamicContent = {};
    }
    this.contentInfo$.next(update);
  }

  updateContent(update: Partial<ThemeOptions>) {
    this.contentInfo = { ...this.contentInfo, ...update };
    this.contentInfo$.next(this.contentInfo);
  }

  resetOptions() {
    this.contentInfo = {
      rightPane: null,
      backgroundImage: null,
      header: null,
      fullWidthContent: true,
      hasHeaderInfo: true,
      searchPanel: null,
      bottomPanel: null,
      mainContainerClasses: [],
      dynamicContent: {}
    }
    this.contentInfo$.next(this.contentInfo);
  }

  setDynamicContent(key: string, content: TemplateRef<any>) {
    const dynamicContent = this.contentInfo.dynamicContent;
    dynamicContent[key] = content;
  }

  addMainContainerClasses(classes: string[]) {
    this.contentInfo.mainContainerClasses.push(...classes);
    this.contentInfo$.next(this.contentInfo);
  }

  removeMainContainerClasses(classes: string[]) {
    this.contentInfo.mainContainerClasses = this.contentInfo.mainContainerClasses.filter(c => !classes.includes(c));
    this.contentInfo$.next(this.contentInfo);
  }

  setHeaderInfo(headerInfo: HeaderInfo) {
    this.headerInfo = headerInfo;
    this.headerInfo$.next(headerInfo);
  }

  updateHeaderInfo(headerInfo: Partial<HeaderInfo>) {
    this.headerInfo = { ...this.headerInfo, ...headerInfo };
    this.headerInfo$.next(this.headerInfo);
  }

  clearHeaderInfo() {
    this.headerInfo = {
      title: '',
      subtitle: '',
      avatarName: '',
      avatarImage: '',
      subtitleParams: {}
    };
    this.headerInfo$.next(this.headerInfo);
  }
}
