<ng-template ngbModalContainer></ng-template>
<mtm-banner [bannerType]="bannerTypes.MaintenanceOnly"></mtm-banner>
<div class="p-3 d-lg-flex p-xl-4  login-container">

	<!-- Comment this or set ngIf to false after migration -->
	<!--
	<div class="top-banner" *ngIf="true">
		<span>{{'topBanner_maintenanceText' | translate: {'date':'2022-04-02','start':'09:00', 'end':'13:00'} }}</span>
	</div>
	-->

	<!--

	<div class="row forms">
		<div class="col white-side">
			<div class="justify-content-center text-center logo-container">
				<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
			</div>


			<div class="justify-content-center text-center mkt-content" [innerHTML]="'loginTitle' | translate ">
			</div>

			<div class="justify-content-center mt-4 request-invitation">
				<div class="customer-type-container text-center justify-content-center" *ngIf="!isCustomerTypeSelected">

					<button *ngIf="!checkEnvironment()" id="producer-btn" class="m-1 btn btn-lg btn-danger btn-signup"
						[disabled]="!isRECAPTCHAChecked" (click)="selectCustomerType('PRODUCER')">{{'login_producer' | translate}}
					</button>
					<button *ngIf="!checkEnvironment()" id="advertiser-btn" class="m-1 btn btn-lg btn-danger btn-signup"
						[disabled]="!isRECAPTCHAChecked" (click)="selectCustomerType('ADVERTISER')">{{'login_advertiser' | translate}}
					</button>

				</div>
				<div style="margin-top: -32px; transform:scale(0.7); display: flex; justify-content: center">
					<re-captcha *ngIf="!isRECAPTCHAChecked" (resolved)="resolved($event)"
						siteKey="6LcOWZgUAAAAAI4Gel-E5e_iXH-F2Z6yVu6IIkNt"></re-captcha>
				</div>

				<div class="one-shot-container text-center justify-content-center" *ngIf="isCustomerTypeSelected">
					<div class="close-button" (click)="closeModal()"><i class="fa fa-window-close"
							aria-hidden="true"></i></div>

					<div class="text-center mb-3 title">{{'entryPlanSelector_title'|translate}}</div>
					<button id="market-place-btn" class="m-1 btn btn-lg btn-danger btn-long-text"
						(click)="notifyUser('ONE_SHOT')">{{'entryPlanSelector_produce'|translate}}
					</button>
					<button id="subscription-btn" class="m-1 btn btn-lg btn-danger btn-long-text"
						(click)="notifyUser('SUBSCRIPTION')">
						{{'entryPlanSelector_collab'|translate}}
					</button>
				</div>
			</div>

		</div>
		<div class="col black-side">
			<div class="row mt-3">
				<div class="col black-side">
					<div class="row justify-content-center">
						<mtm-login-form [projectId]="projectId" [projectType]="projectType" [companyType]="companyType"
							[redirectLoginFormOneShot]="redirectLoginFormOneShot">
						</mtm-login-form>
					</div>
				</div>

			</div>

		</div>
	</div>
	-->
	<!--
	<div class="position-absolute w-100 h-100 rounded-xl bg-overlay">
	</div>
-->
	<aside class="d-flex flex-column align-items-center justify-content-between
    rounded-xl py-3 mb-4 mb-lg-0 intro-column ">

		<div class="d-flex flex-column align-items-center justify-content-center flex-grow-1 logo-container">
			<img ngSrc="/assets/img/login/login-logo.svg" width="134" height="112" alt="MTM Logo" class="mb-4" />

			<div class="motto-desc">
				<span class="mr-1 mr-sm-0">{{'login_mottoDescription1' | translate}}</span>
				<span>{{'login_mottoDescription2' | translate}}</span>
			</div>

			<h2 class="fw-700 motto">{{'entry_aside_motto' | translate}}</h2>
		</div>

		<div class="text-center copyright">©{{year}} {{'entry_aside_copyright' | translate}} MASTER THE MONSTER</div>

	</aside>

	<div class="d-flex justify-content-center align-items-center main-container">
		<mtm-login-form [projectId]="projectId" [projectType]="projectType" [companyType]="companyType"
			[redirectLoginFormOneShot]="redirectLoginFormOneShot">
		</mtm-login-form>
	</div>

</div>