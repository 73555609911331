import { DateHelperService } from 'app/shared/services/date-helper.service';

export const checkProjectDates = (startDate, endDate, airDate): boolean => {
    if (!startDate || startDate == '' ||
        !endDate || endDate == '' ||
        !airDate || airDate == '')
        return false;

    let timestampStartDate = DateHelperService.fromDatePickerToDate(startDate);
    let timestampEndDate = DateHelperService.fromDatePickerToDate(endDate);
    let timestampAirDate = DateHelperService.fromDatePickerToDate(airDate);

    return (timestampStartDate < timestampEndDate) && (timestampEndDate <= timestampAirDate);
}

export const checkCpdProjectDates = (startDate, endDate): boolean => {
    if (!startDate || startDate == '' ||
        !endDate || endDate == '')
        return false;

    let timestampStartDate = DateHelperService.fromDatePickerToDate(startDate);
    let timestampEndDate = DateHelperService.fromDatePickerToDate(endDate);

    return (timestampStartDate < timestampEndDate);
}
