//only works for component with isPending property
export function WithPendingGuard(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = function (...args: any[]) {
    if (this.isPending) {
      return;
    }

    originalMethod.apply(this, args);
  }
}
