<div class="col p-0" *ngIf="timelineData">
    <div class="row d-flex align-items-center">
        <!-- <button class="setting-btn" *ngIf="!isDownloadComponentActive"><img
                src=".../../../../../assets/img/project-v2/setting-icon.svg" /></button> -->
        <span class="" [ngClass]="!isDownloadComponentActive ? 'f-22 _500 ml-2': 'f-22 _500'">{{'summary' |
            translate}}</span>
    </div>
    <div class="row" [style.max-height]="'80x'" [style.min-height]="'67px'">
        <div class="col-3 border-top border-right p-0">
            <div><span class="f-12 _700">{{'Product Campaign Name' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.name}}</span></div>
        </div>
        <div class="col-3 border-top border-right">
            <div><span class="f-12 _700">{{'Axe' | translate}}</span></div>
            <div><span class="f-18 _400">{{workspaceName}}</span></div>
        </div>
        <div class="col-6 border-top">
            <div><span class="f-12 _700">{{'Description' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.description}}</span></div>
        </div>
    </div>
    <div class="row" [style.max-height]="'80x'" [style.min-height]="'67px'">
        <div class="col-3 border-bottom border-top border-top border-right p-0">
            <div><span class="f-12 _700">{{'Product Campaign' | translate}}</span></div>
            <div><span class="f-18 _400">{{summaryDetail['Project Type'] | translate}}</span></div>
        </div>
        <div class="col border-bottom border-top border-right">
            <div><span class="f-12 _700">{{'Type of Campaign' | translate}}</span></div>
            <div><span class="f-18 _400">{{summaryDetail['Campaign Type'] | translate}}</span></div>
        </div>
        <div class="col border-bottom border-top border-right">
            <div>
                <span class="f-12 _700">{{'Type of Product' | translate}}</span>
            </div>
            <div><span class="f-18 _400">{{summaryDetail['Product Category'] | translate}}</span></div>
        </div>
        <div class="col border-bottom border-top">
            <div><span class="f-12 _700 text-uppercase">{{'projectLeader' | translate}}</span></div>
            <div>
                <div class="d-flex justify-content-start align-items-center">
                    <div class="row ml-1 d-flex">
                        <div class="user-avatar" *ngFor="let user of timelineData?.projectLeaders; let idx=index;"
                            [style.z-index]="idx+1" [style.left]="((-1)*(idx*8))+'px'">
                            <mtm-user-profile-picture placement="left" container="body"
                                [ngbTooltip]="selectedParticipantTooltip" [username]="user" [height]="24" [width]="24">
                            </mtm-user-profile-picture>
                            <ng-template #selectedParticipantTooltip>
                                <div class="user-tooltip d-flex flex-column align-items-start p-1">
                                    <h6 class="user-name m-0">{{getUserInfo(user)}}</h6>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" [style.max-height]="'80x'" [style.min-height]="'67px'">
        <div class="col-6 border-bottom border-right">
            <div class="row">
                <div class="col-3 p-0" *ngIf="!!summaryDetail['Asset Bundle']">
                    <span class="f-12 _700">{{'Asset List' | translate}}</span>
                </div>
                <!-- <div class="col-9">
                    <span class="f-12 _700">{{'Budget Recommendation' | translate}}</span>
                </div> -->
            </div>
            <div class="row">
                <div class="col-3 p-0" *ngIf="!!summaryDetail['Asset Bundle']">
                    <span>{{summaryDetail['Asset Bundle'].split(' ')[0]}}</span>
                    <i *ngIf="summaryDetail['Asset Bundle']=='1 Star' || summaryDetail['Asset Bundle']=='2 Star' || summaryDetail['Asset Bundle']=='3 Star'"
                        class="fa fa-star ml-2 rating-start" aria-hidden="true"></i>
                    <i *ngIf="summaryDetail['Asset Bundle']=='2 Star' || summaryDetail['Asset Bundle']=='3 Star'"
                        class="fa fa-star ml-1 rating-start" aria-hidden="true"></i>
                    <i *ngIf="summaryDetail['Asset Bundle']=='3 Star'" class="fa fa-star ml-1 rating-start"
                        aria-hidden="true"></i>
                </div>
                <!-- <div class="col-9 metadata">
                    <div class="row">
                        <div class="col-5">
                            Campaign Research? <span class="_700">No</span>
                        </div>
                        <div class="col-3">
                            POSM <span class="_700">No</span>
                        </div>
                        <div class="col-4">
                            DMI Produced <span class="_700">No</span>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="col-3 border-left border-right border-bottom p-0">
            <div class="col"><span class="f-12 _700">{{'Priority Level' | translate}}</span></div>
            <div class="col d-flex align-items-center">
                <span class="f-18 _400 mr-2">{{'cpd_'+timelineData.priority | translate}}</span>
                <div class="d-flex align-items-center">
                    <i class="mtm-icon" [ngClass]="{ 'mtm-icon-priority-high': timelineData.priority=='CRITICAL','mtm-icon-priority-medium': timelineData.priority=='MEDIUM','mtm-icon-priority-normal': timelineData.priority=='OTHER' }"></i>
                </div>
            </div>
        </div> -->
        <div class="col-6 border-bottom">
            <div><span class="f-12 _700">{{'MAD' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.expectedMad | date:'dd.MM.YYYY'}}</span></div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="summaryDetail['Project Type'] == 'Hallmarks'">
        <div class="d-flex align-items-center" *ngIf="summaryDetail['Hallmarks Xmas']">
            <div>
                <span class="f-12 _400">{{'xmas' | translate}}</span>
            </div>
            <div class="ml-3 ">
                <span class="f-12 _700">{{summaryDetail["Hallmarks Xmas"] | translate}}</span>
            </div>
        </div>
        <div class="d-flex align-items-center ml-3">
            <div>
                <span class="f-12 _400">{{'chinaSpecific' | translate}}</span>
            </div>
            <div class="ml-3">
                <span class="f-12 _700">{{summaryDetail["China Specific"] | translate}}</span>
            </div>
        </div>
        <div class="d-flex align-items-center ml-3">
            <div>
                <span class="f-12 _400">{{'posm' | translate}}</span>
            </div>
            <div class="ml-3">
                <span class="f-12 _700">{{summaryDetail["POSM"] | translate}}</span>
            </div>
        </div>
    </div>
</div>