import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { NgbModalRef, NgbModal, NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent } from 'app/on-boarding/profile-avatar/upload-modal/upload-modal.component';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { ApiService } from 'app/shared/services/api.service';
import { AuthService } from 'app/shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { TimelineService } from 'app/shared/services/timeline.service';
import { UploadService } from 'app/shared/services/upload.service';
import { UserService } from 'app/shared/services/user.service';
import { NewWorkspaceInfo, CreateNewWorkspaceInfo } from 'app/workspace/models/workspace.model';
import { Observable, Subject, catchError, map, takeUntil, throwError } from 'rxjs';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from 'app/shared/services/crypto.service';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import moment from 'moment';
import { WorkspaceService } from 'app/shared/services/workspace.service';

@Component({
  selector: 'mtm-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})

export class CreateCampaignComponent {
  @ViewChild(NgbDropdown, { static: false }) dropdownCampaignStatus: NgbDropdown;
  campaignForm: FormGroup;
  modal: NgbModalRef;
  campaignLogo: any;
  campaignBanner: any;
  companyId: any;
  ngUnsubscribe = new Subject();
  campaignData: any;
  cacheBuster: string = new Date().getTime().toString();
  participants: any[] = [];
  authUser: any;
  campaignLeaders: any[] = [];
  fieldErrors: any = {};
  backupParticipants: any[] = [];
  existingleaders: any[] = [];
  model: NewWorkspaceInfo = CreateNewWorkspaceInfo();
  status: string;
  priority: string;
  canEditStatus: boolean = false;
  // Array of status options
  statusOptions: any = [
    { name: 'In Progress', value: 'IN_PROGRESS' },
    { name: 'Completed', value: 'COMPLETED' },
    { name: 'Delayed', value: 'DELAYED' }
  ];
  workspaceId: string;
  startDate: any;
  endDate: any;
  workspaces: any[] = [];
  selectedWorkspace: any = {
    name: 'Select Workspace',
    id: null
  };
  showWorkspaceDropdown: boolean = false;
  submitted: boolean = false;
  isPending: boolean = false;
  SelectedPriority = 'Select Priority';
  SelectedStatus = 'Select Status';

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public timelineService: TimelineService,
    private analyticService: AnalyticsService,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private uploadService: UploadService,
    private overlayService: OverlayService,
    public userService: UserService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private workspaceService: WorkspaceService
  ) {
    this.campaignForm = this.fb.group({
      "campaignName":new UntypedFormControl ('',Validators.required),
      "collaborators":new UntypedFormControl ('',Validators.required),
      "logo":new UntypedFormControl  (''),
      "banner":new UntypedFormControl (''),
      "assignPriority":new UntypedFormControl  ('',Validators.required),
      "status": new UntypedFormControl ('',Validators.required),
      "startDate": new UntypedFormControl ('',Validators.required),
      "endDate": new UntypedFormControl ('',Validators.required),
      "workspace": new UntypedFormControl ('',Validators.required)
    });
  }

  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.route.queryParams.subscribe(query => {
      if (query.wid) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
        this.showWorkspaceDropdown = false;
        this.campaignForm.patchValue({
          workspace: this.workspaceId
        });
      } else {
        this.showWorkspaceDropdown = true;
        this.getWorkspaces();
      }
    });
    // check permission
    this.canEditStatus = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S));

    const { companyId } = this.authService.getAuthUser();
    this.companyId = companyId;
    if (this.campaignData && this.campaignData.id) {
      this.setData();
      this.workspaceId =this.campaignData.workspaceId;
    }
    if (this.campaignData && this.campaignData.leaders && this.campaignData.leaders.length) {
      this.campaignData.leaders.forEach(owner => {
        this.campaignLeaders.push(owner);
      });
    }
    this.getParticipants();
  }

  // set data for edit brand
  setData() {
    if (this.campaignData) {
      if (this.campaignData.logo) {
        this.campaignLogo = !this.campaignData.logo.includes('data:image') ? HelperService.getCachebusterUrl(this.campaignData.logo) : this.campaignData.logo;
      }
      if (this.campaignData.banner) {
        this.campaignBanner = !this.campaignData.banner.includes('data:image') ? HelperService.getCachebusterUrl(this.campaignData.banner) : this.campaignData.banner;
      }

      this.startDate = DateHelperService.fromDateToDatepicker(new Date(this.campaignData.startDate || moment().toISOString()));
      this.endDate = DateHelperService.fromDateToDatepicker(new Date(this.campaignData.endDate));
      this.campaignForm.patchValue({
        logo: this.campaignData.logo,
        banner: this.campaignData.banner,
        campaignName: this.campaignData.name,
        assignPriority: this.campaignData.priority,
        status: this.campaignData.status,
        startDate: this.startDate,
        endDate: this.endDate,
        collaborators: this.campaignData.leaders,
        workspace: this.campaignData.workspaceId || this.workspaceId
      });
    }
  }

  isValid(field: string): boolean {
    const formField = this.campaignForm.get(field);
    return formField ? formField.invalid && this.submitted : false;
  }

  // Create a method to handle form submission
  onSubmit() {
    this.submitted = true;

    if (this.isPending) {
      return;
    }
    if (this.campaignForm.valid) {
      this.isPending = true;
      const leaders: any[] = [];
      this.existingleaders.filter(user => {
        leaders.push(user.username)
      })
      let endDate = this.campaignForm.get('endDate').value;
      let startDate = this.campaignForm.get('startDate').value;
      const edate = DateHelperService.fromDatePickerToDate(endDate);
      this.endDate = DateHelperService.fromDateToApi(edate);
      const sdate = DateHelperService.fromDatePickerToDate(startDate);
      this.startDate = DateHelperService.fromDateToApi(sdate);
      let formData: any = {
        name: this.campaignForm.get('campaignName').value,
        workspaceId: this.campaignForm.get('workspace').value,
        leaders: leaders ? leaders : this.campaignData.leaders,
        priority: this.campaignForm.get('assignPriority').value,
        status: this.campaignForm.get('status').value,
        endDate: this.endDate,
        startDate: this.startDate
      };
      const isNew = !this.campaignData?.id;
      if (this.campaignData && this.campaignData.id) {
        formData = {
          name: this.campaignForm.get('campaignName').value,
          workspaceId: this.workspaceId,
          id: this.campaignData.id,
          leaders: leaders ? leaders : this.campaignData.leaders,
          priority: this.campaignForm.get('assignPriority').value,
          endDate: this.endDate,
          startDate: this.startDate,
          status: this.campaignForm.get('status').value,
          logo: this.campaignForm.get('logo').value,
          banner: this.campaignForm.get('banner').value
        };
      }

      this.analyticService.createCampaign(formData).pipe(takeUntil(this.ngUnsubscribe)).
        subscribe({
          next: (result: any) => {
            this.isPending = false;
            // for logo
            if (!formData.logo || this.campaignLogo) {
              this.uploadLogoImage(
                this.campaignLogo,
                `/api/loreal-campaign/${this.workspaceId}/${result.id}/logo`
              ).pipe(
                takeUntil(this.ngUnsubscribe)
              ).subscribe({
                next: (resAvatarURL: any) => {
                  if (resAvatarURL) {
                    this.closeModal();
                  }
                },
                error(err) {
                  console.error('error', err);

                },
                complete: () => {
                  this.refreshImageVersion();
                },
              });
            }
            // } else {
            //   this.closeModal();
            // }
            // for banner
            if (!formData.banner || this.campaignBanner) {
              this.uploadBannerImage(
                this.campaignBanner,
                `/api/loreal-campaign/${this.workspaceId}/${result.id}/banner`
              ).pipe(
                takeUntil(this.ngUnsubscribe)
              ).subscribe({
                next: (resbannerURL: any) => {
                  if (resbannerURL) {
                    this.closeModal();
                  }
                },
                error(err) {
                  console.error('error', err)
                },
                complete: () => {
                  this.refreshImageVersion();
                },
              });
            }
            // } else {
            // }
            this.campaignData = result;
            this.activeModal.close(result);
            // }
          },
          error: (err) => {
            this.overlayService.showError(err.message);
            this.submitted = false;
            this.isPending = false;
          },
          complete: () => {
            console.log('complete');
          },
        });
    } else {
      this.isPending = false;
    }
  }

  /**
 * Used to send image to second cropper
 * @param $event
 */
  campaignFileChangeListener($event): void {
    let files = [];
    if ($event && $event.files) {
      files = $event.files[0];
      this.campaignForm.patchValue({
        banner: files
      });
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setBrandProfileImage(files);
      }
    } else if ($event && $event.target.files) {
      files = $event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event;
        this.setBrandProfileImage(files[0]);
      }
    }
  }

  private setBrandProfileImage(file) {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.campaignLogo = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  }

  removeBanner() {
    this.campaignBanner = null;
  }

  bannerFileChangeListener($event): void {
    let files = [];
    if ($event && $event.files) {
      files = $event.files[0];
      this.campaignForm.patchValue({
        banner: files
      });
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setCamapignBannerImage(files);
      }
    } else if ($event && $event.target.files) {
      files = $event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event;
        this.setCamapignBannerImage(files[0]);
      }
    }
  }

  private setCamapignBannerImage(file) {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.campaignBanner = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  }

  /**
   * Open crop modal
   */
  private openModal() {
    this.modal = this.modalService.open(UploadModalComponent, { size: 'lg' });
    this.modal.componentInstance.setCrop(true);
  }

  closeModal() {
    this.activeModal.close();
  }

  private refreshImageVersion() {
    this.cacheBuster = new Date().getTime().toString();
  }

  getParticipants() {
    this.userService.getUsersByCompanyId(this.authUser.companyId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(result => {
        this.participants = result;
        this.backupParticipants = result;
        if (this.campaignData && this.campaignData.leaders) {
          const ownerUsernames = this.campaignData.leaders.map(owner => owner.toLowerCase()); // Convert owner usernames to lowercase
          this.existingleaders = this.participants.filter(participant => ownerUsernames.includes(participant.username.toLowerCase()));
          this.participants.filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selected = ownerUsernames.includes(usernameLower);
            return participant;
          });
        }
      })
  }

  searchParticipants(e) {
    const participants = this.backupParticipants.filter(p => !!p.fullName.trim());
    if (e.target.value) {
      this.participants = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
    } else {
      this.participants = participants.filter(p => !!p.fullName.trim() && !(p.roles.length == 1 && p.roles[0] == 'COMPANY_OCCASIONAL'));
    }
  }

  leaderselected(e, participant) {
    const { username } = participant;
    const indexOfUsername = this.existingleaders.findIndex(owner => owner.username === username);

    if (indexOfUsername === -1) {
      // The participant is not in the list, so add them.
      this.campaignLeaders.push(username);
      this.existingleaders.push(participant);
      participant.selected = true;
        this.campaignForm.patchValue({
          collaborators: this.campaignLeaders
        });
    } else {
      // The participant is already in the list, so remove them.
      this.campaignLeaders = this.campaignLeaders.filter(owner => owner !== username);
      this.existingleaders.splice(indexOfUsername, 1); // Remove the participant by index.
      participant.selected = false;
      if (this.campaignLeaders) {
        this.campaignForm.patchValue({
          collaborators: this.campaignLeaders
        });
      }
    }
  }

  changeStatus(event: any): void {
    // Access the selected value from the event
    // this.status = event.target.value;
  }

  private uploadLogoImage(image, partialURL): Observable<any> {
          return new Observable((observer) => {
        this.uploadService
          .uploadImage(image, this.apiService.baseUrl + partialURL).pipe(
          takeUntil(this.ngUnsubscribe)
        )
          .subscribe(
          (resAvatar) => {
            if (!resAvatar.logo) return;

            observer.next(resAvatar.logo);
          },
          (err) => {
            observer.error();
          }
        );
    });
  }

  private uploadBannerImage(image, partialURL): Observable<any> {
          return new Observable((observer) => {
        this.uploadService
        .uploadImage(image, this.apiService.baseUrl + partialURL).pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (resAvatar) => {
            if (!resAvatar.banner) return;

            observer.next(resAvatar.banner);
          },
          (err) => {
            observer.error();
          }
        );
    });
  }

  // remove campaign
  deleteCampaign(item) {
    this.analyticService.deleteCampaign(item)
      .subscribe({
        next: (res: any) => {
          console.log('res', res);
          this.campaignData = res;
          this.closeModal();
        },
        error: (err) => {
          console.log(err)
        }
      });
  }

  // getWorkspaces() {
  //   this.workspaces = []
  //   this.workspaceService.getWorkspaces(this.authUser.company.id)
  //     .subscribe({
  //       next: (result) => {
  //         this.workspaces = result;
  //       }, error: (err) => {
  //         console.error('err', err)
  //       }
  //     });
  // }

  getWorkspaces() {
    this.workspaces = [];
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
            // this.workspaces.sort((d1, d2) => d1.name.localeCompare(d2.name));
            if (this.workspaceId) {
              let workspace = this.workspaces.find(w => w.id === this.workspaceId);
              this.timelineService.workspaceName  = workspace.name;
              if (workspace) {
                this.selectedWorkspace = workspace;
                this.changeWorkspace( this.selectedWorkspace);
              } else {
                console.warn('Workspace not found with id:', this.workspaceId);
              }
            }
          })
        }, error: (err) => {
          console.error('err', err)
        }
      });
  }

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  changeWorkspace(workspace) {
    if (workspace === 'all_workspaces') {
      this.selectedWorkspace = {
        name: 'Select Workspace',
        id: null
      }
    }
    const selectedWorkspaceName = this.campaignForm.get('workspace').value;
    this.selectedWorkspace = this.workspaces.find(workspace => workspace.id === selectedWorkspaceName);
    this.selectedWorkspace = workspace;
    this.workspaceId = workspace.id;
    if (this.workspaceId) {
      this.campaignForm.patchValue({
        workspace: this.workspaceId
      });
    }
  }

  changePriority(priority){
  }

}
