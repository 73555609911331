import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MtmEditAssetModalComponent } from './edit-asset-modal/edit-asset-modal.component';
import { UUID } from 'angular2-uuid';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { AssetListService } from 'app/shared/services/asset-list.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { Subject, takeUntil } from 'rxjs';
import { AssetListColor, AssetListStatus, DriveCampaignColor, DriveCampaignStatus, ProjectColor, ProjectStatus } from 'app/layouts/my-projects/project.const';
import { EmitterService } from 'app/shared/services/emitter.service';
import { DateHelperService } from 'app/shared/services/date-helper.service';

@Component({
    selector: 'mtm-asset-list',
    templateUrl: './asset-list.component.html',
    styleUrls: ['./asset-list.component.scss']
})
export class MtmAssetListComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChildren('projectStatusDropdown', { read: NgbDropdown }) statusDropdowns: QueryList<NgbDropdown>;
    @Input() bundle: any;
    @Input() assetStar: any;
    @Input() canEdit: boolean;
    @Input() asSelector: boolean = false;
    @Input() canAdd: boolean = false;
    @Input() multiple: boolean = false;
    @Input() selectedAssets: any;
    @Input() showSettingButton: boolean = false;
    @Input() workspaceId: string;
    @Input() campaignId: string;
    @Input() readOnly: boolean = false;
    @Input() hideTitle: boolean = false;
    @Input() hideFilter: boolean = false;
    @Input() showStatus: boolean = false;
    @Input() onlyShowAssetWithStatus: boolean = false;
    @Input() asWidget: boolean = false;
    @Input() projectId: string;

    @Output() assetsUpdated: EventEmitter<any> = new EventEmitter();
    @Output() assetSelected: EventEmitter<any> = new EventEmitter();
    @Output() updateTimelineAndProject: EventEmitter<any> = new EventEmitter();

    company = 'cpd';
    selectedMilestone = null;

    isFilterVideoActive = false;
    isFilterStillActive = false;

    projectColor = AssetListColor;
    projectStatus = AssetListStatus;

    displayedAssetList: any;

    editModal: any;
    selectedAssetTypes: any = [];
    ngUnsubscribe = new Subject();

    milestones = [
        'HERO SHOOT',
        'GO 360 STRATEGIC NOTE'
    ]

    allBundle: any = [];
    allAssets: any = [];

    isBusy: boolean = false;

    affectedAssets: any = {};

    constructor(
        private projectV2ServiceService: ProjectV2ServiceService,
        private assetListService: AssetListService,
        private overlayService: OverlayService,
        private modalService: NgbModal
    ) {
        EmitterService.get('NEW_ASSET_ADDED').pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next: (result) => {
                if (!result) {
                    return;
                }
                result.id = UUID.UUID();
                result.assetBundle = this.assetStar;
                if (!this.bundle) {
                    this.bundle = [];
                }
                this.bundle.push(result);
                this.allBundle.push(result);
                this.allAssets = JSON.parse(JSON.stringify(this.bundle));
                this.assetsUpdated.emit(this.bundle);
                if (this.projectId && this.asWidget) {
                    this.updateTimelineAndProject.emit({ allBundle: this.allBundle, newAsset: result });
                }
            }
        });
    }

    ngOnInit() {
        if (this.showStatus || this.onlyShowAssetWithStatus || this.asSelector) {
            this.getLinkedAssets();
            EmitterService.get('CAMPAIGN_PROJECT_STATUS_UPDATED').pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe({
                next: (project) => {
                    this.getLinkedAssets();
                }
            });
            EmitterService.get('PROJECT_ASSET_LINKED').pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe({
                next: (project) => {
                    this.getLinkedAssets();
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.bundle?.currentValue) {
            this.allAssets = JSON.parse(JSON.stringify(this.bundle));
            if (this.projectId && this.asWidget) {
                this.allBundle = JSON.parse(JSON.stringify(this.bundle));
            }
        }
        if (changes.selectedAssets?.currentValue && changes.selectedAssets.currentValue.length) {
            this.selectedAssetTypes = this.selectedAssets.map(a => a.assetListId);
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    getAllAsset() {
        return JSON.parse(JSON.stringify(this.allAssets));
    }

    getLinkedAssets() {
        this.assetListService.getAffectedAssetByCampaignId(this.campaignId).subscribe({
            next: (res: any) => {
                this.affectedAssets = {};
                for (let i = 0; i < res.length; i++) {
                    if (this.projectId && this.asWidget) {
                        if (res[i].projectId == this.projectId) {
                            this.affectedAssets[res[i].assetListId] = res[i];
                            if (this.affectedAssets[res[i].assetListId].deliveryDate) {
                                this.affectedAssets[res[i].assetListId].deliveryDate = DateHelperService.fromDateToDatepicker(new Date(this.affectedAssets[res[i].assetListId].deliveryDate));
                            }
                        }
                    } else {
                        this.affectedAssets[res[i].assetListId] = res[i];
                        if (this.affectedAssets[res[i].assetListId].deliveryDate) {
                            this.affectedAssets[res[i].assetListId].deliveryDate = DateHelperService.fromDateToDatepicker(new Date(this.affectedAssets[res[i].assetListId].deliveryDate));
                        }
                    }
                }
                if (this.onlyShowAssetWithStatus) {
                    this.filterBundleWithStatus();
                }
            }
        });
    }

    filterBundleWithStatus() {
        if (!this.bundle?.length) {
            return;
        }
        this.bundle = this.bundle.filter(b => {
            return this.affectedAssets[b.id] && this.affectedAssets[b.id].status;
        })
    }

    onMilestoneSelect(milestone) {
        this.selectedMilestone = milestone?.timeline || null;
        this.filterAssets();
    }

    filterAssets() {
        const data = this.getAllAsset();
        this.bundle = data.filter(a => {
            if (this.onlyShowAssetWithStatus) {

            }
            if (this.isFilterVideoActive && this.isFilterStillActive) {
                return a.categories?.indexOf('VIDEO') > -1 && a.categories?.indexOf('STILL') > -1;
            } else if (!this.isFilterVideoActive && !this.isFilterStillActive) {
                return true;
            } else if (this.isFilterVideoActive && !this.isFilterStillActive) {
                return a.categories?.indexOf('VIDEO') > -1
            } else if (!this.isFilterVideoActive && this.isFilterStillActive) {
                return a.categories?.indexOf('STILL') > -1
            }
        });
    }

    addNewAsset(funnel) {
        this.editModal = this.modalService.open(MtmEditAssetModalComponent, { size: 'lg', modalDialogClass: 'responsive-modal' });
        this.editModal.componentInstance.funnel = funnel;
        this.editModal.result.then((result: any) => {
            if (!result) {
                return;
            }
            result.id = UUID.UUID();
            result.assetBundle = this.assetStar;
            if (!this.bundle) {
                this.bundle = [];
            }
            this.bundle.push(result);
            this.allBundle.push(result);
            this.allAssets = JSON.parse(JSON.stringify(this.bundle));
            this.assetsUpdated.emit(this.bundle);
            if (this.projectId && this.asWidget) {
                this.updateTimelineAndProject.emit({ allBundle: this.allBundle, newAsset: result });
            }
        });
    }

    openEditAssetModal(funnel, asset) {
        this.editModal = this.modalService.open(MtmEditAssetModalComponent, { size: 'lg', keyboard: false, backdrop: 'static', modalDialogClass: 'responsive-modal' });
        this.editModal.componentInstance.asset = asset;
        this.editModal.componentInstance.funnel = funnel;
        this.editModal.result.then((result: any) => {
            if (!result) {
                return;
            }
            if (result.funnel.toLowerCase() != asset.funnel.toLowerCase()) {
                if (!this.bundle) {
                    this.bundle = [];
                }
                this.bundle.push({ ...asset, ...result });
                this.bundle = this.bundle.filter(a => a.id != asset.id);
            } else {
                this.bundle = this.bundle.map(a => {
                    if (a.id == asset.id) {
                        a = { ...a, ...result };
                    }
                    return a;
                })
            }
            this.allAssets = JSON.parse(JSON.stringify(this.bundle));
            this.assetsUpdated.emit(this.bundle);
        });
    }

    selectAsset(e, asset) {
        this.isBusy = true;
        const idx = this.selectedAssetTypes.indexOf(asset.id);
        if (idx < 0) {
            this.assetListService.getAssetById(asset.id).pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe({
                next: (res: any) => {
                    if (res.length) {
                        e.target.checked = false;
                        this.overlayService.showError('Asset has been selected by another project');
                    } else {
                        if (this.multiple) {
                            this.selectedAssetTypes.push(asset.id);
                        } else {
                            this.selectedAssetTypes = [asset.id];
                        }
                        this.assetSelected.emit(this.selectedAssetTypes);
                    }
                    this.isBusy = false;
                },
                error: (err) => {
                    this.isBusy = false;
                    this.overlayService.showError(err.message);
                }
            });
        } else {
            this.isBusy = false;
            this.affectedAssets[asset.id] = null;
            this.selectedAssetTypes = this.selectedAssetTypes.filter(l => l != asset.id);
            this.assetSelected.emit(this.selectedAssetTypes);
        }
    }

    deleteAsset(funnel, asset) {
        this.bundle = this.bundle.filter(a => a.id != asset.id)
        this.allAssets = JSON.parse(JSON.stringify(this.bundle));
        this.assetsUpdated.emit(this.bundle);
    }

    editTimeline() {
        this.projectV2ServiceService.updateSelectedDriveCampaign(this.workspaceId, this.campaignId, true);
    }

    getGroupedAsset(funnel) {
        return this.bundle?.filter(a => a.funnel.toUpperCase() == funnel.toUpperCase()) || [];
    }

    updateStatus(asset, status) {
        asset.status = status;
        asset.deliveryDate = DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(asset.deliveryDate))
        this.assetListService.updateAssetListStatusOrDelivery(asset).subscribe({
            next: (data) => {
                console.log(data);
            }
        });
    }

    updateDeliveryDate(asset) {
        asset.deliveryDate = DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(asset.deliveryDate))
        this.assetListService.updateAssetListStatusOrDelivery(asset).subscribe({
            next: (data) => {
                asset.deliveryDate = DateHelperService.fromDateToDatepicker(new Date(asset.deliveryDate));
            }
        });
    }

    changeProjectStatus(asset, status) {
        if (asset) {
            const className = `status-${asset.assetListId}`;
            this.statusDropdowns.toArray().find(({ dropdownClass }) => dropdownClass === className)?.close();
            this.updateStatus(asset, status);
        }
    }

    addAsset() {
        this.modalService.open(MtmEditAssetModalComponent, { size: 'lg', modalDialogClass: 'responsive-modal' }).result.then((result: any) => {
            EmitterService.get('NEW_ASSET_ADDED').emit(result);
        });
    }
}