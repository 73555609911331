<div class="project-asset-list-component p-3">
    <div *ngIf="project?.campaignId" class="asset-list">
        <label class="header-title">{{'add_asset'|translate}}</label>
        <mtm-asset-list class="w-100" [bundle]="assetBundle" [canAdd]="true" [canEdit]="false" [asSelector]="true"
            [assetStar]="assetStar" [multiple]="true" (assetsUpdated)="assetBundleUpdated($event)"
            [showSettingButton]="false" (assetSelected)="assetBundleSelected($event)" [hideTitle]="true"
            [selectedAssets]="project?.assetList" [campaignId]="project?.campaignId"></mtm-asset-list>
    </div>
    <div class="d-flex flex-column flex-md-row py-4 m-0 justify-content-between footer-content">
        <button class="btn btn-lg border-dark btn-light mr-2 mb-3 text-uppercase" type="button"
            (click)="returnToProject()">
            <span>{{'returnProject' | translate}}</span>
        </button>
        <button type="button" class="btn btn-lg btn-dark ml-md-auto text-uppercase mb-3"
            (click)="continue()">{{'Next' | translate}}</button>
    </div>
</div>