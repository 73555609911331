<div 
  class="overlay" 
  [ngStyle]="{
    'top': top,
    'left': left,
    'width': width,
    'height': height,
    'z-index': zindex
  }">
    <div class="overlay-content">
        <div class="header">
            <div class="status d-flex">
               <img src="../../assets/svg/comming-soon.svg" alt="">
               <span class="f-14 _700">Coming  soon...</span>
            </div>
        </div>
    </div>
</div>

  