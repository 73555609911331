<div class="card">
    <div class="row d-flex justify-content-center align-items-center mt-3">
        <div class="user-log">
            <img width="31px" height="27px" src="../../../../../../assets/svg/add-user.svg" alt="">
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-1">
        <span class="f-18 _700" [style.color]="'#18182D'">Add New user</span>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="mt-3 horizontal-line"></div>
    </div>
    <div class="row mt-3 d-flex justify-content-center align-items-center pl-3 pr-3">
        <div class="ml-2 col d-flex justify-content-center align-items-center">
            <div class="d-flex align-items-center" ngbDropdown>
                <div class="mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                    <div class="flex-fill mr-1">
                        <div *ngIf="selectedUser && selectedUser.length" class="task-collaborators">
                            <div class="task-collaborator-wrapper d-flex align-items-center">
                                <div class="collaborator-avatar mr-1" *ngFor="let owner of selectedUser">
                                    <img class="participant-img" *ngIf="owner.avatarUrl" [src]="owner.avatarUrl" alt="">
                                    <span *ngIf="!owner.avatarUrl"
                                        class="d-flex justify-content-center align-items-center c-full-white participant-img text-uppercase"
                                        [style.backgroundColor]="timelineService.getColorFromName(owner.fullName)">
                                        {{owner.fullName | initialName}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                    <div class="p-1 w-100">
                        <input type="text" placeholder="Search..." class="user-search" [(ngModel)]="searchInput"
                            (ngModelChange)="searchParticipants()" />
                    </div>
                    <div *ngFor="let participant of participants"
                        class="task-dropdown-item d-flex align-items-center pointer" (click)="selectUser(participant)"
                        ngbDropdownItem>
                        <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                            [ngClass]="{ 'selected': participant.selected }">
                            <i class="fa fa-check" *ngIf="participant.selected"></i>
                        </div>
                        <img class="participant-img" *ngIf="participant.avatarUrl" [src]="participant.avatarUrl" alt="">
                        <span *ngIf="!participant.avatarUrl"
                            class="d-flex justify-content-center align-items-center c-full-white participant-img text-uppercase"
                            [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                            {{participant.fullName | initialName}}
                        </span>
                        <span class="ml-2 _600">{{participant.fullName}}</span>
                    </div>
                </div>
            </div>

            <div class="ml-4 p-0 col d-flex justify-content-center align-items-center">
                <select class="ml-3 team-select" name="teamSelect" id="teamSelect" (change)="onTeamSelect($event)">
                    <option value="null">{{'Select team' | translate }}</option>
                    <option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="selectedUserDetail && selectedUserDetail.username">
        <div class="row mt-3 d-flex justify-content-center align-items-center pl-3 pr-3">
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Name" type="text" value="{{selectedUserDetail.firstName}}"
                    [attr.disabled]="true" style="cursor: default !important;">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Last Name" type="text" value="{{selectedUserDetail.lastName}}"
                    [attr.disabled]="true" style="cursor: default !important;">
            </div>
        </div>
        <div class="row mt-3 d-flex justify-content-center align-items-center pl-3 pr-3">
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Email" type="text" value="{{selectedUserDetail.email}}"
                    [attr.disabled]="true" style="cursor: default !important;">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Job description" type="text"
                    value="{{selectedUserDetail.jobTitle}}" [attr.disabled]="true" style="cursor: default !important;">
            </div>
        </div>
    </div>

    <div *ngIf="!selectedUserDetail">
        <div class="row mt-3 d-flex justify-content-center align-items-center pl-3 pr-3">
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Name" type="text" [attr.disabled]="true"
                    style="cursor: default !important;">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Last Name" type="text" [attr.disabled]="true"
                    style="cursor: default !important;">
            </div>
        </div>
        <div class="row mt-3 d-flex justify-content-center align-items-center pl-3 pr-3">
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Email" type="text" [attr.disabled]="true"
                    style="cursor: default !important;">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <input class="user-detail" placeholder="Job description" type="text" [attr.disabled]="true"
                    style="cursor: default !important;">
            </div>
        </div>
    </div>

    <div class="row d-flex justify-content-center align-items-center mt-4">
        <div class="col d-flex justify-content-end align-items-center">
            <div class="d-flex">
                <button (click)="cancel()" class="f-12 _700 cancel-btn text-uppercase">cancel</button>
            </div>
        </div>
        <div class="col d-flex">
            <div class="d-flex">
                <button class="f-12 _700 add-user-btn text-uppercase" (click)="addTeamMember()"> add user</button>
            </div>
        </div>
    </div>
</div>