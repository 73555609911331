<div class="p-2 create-container">

	<div class="row justify-content-md-center">
		<div class="col col-lg-10">

			<div class="image-wrapper">

				<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=true
					[isRedirectImageLoadError]=false [imageURL]="model ? model.imageUrl : ''"
					bottomHtmlText="'Click here to upload workspace logo'" (files)="fileChangeListener($event)"
					[style.maxWidth]="'200px'" [style.maxHeight]="'200px'" [style.minWidth]="'200px'"
					[style.minHeight]="'200px;'">
				</mtm-upload-file>

			</div>

			<form [formGroup]="workspaceForm" (ngSubmit)="submit()">
				<div class="form-group">
					<label for="name">{{'workspace_workspaceName'| translate}}</label>
					<input type="text" id="name" class="form-control" formControlName="name" autocomplete="off">
				</div>
				<div class="form-group">
					<label>{{'workspace_create_workspaceOwners'| translate}}</label>
					<div style="color:red;" *ngIf="! model.owners.length">{{'workspace_create_workspaceOwnersRequired'|
						translate}}</div>

					<div class="owner" *ngFor="let owner of model.owners">
						<!--
						<img class="avatar" src="{{owner.avatarUrl}}" alt="User" style="width: 25px; height:25px; border-radius: 50%;" />
						-->
						<div class="d-flex flex-column flex-md-row align-items-start profile-column">
							<mtm-user-profile-picture forceLoadProfile="true" [user]="owner.user"
								[username]="owner.email" [height]="25" [width]="25">
							</mtm-user-profile-picture>
							<span class="name" *ngIf="owner.name">{{owner.name}}</span>
							<span class="email" *ngIf="!owner.name">{{owner.email}}</span>
							<span class="new-user" *ngIf="owner.status == 'PENDING_INPROGRESS'">{{'newUser' |
								translate}}</span>
							<span class="job" *ngIf="owner.jobTitle">{{owner.jobTitle}}</span>
						</div>
						<div class="d-flex flex-column flex-md-row align-items-end role-column">
							<span class="label" *ngIf="owner.role">{{'role'+owner.role | translate}}</span>
							<span class="delete" *ngIf="owner.role != 'COMPANY_PRINCIPAL'"
								(click)="removeUser(owner)"><i class="fa fa-times-circle" aria-hidden="true"></i></span>
						</div>
					</div>
					<div id="create-workspace" class="user-suggest">
						<ng-autocomplete *ngIf="isAdding && companyUsers" [data]="companyUsers"
							(selected)="addUser($event)" [searchKeyword]="keyword" [itemTemplate]="itemTemplate"
							type="text" placeholder="Select Team Members..."></ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a class="d-flex flex-column flex-md-row align-items-start align-items-md-center ws-user">
								<span class="name" [innerHTML]="item.fullName"></span>
								<span class="email" [class.no-name]="item.fullName === ' '" [innerHTML]="item.email"></span>
							</a>
						</ng-template>
					</div>
				</div>


				<div class="add-container">
					<button type="button" class="btn add-user" (click)="showUserField()">
						<i class="fa fa-plus-circle" aria-hidden="true"></i>{{'workspace_create_addWorkspaceOwner' |
						translate}}</button>
				</div>
			</form>
		</div>
	</div>
	<div class="row justify-content-md-center">
		<div class="col col-lg-10 buttons">
			<button class="btn cancel" type="button" (click)="cancel()">{{'cancel' | translate}} </button>
			<button class="btn save" type="button" [disabled]="isSaving" (click)="submit()">{{'saveChanges' |
				translate}}</button>
		</div>
	</div>

</div>