export const ASSET_CATEGORIES = [
    'VIDEO',
    'STILL'
]

export const ASSET_FUNNEL = [
    'AWARENESS',
    'CONSIDERATION',
    'CONVERSION'
]

export const ASSET_TYPE = [
    'HERO VIDEO',
    'SOCIAL CAPSULE',
    'SOCIAL STILL',
    'PRINT',
    'OOH',
    'ECOMM',
    'ADVOCACY'
]

export const ASSET_BET_LIST = [
    'TVC',
    'YOUTUBE',
    'META',
    'TIKTOK',
    'OOH',
    'PRINT',
    'ECOM',
    'WEBSITE',
    'MERCH'
];

export const ASSET_LENGTH = [
    'SHORT 6"',
    'MEDIUM 15-20"',
    'LONG 30+"'
]

export const ASSET_FORMAT = [
    'H 16:9',
    'V 9:16',
    'V 4:5',
    'S 1:1'
]

export const ASSET_FOCUS = [
    'HERO',
    'ENTERTAINMENT / TRENDS',
    'INFORMATION',
    'ENDORSEMENT',
    'TESTIMONIAL',
    'EDUCATION',
    'BENEFIT',
    'BEFORE / AFTER',
    'CRASH TEST',
    'PRODUCT CENTRIC',
    'ECOMM',
    'ADVOCACY'
];

export class Asset {
    assetBundle: string;
    milestone: string;
    funnel: string;
    type: string;
    detail: string;
    focus: string;
    videoStill: string;
    bets: string[];
    length: string[];
    format: string[];
}