import { Component, EventEmitter, Input, Output } from "@angular/core";
import {CampaignMilestone, ProjectSection, ProjectStatusList, ProjectSubsection} from "../../interfaces";

export type SectionStatusSource = ProjectSection | ProjectSubsection | null;
export type GeneralStatusSource = SectionStatusSource | CampaignMilestone;

export function isStatusSourceSubsection(source: SectionStatusSource): source is ProjectSubsection {
  return source && (source as ProjectSubsection).subSection != null;
}

@Component({
  selector: 'mtm-status-flag',
  templateUrl: './status-flag.component.html'
})
export class StatusFlagComponent {

  flagBgColor: string = '#fff';
  flagFillColor: string = '#43CB9A';
  _source: GeneralStatusSource = null;
  _status: string = ProjectStatusList.TO_COME;

  @Output() statusClick: EventEmitter<GeneralStatusSource> = new EventEmitter<GeneralStatusSource>();

  @Input()
  isTransparentBg: boolean = false;

  @Input()
  flagWidth: number = 17;

  @Input()
  flagHeight: number = 17;

  get status(): string {
    return this._status;
  }

  @Input()
  set status(value: string) {
    this._status = value;
    this.adjustStatus();
  }

  get viewBox(): string {
    return `0 0 17 17`;
  }

  get source() {
    return this._source;
  }

  @Input()
  set source(value: GeneralStatusSource) {
    this._source = value;
    if (!this.source) {
      return;
    }
    this._status = this.source.status;
    this.adjustStatus();
  }

  private adjustStatus() {
    switch (this.status) {
      case ProjectStatusList.IN_PROGRESS:
        this.flagBgColor = '#fff';
        this.flagFillColor = '#7e7e8d';
        break;
      case ProjectStatusList.UPCOMING_EXPIRY:
      case ProjectStatusList.BEWARE:
        this.flagBgColor = '#fff';
        this.flagFillColor = '#f77f40';
        break;
      case ProjectStatusList.DELAYED:
        this.flagBgColor = '#fff';
        this.flagFillColor = '#f76769';
        break;
      case ProjectStatusList.COMPLETED:
        this.flagBgColor = '#fff';
        this.flagFillColor = '#43CB9A';
        break;

      case ProjectStatusList.TO_COME:
      default:
        this.flagBgColor = '#fff';
        this.flagFillColor = '#555';
        break;
    }

    if(this.isTransparentBg){
      this.flagBgColor = 'none';
    }
  }

  triggerStatusClick() {
    this.statusClick.emit(this.source);
  }
}
