// export interface Project {
// 	name?: any,
// 	description: string,
// 	location?: string,
// 	storagePath?: string,
// 	id?: any,
// 	company?: {},
// 	manager?: string,
// 	users?: string[]
// }

import { SimpleUser } from "./user.interface";


export interface Project {
  name?: string,
  description: string,
  manager: string,
  location?: string,
  storagePath?: string,
  id?: string,
  budget?: string,
  company: any,
  currency?: string,
  plannedEndDate?: string,
  startDate?: string,
  users?: string[],
  videoGenre?: string,
  videoGenreOther?: string,
  languages?: string[],
  subTitleLanguages?: string[],
  dubbingLanguages?: string[],
  airDate?: string,
  validationMode?: string,
  validators?: string[],
  videoLength?: number,
  tags?: string[],
  displayDueDate?: number,
  downPaymentDeadline?: number,
  finalPaymentDeadline?: number,
  downPaymentPaid?: boolean,
  finalPaymentPaid?: boolean,
  projectType?: string,
  workspaceId?: string,
  status?: string,
  completionDate?: string;
  avatarUrl?: string;
  participants?: SimpleUser[];
  campaignId?: string;
  sections?: ProjectSection[];
}


export type APIDateType = number | string | null;


export const ProjectImageUpdatedEvent = 'project.imageUpdated';

export const ProjectStatusList = {
  TO_COME: 'TO_COME',
  IN_PROGRESS: 'IN_PROGRESS',
  UPCOMING_EXPIRY: 'UPCOMING_EXPIRY',
  BEWARE: 'BEWARE',
  COMPLETED: 'COMPLETED',
  DELAYED: 'DELAYED',
}

export interface ProjectSection {
  id: string | number;
  name: string;
  description: string;
  startDate?: APIDateType;
  endDate?: APIDateType;
  phase: string;
  section: string;
  projectId: string;
  orderNumber: number;
  color?: string;
  projectSectionKey?: { projectId: string; id: string };
  projectSubSections?: ProjectSubsection[];
  status?: string;
}

export interface ProjectSubsection {
  phase: string;
  section: string;
  subSection: string;
  id: string | number;
  name?: string;
  description?: string;
  startDate?: APIDateType;
  endDate?: APIDateType;
  orderNumber?: number;
  projectSectionId?: string;
  status?: string;
  dates?: boolean;
  dynamic?: boolean;
  pre?: boolean;
}

export const ProjectSectionNames = {
  PRODUCTION_PARTNER_SELECTION: 'PRODUCTION_PARTNER_SELECTION',
  PRODUCTION_BRIEF: 'PRODUCTION_BRIEF',
  PRODUCTION_COMPANY_PROPOSALS: 'PRODUCTION_COMPANY_PROPOSALS',
  BRIEF: 'BRIEF',
  PRE_PRODUCTION: 'PRE_PRODUCTION',
  SHOOTING: 'SHOOTING',
  POST_PRODUCTION: 'POST_PRODUCTION',
  DIFFUSION: 'DIFFUSION',
};

export const ProjectSubsectionNames = {
  CREATIVE_BRIEF: 'CREATIVE_BRIEF',
  CREATIVE_PROPOSAL: 'CREATIVE_PROPOSAL',
  ANIMATIC_2D: 'ANIMATIC_2D',
  ANIMATIC_3D: 'ANIMATIC_3D',
  CASTING: 'CASTING',
  HAIR_DRESSER: 'HAIR_DRESSER',
  LOCATION: 'LOCATION',
  MAKE_UP: 'MAKE-UP',
  MUSIC_SELECTION: 'MUSIC_SELECTION',
  SET_DESIGN: 'SET_DESIGN',
  SHOOTING_BOARD: 'SHOOTING_BOARD',
  STORYBOARD: 'STORYBOARD',
  STYLISM: 'STYLISM_/_COSTUME_DESIGN',
  QA: 'Q&A',
  UPLOAD_BRIEF: 'UPLOAD_BRIEF',
  PROPOSALS_DISCUSSION: 'PROPOSALS_&_DISCUSSION',
  COMPANY_PROFILE_SUGGESTIONS: 'COMPANY_PROFILE_SUGGESTIONS',
  CONTENT: 'CONTENT',
  SCHEDULE_PRICING: 'SCHEDULE_&_PRICING',
  EXPORTS: 'EXPORTS',
  FORMAT_REQUEST: 'FORMAT_REQUEST',
  PROJECT_DELIVERABLES: 'PROJECT_DELIVERABLES',
  COLOR_GRADING: 'COLOR_GRADING',
  DIGITAL_EFFECTS: 'DIGITAL_EFFECTS',
  EDITING: 'EDITING',
  MUSIC: 'MUSIC',
  SOUND_DESIGN: 'SOUND_DESIGN',
  SUBTITLES: 'SUBTITLES',
  VOICE_OVER_CASTING: 'VOICE_OVER_CASTING',
  VOICE_OVER_RECORDING: 'VOICE_OVER_RECORDING',
}

//for subsection text mapping
export const ProjectSubsectionText = {
  CREATIVE_BRIEF: 'Creative Brief',
  CREATIVE_PROPOSAL: 'Creative Proposal',
  ANIMATIC_2D: 'Animatic 2d',
  ANIMATIC_3D: 'Animatic 3d',
  CASTING: 'Casting',
  HAIR_DRESSER: 'Hair Dresser',
  LOCATION: 'Location',
  MAKE_UP: 'Make-Up',
  MUSIC_SELECTION: 'Music Selection',
  SET_DESIGN: 'Set Design',
  SHOOTING_BOARD: 'Shooting Board',
  STORYBOARD: 'Storyboard',
  STYLISM: 'Stylism / Costume Design',
  QA: 'Q&a',
  UPLOAD_BRIEF: 'Upload Brief',
  PROPOSALS_DISCUSSION: 'Proposals & Discussion',
  COMPANY_PROFILE_SUGGESTIONS: 'Company Profile Suggestions',
  CONTENT: 'Content',
  SCHEDULE_PRICING: 'Schedule & Pricing',
  EXPORTS: 'Exports',
  FORMAT_REQUEST: 'Format Request',
  PROJECT_DELIVERABLES: 'Project Deliverables',
  COLOR_GRADING: 'Color Grading',
  DIGITAL_EFFECTS: 'Digital Effects',
  EDITING: 'Editing',
  MUSIC: 'Music',
  SOUND_DESIGN: 'Sound Design',
  SUBTITLES: 'Subtitles',
  VOICE_OVER_CASTING: 'Voice Over Casting',
  VOICE_OVER_RECORDING: 'Voice Over Recording',
}


export const SectionUpdateErrorCodes = {
  WRONG_DATES: 'WRONG_DATES',
  START_DATE_AFTER_END_DATE: 'START_DATE_AFTER_END_DATE', //for start date > end date
  WRONG_START_DATE: 'WRONG_START_DATE', // for start date > subsections start date
  WRONG_END_DATE: 'WRONG_END_DATE', // for end date < subsections end date
  INCORRECT_STATUS: 'INCORRECT_STATUS',
}
