import { EventEmitter } from '@angular/core';
import { MovedKeyFile } from 'app/shared/interfaces';

export const listenerFileUploadLoaded: EventEmitter<any> = new EventEmitter<any>();
export const listenerUploadStatusChanged: EventEmitter<any> = new EventEmitter<any>();
export const listenerFileUploadCompleted: EventEmitter<any> = new EventEmitter<any>();
export const listenerUploadProgressChanged: EventEmitter<any> = new EventEmitter<any>();
export const listenerStartedUploadingFile: EventEmitter<any> = new EventEmitter<any>();
export const listenerFileUploaded: EventEmitter<any> = new EventEmitter<any>();
export const projectKeyFileDeleted: EventEmitter<any> = new EventEmitter<any>();
export const projectKeyFileFolderAdded: EventEmitter<any> = new EventEmitter<any>();
export const projectKeyFileFolderRemoved: EventEmitter<any> = new EventEmitter<any>();
export const projectKeyFileMoved: EventEmitter<MovedKeyFile> = new EventEmitter<MovedKeyFile>();
export const listenerFileDownloadLoaded: EventEmitter<any> = new EventEmitter<any>();
export const listenerFileDownloadResumed: EventEmitter<any> = new EventEmitter<any>();
export const listenerDownloadStatusChanged: EventEmitter<any> = new EventEmitter<any>();
export const listenerDownloadProgressChanged: EventEmitter<any> = new EventEmitter<any>();
export const listenerFileDownloadCompleted: EventEmitter<any> = new EventEmitter<any>();
export const listenerFileDownloadRemoved: EventEmitter<any> = new EventEmitter<any>();
export const listenerMonitoredFileChanged: EventEmitter<any> = new EventEmitter<any>();
export const listenerResumableUploadFailed: EventEmitter<any> = new EventEmitter<any>();
export const listenerUploadResumeFailed: EventEmitter<any> = new EventEmitter<any>();
