import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import FUMLocalSetting from '../common/fum-local-setting';
import {
	listenerUploadStatusChanged,
	listenerStartedUploadingFile,
	listenerDownloadStatusChanged,
	listenerFileDownloadResumed,
	listenerUploadResumeFailed
} from 'app/shared/services/signed-url/listeners';
import { TransferStatus, SURLFUManagerService, MTMCustomFile } from 'app/shared/services/signed-url';
import { AuthService } from 'app/shared/services/auth.service';
import { MTMFileDownloadService, onDownloadStart } from '../../mtm-file-download/mtm-file-download.service';
import { ActivatedRoute } from '@angular/router';
import { FileTransferService } from '../../../services/signed-url/file-transfer.service';
import { OverlayService } from "../../../services/overlayService";
import { TranslatePipe } from "../../../pipes/translate.pipe";
import { EmitterService } from 'app/shared/services/emitter.service';
import { MTMFileDownload } from "../../../services/signed-url/mtm-file-download";
import { Subject, forkJoin, takeUntil } from "rxjs";

/**
 * @deprecated DO NOT USE THIS COMPONENT
 */
@Component({
	selector: 'selector-file-upload-dropdown',
	templateUrl: './file-upload-dropdown.component.html',
	styleUrls: ['./file-upload-dropdown.component.scss', '../common/common.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileUploadDropdown implements OnInit, OnDestroy, AfterViewInit {
	dropdownContainerMaxHeight: number = 0;

	private fumSetting = new FUMLocalSetting();

	listLatestFourItems: MTMCustomFile[] = [];

	allActivitiesSum: number = 0;
	uploadingCount: number = 0;
	downloadingCount: number = 0;
	downloadList: MTMFileDownload[] = [];

	isBackground: boolean = true;
	dropdownStatus: boolean = false;
	activeTab: string = 'upload';
	publicReviewLink: any = false;
	ngUnsubscribe = new Subject();

	constructor(private route: ActivatedRoute,
		private authService: AuthService,
		private mtmFileDownloadService: MTMFileDownloadService,
		private transferService: FileTransferService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe) {

		listenerStartedUploadingFile.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (silentMode: any) => this.startedTheUploadingNewFiles(silentMode)
		})

		listenerUploadStatusChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: () => {
				this.countUploadingFiles();
				this.takeLastItems();
			}
		})

		listenerDownloadStatusChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: () => {
				this.countDownloadingFiles();
			}
		})

		listenerFileDownloadResumed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: () => {
				this.activeTab = 'download';
			}
		})

		listenerUploadResumeFailed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (file) => {
				this.transferService.cancelUpload(file);
				this.overlayService.showError(this.translatePipe.transform('fileTransfer_cantResumeUpload'));
			}
		})

		onDownloadStart.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (silentMode: any) => this.onFileDownloadStarted(silentMode)
		})

		EmitterService.get('user.logout').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: () => {
				this.transferService.pauseAllTransfers();
			}
		})
	}

	ngOnInit() {
		this.calculateDropdownMaxHeight();
		window.addEventListener('resize', this.calculateDropdownMaxHeight);
		//this.downloadFileList = this.mtmFileDownloadService.getAllDownloadFile();
		this.publicReviewLink = this.route.snapshot.data.publicReviewLink;
		if (this.publicReviewLink) {
			this.activeTab = 'download'
		}
	}

	ngAfterViewInit(): void {
		const userName = this.authService.getAuthUserName();

    //this methods will be called in the upload-popup and download-popup components
    /*
		forkJoin([
			this.transferService.loadActiveUploads(),
			this.transferService.loadActiveDownloads()
		]).subscribe({
			next: (data) => {
				const [uploads, downloads] = data;
				if (uploads.length) {
					this.countUploadingFiles();
					this.takeLastItems();
				}
				if (downloads.length) {
					this.countDownloadingFiles();
				}

				let toastMessage = ''

				if (uploads.length) {
					toastMessage = 'asset_unfinishedUpload';
					this.activeTab = 'upload';
				} else if (downloads.length) {
					this.activeTab = 'download';
					toastMessage = 'asset_unfinishedDownload'
				}

				if (toastMessage) {
					const toast = this.overlayService.showWarning(this.translatePipe.transform(toastMessage));
					toast.onTap.subscribe(() => {
						this.switchDropdownPanel(true);
					});
				}
			}
		});
     */
	}

	ngOnDestroy() {
		window.removeEventListener('resize', this.calculateDropdownMaxHeight);
	}

	getTotalDownloadInProgress() {
		return this.mtmFileDownloadService.getTotalDownloadInProgress();
	}

	private calculateDropdownMaxHeight = () => {
		this.dropdownContainerMaxHeight = document.body.offsetHeight - 85;
	}

	onChangeBackgroundMode() {
		this.fumSetting.updateBackgroundMode(this.isBackground);
	}

	switchDropdownPanel(status: any) {
		this.takeLastItems();
		setTimeout(() => {
			this.dropdownStatus = status;
		}, 250);
	}

	private getBackgroundModeFromLocalStorage() {
		this.isBackground = this.fumSetting.getBackgroundMode();
	}

	private startedTheUploadingNewFiles(silentMode = false) {
		this.activeTab = 'upload';
		this.getBackgroundModeFromLocalStorage();
		if (this.isBackground || silentMode) // isBackground == true
			return;

		if (!this.dropdownStatus) {
			setTimeout(() => {
				this.dropdownStatus = true;
			}, 250);
		}
	}

	onFileDownloadStarted(silentMode = false) {
		this.activeTab = 'download';
		this.getBackgroundModeFromLocalStorage();
		if (this.isBackground || silentMode)
			return;
		if (!this.dropdownStatus) {
			setTimeout(() => {
				this.dropdownStatus = true;
			}, 250);
		}
	}

	onMoveScroll(container: any, direction: string) {
		const scrollStep = 93;
		let latestTop: number = 0;

		switch (direction) {
			case 'UP':
				latestTop = container.scrollTop - scrollStep;
				latestTop = latestTop < 0 ? 0 : latestTop;
				break;

			case 'DOWN':
				latestTop = container.scrollTop + scrollStep;
				latestTop = latestTop > container.scrollHeight ? container.scrollHeight : latestTop;
				break;
		}

		container.scroll(0, latestTop);
	}

	private countUploadingFiles() {
		/*
		this.uploadingCount = SURLFUManagerService.serviceSURL.listFilesUploadStruct
			.filter(k => k.authUserName == this.serviceAuth.getAuthUserName())
			.map(item => {
				return item.fileUploadObject.uploadFiles
					.filter(newFile =>
						newFile.uploadStatusCode == SIGNED_URL_UPLOAD_TYPES.WAITING
						||
						newFile.uploadStatusCode == SIGNED_URL_UPLOAD_TYPES.SENDING
					)
					.length;
			}).reduce((a, b) => a + b, 0);
		*/
		const uploadingStatus = [TransferStatus.WAITING, TransferStatus.SENDING]
		this.uploadingCount = this.transferService.filesToUpload.filter(file => uploadingStatus.includes(file.uploadStatusCode)).length;
	}

	private takeLastItems() {
		/*
		let list: MTMCustomFile[] = [];

		SURLFUManagerService.serviceSURL.listFilesUploadStruct
			.filter(k => k.authUserName == this.serviceAuth.getAuthUserName())
			.map(item => {
				item.fileUploadObject.uploadFiles.map((uF: any) => {
					uF.projectName = item.projectName;
					uF.label = item.label;
					list.push(uF)
				});
			});

		this.allActivitiesSum = list.length;
		this.listLatestFourItems = list;
		this.listLatestFourItems.sort((x, y) => { return y.timeToStartUploading - x.timeToStartUploading });
		*/
		this.listLatestFourItems = [...this.transferService.filesToUpload];
		this.allActivitiesSum = this.transferService.filesToUpload.length;
	}

	private countDownloadingFiles() {
		const includedStatus = [TransferStatus.WAITING, TransferStatus.DOWNLOADING]
		this.downloadingCount = this.transferService.filesToDownload.filter(file => includedStatus.includes(file.status)).length;
		this.downloadList = [...this.transferService.filesToDownload];
	}
}
