<div (click)="onNotificationClick($event, notification)"
	*ngIf="notificationDisplay && notificationDisplay.label && !lorealUser"
	class="mt-sm-1 mt-md-2 mt-lg-3 mb-sm-1 mb-md-2 mb-lg-3 bs-callout notification-container d-flex align-items-center"
	[style.opacity]="NotificationViewType.Toast ? viewOpacity : 1" [style.position]="'relative'"
	[ngClass]="{'recent-activity-item': mode == NotificationViewType.RecentActivity || mode == NotificationViewType.Toast,
		'pre-prod' : hasName && notification.object.sectionName == 'Pre-Production'
                                                                                                                ,'post-prod' : hasName && notification.object.sectionName == 'Post-Production'
                                                                                                                ,'shooting' : hasName && notification.object.sectionName == 'Shooting'
                                                                                                                ,'creative-partner-selection' : hasName && notification.object.sectionName == 'Creative Partner Selection'
                               					                                                                ,'creative-brief' : hasName && notification.object.sectionName == 'Creative Brief'
                                                                                                                ,'creative-work' : hasName && notification.object.sectionName == 'Creative Work'
                                                                                                                ,'production-partner-selection' : hasName && notification.object.sectionName == 'Production Partner Selection'
                                                                                                                ,'production-brief' : hasName && notification.object.sectionName == 'Production Brief'
                                                                                                                ,'production-proposal' : hasName && notification.object.sectionName == 'Production Company Proposals'
                                                                                                                ,'self-fix-edit' : hasName && notification.object.sectionName == 'Self-fix Edit'
                                                                                                                ,'diffusion' : hasName && notification.object.sectionName == 'Diffusion'
                                                                                                                ,'call' : !hasName && notification.code == 'CONVERSATION_CALL'}">
	<div *ngIf="showSectionBorder"
		[class]="'section-border  '+(notification.customBorderClass? notification.customBorderClass:'')">
	</div>
	<div class="notification-body">
		<div class="notification">


			<div class="avatar">
				<mtm-user-profile-picture [forceLoadProfile]="true" [username]="notification.object.notifiedByUsername"
					[height]="this.avatarSize" [width]="this.avatarSize">
				</mtm-user-profile-picture>
			</div>

			<div class="content d-flex">
				<div class="notify-user">
					<div class="w-100 name">
						<span *ngIf="notification.object" class="fw-600">{{notification.object.notifiedBy}}</span>
					</div>
					<div class="w-100 job"
						*ngIf="mode != NotificationViewType.RecentActivity && mode != NotificationViewType.Toast">
						<span *ngIf="notification.object" class="role">{{notification.object.jobTitle}}</span>
					</div>
				</div>

				<div class="description">
					<div class="notification-new-date" *ngIf="notification.date">

						<span *ngIf="mode == NotificationViewType.List">{{notification.date | mtmDateTime}}</span>

						<ng-container
							*ngIf="mode == NotificationViewType.RecentActivity || mode == NotificationViewType.Toast">
							<span>{{notification.date | mtmDateTime}}</span>
						</ng-container>

					</div>
					<p *ngIf="notification.object" style="margin-bottom: 3px;" [ngClass]="{'pre-prod' : notification.object.sectionName == 'Pre-Production'
																											,'post-prod' : notification.object.sectionName == 'Post-Production'
																											,'shooting' : notification.object.sectionName == 'Shooting'
																											,'creative-partner-selection' : notification.object.sectionName == 'Creative Partner Selection'
																											,'creative-brief' : notification.object.sectionName == 'Creative Brief'
																											,'creative-work' : notification.object.sectionName == 'Creative Work'
																											,'production-partner-selection' : notification.object.sectionName == 'Production Partner Selection'
																											,'production-brief' : notification.object.sectionName == 'Production Brief'
																											,'production-proposal' : notification.object.sectionName == 'Production Company Proposals'
																											,'self-fix-edit' : notification.object.sectionName == 'Self-fix Edit'
																											,'diffusion' : notification.object.sectionName == 'Diffusion' }">
						{{notification.object.sectionName}}


					</p>
					<div class="label" style="color:#ada9a9; font-weight: 600">
						{{notificationDisplay.label}}
					</div>
					<div *ngIf="notification.object && notification.object.projectName && nameVisible === false && projectMoved === false"
						class="project-name" (mouseenter)="mouseEnter()">
						{{getProjectName(notification)}}
						<!-- {{notification.object.projectName.length>20 ? ((notification.object.projectName).slice(0,
						20) +
						'...') : notification.object.projectName }} -->
					</div>

					<div *ngIf="notification.object && notification.object.projectName && nameVisible === true && projectMoved === false"
						class="project-name" (mouseleave)="mouseLeave()">
						{{notification.object.projectName}}
					</div>

					<div *ngIf="notification.object && notification.object.workspaceName && nameVisible === false"
						class="project-name" (mouseenter)="mouseEnter()">
						{{notification.object.workspaceName.length>20 ?
						((notification.object.workspaceName).slice(0,
						20) + '...') : notification.object.workspaceName }}
					</div>

					<div *ngIf="notification.object && notification.object.workspaceName && nameVisible===true"
						class="project-name" (mouseleave)="mouseLeave()">
						{{notification.object.workspaceName}}
					</div>

					<div *ngIf="notificationDisplay.action.event == 'PAYMENT'" class="project-name">
						<span class="clickable"
							(click)="this.onPaymentEvent()">{{notificationDisplay.action.message}}</span>
					</div>
					<div class="project-name" *ngIf="notificationDisplay?.action?.showCompany">
						<span>{{ 'notificationCOMPANY_NAME' | translate:{ COMPANY_NAME:
							notificationDisplay.action.companyName } }}</span>
					</div>
				</div>
			</div>

		</div>
		<div class="d-flex justify-content-between align-items-center">
			<i class="fa fa-chevron-left" aria-hidden="true"
				style="color: #ada9a9; position: relative; transform: rotate(180deg);"></i>
		</div>
		<div class="p-md-3 p-sm-2 p-lg-4 d-flex justify-content-between align-items-center">
			<div *ngIf="!isSeenNotification">
				<i class="fa fa-circle" aria-hidden="true" style="color: #fe6163; font-size: 14px; position: relative;">
					<span class="sr-only">Unread</span>
				</i>
			</div>

			<div *ngIf="isSeenNotification">
				<i class="fa fa-check" aria-hidden="true" style="color: #52cfa2; font-size: 18px;">
					<span class="sr-only">Read</span>
				</i>
			</div>
		</div>
	</div>
	<span class="close-button" *ngIf="showCloseButton" (click)="$event.stopPropagation();closeNotification();"><i
			class="fa fa-times" aria-hidden="true"></i></span>
</div>


<!-- loreal notification block -->
<div (click)="onWokspaceV2NotificationClick($event, notification)"
	*ngIf="notificationDisplay && notificationDisplay.label && lorealUser"
	class="mt-sm-1 mt-md-2 mt-lg-3 mb-sm-1 mb-md-2 mb-lg-3 bs-callout  notification-container d-flex align-items-center"
	[style.opacity]="NotificationViewType.Toast ? viewOpacity : 1" [style.position]="'relative'"
	[ngClass]="{'recent-activity-item': mode == NotificationViewType.RecentActivity || mode == NotificationViewType.Toast,
		'pre-prod' : hasName && notification.object.sectionName == 'Pre-Production'
                                                                                                                ,'post-prod' : hasName && notification.object.sectionName == 'Post-Production'
                                                                                                                ,'shooting' : hasName && notification.object.sectionName == 'Shooting'
                                                                                                                ,'creative-partner-selection' : hasName && notification.object.sectionName == 'Creative Partner Selection'
                               					                                                                ,'creative-brief' : hasName && notification.object.sectionName == 'Creative Brief'
                                                                                                                ,'creative-work' : hasName && notification.object.sectionName == 'Creative Work'
                                                                                                                ,'production-partner-selection' : hasName && notification.object.sectionName == 'Production Partner Selection'
                                                                                                                ,'production-brief' : hasName && notification.object.sectionName == 'Production Brief'
                                                                                                                ,'production-proposal' : hasName && notification.object.sectionName == 'Production Company Proposals'
                                                                                                                ,'self-fix-edit' : hasName && notification.object.sectionName == 'Self-fix Edit'
                                                                                                                ,'diffusion' : hasName && notification.object.sectionName == 'Diffusion'
                                                                                                                ,'call' : !hasName && notification.code == 'CONVERSATION_CALL'}">

	<div *ngIf="showSectionBorder"
		[class]="'section-border '+(notification.customBorderClass? notification.customBorderClass:'')">
	</div>
	<div class="notification-body">
		<div class="notification">

			<ng-container *ngIf="!notification.dummy">

				<div class="avatar">
					<!-- <mtm-user-profile-picture [forceLoadProfile]="true" [username]="notification.object.notifiedByUsername"
					[height]="this.avatarSize" [width]="this.avatarSize">
				</mtm-user-profile-picture> -->
					<div class="avtar-image-div">
						<img width="35px" height="35px" [src]="notification.object.notifiedByAvatarUrl" alt="">
					</div>
					<div class="mt-2 ml-3" [style.max-width]="'300px'" [style.min-width]="'250px'">
						<div class="row label" style="color:#00000099; font-weight: 400">
							<span>
								{{notificationDisplay.label}}
							</span>
							<span class="_400 new-project-name ml-1">
								{{getProjectName(notification)}}
								<!-- {{notification.object.projectName.length>20 ?
								((notification.object.projectName).slice(0, 20) +
								'...') : notification.object.projectName }} -->
							</span>
						</div>
					</div>
				</div>

				<div class="content d-flex" style="margin-left: -10px;">
					<div class="new-description">
						<p *ngIf="notification.object" class="f-10 c-mtm-full-black _700" style="margin-bottom: 3px;">
							{{notification.object.notifiedBy}}
						</p>

						<div *ngIf="notification.object && notification.object.projectName && nameVisible === true && projectMoved === false"
							class="project-name" (mouseleave)="mouseLeave()">
							{{notification.object.projectName}}
						</div>

						<div *ngIf="notification.object && notification.object.workspaceName && nameVisible === false"
							class="project-name" (mouseenter)="mouseEnter()">
							{{notification.object.workspaceName.length>20 ?
							((notification.object.workspaceName).slice(0,
							20) + '...') : notification.object.workspaceName }}
						</div>

						<div *ngIf="notification.object && notification.object.workspaceName && nameVisible===true"
							class="project-name" (mouseleave)="mouseLeave()">
							{{notification.object.workspaceName}}
						</div>

						<div *ngIf="notificationDisplay.action.event == 'PAYMENT'" class="project-name">
							<span class="clickable"
								(click)="this.onPaymentEvent()">{{notificationDisplay.action.message}}</span>
						</div>
						<div class="project-name" *ngIf="notificationDisplay?.action?.showCompany">
							<span>{{ 'notificationCOMPANY_NAME' | translate:{ COMPANY_NAME:
								notificationDisplay.action.companyName } }}</span>
						</div>
					</div>

				</div>

			</ng-container>
			<ng-container *ngIf="notification.dummy">
				<!-- DUMMY VIEW -->

				<div class="d-flex dummy-notification">
					<img class="d-inline-block mr-2" width="35" height="35"
						[src]="notification.object.notifiedByAvatarUrl">
					<img class="d-inline-block mr-2" width="51" height="37" [src]="notification.object.uploadImageUrl"
						*ngIf="notification.object.uploadImageUrl">
					<div class="d-flex flex-column align-items-center" style="flex: 1; ">
						<div class="w-100 name" style="font-weight: 700;">{{notification.object.notifiedBy}}</div>
						<div class="label" [innerHTML]="notificationDisplay.label">
						</div>
					</div>
				</div>

				<!--END DUMMY VIEW -->
			</ng-container>
		</div>
		<!-- <div class="notification-date" *ngIf="notification.date" [ngClass]="{'dummy-date':notification.dummy}">

			<span *ngIf="mode == NotificationViewType.List">{{notification.date | mtmDateTime}}</span>

			<ng-container *ngIf="mode == NotificationViewType.RecentActivity || mode == NotificationViewType.Toast">
				<span>{{notification.date | mtmDateTime}}</span>
			</ng-container>

		</div> -->
	</div>
</div>