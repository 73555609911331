import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { MTMFileDownload } from "./mtm-file-download";
import { TransferProgress } from "./transfer-progress";
import { TransferStatus } from "./transfer-status";
import { TransferDbManagerService } from "./transfer-db-manager.service";
import { Observable, Subject } from "rxjs";
import { DownloadChunkRecord, DownloadRecord } from "./idx-schema";
import { HelperService } from "../helper.service";
import { listenerDownloadStatusChanged, listenerFileDownloadCompleted, listenerFileDownloadResumed } from "./listeners";
import { IS_UNCANCELABLE_REQUEST } from "../../models";

const downloadChunkSize: number = 20 * 1024 * 1024; //20MB

@Injectable({
  providedIn: 'root'
})
export class SignedUrlDownloader {
  constructor(
    private http: HttpClient,
    private dbManager: TransferDbManagerService
  ) {

  }

  download(file: MTMFileDownload) {
    if (file.isResumable) {
      this.initProgressHandler(file);
      listenerFileDownloadResumed.emit(file);
      this.checkFileSize(file).subscribe(() => {
        this.recordDownload(file).subscribe(() => {
          listenerDownloadStatusChanged.next(file);
          this.downloadSlice(file);
        });
      });

      return;
    }

    this.directDownload(file);
  }

  private checkFileSize(file: MTMFileDownload): Observable<any> {
    const subject = new Subject();

    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name && name != 'Content-Type') {
        headers[name] = values[0];
      }
    });
    file.currentRequest = this.http.get(file.url, {
      headers: {
        ...headers,
        Range: `bytes=0-1`
      },
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true),
      observe: 'response',
      responseType: 'arraybuffer'
    }).subscribe({
      next: (response: HttpResponse<any>) => {
        const range = response.headers.get('x-goog-stored-content-length');
        if (range) {
          const newFileSize = parseInt(range, 10);
          if (!isNaN(newFileSize)) {
            file.size = newFileSize;
          }
        }
        subject.next(undefined);
        subject.complete();
      }, error: (error: HttpErrorResponse) => {
        console.log(error);
        subject.next(undefined);
        subject.complete();
      }
    });

    return subject.asObservable();
  }


  private initProgressHandler(item: MTMFileDownload) {
    item.progress$.subscribe({
      next: (progress: TransferProgress) => {
        switch (progress.step) {
          case 'retry':
            if (item.retryCount < item.maxRetry) {
              item.retryCount++;
            } else {
              item.status = TransferStatus.ERROR;
              return;
            }
            break;
        }

        switch (progress.status) {
          case TransferStatus.COMPLETED:
            item.status = TransferStatus.COMPLETED;
            item.percentage = 100;
            item.progress$.complete();
            item.done$.next(item);
            item.done$.complete();
            listenerDownloadStatusChanged.next(item);
            listenerFileDownloadCompleted.next(item);
            this.dbManager.deleteDownload(item.id);
            break;

          case TransferStatus.DOWNLOADING:
            if (progress.startIndex >= item.size) {
              item.progress$.next({
                status: TransferStatus.COMPLETED,
                startIndex: progress.startIndex
              });
              return;
            }
            item.percentage = Math.floor((progress.startIndex * 1.0 / item.size) * 100);
            item.startIndex = progress.startIndex;
            const inactiveStatusList = [TransferStatus.INACTIVE, TransferStatus.PAUSED];
            if (inactiveStatusList.indexOf(item.status) == -1) {
              item.status = TransferStatus.DOWNLOADING;
            }
            listenerDownloadStatusChanged.next(item);
            listenerDownloadStatusChanged.next(item);
            this.dbManager.updateDownload(item.id, {
              status: item.status,
              startIndex: progress.startIndex,
              percentage: item.percentage
            }).subscribe(() => {
              if (inactiveStatusList.indexOf(item.status) == -1) {
                item.status = TransferStatus.DOWNLOADING;
                this.downloadSlice(item);
              }
            });
            break;
        }
      }
    });
  }

  private recordDownload(item: MTMFileDownload): Observable<any> {
    const subject = new Subject();
    this.dbManager.getDownload(item.id).subscribe({
      next: (data: undefined | DownloadRecord) => {
        if (!data) {
          this.dbManager.addDownload(item.toDownloadRecord()).subscribe({
            next: () => {
              subject.next(true);
              subject.complete();
            }
          });
          return;
        }
        subject.next(false);
        subject.complete();
      }, error: (error) => {
        subject.error(error);
      }
    })

    return subject.asObservable();
  }

  private recordDownloadChunk(chunk: DownloadChunkRecord) {
    const subject = new Subject();
    this.dbManager.addDownloadChunk(chunk).subscribe({
      next: () => {
        subject.next(true);
        subject.complete();
      }, error: (error) => {
        subject.error(error);
      }
    });
    return subject.asObservable();
  }

  private downloadSlice(file: MTMFileDownload) {
    const startChunk: number = file.startIndex || 0;
    const encodedName = encodeURIComponent(file.name);
    let endChunk = Math.min(startChunk + downloadChunkSize - 1, file.size - 1);
    let format = `bytes=${startChunk}-${endChunk}`;
    let url = file.url;
    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name && name != 'Content-Type') {
        headers[name] = values[0];
      }
    });
    file.status = TransferStatus.DOWNLOADING;
    file.currentRequest = this.http.get(url, {
      headers: {
        ...headers,
        Range: format
      },
      observe: 'events',
      responseType: 'arraybuffer',
      reportProgress: true,
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: (response: HttpEvent<ArrayBuffer>) => {
        switch (response.type) {
          case HttpEventType.DownloadProgress:
            file.status = TransferStatus.DOWNLOADING;
            file.percentage = Math.floor(100 * (response.loaded + startChunk) / file.size);
            break;
          case HttpEventType.Response:
            const ab = response.body;
            const newArray = new Uint8Array(file.buffer.byteLength + ab.byteLength);
            newArray.set(file.buffer, 0);
            const newBytes = new Uint8Array(ab)
            newArray.set(newBytes, file.buffer.byteLength);
            file.buffer = newArray;
            this.recordDownloadChunk({
              downloadId: file.id,
              part: Math.floor(file.startIndex / downloadChunkSize),
              content: newBytes,
            }).subscribe({
              next: () => {
                file.retryCount = 0;
                file.progress$.next({
                  startIndex: endChunk + 1,
                  status: TransferStatus.DOWNLOADING,
                });
              },
              error: (error: Error) => {
                file.progress$.next({
                  status: TransferStatus.INACTIVE,
                  startIndex: startChunk,
                  error: error.message,
                  step: 'retry'
                });
              }
            })
            break;
        }


      },
      error: (error: Error) => {
        file.progress$.next({
          status: TransferStatus.INACTIVE,
          startIndex: startChunk,
          error: error.message,
          step: 'retry'
        });
      }
    })
  }

  pauseDownload(file: MTMFileDownload) {
    if (file.currentRequest) {
      file.currentRequest.unsubscribe();
    }
    file.status = TransferStatus.PAUSED;
    listenerDownloadStatusChanged.next(file);
  }

  resumeDownload(file: MTMFileDownload) {
    const wasInactive = file.status == TransferStatus.INACTIVE;
    listenerFileDownloadResumed.emit(file);
    if (wasInactive) {
      this.dbManager.getDownloadChunks(file.id).subscribe({
        next: (chunks: DownloadChunkRecord[] | undefined) => {
          let buffer = new Uint8Array(0);
          chunks.filter(c => c).sort((a, b) => a!.part - b!.part).forEach((c, index) => {
            if (index == 0) {
              buffer = new Uint8Array(c!.content.byteLength);
              buffer.set(c!.content);
            } else {
              const newArray = new Uint8Array(buffer.byteLength + c!.content.byteLength);
              newArray.set(buffer, 0);
              newArray.set(c!.content, buffer.byteLength);
              buffer = newArray;
            }
          });
          file.status = TransferStatus.DOWNLOADING;
          file.buffer = buffer;
          file.startIndex = chunks.length * downloadChunkSize;

          if (wasInactive) {
            this.initProgressHandler(file);
          }
          file.progress$.next({
            status: TransferStatus.DOWNLOADING,
            startIndex: file.startIndex
          });
        }
      });
    } else {
      file.status = TransferStatus.DOWNLOADING;
      file.progress$.next({
        status: TransferStatus.DOWNLOADING,
        startIndex: file.startIndex
      });
    }
  }

  cancelDownload(file: MTMFileDownload): Observable<any> {
    file.status = TransferStatus.CANCELED;
    file.buffer = new Uint8Array(0);
    file.progress$.complete();
    file.done$.complete();
    listenerDownloadStatusChanged.next(file);
    return this.dbManager.deleteDownload(file.id);
  }

  private directDownload(file: MTMFileDownload) {
    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name && name != 'Content-Type') {
        headers[name] = values[0];
      }
    });
    let url = file.url;
    file.currentRequest = this.http.get(url, {
      headers: {
        ...headers
      },
      observe: 'events',
      responseType: 'arraybuffer',
      reportProgress: true,
    }).subscribe({
      next: (response: HttpEvent<ArrayBuffer>) => {
        switch (response.type) {
          case HttpEventType.DownloadProgress:
            file.status = TransferStatus.DOWNLOADING;
            const actualPercentage = 100 * response.loaded / response.total;
            file.percentage = Math.floor(actualPercentage);
            if(file.percentage > 0) {
              this.calculateRemainingTime(file, actualPercentage);
              listenerDownloadStatusChanged.next(file);
            }
            break;
          case HttpEventType.Response:
            file.status = TransferStatus.COMPLETED;
            file.percentage = 100;
            file.buffer = new Uint8Array(response.body);
            file.done$.next(file);
            file.done$.complete();
            listenerDownloadStatusChanged.next(file);
            listenerFileDownloadCompleted.next(file);
            break;
        }
      }
    });
  }

  private calculateRemainingTime(file: MTMFileDownload, percentage: number) {
    const elapsedTime = Date.now() - file.startDate;
    const expectedDuration = 100 / file.percentage * elapsedTime;
    file.extraInfo.remainingTime = expectedDuration - elapsedTime;
  }
}
