import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../../shared/services/auth.service";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UploadModalComponent } from "../../../../on-boarding/profile-avatar/upload-modal/upload-modal.component";
import { finalize, takeUntil } from "rxjs/operators";
import { UUID } from "angular2-uuid";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as Validator from "validator";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { ApiService } from "app/shared/services/api.service";
import { UploadService } from "app/shared/services/upload.service";
import { EmitterService } from "app/shared/services/emitter.service";
import {
	WorkspaceAvatarUpdatedEvent,
	WorkspaceCoverUpdatedEvent,
	WorkspaceUpdatedEvent
} from "app/shared/interfaces/workspace.interface";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { DataTransferBetweenComponentsService } from "app/shared/services/data-transfer-between-components.service";
import { forkJoin } from "rxjs";
import { concat } from "rxjs";
// import { concatAll } from "rxjs/operator/concatAll";
import { NotificationService } from "app/shared/services/notification.service";
import { UploadFileComponent } from "../../../../shared/components/mtm-upload-file/upload-file.component";
import { EventEmitter } from "stream";
import { HelperService } from "app/shared/services/helper.service";
import { WithPendingGuard } from "app/shared/decorators";

@Component({
	selector: 'mtm-workspace-edit-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class WorkspaceEditHeaderComponent implements OnInit, OnDestroy {
	@ViewChild("avatarUploader", { static: false }) avatarUploader: UploadFileComponent;
	@ViewChild("coverUploader", { static: false }) coverUploader: UploadFileComponent;

	private _workspace: any

	get workspace(): any {
		return this._workspace;
	}

	@Input()
	set workspace(value: any) {
		this._workspace = value;
		this.initForm();
		if (value) {
			if (value.avatarUrl)
				this.avatarUrl = HelperService.getCachebusterUrl(value.avatarUrl);
			if (value.coverUrl)
				this.coverUrl = HelperService.getCachebusterUrl(value.coverUrl);
		}

	}

	private uploadModal: NgbModalRef;
	private ngUnsubscribe = new Subject();
	headerForm: UntypedFormGroup = null;
	avatarUrl: string = '';
	coverUrl: string = '';
	isPending: boolean = false;

	constructor(private authService: AuthService,
		private router: Router,
		private workspaceService: WorkspaceService,
		private modalService: NgbModal,
		private apiService: ApiService,
		private uploadService: UploadService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private emitterService: EmitterService,
		private transferService: DataTransferBetweenComponentsService,
		private notificationService: NotificationService,
		private fb: UntypedFormBuilder) {
	}


	ngOnInit() {
		this.initForm();

		EmitterService.get(WorkspaceAvatarUpdatedEvent)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((info: any) => {
				const { workspaceId = '', avatarUrl = '' } = info;
				if (this._workspace && this._workspace.id == workspaceId) {
					this.avatarUrl = avatarUrl;
					this._workspace.avatarUrl = avatarUrl;
				}
			})
	}

	private initForm() {
		this.headerForm = this.fb.group({
			'name': [this.workspace ? this.workspace.name : '', Validators.required]
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	cancel() {
		const isLTG = localStorage.getItem('hasLtgPermission');
		if (isLTG === "true") {
			this.router.navigate(['/workspaces']);
		} else {
			this.router.navigate(['/projects']);
		}
	}

	validateFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateFields(control);
			}
		});
	}

	@WithPendingGuard
	save() {
		this.validateFields(this.headerForm);
		if (!this.headerForm.valid)
			return;
		const updatedData = Object.assign({}, this._workspace, this.headerForm.value);
		this.isPending = true;
		this.workspaceService.editWorkspace(updatedData.id, updatedData).pipe(
			finalize(() => this.isPending = false),
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (result: any) => {
				EmitterService.get(WorkspaceUpdatedEvent).emit(result);
				this._workspace.name = this.headerForm.value.name;
				this.transferService.ngOnChangesBreadcrumbComponent();
				this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
			}, error: (error) => {
				let errorCode = error.errorCode;
				let errorMessage = errorCode == 'DUPLICATE_NAME' ? 'workspace_edit_duplicateError' : 'workspace_edit_error';
				this.overlayService.showError(
					this.translatePipe.transform(errorMessage, { name: updatedData.name }), 'Error');
			}
		});
	}

	deleteWorkspace() {
		this.notificationService.open({
			title: this.translatePipe.transform('workspace_delete_title'),
			description: this.translatePipe.transform('workspace_delete_confirm', { name: this._workspace.name }),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			if (!confirm)
				return;
			this.deleteCurrentWorkspace();
		});
	}

	@WithPendingGuard
	private deleteCurrentWorkspace() {
		const user = this.authService.getAuthUser();
		this.isPending = true;
		this.workspaceService.deleteWorkspace(user.company.id, this._workspace.id)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: () => {
					this.workspaceService.getWorkspaces(user.company.id)
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe({
							next: (result) => {
								this.isPending = false;
								if (result.length) {
									const workspaces = result.sort((a, b) => a.name.localeCompare(b.name));
									this.setNewActiveWorkspace(workspaces[0]);
								} else {
									this.unsetWorkspace();
								}
							}, error: () => {
								this.isPending = false;
								this.unsetWorkspace();
							}
						});

				}, error: err => {
					this.isPending = false;
					this.overlayService.showError(this.translatePipe.transform('workspace_delete_error'), 'Error');
				}
			});
	}

	private unsetWorkspace() {
		this.workspaceService.unsetActiveWorkspace();
		EmitterService.get(WorkspaceUpdatedEvent).emit({});
		this.router.navigate(['/projects']);
	}

	private setNewActiveWorkspace(workspace: any) {
		this.workspaceService.setActiveWorkspace(workspace);
		EmitterService.get(WorkspaceUpdatedEvent).emit({});
		this.router.navigate(['/projects']);
	}
	private openModal() {
		this.uploadModal = this.modalService.open(UploadModalComponent, { size: 'lg' });
		this.uploadModal.componentInstance.setCrop(false);
	}

	fileChangeListener($event, imageType: string = 'avatar'): void {
		const files = $event.files[0];
		if (files.length == 0)
			return;

		if (imageType === 'avatar') {
			this.openModal();
			this.uploadModal.componentInstance.imageFile = files;
		}

		const file = files;
		const fileReader = new FileReader();
		fileReader.onloadend = (loadEvent: any) => {
			if (imageType == 'cover') {
				this.setCoverImage(loadEvent.target.result);
			} else {
				this.setAvatarImageOnModal();
			}

		};
		fileReader.readAsDataURL(file);
	}

	private setCoverImage(result: string) {
		const authUser = this.authService.getAuthUser();
		this.coverUrl = result;
		this._workspace.coverUrl = result;
		const workspaceUrl = this.apiService.baseUrl + `/api/workspace/${authUser.company.id}/${this._workspace.id}/cover`;
		this.uploadService.uploadImage(result, workspaceUrl).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this._workspace.coverUrl = data.coverUrl;
			this.coverUrl = HelperService.getCachebusterUrl(data.coverUrl);
			this.workspaceService.setActiveWorkspaceCoverAndAvatar({
				cover: data.coverUrl
			})
			EmitterService.get(WorkspaceCoverUpdatedEvent).emit(this._workspace.coverUrl);
		});
	}

	private setAvatarImageOnModal() {
		this.uploadModal.componentInstance.uploadObserver.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((result) => {
			if (result) {
				const authUser = this.authService.getAuthUser();
				this.avatarUrl = result;
				this._workspace.avatarUrl = result;
				const workspaceUrl = this.apiService.baseUrl + `/api/workspace/${authUser.company.id}/${this._workspace.id}/avatar`;

				this.uploadService.uploadImage(result, workspaceUrl).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((data: any) => {
					this._workspace.avatarUrl = data.avatarUrl;
					this.avatarUrl = HelperService.getCachebusterUrl(data.avatarUrl);
					
					EmitterService.get(WorkspaceAvatarUpdatedEvent).emit({
						workspaceId: result.id,
						avatarUrl: data.avatarUrl
					});
					this.workspaceService.setActiveWorkspaceCoverAndAvatar({
						avatar: data.avatarUrl
					})
				});

			}
		});
	}

	editAvatar() {
		this.avatarUploader.upload();
	}

	editCover() {
		this.coverUploader.upload();
	}

	removeCover() {
		this.notificationService.open({
			title: this.translatePipe.transform('confirmation'),
			notificationType: 'danger',
			centerHeader: true,
			question: this.translatePipe.transform('workspace_edit_removeCoverConfirm'),
			confirmBtn: this.translatePipe.transform('delete'),
			cancelBtn: this.translatePipe.transform('cancel')
		}).subscribe(confirm => {
			if (!confirm) {
				return;
			}

			this.workspaceService.deleteWorkspaceCover(this.workspace.companyId, this.workspace.id)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe({
					next: (result) => {
						this.workspace.coverUrl = null;
						this.coverUrl = '';
						this.workspaceService.removeActiveWorkspaceCover();
						EmitterService.get(WorkspaceCoverUpdatedEvent).emit(null);
					},
					error: (error) => {
						this.overlayService.showError(this.translatePipe.transform('workspace_edit_removeCoverFailed'), 'Error');
					}
				});
		});
	}
}
