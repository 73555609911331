<div class="d-flex align-items-center upload-popup-item-container">
  <img [src]="file.iconUrl" onError="this.src='assets/img/icons/file.svg'" type="image/svg+xml" width="28">
  <div class="d-flex flex-column align-items-start name-column">
    <div class="filename mr-auto flex-fill" fileNameDisplay [fileName]="file?.fileName" [title]="file?.fileName"
      (click)="goToSource($event)"></div>
    <div class="d-inline-block file-size">{{ file.fileSize | mtmFileSize }}</div>
  </div>
  <mtm-radial-transfer-bar [percentage]="file.progress" *ngIf="inProgressStatusList.includes(file.uploadStatusCode)" />
  <div class="d-flex align-items-center buttons">
    <button class="btn px-0 action-btn btn-error" *ngIf="file.uploadStatusCode === transferStatus.ERROR"
      (click)="handleUploadError($event)">
      <i class="fa fa-exclamation"></i>
    </button>
    <button class="btn px-0 action-btn btn-completed" *ngIf="file.uploadStatusCode === transferStatus.COMPLETED"
      (click)="removeFromList($event)">
      <i class="fa fa-check"></i>
    </button>
    <button class="btn p-0 action-btn btn-pause"
      *ngIf="file.isResumable() && file.uploadStatusCode === transferStatus.SENDING" (click)="pauseUpload($event)">
      <i class="fa fa-pause"></i>
    </button>
    <button class="btn p-0 action-btn btn-resume"
      *ngIf="file.isResumable() && resumableStatusList.includes(file.uploadStatusCode)"
            (click)="resumeUpload($event)">
      <i class="fa fa-play"></i>
    </button>
    <button class="btn p-0 action-btn btn-delete" *ngIf="file.uploadStatusCode !== transferStatus.COMPLETED"
      (click)="removeFromList($event)">
      <i class="fa fa-times"></i>
    </button>
    <button class="btn p-0 action-btn btn-find" *ngIf="file.uploadStatusCode === transferStatus.COMPLETED && hasPreviewUrl"
      (click)="goToSource($event)">
      <i class="fa fa-search"></i>
    </button>
  </div>
</div>
