import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// HttpModule,
import { RequestOptions, Http, XHRBackend, HttpModule } from "@angular/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from '@angular/router';
import { Ng5BreadcrumbModule } from 'ng5-breadcrumb';
import { StompService } from 'ng2-stomp-service';
import { MomentModule } from 'angular2-moment';
import { DragulaModule } from 'ng2-dragula';
import { DragScrollModule } from "ngx-drag-scroll";
// import { ClickOutsideModule } from 'ng-click-outside';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng5SliderModule } from 'ng5-slider';
import { AuthGuard } from "app/shared/guards/auth.guard";
import { NoAuthGuard } from "app/shared/guards/no-auth.guard";
import { ProjectManagerGuard } from "app/shared/guards/project-manager.guard";
import { MtmAdminGuard } from "app/shared/guards/mtm-admin.guard";
import { PaymentGuard } from "./shared/guards/payment.guard";
import { AppComponent } from "app/app.component";
import { MainLayoutComponent } from "app/layouts/main-layout.component";
import { Error404Component } from 'app/errors/404/error-404.component';
import { MenubarComponent } from "app/layouts/menubar/menubar.component";
import { CreativeIconComponent } from 'app/layouts/menubar/creative-icon/creative-icon.component';
import { CalendarIconComponent } from 'app/layouts/menubar/calendar-icon/calendar-icon.component';
import { AIReframingIconComponent } from 'app/layouts/menubar/ai-reframing-icon/ai-reframing-icon.component';
import { DataIconComponent } from 'app/layouts/menubar/data-icon/data-icon.component';
import { SearchIconComponent } from 'app/layouts/menubar/search-icon/search-icon.component';
import { VideoIconComponent } from 'app/layouts/menubar/video-icon/video-icon.component';
import { TeamFilesIconComponent } from "app/layouts/menubar/team-files-icon/team-files-icon.component";
import { EditProjectIconComponent } from "app/layouts/menubar/edit-project-icon/edit-project-icon.component";
import { FileUploadDropdown } from 'app/shared/components/filesupload';
import { PushNotificationComponent } from 'app/shared/components/push-notification/push-notification.component';
import { FileReorderModalComponent } from 'app/shared/components/file-reorder-modal/file-reorder-modal.component';
import { NotificationDropdown } from 'app/layouts/user-notifications/notification-dropdown/notification-dropdown.component';
import { NotificationModalComponent } from 'app/shared/components/notification-modal/notification-modal.component';
import { PopupMediaPlayerComponent } from 'app/shared/components/popup-media-player/popup-media-player.component';
import { TeamService } from "app/shared/services/team.service";
import { GuardProjectSubSectionEdit } from 'app/shared/guards/projectsubsection-edit.guard';
import { SimpleChatService } from 'app/shared/services/simple-chat.service';
import { CacheService } from 'app/shared/services/cache.service';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { AppRoutingModule } from "app/app.routes";
import { HttpInterceptor } from "app/shared/interceptors/http.interceptor";
import { TranslatePipe } from "./shared/pipes/translate.pipe";
import { SuccessComponent } from "./success/success.component";
import { FailureComponent } from "./errors/failure/failure.component";
import { MtmDatePipe } from "./shared/pipes/mtm-date.pipe";
import { ActivityLogButton } from "./layouts/activity-log/activity-log-button/activity-log-button.component";
import { MTMFileDownloadService } from "./shared/components/mtm-file-download/mtm-file-download.service";
import { ProjectSharedModule } from "./sub-modules/project-modules/project-SharedModule";
import { MtmChatModule } from "./sub-modules/mtm-chat-module/mtm-chat.module";
import { CurrencyService } from "./shared/services/currency.service";
import { UnseenNotifierService } from "./shared/services/unseen-notifier.service";
import { SubscriptionModule } from "./subscriptions/subscription.module";
import { SignupModule } from "./signup/signup.module";
import { VerificationModule } from "./verification/verification.module";
import { WorkspaceModule } from "./workspace/workspace.module";
import { ProjectCreationGuard } from "./shared/guards/project-creation.guard";
import { WorkspaceService } from "./shared/services/workspace.service";
import { PaymentMethodService } from "./shared/services/payment-method.service";
import { SubscriptionCheckService } from "./shared/services/subscription-check.service";
import { ClickableNotificationService } from "./shared/services/clickable-notification.service";
import { MtmCurrencyPipe } from "./shared/pipes/mtm-currency.pipe";
import { GoogleService } from "./shared/services/google.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { EmitterService } from "./shared/services/emitter.service";
import { AdminService } from "./admin/services/admin.service";
import { ApiService } from "./shared/services/api.service";
import { AudioService } from "./shared/services/audio.service";
import { AuthService } from "./shared/services/auth.service";
import { BroadcastService } from "./shared/services/broadcast.service";
import { CommonService } from "./shared/services/common.service";
import { CompanyService } from "./shared/services/company.service";
import { ConversationService } from "./shared/services/conversation.service";
import { DataTransferBetweenComponentsService } from "./shared/services/data-transfer-between-components.service";
import { DateHelperService } from "./shared/services/date-helper.service";
import { DesktopNotificationService } from "./shared/services/desktop-notification.service";
import { EventService } from "./shared/services/event.service";
import { FileReorderService } from "./shared/services/files-reorder.service";
import { GoogleAnalyticsEventsService } from "./shared/services/google-analytics-events.service";
import { HelperService } from "./shared/services/helper.service";
import { JitsiService } from "./shared/services/jitsi.service";
import { MtmWebSocketService } from "./shared/services/mtm-websocket.service";
import { NotificationService } from "./shared/services/notification.service";
import { OverlayService } from "./shared/services/overlayService";
import { PaymentCheckService } from "./shared/services/payment-check.service";
import { PaymentService } from "./shared/services/payment.service";
import { PermissionService } from "./shared/services/permissions.service";
import { ProjectService } from "./shared/services/project.service";
import { ProposalInvitationService } from "./shared/services/proposal-invitation.service";
import { ProposalCommentService } from "./shared/services/proposal-services/comment.service";
import { ProposalFileService } from "./shared/services/proposal-services/proposalfile.service";
import { RegistrationService } from "./shared/services/registration.service";
import { SURLFileUploadService, SURLFUManagerService } from "./shared/services/signed-url";
import { SignedUrlService } from "./shared/services/signedurl.service";
import { UploadService } from "./shared/services/upload.service";
import { UserService } from "./shared/services/user.service";
import { ValidatorService } from "./shared/services/validator.service";
import { MtmTimePipe } from "./shared/pipes/mtm-time.pipe";
import { projectFilterPipe } from "./shared/pipes/project-filter.pipe";
import { archivedProjectFilterPipe } from "./shared/pipes/archived-project-filter.pipe";
import { SubsectionService } from "./shared/services/subsection.service";
import { SubtitleCreatorComponent } from "app/layouts/post-prod/subtitles/subtitle-uploader-container/subtitle-creator/subtitle-creator.component";
import { SubtitleUploaderContainerComponent } from "app/layouts/post-prod/subtitles/subtitle-uploader-container/subtitle-uploader-container.component";
import { SubtitleImportComponent } from "app/layouts/post-prod/subtitles/subtitle-uploader-container/subtitle-creator/subtitle-import.component";
import { FileModalComponent } from "./shared/components/hierarchical-files/file-modal/file-modal.component";
import { HttpCancelInterceptor } from "./shared/interceptors/cancel-interceptor";
import { HttpCancelService } from "./shared/services/http-cancel.service";
import { ColorPickerModule } from "ngx-color-picker";
import { FilePreviewModalComponent } from "./shared/components/hierarchical-files/file-preview-modal/file-preview-modal.component";
import { AgGridModule } from 'ag-grid-angular';
import { TopNavComponent } from "./layouts/top-nav/top-nav.component";
import { RealTimeIconComponent } from "./layouts/menubar/real-time-icon/real-time-icon.component";
import { TasksIconComponent } from "./layouts/menubar/tasks-icon/tasks-icon.component";
import { ReviewLinkIconComponent } from "./layouts/menubar/review-link-icon/review-link-icon.component";
import { SubtitlesIconComponent } from "./layouts/menubar/subtitles-icon/subtitles-icon.component";
import { QuillModule } from 'ngx-quill';
import { WorkspaceV2Module } from './workspace-v2/workspace-v2.module';
import { MTMToasterComponent } from './shared/components/mtm-toaster/mtm-toaster.component';
import { CommentFileUploaderContainerComponent } from "./shared/components/comment/comment-file-uploader-container/comment-file-uploader-container.component";
import { CommentFileTranscriptionGenerator } from "./shared/components/comment/comment-file-uploader-container/comment-file-generator.component";
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { AiFramingFolderBuilderContainerComponent } from "./layouts/ai-reframing/components/shared/entity-builder/folder/ai-framing-folder-builder.component";
import { AiFramingMovieBuilderComponent } from "./layouts/ai-reframing/components/shared/entity-builder/movie/ai-framing-movie-builder.component";
import { AiFramingFolderBuilderComponent } from "./layouts/ai-reframing/components/shared/entity-builder/folder/ai-framing-folder-builder-container.component";
import { AiFramingMovieBuilderContainerComponent } from "./layouts/ai-reframing/components/shared/entity-builder/movie/ai-framing-movie-builder-container.component";
import { MtmFileSizePipe } from "./shared/pipes/mtm-file-size.pipe";
import { UseronboardingtoolModule } from 'app/useronboardingtool/useronboardingtool.module';
import { UsertourprogressComponent } from "./useronboardingtool/usertourprogress/usertourprogress.component";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { WarningInactiveModalComponent } from './shared/components/warning-inactive-modal/warning-inactive-modal.component';
import { WarningLoggedoutModalComponent } from './shared/components/warning-loggedout-modal/warning-loggedout-modal.component';
import { ComponentStateService } from './shared/services/component-state.service';
import { LeftSidebarComponent } from "./layouts/left-sidebar/left-sidebar.component";
import { NgOptimizedImage } from "@angular/common";
import { LorealLayoutComponent } from "./layouts/loreal-layout/loreal-layout.component";
import { StandardLayoutComponent } from "./layouts/standard-layout/standard-layout.component";
// import { SelfTrainerModalComponent } from './shared/components/self-trainer-modal/self-trainer-modal.component';

export function authHttpServiceFactory(backend: XHRBackend, defaultOptions: RequestOptions) {
  return new HttpInterceptor(backend, defaultOptions);
}

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    SuccessComponent,
    FailureComponent,
    MainLayoutComponent,
    LorealLayoutComponent,
    StandardLayoutComponent,
    TopNavComponent,
    NotificationModalComponent,
    PopupMediaPlayerComponent,
    FileUploadDropdown,
    NotificationDropdown,
    MenubarComponent,
    LeftSidebarComponent,
    SearchIconComponent,
    TeamFilesIconComponent,
    CreativeIconComponent,
    CalendarIconComponent,
    DataIconComponent,
    VideoIconComponent,
    RealTimeIconComponent,
    SubtitlesIconComponent,
    EditProjectIconComponent,
    TasksIconComponent,
    ReviewLinkIconComponent,
    PushNotificationComponent,
    FileReorderModalComponent,
    ActivityLogButton,
    SubtitleUploaderContainerComponent,
    SubtitleCreatorComponent,
    SubtitleImportComponent,
    FileModalComponent,
    FilePreviewModalComponent,
    MTMToasterComponent,
    CommentFileUploaderContainerComponent,
    CommentFileTranscriptionGenerator,
    AIReframingIconComponent,
    AiFramingFolderBuilderComponent,
    AiFramingFolderBuilderContainerComponent,
    AiFramingMovieBuilderContainerComponent,
    AiFramingMovieBuilderComponent,
    UsertourprogressComponent,
    WarningInactiveModalComponent,
    WarningLoggedoutModalComponent,
    // SelfTrainerModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    Ng5BreadcrumbModule.forRoot(),
    TimepickerModule,
    DragulaModule,
    MomentModule,
    DragScrollModule,
    NgClickOutsideDirective,
    Ng5SliderModule,
    ProjectSharedModule,
    SharedModule,
    AppRoutingModule,
    MtmChatModule,
    HttpClientModule,
    SharedModule,
    SubscriptionModule,
    SignupModule,
    VerificationModule,
    WorkspaceModule,
    ColorPickerModule,
    AgGridModule,
    QuillModule.forRoot(),
    WorkspaceV2Module,
    UseronboardingtoolModule,
    NgIdleKeepaliveModule.forRoot(),
    NgOptimizedImage
  ],
  providers: [
    StompService,
    EmitterService,
    ApiService,
    AuthService,
    ProjectService,
    PermissionService,
    UserService,
    CommonService,
    OverlayService,
    SURLFileUploadService,
    SURLFUManagerService,
    SignedUrlService,
    MtmWebSocketService,
    AdminService,
    GoogleService,
    JitsiService,
    UploadService,
    CompanyService,
    CacheService,
    AudioService,
    NotificationService,
    UnseenNotifierService,
    ConversationService,
    HelperService,
    DateHelperService,
    SimpleChatService,
    RegistrationService,
    ProposalInvitationService,
    BroadcastService,
    DesktopNotificationService,
    GoogleAnalyticsEventsService,
    DataTransferBetweenComponentsService,
    FileReorderService,
    PaymentService,
    PaymentMethodService,
    PaymentCheckService,
    ProposalFileService,
    ProposalCommentService,
    TeamService,
    ValidatorService,
    EventService,
    NoAuthGuard,
    AuthGuard,
    PaymentGuard,
    MtmAdminGuard,
    ProjectCreationGuard,
    GuardProjectSubSectionEdit,
    ProjectManagerGuard,
    TranslatePipe,
    MTMFileDownloadService,
    SubsectionService,
    WorkspaceService,
    HttpCancelService,
    {
      provide: Http,
      useFactory: authHttpServiceFactory,
      deps: [XHRBackend, RequestOptions]
    },
    MtmDatePipe,
    CurrencyService,
    SubscriptionCheckService,
    ClickableNotificationService,
    MtmCurrencyPipe,
    MtmTimePipe,
    projectFilterPipe,
    MtmFileSizePipe,
    archivedProjectFilterPipe,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor
    },
    ComponentStateService
  ],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
