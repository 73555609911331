import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AuthService } from "app/shared/services/auth.service";
import { HeaderInfo, ProfileSettings, ThemeOptions, User } from "app/shared/interfaces";
import { KanbanProjectService } from "../../kanban/project/state/project/project.service";


@Component({
  selector: 'mtm-loreal-layout',
  templateUrl: './loreal-layout.component.html',
  styleUrls: ['./loreal-layout.component.scss']
})
export class LorealLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('rootLayoutContainer', { static: true }) mainLayoutContainer: ElementRef;
  private authService = inject(AuthService);
  private kanbanProjectService = inject(KanbanProjectService);
  authUser: User;
  isPublicPage: boolean = false;
  isKanban: boolean = false;

  @Input() themeOptions: ThemeOptions;
  @Input() headerInfo: HeaderInfo;
  @Input() userProfile: ProfileSettings;
  @Output() projectNavigationClick: EventEmitter<any> = new EventEmitter<any>();


  get headerBgImage(): string { return this.themeOptions?.backgroundImage ?? ''; }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();

    this.kanbanProjectService.isKanban.subscribe(data => {
      this.isKanban = data;
    });
  }

  // goToProject() {
  //   this.projectNavigationClick.emit();
  // }

  ngOnDestroy() {
  }
}
