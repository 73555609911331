import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
  selector: 'mtm-radial-transfer-bar',
  templateUrl: './radial-transfer-bar.component.html',
  styleUrls: ['./radial-transfer-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadialTransferBarComponent {
  @Input() radius: number = 10;
  @Input() strokeWidth: number = 4;
  @Input() bgColor: string = '#CFD7EE';
  @Input() percentageColor: string = '#43CB9A';
  _percentage: number = 0;
  strokeDasharray: string = 'none';
  strokeDashoffset: number = 0;

  get percentage(): number {
    return this._percentage;
  }

  get position(): number {
    return this.radius + this.strokeWidth;
  }

  get diameter(): number {
    return this.position * 2;
  }

  @Input()
  set percentage(value: number) {
    this._percentage = value;
    this.calculateDashOffset()
  }

  private calculateDashOffset(){
    const circumference = 2 * Math.PI * this.radius;
    const offset = circumference - (this._percentage / 100) * circumference;
    this.strokeDasharray = `${circumference} ${circumference}`;
    this.strokeDashoffset = offset;
  }
}
