import { HelperService } from "app/shared/services/helper.service";
import { ImageComp } from "../lib/image-component";
import { Annotation } from "./annotation";
import { SelectableShape } from "./selectableShape";

export class Controls extends ImageComp {

    uiState: any;
    selectableShape: SelectableShape;

    constructor(image: Object) {
        super(image);
        this.initAPI(this, 'Controls');

        this.uiState = { adding: false, editing: false }

        this.selectableShape = new SelectableShape(image);
    }

    startAddNew() {
        if (!this.plugin.active) this.plugin.toggleAnnotationMode();
        if (this.uiState.adding || this.uiState.editing) return;
        const _this2 = this;
        this.uiState.adding = true;

        this.plugin.annotationState.clickOutAnnotation();

        this.plugin.annotationState.disableAnnotationHover();
        this.plugin.annotationState.disableAnnotationClick();

        // Renabling image move
        this.plugin.enableImageMove();

        this.selectableShape.initUndoRedo(true);

        this.plugin.primordial.annotatingMode = true;
        this.plugin.fire('enteredAddingAnnotation');
    }

    cancelAddNew(isGeneralComment=false) {
        if (!this.uiState.adding && !this.uiState.editing && !isGeneralComment) return;
        let _this2 = this;
        // this.plugin.colourPicker.set("#A3F307");
        this.plugin.firstTouch = false;
        this.uiState.adding = false;
        this.uiState.editing = false;
        const canvasFabric = this.plugin.canvasFabric;

        this.plugin.annotationState.closeAllAnnotations();
        canvasFabric.clear();
        const imageURL = HelperService.isConvertibleImageFormat(this.plugin.options.item) ? this.plugin.options.item.signedURL + '?thumbnail=_IMG'
			: this.plugin.options.item.item.signedURL;
        canvasFabric.setBackgroundImage(imageURL, img => {
            canvasFabric.renderAll();
            _this2.plugin.centerImage();
        }, {
            id: "background",
            excludeFromExport: true,
            selectable: false,
            hoverCursor: 'cursor'
        });
        canvasFabric.viewportTransform = this.plugin.viewportTransform;
        canvasFabric.off('mouse:down');
        canvasFabric.off('mouse:up');
        canvasFabric.off('mouse:move');
        canvasFabric.isDrawingMode = false;

        $(document).off('keydown');
        $(document).off('keyup');

        // redrawing all annotations
        this.plugin.annotationState.openAllAnnotations();

        this.plugin.annotationState.enableAnnotationHover();
        this.plugin.annotationState.enableAnnotationClick();

        // Renabling image move
        this.plugin.enableImageMove();

        this.plugin.primordial.annotatingMode = false;
        this.plugin.primordial.editingMode = false;
        this.plugin.primordial.editingAnnotation = null;
        this.plugin.fire('exitingAddingAnnotation');
    }

    saveNew() {
        const resolution = {
            height: this.plugin.canvasFabric.getHeight(),
            width: this.plugin.canvasFabric.getWidth()
        }

        let shape = JSON.parse(JSON.stringify(this.plugin.canvasFabric))
        shape["viewportTransform"] = this.plugin.canvasFabric.viewportTransform

        /*
        const annotation = Annotation.newFromData(
            JSON.stringify(shape),
            this.plugin.primordial.annotationComment,
            null,
            resolution,
            this.plugin,
          null,
          null,
        );
         */
        const annotation = Annotation.createNew({
            shape: JSON.stringify(shape),
            comment: this.plugin.primordial.annotationComment,
            resolution: resolution,
            plugin: this.plugin,
            parentId: null,
            privateConditionKey: this.plugin.primordial.privateCondition
        })
        this.plugin.annotationState.addNewAnnotation(annotation);
        this.cancelAddNew();
    }

    saveNewGeneralComment() {
        const resolution = {
            height: null,
            width: null
        }
        const range = {
            start: null,
            end: null,
            isGeneralComment: true
        }
        let shape = JSON.parse(JSON.stringify(this.plugin.canvasFabric))


        const annotation = Annotation.createNew({
            shape: JSON.stringify(shape),
            comment: this.plugin.primordial.annotationComment,
            resolution: resolution,
            plugin: this.plugin,
            parentId: null,
            privateConditionKey: this.plugin.primordial.privateCondition,
            range
        });
        this.plugin.annotationState.addNewAnnotation(annotation);
        this.cancelAddNew(true);
    }

    editingStart(annotation) {
        this.uiState.editing = true;

        this.plugin.annotationState.clickOutAnnotation();

        this.plugin.annotationState.disableAnnotationHover();
        this.plugin.annotationState.disableAnnotationClick();

        // Renabling image move
        this.plugin.enableImageMove();

        let body = annotation.commentList.comments[0].body;
        this.plugin.primordial.annotationComment = body;
        //$("#annotCommentArea").text(body);
        annotation.openAnnotationEdit();

        this.selectableShape.initUndoRedo(true);

        this.plugin.primordial.editingMode = true;
        this.plugin.primordial.editingAnnotation = annotation;

    }

    teardown() {
        this.cancelAddNew();
        super.teardown();

        return null;
    }
}
