import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectRouteGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const hasLtgPermission = localStorage.getItem('hasLtgPermission') == 'true';
        if (hasLtgPermission) {
            this.router.navigate(['/workspaces']);
        }
        return !hasLtgPermission;
    }

}