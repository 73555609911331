<img src="{{finalImageUrl}}" [ngStyle]="{'border-radius': borderRadius, 'object-fit': objectFit}" *ngIf="finalImageUrl"
[alt]="name" [attr.loading]="imageLoading" class="mtm-image-el" [ngClass]="cssClass" />

<div class="mtm-image-initial-container" [ngClass]="cssClass" *ngIf="!imageUrl && !generatedTextColor"
     [ngStyle]="{'border-radius': borderRadius, 'background-color': initialColor}">
		<span class="initial" >
			{{initialName}}
		</span>
</div>

<div class="mtm-image-initial-container" [ngClass]="cssClass" *ngIf="!imageUrl && generatedTextColor"
     [ngStyle]="{'border-radius': borderRadius, 'color': initialColor, 'background-color': generatedBackground}"
     >
		<span class="initial">
			{{initialName}}
		</span>
</div>
