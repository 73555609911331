import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export interface MtmBreadcrumbLink {
    label: string;
    url?: string[];
    queryParams?: any;
}

export interface MtmBreadcrumbLinkWithCurrentUrl extends MtmBreadcrumbLink {
    isCurrentUrl: boolean;  
}

@Injectable({
    providedIn: 'root'
})
export class MtmBreadcrumbService {

    private linkSubject: BehaviorSubject<MtmBreadcrumbLink[]> = new BehaviorSubject<MtmBreadcrumbLink[]>([]);
    $linkSubject: Observable<MtmBreadcrumbLink[]> = this.linkSubject.asObservable();
    
    constructor() { }

    setLinks(links: MtmBreadcrumbLink[]) {
        this.linkSubject.next(links);
    }

    clearLinks() {
        this.linkSubject.next([]);
    }
}