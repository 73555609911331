import { Component, OnInit, inject } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";
import { EditWorkspacePermissionsComponent } from "../../../workspace-permissions.component";
import { NotificationService } from "app/shared/services/notification.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";

@Component({
    selector: 'action-cell',
    templateUrl: './action-cell.component.html',
    styleUrls: ['./action-cell.component.scss']
})
export class ActionCellComponent implements ICellRendererAngularComp, OnInit {
    params: any;
    private notificationService = inject(NotificationService);
    private translatePipe = inject(TranslatePipe);

    constructor() { }

    ngOnInit(): void {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }

    removeUserFromWorkspace(){
        let displayName = this.params.data.fullName ? `${this.params.data.fullName} (${this.params.data.username})`  : this.params.data.username;
        this.notificationService.open({
			title: this.translatePipe.transform('workspace_removeUser'),
			description: this.translatePipe.transform(`workspace_removeUser_confirm`, {name: displayName}),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).subscribe((confirm: boolean) => {
			if (!confirm)
				return;
			this.params.context.componentParent.removeUser(this.params.data);
		});
    }
}

