import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import * as moment from 'moment';
import 'moment-timezone';
import { DragulaService } from "ng2-dragula";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EditSectionComponent } from "app/layouts/my-projects/new-project/project-stages/edit-section/edit-section.component";
import { HelperService } from "app/shared/services/helper.service";
import { AuthService } from 'app/shared/services/auth.service';
import { Authority, PermissionService } from "../../../../shared/services/permissions.service";
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectStatusList } from 'app/shared/interfaces';
import { OverlayService } from 'app/shared/services/overlayService';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { NotificationService } from 'app/shared/services/notification.service';

enum sortedValue {
	AtoZ,
	ZtoA,
	StartDate,
	EndDate
}

@Component({
	selector: 'mtm-project-stages',
	templateUrl: './project-stages.component.html',
	styleUrls: ['./project-stages.component.scss']
})
export class ProjectStagesComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('scrollLeft', { static: false }) scrollLeft: ElementRef;
	@ViewChild('scrollRight', { static: false }) scrollRight: ElementRef;
	@ViewChild('scrollElement', { static: false }) scrollElement: ElementRef;

	@Input() isProposal: boolean = false;
	startDate: Date;
	endDate: Date;
	sectionSelected: any;
	sectionToLoad: string = '';
	position: number;
	subsections: any[] = [];
	draggables: any[] = [];
	firstTimeOpen: boolean = true;
	dragDrop: any;
	project;
	ngUnsubscribe = new Subject();
	private barClarity: boolean[];
	sortingValue = sortedValue;
	selectedSubsection = 'Sort Subsection';
	subSectionChanged: boolean = false;
	subs = new Subscription();
	currentSection: Subject<string> = new Subject<string>();

	scrollInterval: any;
	lastScrollTime: number = 0;
	animationFrameRequest: any;
	isLeftArrowVisible: boolean = false;
	isRightArrowVisible: boolean = false;

	constructor(public service: ProjectService,
		private router: Router,
		private dragulaService: DragulaService,
		private modalService: NgbModal,
		private serviceAuth: AuthService,
		public permissionService: PermissionService,
		private overlayService: OverlayService,
		private notificationService: NotificationService,
		private translatePipe: TranslatePipe) {

		dragulaService.createGroup('subsections', {
			moves: (el: any, container: any, handle: any) => {

				if (el.className.indexOf('drop-here') !== -1) {
					return false;
				}

				if (HelperService.isUUID(el.id)) {
					let subsection = this.subsections.filter(ss => ss.id === el.id).pop();
					return this.isDynamic(subsection);
				}

				return true;
			},
			accepts: (el, target, source, sibling) => {
				return !target.classList.contains('stages-draggables');
			}
		});

		this.subs.add(this.dragulaService.drag("subsections")
			.subscribe((ars) => {
				console.log(ars);
			})
		);

		this.subs.add(dragulaService.dropModel('subsections').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((value) => {
			if (value.target.classList.contains('stages-created')) {
				let find = this.draggables.filter((d) => {
					if (d !== undefined)
						return value.el.id === d.subSection;
					return false;
				}).pop();
				if (find && !find.dates) {
					setTimeout(this.updateSection.bind(this), 100);
				}

				if (find && find.dates) {
					setTimeout(() => {
						let m = this.service.subsections.findIndex(k => !HelperService.isUUID(k.id));
						find.orderNumber = m + 101;
						this.openEditModal(find, {
							sourceModel: value.sourceModel,
							targetModel: value.targetModel,
							item: value.item,
							source: value.source,
							el: value.el
						});
					}, 750);
				}
			}
		}));

		this.barClarity = this.service.sections.map(i => true);
	}

	ngAfterViewInit(): void {
		this.checkScrollOffset();
	}

	private saveOrUpdate(o, projectId: string, sectionId: string) {

		this.service.updateOrderNumber(o, projectId, sectionId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data: any) => {
				console.log(data);
			},
			(err: any) => {
				console.log(err);
			}
		);
	}

	ngOnInit(): void {
		this.checkCompanyUser();


		if (this.service.projectOnloaded) {
			this.project = this.service.project;
			this.setBarClarity();
			this.initData();
		} else {
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((p) => {
				this.project = p;
				this.setBarClarity();
				this.initData();
			});
		}
	}

	/**
	 * Check if a subsection is dynamic or static
	 *
	 * @param subsection
	 */
	isDynamic(subsection: any): boolean {
		let find: any;
		if (subsection) {
			find = this.service.subsections.filter(ss => ss.subSection === subsection.subSection).pop();
		} else {
			return false;
		}
		if (find)
			return find.dynamic;
		return true;
	}

	isDeletableSubsection(subsection) {
		if (subsection.section == 'SHOOTING') {
			return false;
		}

		const existingSubsection = this.service.subsections.find(ss => ss.subSection === subsection.subSection);
		if (!existingSubsection) return true;

		const isDynamic = existingSubsection.dynamic;

		if (!existingSubsection.dynamic) {
			return false;
		}

		return true;
	}

	initData() {
		// Check if user has PM access
		if (!this.permissionService.hasAuthority(Authority.U, this.service.project.id)) {
			this.router.navigate(['projects', this.service.project.id]);
			return;
		}

		if (this.service.project.id && this.service.subsections.length) {
			if (this.sectionToLoad) {
				this.loadSpecificSection();
			} else {
				this.initSwitchSelectSection();
			}

		} else {
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((s) => {
				if (this.firstTimeOpen) {
					this.firstTimeOpen = false;
					if (this.sectionToLoad) {
						this.loadSpecificSection();
					} else {
						this.initSwitchSelectSection();
					}
				}
			});
		}
	}

	private initSwitchSelectSection() {
		let position = Math.min(this.service.sections.length - 1, 3);
		this.selectSection(this.service.sections[position], position);
	}

	switchSection(section: any, position: number) {
		if (this.sectionSelected == section) {
			return;
		}

		this.currentSection.next(section.section);
		this.selectSection(section, position);
	}

	private loadSpecificSection() {
		const index = this.service.sections.findIndex(section => section.section == this.sectionToLoad);
		if (index == -1) {
			this.initSwitchSelectSection();
			return;
		}
		const section = this.service.sections[index];
		this.selectSection(section, index);
	}

	private setBarClarity() {
		this.barClarity = [];

		if (this.permissionService.hasAuthority(Authority.Z, null)) {
			this.barClarity = this.service.sections.map(i => true);
			return;
		}

		const ppsSections = ['PRODUCTION_PARTNER_SELECTION', 'PRODUCTION_BRIEF', 'PRODUCTION_COMPANY_PROPOSALS'];
		for (let i = 0; i < this.service.sections.length; i++) {
			const section = this.service.sections[i];
			this.barClarity.push(ppsSections.indexOf(section.section) == -1);
		}
	}


	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.dragulaService.destroy('subsections');
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	/**
	   * Open new entry modal
	   */
	private openEditModal(subsection: any, params: any = null): NgbModalRef {
		this.sectionToLoad = this.sectionSelected.section;
		let modal = this.modalService.open(EditSectionComponent, { size: 'lg', backdrop: 'static', modalDialogClass: 'responsive-modal', keyboard: false });
		let editComponent = modal.componentInstance
		editComponent.section = this.sectionSelected;
		editComponent.subSection = subsection;
		modal.result.then((sub) => {
			if (sub) {
				this.reloadSections();
			} else {
				if (params) {
					const { item, targetModel, sourceModel, el, source } = params;
					source.appendChild(el);
					const index = this.subsections.findIndex(s => s.subSection == item.subSection);
					if (index > -1) {
						this.subsections.splice(index, 1);
					}
				}
			}

		}, (v) => {
			this.loadSubsections()
		});

		return modal;
	}

	/**
	 * Select Section
	 *
	 * @param section
	 * @param position
	 */
	private selectSection(section: any, position): void {
		if (!this.project) return;
		if (this.project.projectType == 'ONE_SHOT') {
			if (section.section == 'CREATIVE_PARTNER_SELECTION' || section.section == 'CREATIVE_BRIEF' || section.section == 'CREATIVE_WORK' || section.section == 'SELF_FIX_EDIT'
				|| (section.section == 'PRE_PRODUCTION' && this.project.paymentStatus == 'MUST_PAY')
				|| (section.section == 'SHOOTING' && this.project.paymentStatus == 'MUST_PAY')
				|| (section.section == 'POST_PRODUCTION' && this.project.paymentStatus == 'MUST_PAY'))
				return;
		} else if (this.project.projectType == 'FREE') {
			if (section.section == 'PRODUCTION_PARTNER_SELECTION' || section.section == 'PRODUCTION_BRIEF' ||
				section.section == 'PRODUCTION_COMPANY_PROPOSALS' || section.section == 'CREATIVE_PARTNER_SELECTION' ||
				section.section == 'CREATIVE_BRIEF' ||
				section.section == 'CREATIVE_WORK' || section.section == 'SELF_FIX_EDIT')
				return;
		}
		else {
			if (section.section == 'CREATIVE_PARTNER_SELECTION' || section.section == 'CREATIVE_BRIEF' || section.section == 'CREATIVE_WORK' || section.section == 'SELF_FIX_EDIT')
				return;
		}
		this.position = position;
		this.sectionSelected = section;
		this.loadSubsections();
		this.selectedSubsection = 'Sort Subsection';
	}

	edit(subsection: any): void {
		if (subsection.id)
			this.openEditModal(subsection);
	}

	/**
	 * Load Sub-sections
	 */
	private loadSubsections(subsection?): void {

		this.subsections = []
		this.draggables = []
		if (this.sectionSelected.id) {
			this.service.getSubsections(this.sectionSelected.id).pipe(
				takeUntil(this.ngUnsubscribe),
				takeUntil(this.currentSection)
			).subscribe((res) => {
				this.subsections = res;
				this.subsections.sort((a, b) => a.orderNumber - b.orderNumber);
				this.loadDraggables(this.sectionSelected.section);
			});
		} else if (subsection) {
			this.sectionSelected.id = subsection.projectSectionId;
			this.subsections = [subsection];
			this.loadDraggables(this.sectionSelected.section);
		} else {
			this.subsections = [];
			this.loadDraggables(this.sectionSelected.section);
		}
	}

	/**
	 * Create a Sub-section
	 *
	 * @param subsection
	 */
	private addSubsection(subsection): void {
		this.service.createSubsection(subsection.projectSectionId, subsection).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.subsections = this.subsections.map((sub) => {
				if (sub.subSection === res.subSection)
					return res;
				return sub;
			});
		});
	}

	/**
	 * Check if given subsection has date
	 *
	 * @param subsection
	 */
	private hasDate(subsection): boolean {
		return subsection.startDate && subsection.endDate;
	}

	/**
	 * Print subsection dates
	 *
	 * @param subsection
	 */
	/*
	private showDates(subsection): string {
	  if (this.hasDate(subsection))
		return this.formatDate(subsection.startDate) + '-' + this.formatDate(subsection.endDate);
	}
	*/

	/**
	 * Load draggable sub-sections
	 */
	private loadDraggables(section): void {
		if (this.service.subsections.length && this.service.subsections.filter((sub) => sub.section === section).length) {
			this.draggables = this.service.subsections.filter((sub) => {
				let find = this.subsections.filter((s) => {
					return s.subSection === sub.subSection;
				}).pop();
				return sub.section === section && !find;
			});
		}
	}

	/**
	 * Retrieve start & end dates from a list of subsections
	 *
	 * @param subsections
	 */
	private getStartEndDates(subsections: any[]): any {

		let _length = subsections.length;
		let start_date: Date = new Date();
		let end_date: Date;

		for (let i = 0; i < _length; i++) {
			if (start_date > subsections[i].startDate)
				start_date = subsections[i].startDate;

			if (end_date < subsections[i].endDate)
				end_date = subsections[i].endDate;
		}

		return {
			startDate: start_date,
			endDate: end_date
		};
	}

	/**
	 * Update / Create Section
	 */
	private updateSection(): void {
		let section = this.sectionSelected;
		if (!section.id) {
			let dates = this.getStartEndDates(this.subsections);
			section.startDate = dates.startDate;
			section.endDate = dates.endDate;
			if (!section.status) {
				section.status = ProjectStatusList.TO_COME;
			}
			this.service.createSection(section).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((res) => {
				this.sectionSelected = res;
				this.updateSubSections(res);
			});
		} else {
			this.updateSubSections(section);
		}
	}

	/**
	 * Update / Create sub-sections
	 */
	private updateSubSections(section: any): void {
		let _length = this.subsections.length;
		for (let i = 0; i < _length; i++) {
			this.subsections[i].projectSectionId = section.id;
			this.addSubsection(this.subsections[i]);
		}
	}

	continue() {
		if (this.isProposal)
			this.router.navigate(['projects', this.service.project.id, 'dashboard']);
		else
			this.router.navigate(['projects', 'edit', this.service.project.id, 'users-permissions']);
	}

	/**
	 * Delete sub-section
	 */
	delete(subsectionId: string): void {
		this.service.deleteSubsection(this.sectionSelected.id, subsectionId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (res) => {
				this.sectionToLoad = this.sectionSelected.section;
				this.reloadSections();
			},
			error: (err) => {
				this.overlayService.showError(this.translatePipe.transform('project_stages_deleteSubsectionFailed'));
			}
		});
	}

	promptDelete(subSection: any): void {
		this.notificationService.open({
			title: this.translatePipe.transform('project_stages_deleteSubsection'),
			description: this.translatePipe.transform('project_stages_confirmDeleteSubsection', { name: subSection.name }),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (confirm) => {
				if (!confirm) {
					return;

				}
				this.delete(subSection.id);
			}

		});
	}

	private reloadSections() {
		let startDate = new Date(this.service.project.startDate);
		let endDate = new Date(this.service.project.plannedEndDate);
		this.service.loadSections(startDate, endDate).then(v => {
			if (v)
				this.loadSubsections();
			this.service.loadSectionsAndSubsections(startDate, endDate)
		});
	}

	private returnToProject() {
		this.router.navigate(['projects', this.service.project.id]);
	}

	private checkCompanyUser() {
		let user = this.serviceAuth.getAuthUser();
		if (!user || typeof user != 'object')
			this.returnToProject();
	}

	sortSubSection(event) {
		this.selectedSubsection = event;
		if (event == this.sortingValue[0]) {
			this.subsections.sort((a, b) => (a.name > b.name ? 1 : -1))
		}
		if (event == this.sortingValue[1]) {
			this.subsections.sort((a, b) => (a.name > b.name ? -1 : 1))
		}
		if (event == this.sortingValue[2]) {
			this.subsections.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
		}
		if (event == this.sortingValue[3]) {
			this.subsections.sort((a, b) => (a.endDate > b.endDate ? 1 : -1))
		}

		this.subsections.forEach((sub, index) => {
			sub.orderNumber = index + 101;
		});

		if (this.subsections.length > 0) {
			const sectionId = this.subsections[0].projectSectionId;
			this.service.updateSubsectionsOrder(this.service.project.id, sectionId, this.subsections)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe({
					next: (res) => {

					},
					error: (err) => {
						console.log(err);
						this.overlayService.showError(this.translatePipe.transform('projectStages_failedToSaveOrderChanges'), 'Error');
					}
				});
		}

	}

	addSubSection(subSection) {
		let idx = this.service.subsections.findIndex(k => !HelperService.isUUID(subSection.id));
		subSection.orderNumber = idx + 101;
		this.openEditModal(subSection);
	}

	decrementScroll() {
		this.scrollInterval = 1;
		window.requestAnimationFrame(time => this.scrollToLeft(time));
	}

	incrementScroll() {
		this.scrollInterval = 1;
		window.requestAnimationFrame(time => this.scrollToRight(time));
	}

	private scrollToRight(time: number) {
		if (!this.scrollInterval) {
			return;
		}

		if (this.lastScrollTime) {
			const delta = time - this.lastScrollTime;
			this.scrollElement.nativeElement.scrollLeft += Math.floor(delta / 2);
			this.checkScrollOffset();
		}

		this.lastScrollTime = time;
		this.animationFrameRequest = window.requestAnimationFrame(time => this.scrollToRight(time));
		if (!this.isRightArrowVisible && this.scrollInterval) {
			this.stopScroll();
		}
	}

	private scrollToLeft(time: number) {
		if (!this.scrollInterval) {
			return;
		}

		if (this.lastScrollTime) {
			const delta = time - this.lastScrollTime;
			this.scrollElement.nativeElement.scrollLeft -= Math.floor(delta / 2);
			this.checkScrollOffset();
		}

		this.lastScrollTime = time;
		this.animationFrameRequest = window.requestAnimationFrame(time => this.scrollToLeft(time));
		if (!this.isLeftArrowVisible && this.scrollInterval) {
			this.stopScroll();
		}
	}

	stopScroll() {
		this.scrollInterval = 0;
		this.lastScrollTime = 0;
		window.cancelAnimationFrame(this.animationFrameRequest);
	}

	checkScrollOffset() {
		if (!this.scrollElement?.nativeElement) {
			return;
		}
		this.isLeftArrowVisible = this.scrollElement.nativeElement.scrollLeft > 0;
		let scrollLeftMax = this.scrollElement.nativeElement.scrollWidth - this.scrollElement.nativeElement.clientWidth;
		this.isRightArrowVisible = this.scrollElement.nativeElement.scrollLeft < scrollLeftMax;
	}
}
