export interface SimpleUser {
    email: string;
    fullName: string;
    username: string;
    roles: string[];
    rsvp: string;
    blocked: boolean;
    avatarUrl: string;
    jobTitle: string;
}

export interface SimpleCompany {
    id: string;
    name: string;
    description: string;
    companyType: "ADVERTISER" | "PRODUCER";
}

export interface User {
    username: string;
    firstName: string;
    lastName: string;
    company: SimpleCompany;
    companyId: string;
    email: string;
    phone: string;
    message: string | null;
    avatarUrl: string | null;
    verified: boolean;
    blocked: boolean | null;
    blockDate: boolean | null;
    unblockDate: number | null;
    activationDate: number;
    activationStatus: string;
    globalRole: string;
    userConnectionId: string | null;
    termsAndConditionsAgree: boolean;
    deleted: boolean;
    inviter: string;
    jobTitle: string;
    termsAndConditionsAcceptedDate: number | null;
    lastSeen: number | null;
    lastauthattempt: number | null;
    lastauthsuccess: number | null;
    oneshotaccess: boolean;
    subscriptionaccess: boolean;
    brandId: string | null;
    campaign: boolean;
    driveCampaign: boolean;
    fullName: string;
    storagePath: string;
    domainEmail: string;
    defaultProjectRoles: string[];
}

export enum OwnershipTransferTypes {
    PROJECT = "PROJECT",
    WORKSPACE = "WORKSPACE"
}

export interface UserRole {
    id: string;
    name: string;
}

export interface RoleInfo {
    code: string;
    label: string;
}

export interface ProfileSettings {
  username: string,
  language: string,
  startWeekOn: string,
  dateFormat: string,
  timeFormat: string,
  timeDisplay: string,
  timeZone: string,
  currency: string,
  logTime: number,
  numberFormat: string,
  soundChatNotifications: boolean,
  generalNotifications: boolean,
  muteConversations: boolean,
  twoFa: boolean,
  mfaMechanism: string,
  crispChatEnable: boolean,
  uploadResumable: boolean,
  dateTimePattern: string,
  startWeekDayIndex: number,
  timePattern: string,
  datePattern: string
}
