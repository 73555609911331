import { Injectable } from "@angular/core";
// import initHelpHero, { HelpHero } from "helphero";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HelpHeroService {
    // private instance: HelpHero = null;

    constructor() {
        // this.instance = initHelpHero(environment.helpHeroId);
    }

    identifyUser(authUser: any) {
        // if (!authUser?.username || !this.instance) {
        //     return;
        // }

        // console.log('identify help hero user');
        // //based on https://helphero.co/docs/javascript/
        // this.instance.identify(location.hostname + authUser.username, {
        //     role: authUser.globalRole,
        //     created_at: authUser.activationDate
        // });
    }

    resetUser() {
        try {
            (window as any).HelpHero.reset();
            console.log('reset help hero user');
        } catch (e) {

        }
    }
}
