<div class="mtm-breadcrumb-component">
    <div class="d-none d-sm-block">
        <div class="d-flex align-items-center">
            <div ngbDropdown>
                <div class="d-flex align-items-center dropdown-toggle pointer" *ngIf="links.length > 3" ngbDropdownToggle>
                    <i class="mtm-icon mtm-icon-left-triangle-circle-black"></i>
                    <i class="ml-2 fa fa-ellipsis-h"></i>
                </div>
                <div ngbDropdownMenu>
                    <div ngbDropdownItem *ngFor="let other of otherLinkForDesktop">
                        <a class="p-2 link-label _700 link-label-other" [ngClass]="{ 'no-link': !other.url }"
                            (click)="goToPage(other.url, other.queryParams)">{{other.label | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="breadcrumb-links d-flex align-items-center ml-1"
                *ngFor="let link of forCurrentUrl_linkForDesktop;let i=index">
                <a class="p-2 link-label _700 "
                    [ngClass]="{ 'active': i==(linkForDesktop.length-1) }" [ngClass]="{'no-link': !link.url || link.isCurrentUrl}"
                    (click)="goToPage(link.url, link.queryParams)">{{link.label | translate}}</a>
                <i *ngIf="i!=(linkForDesktop.length-1)" class="mtm-icon mtm-icon-right-triangle-black"></i>
            </div>
        </div>
    </div>
    <div class="d-sm-none">
        <div class="d-flex align-items-center">
            <div ngbDropdown>
                <div class="d-flex align-items-center dropdown-toggle pointer" *ngIf="links.length > 1" ngbDropdownToggle>
                    <i class="mtm-icon mtm-icon-left-triangle-circle-black"></i>
                    <i class="ml-2 fa fa-ellipsis-h"></i>
                </div>
                <div ngbDropdownMenu>
                    <div ngbDropdownItem *ngFor="let other of otherLinkForSmall">
                        <a class="p-2 link-label link-label-other" [ngClass]="{ 'no-link': !other.url }"
                            (click)="goToPage(other.url, other.queryParams)">{{other.label | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="breadcrumb-links d-flex align-items-center ml-1" *ngFor="let link of linkForSmall;let i=index">
                <a class="p-2 link-label _700" [ngClass]="{ 'no-link': !link.url }"
                    [ngClass]="{ 'active': i==(linkForSmall.length-1) }"
                    (click)="goToPage(link.url, link.queryParams)">{{link.label | translate}}</a>
                <i *ngIf="i!=(linkForSmall.length-1)" class="mtm-icon mtm-icon-right-triangle-black"></i>
            </div>
        </div>
    </div>
</div>