<div class="notifications">
	<div ngbDropdown class="d-inline-block" placement="bottom">
		<button class="btn notification-dropdown-btn" id="notifications" (click)="buzzerOff()" ngbDropdownToggle
			[ngClass]="isVisited()">
			<i class="fa fa-bell-o fa-fw mr-1" [ngClass]="{'bell-buzzer': newmessage }" aria-hidden="true"></i>
			<span [ngSwitch]="status" class="mr-3">
				<span *ngSwitchCase="'LOADED'">{{unseenTotal | mtmRoundedNumber}}</span>
				<i *ngSwitchCase="'LOADING'" class="fa fa-spinner fa-pulse"></i>
				<i *ngSwitchCase="'ERROR'" class="fa fa-exclamation-triangle fa-fw"></i>
			</span>
		</button>

		<!-- Notification count for Loreal test -->
		<!-- <button *ngIf="showLorealTestCountButton()" class="btn notification-dropdown-btn" id="notifications" (click)="buzzerOff()" ngbDropdownToggle
      [ngClass]="isVisited()">
      <i class="fa fa-bell-o fa-fw mr-1" [ngClass]="{'bell-buzzer': newmessage }" aria-hidden="true"></i>
      <span [ngSwitch]="status" class="mr-3">
        <span *ngSwitchCase="'LOADED'">{{totalNotifications}}</span>
        <i *ngSwitchCase="'LOADING'" class="fa fa-spinner fa-pulse"></i>
        <i *ngSwitchCase="'ERROR'" class="fa fa-exclamation-triangle fa-fw"></i>
      </span>
    </button> -->
		<!-- Notification count for Loreal test -->

		<div ngbDropdownMenu aria-labelledby="notifications" class="pb-0 combobox-notifications"
			[ngClass]="{'loareal-notification': isHrUser || isYslUser}">
			<div ngbDropdownItem *ngIf="status=='LOADED'" class="w-100 list-container mtm-color-scroll">
				<div class="d-flex align-items-center pb-3 pr-3 pl-3 pt-2" *ngIf="todays?.length">
					<span class="label-today-past mr-auto">{{
						'today' | translate }}</span>
					<!--	
					<a *ngIf="isButtoneVisible" href="#" class="ml-auto notification-button" [routerLink]="'/account/notifications-settings'">
						<i class="fa fa-cogs fa-lg" aria-hidden="true"></i>
						<span class="pl-1" style="font-weight: 600">{{'changeNotificationSettings' | translate}}</span>
					</a>
					-->
				</div>
				<notification-view *ngFor="let notify of todays" [isNotificationProject]="projectId ? true : false"
					[notification]="notify"></notification-view>
				<div class="d-flex align-items-center pb-3 pr-3 pl-3 pt-2" *ngIf="past.length > 0">
					<span class="label-today-past mr-auto">{{'past' | translate}}</span>
					<!--
					<a *ngIf="!todays?.length && isButtoneVisible" href="#" class="ml-auto notification-button" [routerLink]="'/account/notifications-settings'">
						<i class="fa fa-cogs fa-lg" aria-hidden="true"></i>
						<span class="pl-1" style="font-weight: 600">{{'changeNotificationSettings' | translate}}</span>
					</a>
					-->
				</div>
				<notification-view *ngFor="let notify of past" [isNotificationProject]="projectId ? true : false"
					[notification]="notify"></notification-view>
			</div>
			<div class="mt-2 mb-3">
				<div class="text-center" *ngIf="!todays?.length && !past?.length">
					<h5 [style.color]="'#000000'">{{ 'NoRecentNotification' |
						translate }}
					</h5>
				</div>
				<div class="notification-actions d-flex">
					<button class=" view-more" (click)="goToNotification()">
						<span>{{'notification_seeAll' | translate}}</span>
					</button>
					<button type="button" *ngIf="unseenTotal" class=" mark-all-read"
						(click)="markNotificationsAsRead()">
						<span>{{'markAllAsRead' | translate}}</span>
					</button>
				</div>


			</div>
		</div>
	</div>
	<div class="mtm-toaster-component">
		<mtm-toaster [notification]="toastNotification" [projectId]="getCurrentProjectId()"></mtm-toaster>
	</div>
</div>