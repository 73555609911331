<ng-template #headerContent>
	<div class="container mb-0">
		<div class="p-3">
			<h4 class="text-left mt-3" *ngIf="!project">{{ status }} Project</h4>
			<h4 class="text-left mt-3" *ngIf="project">{{project.name}}</h4>
			<ul class="list-inline steps py-2 px-3 tab-container align-items-center d-none d-md-flex"
				style="display: block;">
				<li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active"
					*ngFor="let link of links; let i = index" [ngClass]="{'step-complete': isComplete(i)}">
					<a [routerLink]="link.url" [queryParams]="link.queryParams"
						class="project-link d-flex align-items-center flex-nowrap"
						[class.disabled]="link.requiresProject && ! projectId">
						<i *ngIf="isComplete(i)"
							class="d-flex align-items-center justify-content-center fa fa-check-circle mr-2"
							aria-hidden="true"></i>
						<div *ngIf="!isComplete(i)"
							class="step-number d-flex align-items-center justify-content-center mr-2">{{i + 1}}</div>
						<span class="text-uppercase link-label">{{link.title || '' | translate}}</span>
					</a>
				</li>
			</ul>
			<ul class="list-inline steps py-2 px-3 tab-container d-flex align-items-center d-md-none menu-mobile"
				style="display: block;">
				<li class="list-inline-item mr-2" #rla="routerLinkActive" routerLinkActive="active"
					*ngFor="let link of links; let i = index" [ngClass]="{'step-complete': isComplete(i)}">
					<a [routerLink]="link.url" [queryParams]="link.queryParams"
						class="project-link d-flex align-items-center flex-nowrap"
						[class.disabled]="link.requiresProject && ! projectId">
						<i *ngIf="isComplete(i)"
							class="d-flex align-items-center justify-content-center fa fa-check-circle mr-2"
							aria-hidden="true"></i>
						<div *ngIf="!isComplete(i)"
							class="step-number d-flex align-items-center justify-content-center mr-2">{{i + 1}}</div>
						<span class="text-uppercase link-label">{{link.title || '' | translate}}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</ng-template>
<router-outlet></router-outlet>