import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { CampaignMilestone, DriveCampaign, ProjectStatusList } from "app/shared/interfaces";
import { FormBuilder, Validators } from "@angular/forms";
import { ProjectV2ServiceService } from "app/shared/services/project-v2-service.service";
import { DateHelperService } from "app/shared/services/date-helper.service";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";

@Component({
  selector: 'mtm-milestone-status-editor',
  templateUrl: './milestone-status-editor.component.html',
  styleUrls: ['./milestone-status-editor.component.scss']
})
export class MilestoneStatusEditorComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private lorealProjectService = inject(ProjectV2ServiceService);
  private overlayService = inject(OverlayService);
  private translatePipe = inject(TranslatePipe);


  private _milestone: CampaignMilestone;
  isLoading: boolean;

  targetStatusList: string[] = [ProjectStatusList.IN_PROGRESS, ProjectStatusList.COMPLETED, ProjectStatusList.DELAYED];
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  get milestone(): CampaignMilestone {
    return this._milestone;
  }

  @Input()
  set milestone(value: CampaignMilestone) {
    this._milestone = value;
    this.updateFormValues();
  }

  @Input() campaign: DriveCampaign;

  milestoneForm = this.fb.group({
    id: ['', {
      validators: [Validators.required],
    }],
    driveDate: [null, {
      validators: [Validators.required],
    }],
    negotiatedDate: [null],
    actualDate: [null],
    status: [ProjectStatusList.IN_PROGRESS, {
      validators: [Validators.required]
    }],
  });

  ProjectStatus = ProjectStatusList;

  get statusValue(): string {
    return this.milestoneForm.get('status').value;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  private updateFormValues() {
    if (!this.milestone) {
      return;
    }

    let value: Partial<typeof this.milestoneForm.value> = {
      id: this.milestone.id,
      status: this.milestone.status || ProjectStatusList.IN_PROGRESS
    };

    if (this.milestone.driveDate) {
      value.driveDate = DateHelperService.fromDateToDatepicker(new Date(this.milestone.driveDate));
    }
    if (this.milestone.negotiatedDate) {
      value.negotiatedDate = DateHelperService.fromDateToDatepicker(new Date(this.milestone.negotiatedDate));
    }
    if (this.milestone.actualDate) {
      value.actualDate = DateHelperService.fromDateToDatepicker(new Date(this.milestone.actualDate));
    }

    this.milestoneForm.patchValue(value);
  }

  changeStatus(newStatus: string) {
    this.milestoneForm.patchValue({
      status: newStatus
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  save() {
    if (!this.milestoneForm.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;

    const newMilestone = this.milestoneForm.value;
    const timelines = JSON.parse(this.campaign.timelines) as any[];
    let isFound = false;
    for (let i = 0; i < timelines.length; i++) {
      const timeline = timelines[i];
      if (timeline.items.length) {
        const existingMilestone = timeline.items.find(m => m.id == newMilestone.id);

        if (!!existingMilestone) {
          this.adjustMilestoneData(existingMilestone, newMilestone);
          isFound = true;
          break;
        }

        for (let j = 0; j < timeline.items.length; j++) {
          const item = timeline.items[j];
          if (item.items && item.items.length) {
            const existingChildMilestone = item.items.find(m => m.id == newMilestone.id);
            if (!!existingChildMilestone) {
              this.adjustMilestoneData(existingChildMilestone, newMilestone);
              isFound = true;
              break;
            }
          }
        }
      }
    }

    const newTimeline = JSON.stringify(timelines);
    const campaignToUpdate = { ...this.campaign, timelines: newTimeline };
    this.lorealProjectService.createTimeline(campaignToUpdate)
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.campaign.timelines = newTimeline;
          this.activeModal.close(newMilestone);
        },
        error: (error) => {
          this.isLoading = false;
          console.error('Error updating timeline', error);
          this.overlayService.showError(this.translatePipe.transform('overlaySaveError'));
        },
      });
  }

  adjustMilestoneData(existingMilestone: CampaignMilestone, newMilestone: Partial<typeof this.milestoneForm.value>) {
    existingMilestone.status = newMilestone.status;
    if (newMilestone.negotiatedDate) {
      existingMilestone.negotiatedDate = DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(newMilestone.negotiatedDate));
    } else {
      existingMilestone.negotiatedDate = null;
    }

    if (newMilestone.actualDate) {
      existingMilestone.actualDate = DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(newMilestone.actualDate));
    } else {
      existingMilestone.actualDate = null;
    }
  }

}
