<!-- setting block -->

<div class="mt-3 timeline-header-div" *ngIf="isRecap">
  <div class="ml-3 position-relative" (mouseover)="showTooltip = true" (mouseleave)="showTooltip = false">
    <button class="info-btn">
      <i class="fa fa-info" aria-hidden="true"></i>
    </button>
    <div class="custom-tooltip" [style.width.px]="getTooltipWidth()" [class.show-tooltip]="showTooltip">
      <div class="arrow-container">
        <i class="fa fa-caret-right fa-xl top-arrow-icon c-full-white"></i>
      </div>
      <div class="text-left c-mtm-full-white col">
        <div class="row">
          <ng-container *ngFor="let item of colorInfoData">
            <div class="col-6 d-flex align-items-center my-2">
              <div class="d-inline-block mr-2">
                <ng-container *ngIf="item.name === 'CAMPAIGN'">
                  <div [style.background-color]="item.color" class="d-flex justify-content-center align-items-center info-circle">
                    <div class="inner-div-cicle"></div>
                  </div>
                </ng-container>
                <ng-container *ngIf="item.name !== 'CAMPAIGN'">
                  <div [style.background-color]="item.color" class="info-circle"></div>
                </ng-container>
              </div>
              <span class="_700 f-8 c-mtm c-mtm-full-black">{{ item.name | translate }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="ml-2" (click)="editTimeline()" *ngIf="canViewEditTimeline">
    <button class="setting-btn">
      <img src="/assets/img/project-v2/setting-icon.svg" />
    </button>
  </div>
  <div class="ml-2">
    <div class="select-wrapper mr-3">
      <select class="dropdown-toggle section-filter" (change)="handleTimelineView($event)">
        <option *ngFor="let item of timelineDropdown" class="option-hover" [value]="item">{{ getBlockName(item) | titlecase  | translate}}</option>
      </select>
    </div>
  </div>
</div>

<!-- pineed timeline blocks -->
<div class="pinned-timeline" #pinnedtimelinediv *ngIf="pinnedTimelines && pinnedTimelines.length">
  <div class="d-flex mt-3" style="box-shadow: 0px 0px 10px red;">
    <div *ngFor="let timeline of pinnedTimelines" class="pin-timeline-content-open" style="height: 99px;"
      [ngClass]="timeline.removeBlock?'d-none':''" [style.background]="timeline.blockColor"
      [style.border]="'3px solid ' + timeline.blockColor">
      <div class="d-flex" *ngIf="!timeline.showBlock && timeline.showToggleIcons"
        (click)="toggleBlockView(timeline); handlePinBlocks(null);">
        <img src="/assets/img/project-v2/right-triangle-white.svg" alt="" />
        <img class="ml-1" src="/assets/img/project-v2/left-triangle-white.svg" alt="" />
      </div>
      <div class="campiang-scroll-container ml-1" *ngIf="timeline.showBlock">
        <div class="pin-div" (click)="handlePinBlocks(timeline)">
          <i class="fa fa-thumb-tack" [ngClass]="timeline.pinBlock?'text-white rotate-pin':''"
            style="font-size: 10px; color: '#c4c4c4';" aria-hidden="true"></i>
        </div>
        <div *ngIf="timeline.showToggleIcons" class="open-button" [style.border-left-color]="timeline.blockColor"
          (click)="toggleBlockView(timeline); handlePinBlocks(null);">
          <i class="right-icon fa fa-caret-right fa-lg" [style.color]="timeline.blockColor"></i>
        </div>
        <div *ngIf="timeline.showToggleIcons" class="close-button mr-1" [style.border-right-color]="timeline.blockColor"
          (click)="toggleBlockView(timeline); handlePinBlocks(null);">
          <i class="left-icon fa fa-caret-left fa-lg" [style.color]="timeline.blockColor"></i>
        </div>
        <div class="campiang-scroll mr-1">
          <div class="milestone-item" *ngFor="let milestone of timeline.items; let i=index"
               (click)="editMilestone(milestone)">
            <div *ngIf="!milestone.items">
              <div class="" style="position: relative;" [style.background]="timeline.blockColor">
                <div class="pt-1">
                  <span class="timeline-mad-div-text">{{milestone.mad}}</span>
                </div>
                <div class="pb-1">
                  <span class="timeline-mad-div-text">{{milestone.driveDate | date: 'dd.MM.YYYY'}}</span>
                </div>
              </div>
              <div class="timeline-name-div" [style.background]="'#FFFFFF'">
                <img src="/assets/img/project-v2/calendar-black.svg" width="12" height="10" class="calendar-icon" />
                <span class="timeline-name-div-text">{{milestone.name}}</span>
              </div>
            </div>
            <!-- sub items -->
            <!-- sub items campaign timeline -->
            <div *ngIf=" timeline.name == 'CAMPAIGN' && milestone.items "
              class="timeline-name-div-text d-flex d-flex justify-content-center bg-white text-black py-0"
              style="position: relative; top: 30px; height: 14px; font-size: 35%">{{milestone.name}}</div>
            <div *ngIf=" timeline.name == 'CAMPAIGN' && milestone.items" class="d-flex" style="width: 100%;">
              <div *ngFor="let subItem of milestone.items; let i = index;"  (click)="editMilestone(subItem)">
                <div class="" style="top: -14px; position: relative; height: 17px"
                  [style.background]="timeline.blockColor">
                  <div class="pt-1">
                    <span class="timeline-mad-div-text ">{{subItem.mad}}</span>
                  </div>
                  <div class="pb-1">
                    <span class="timeline-mad-div-text">{{subItem.driveDate | date: 'dd.MM.YYYY'}}</span>
                  </div>
                </div>
                <div class="timeline-name-div" style="align-items: end;" [style.background]="timeline.blockColor">
                  <img src="/assets/img/project-v2/calendar-black.svg" width="12" height="10" class="calendar-icon" />
                  <span
                    class="timeline-name-div-text text-white">{{subItem.name}}</span>
                </div>
              </div>
            </div>
            <!-- sub items other timelines -->
            <div *ngIf=" timeline.name !== 'CAMPAIGN' && milestone.items" class="d-flex">
              <div class="col" *ngFor="let subItem of milestone.items; let subi = index;"
                [style.background]="timeline.blockColor" (click)="editMilestone(subItem)">
                <div class="" style="position: relative;" [style.background]="timeline.blockColor">
                  <div class="d-flex justify-content-center">
                    <span class="d-flex align-item-center _600 f-10 c-full-white">{{subItem.mad}}</span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <span class="d-flex align-item-center _600 f-10 c-full-white">{{subItem.driveDate | date:
                      'dd.MM.YYYY'}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col border-right  d-flex justify-content-center align-items-center "
                    [style.max-width]="'100%'" [style.min-width]="'73px'"
                    [style.background]="'linear-gradient(0deg, rgba(255, 255, 255, ' + (i * 0.2 + 0.2) + '), rgba(255, 255, 255, ' + (i * 0.2 + 0.2) + ')), ' + timeline.blockColor + ''"
                    [style.height]="'25px'">
                    <span class="text-center d-flex align-item-center _600 f-7 c-full-white">{{subItem.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf=" timeline.name !== 'CAMPAIGN' && milestone.items"
              class="col border-right d-flex justify-content-center" [style.height]="'35px'"
              [style.background]="'#fff'">
              <div class="d-flex justify-content-center align-items-center">
                <span class=" d-flex align-item-center _600 f-9 c-mtm-full-black">{{milestone.name}}</span>
              </div>
            </div>
            <!-- <div *ngIf="milestone.items"
              class="timeline-name-div-text d-flex d-flex justify-content-center bg-white text-black py-0" style="
                position: relative;
                top: 30px;
                height: 14px;
                font-size: 35%;
              ">
              {{milestone.name}}
            </div>
            <div *ngIf="milestone.items" class="d-flex">
              <div *ngFor="let subItem of milestone.items; let i = index;">
                <div class="row" style="top: -14px; position: relative; height: 17px;"
                  [style.background]="timeline.blockColor">
                  <div class="col-12 p-0">
                    <span class="timeline-mad-div-text">{{subItem.mad}}</span>
                  </div>
                  <div class="col-12 p-0">
                    <span class="timeline-mad-div-text">{{subItem.driveDate | date: 'dd.MM.YYYY'}}</span>
                  </div>
                </div>
                <div class="timeline-name-div" style="align-items: end;" [style.background]="timeline.blockColor">
                  <span class="timeline-name-div-text text-white">{{subItem.name}}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- timeline block -->
<div class="timeline-card" *ngIf="timelines && timelines.length">
  <div class="d-flex mt-3 timeline-border-radius timeline-scroll" id="scroll-container"
    (wheel)="timelineScrollOnWheel($event)" (scroll)="checkScrollState($event)">
    <!-- left-side button for scroll -->
    <div id="scroll-left-button" class="left-btn-gradiant" *ngIf="!fixedTimelineSize && isLeftArrowVisible">
      <div class="scroll-btn scroll-btn-left" (click)="scrollToLeft()">
        <img src="/assets/img/project-v2/right-triangle-white.svg" alt="" />
      </div>
    </div>

    <!-- Milestone Blocks -->
    <div class="d-flex mx-0" [style.padding-left]="pinnedTimelineDivWidth+'px'">
      <div *ngFor="let timeline of timelines" class="timeline-content-open"
        [ngClass]="timeline?.removeBlock || timeline?.pinBlock ? 'd-none' : ''"
        [style.background]="timeline?.blockColor" [style.border]="'1px solid #D6DDEA'">
        <div class="d-flex" *ngIf="!timeline.showBlock && timeline.showToggleIcons" (click)="toggleBlockView(timeline)">
          <img src="/assets/img/project-v2/right-triangle-white.svg" alt="" />
          <img class="ml-1" src="/assets/img/project-v2/left-triangle-white.svg" alt="" />
        </div>
        <div class="campiang-scroll-container" *ngIf="timeline.showBlock && !timeline.pinBlock">
          <div *ngIf="timeline.showToggleIcons" class="pin-div" (click)="handlePinBlocks(timeline)">
            <i class="fa fa-thumb-tack" [ngClass]="timeline.pinBlock?'text-white rotate-pin':''"
              style="font-size: 10px; color: '#c4c4c4';" aria-hidden="true"></i>
          </div>
          <div *ngIf="timeline.showToggleIcons" class="open-button" [style.border-left-color]="timeline.blockColor"
            (click)="toggleBlockView(timeline)">
            <i class="right-icon fa fa-caret-right fa-lg" [style.color]="timeline.blockColor"></i>
          </div>
          <div *ngIf="timeline.showToggleIcons" class="close-button mr-1"
            [style.border-right-color]="timeline.blockColor" (click)="toggleBlockView(timeline)">
            <i class="left-icon fa fa-caret-left fa-lg" [style.color]="timeline.blockColor"></i>
          </div>
          <div class="campiang-scroll">
            <div class="milestone-item" *ngFor="let milestone of timeline.items; let i=index"
            [ngStyle]="{ 'opacity': milestone.opacity}"
                 (click)="editMilestone(milestone)"
              >
              <div *ngIf="!milestone.items">
                <div class="" style="position: relative;" [style.background]="timeline.blockColor">
                  <div class="pt-1">
                    <span class="timeline-mad-div-text">{{milestone.mad}}</span>
                  </div>
                  <div class="pb-1">
                    <span class="timeline-mad-div-text">{{milestone.driveDate | date: 'dd.MM.YYYY'}}</span>
                  </div>
                </div>
                <div class="col p-0">
                    <div class="d-flex justify-content-end cross-btn-div">
                      <span *ngIf="milestone.isCross && !editTimelineComponent && !isShow && !isRecap" class="badge badge-edit-button" (click)="$event.stopPropagation();backToDashboard(milestone)" >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </div>
                  <div class="timeline-name-div here" [style.width]="fixedTimelineSize ? timelineElementWidth + 'px' : '85px'" [ngStyle]="{ 'background':milestone.status === 'COMPLETED' ? '#43CB9A' :milestone.status === 'IN_PROGRESS' ? '#FFFF' : milestone.status === 'DELAYED' ? '#F67373' :''}">
                    <img src="/assets/img/project-v2/calendar-black.svg" width="12" height="10" class="calendar-icon" />
                    <span class="timeline-name-div-text">{{milestone.name}}</span>
                  </div>
                </div>
              </div>
              <!-- sub items -->
              <div *ngIf="milestone.items" class="d-flex">
                <div class="col" *ngFor="let subItem of milestone.items; let subi = index;"
                  [style.background]="timeline.blockColor" (click)="editMilestone(subItem)">
                  <div class="" style="position: relative;" [style.background]="timeline.blockColor">
                    <div class="d-flex justify-content-center">
                      <span class="d-flex align-item-center _600 f-10 c-full-white">{{subItem.mad}}</span>
                    </div>
                    <div class="d-flex justify-content-center">
                      <span class="d-flex align-item-center _600 f-10 c-full-white">{{subItem.driveDate | date:
                        'dd.MM.YYYY'}}</span>
                    </div>
                  </div>
                  <div class="row" [style.background]="subItem.subItemBlockColor">
                    <!-- <div class="d-flex justify-content-end cross-btn-div">
                      <span *ngIf="subItem.isCross  && !editTimelineComponent && !isShow" class="badge badge-edit-button"
                        (click)="$event.stopPropagation();backToDashboard(subItem)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </div> -->
                    <div class="col border-right d-flex justify-content-center align-items-center p-2" [ngStyle]="{'background': isDriveCampaign ? subItem.statusColor: ''}"
                      [style.max-width]="'100%'" [style.min-width]="'73px'" [style.height]="'25px'">
                      <span class="text-center d-flex align-item-center _600 f-7"
                        [style.color]="subItem.subItemTextColor">{{subItem.name}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div  *ngIf="milestone.items" class="d-flex justify-content-end cross-btn-div">
                <span *ngIf="milestone.isCross && !editTimelineComponent && !isShow && !isRecap" class="badge badge-edit-button" (click)="$event.stopPropagation();backToDashboard(milestone)" >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
              <div *ngIf="milestone.items" class="col border-right d-flex justify-content-center text-center p-1 pointer-default"
                [style.height]="'35px'" [style.background]="'#fff'">
                <div class="d-flex justify-content-center align-items-center">
                  <span class=" d-flex align-item-center _600 f-9 c-mtm-full-black ">{{milestone.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- right side button for scroll -->
      <div id="scroll-right-button" class="right-btn-gradiant" *ngIf="!fixedTimelineSize && isRightArrowVisible">
        <div class="scroll-btn scroll-btn-right" (click)="scrollToRight()">
          <img src="/assets/img/project-v2/left-triangle-white.svg" alt="" />
        </div>
      </div>
    </div>
  </div>

<div *ngIf="!timelines || !timelines.length">
  <mtm-timeline-skeleton></mtm-timeline-skeleton>
</div>
