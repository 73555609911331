<div class="d-flex flex-column mtm-edit-asset-component p-4">
    <div class="component-header d-flex flex-column align-items-center">
        <label class="component-title mb-4" *ngIf="isNew">Add an Asset</label>
        <label class="component-title mb-4" *ngIf="!isNew">Edit asset</label>
    </div>
    <div class="row mb-4">
        <div class="col-12 col-md-6 d-flex flex-column mb-3" [style.zIndex]="9999">
            <label class="meta-label _700">Funnel</label>
            <div class="select-wrapper w-100">
                <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                    <div class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                        <div class="flex-fill mr-1">
                            <span *ngIf="!funnel" class="c-mtm-full-black">
                                {{'--Select--' | translate }}
                            </span>
                            <div *ngIf="!!funnel" class="task-collaborators">
                                <div class="task-collaborator-wrapper d-flex align-items-center">
                                    <div class="collaborator-avatar d-flex">
                                        <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                            {{funnel }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                    </div>
                    <div class=" w-100" ngbDropdownMenu>
                        <div *ngFor="let funnel of funnels" class="d-flex align-items-center pointer"
                            (click)="onFunnelSelect(funnel)" ngbDropdownItem>
                            <span class="ml-2 _400">{{ funnel | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column mb-3" [style.zIndex]="9999">
            <label class="meta-label _700">Focus</label>
            <div class="select-wrapper w-100">
                <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                    <div class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                        <div class="flex-fill mr-1">
                            <span *ngIf="!focus" class="c-mtm-full-black">
                                {{'--Select--' | translate }}
                            </span>
                            <div *ngIf="!!focus" class="task-collaborators">
                                <div class="task-collaborator-wrapper d-flex align-items-center">
                                    <div class="collaborator-avatar d-flex">
                                        <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                            {{focus }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                    </div>
                    <div class=" w-100" ngbDropdownMenu>
                        <div *ngFor="let focus of focuses" class="d-flex align-items-center pointer"
                            (click)="onFocusSelect(focus)" ngbDropdownItem>
                            <span class="ml-2 _400">{{ focus | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column mb-3" [style.zIndex]="999">
            <label class="meta-label _700">Type</label>
            <div class="select-wrapper w-100">
                <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                    <div class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                        <div class="flex-fill mr-1">
                            <span *ngIf="!type" class="c-mtm-full-black">
                                {{'--Select--' | translate }}
                            </span>
                            <div *ngIf="!!type" class="task-collaborators">
                                <div class="task-collaborator-wrapper d-flex align-items-center">
                                    <div class="collaborator-avatar d-flex">
                                        <span class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">
                                            {{type }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                    </div>
                    <div class=" w-100" ngbDropdownMenu>
                        <div *ngFor="let type of types" class="d-flex align-items-center pointer"
                            (click)="onTypeSelect(type)" ngbDropdownItem>
                            <span class="ml-2 _400">{{ type | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column mb-3" [style.zIndex]="999">
            <label class="meta-label _700">Detail</label>
            <input type="text" class="form-control" placeholder="Type your text here..." [(ngModel)]="detail"
                [style.font-size]="'12px'" />
        </div>
    </div>
    <div class="mb-3">
        <label class="create-new-label m-0">Categories</label>
        <div class="d-flex bet-list-container">
            <div (click)="selectCategory('VIDEO')" [ngClass]="{ 'selected': selectedCategories?.indexOf('VIDEO') > -1 }"
                class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer">
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                    *ngIf="selectedCategories?.indexOf('VIDEO') > -1"></i>
                <label
                    class="meta-label text-uppercase d-flex align-items-center ml-auto mr-auto mr-1 mt-0 _700">VIDEO</label>
            </div>
            <div (click)="selectCategory('STILL')" [ngClass]="{ 'selected': selectedCategories?.indexOf('STILL') > -1 }"
                class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer">
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                    *ngIf="selectedCategories?.indexOf('STILL') > -1"></i>
                <label
                    class="meta-label text-uppercase d-flex align-items-center ml-auto mr-auto mr-1 mt-0 _700">STILL</label>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <label class="create-new-label m-0">Bets</label>
        <div class="d-flex bet-list-container flex-wrap">
            <div (click)="selectBet(bet)" [ngClass]="{ 'selected': selectedBets?.indexOf(bet) > -1 }"
                class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer"
                *ngFor="let bet of betList.slice(0, 5)">
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                    *ngIf="selectedBets?.indexOf(bet) > -1"></i>
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-{{bet.toLowerCase()}}"></i>
                <label
                    class="meta-label text-uppercase d-flex align-items-center ml-auto mr-auto mr-1 mt-0 _700">{{bet}}</label>
            </div>
        </div>
        <div class="d-flex bet-list-container flex-wrap">
            <div (click)="selectBet(bet)" [ngClass]="{ 'selected': selectedBets?.indexOf(bet) > -1 }"
                class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer"
                *ngFor="let bet of betList.slice(5, 9)">
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                    *ngIf="selectedBets?.indexOf(bet) > -1"></i>
                <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-{{bet.toLowerCase()}}"></i>
                <label
                    class="meta-label text-uppercase d-flex align-items-center ml-auto mr-auto mr-1 mt-0 _700">{{bet}}</label>
            </div>
            <div class="bet-list d-flex flex-column align-items-center justify-content-start m-2"
                [style.border]="'none'">
                <label class="_700">Other</label>
                <input [disabled]="true" type="text" class="form-control text-center"
                    placeholder="Type your text here..." [style.height]="'66px'" [style.font-size]="'12px'"
                    [style.padding]="'10px'" />
            </div>
        </div>
    </div>
    <div class="d-flex flex-column mb-5">
        <div class="d-flex flex-column mb-3">
            <label class="create-new-label m-0">Length</label>
            <div class="d-flex bet-list-container flex-wrap">
                <div (click)="selectLength(length)" [ngClass]="{ 'selected': selectedLengths?.indexOf(length) > -1 }"
                    class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer"
                    *ngFor="let length of availableLength">
                    <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                        *ngIf="selectedLengths?.indexOf(length) > -1"></i>
                    <label
                        class="meta-label text-uppercase d-flex align-items-center ml-auto mr-auto mt-3 mr-1 mb-1 _700 p-3 text-center">{{length}}</label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column mb-3">
            <label class="create-new-label m-0">Format</label>
            <div class="d-flex bet-list-container flex-wrap">
                <div (click)="selectFormat(format)" [ngClass]="{ 'selected': selectedFormats?.indexOf(format) > -1 }"
                    class="bet-list d-flex flex-column align-items-center justify-content-center m-2 pointer"
                    *ngFor="let format of availableFormat">
                    <i class="ml-auto mr-auto mt-3 mr-1 mb-1 mtm-icon mtm-icon-green-check"
                        *ngIf="selectedFormats?.indexOf(format) > -1"></i>
                    <label
                        class="text-uppercase d-flex align-items-center  ml-auto mr-auto mt-3 mr-1 mb-1 _700">{{format}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-component d-flex flex-column flex-md-row align-items-center justify-content-center">
        <div class="mr-0 mr-md-2 mb-2 mb-md-0 button-wrapper">
            <button class="btn mtm-button button-cancel _700 c-mtm-full-black" (click)="cancel()">CANCEL</button>
        </div>
        <div class="mr-0 mr-md-2 button-wrapper">
            <button class="btn mtm-button b-mtm-black _700 c-mtm-full-white" (click)="save()">SAVE</button>
        </div>
    </div>
</div>