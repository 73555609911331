import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Item, Category } from 'app/shared/interfaces';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DownloadTimelineComponent } from './download-timeline/download-timeline.component';
import { AuthService } from 'app/shared/services/auth.service';
import { DummyDataService } from 'app/shared/services/dummy-data.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'app/shared/services/helper.service';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { environment } from 'environments/environment';
import { EmitterService } from 'app/shared/services/emitter.service';

@Component({
  selector: 'mtm-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss', '../timeline-style.scss']
})
export class RecapComponent implements OnInit, OnDestroy {
  productTimeline = [];
  campaignTimeline = [];
  timelineData: any;
  timeline: any;
  showProductBlock: boolean = true;
  showCampiagnBlock: boolean = true;
  showMagellanBlock: boolean = true;
  isFullView: boolean = true;
  isVisulizationEdit: boolean = false;
  ngUnsubscribe = new Subject();
  subscription: Subscription;
  timelinesearch: string;
  timeLineMilestoneData: any[] = [];
  backupTimeLineMilestoneData: any[] = [];
  milestoneTimeline: any[] = [];
  filteredTimelineMilestone: any[] = [];
  visualizations: any = [];
  milestoneBlockIndex: number = null;
  summaryDetail: any;
  colors = {
    'CAMPAIGN': '#000000',
    'PRODUCT': '#1E43A1',
    'MAGELLAN': '#1E43A1',
    'COLLECTOR / PRODUCT': '#086289',
    'GIFT': '#923065',
    'SET': '#502B9D'
  }
  modal: NgbModalRef;
  expectedMad: string;
  priority: string = '';
  milestoneFilterDropdown: string[] = ['Chronological order', 'Timeline Order', 'Both'];
  milestoneFilterValue: string = '';
  isBothSelected: boolean = false;
  selectedMilestone: string;
  namesAndColors: any = [];
  selectedColors: string[] = [];
  isRemoveAllMilestone: boolean = false;
  isNotificationView: boolean = false;
  fromNotification: boolean = false;
  @ViewChild('milestoneSelect') milestoneSelect: ElementRef<any>;
  authUser: any;
  headerImage = '.../../../../../assets/img/project-v2/recap-header.jpg';
  headerLogo = '.../../../../../assets/img/project-v2/comapnny-log.png';
  headerTitle = '';
  headerSubtitle = '';
  scenario4Header = '.../../../../../assets/img/project-v2/workspace-img.png';
  scenario4HeaderLogo = '.../../../../../assets/img/project-v2/helenarubinstein-icon.png';
  // ysl
  scenario3YslHeader = '.../../../../../assets/img/project-v2/ysl-dashboard.png';
  scenario3YslHeaderLogo = '.../../../../../assets/img/project-v2/ysl-icon.png';
  breadcrumbs: any;
  workspaceId: string;
  driveCampaignId: string;
  cacheBuster: string = new Date().getTime().toString();

  company = 'cpd';
  isCpd = false;
  assetBundle: any;
  assetStar: any;
  timelineLoaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ProjectV2Service: ProjectV2ServiceService,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private authService: AuthService,
    private loarealDummyDataService: DummyDataService,
    public projectV2ServiceService: ProjectV2ServiceService,
    private dataTransferService: DataTransferBetweenComponentsService,
    public timelineService: TimelineService,
    private cryptoService: CryptoService,
    private translatePipe: TranslatePipe
  ) {
    EmitterService.get('DRIVE_CAMPAIGN:FORM_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.initialization();
      }
    });
		EmitterService.get('DRIVE_CAMPAIGN:ACTIVE_PAGE').emit('recap');
  }
  ngOnInit(): void {
    // for dashboard image
    if (!this.projectV2ServiceService.timelineId) {
      this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (query) => {
          if (query?.wid && query.dcId) {
            this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
            this.driveCampaignId = this.cryptoService.decryptUsingAES256(query.dcId);
            this.projectV2ServiceService.updateDriveCampaignOnly(this.workspaceId, this.driveCampaignId);
          } else {
            this.initialization();
          }
        }
      });
      return;
    }
    this.initialization();
  }

  ngOnDestroy(): void {
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.remove('campaign-drive-general');
    }
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  initialization() {
    this.timelineLoaded = true;
    this.authUser = this.authService.getAuthUser();
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.add('campaign-drive-general');
    }
    // this.timelineService.componentName.next('recapeComponent');
    this.route.queryParams.subscribe(query => {
      if (query && query.wid && query.dcId) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
        this.driveCampaignId = this.cryptoService.decryptUsingAES256(query.dcId);
      }
    });
    if (!this.driveCampaignId && this.ProjectV2Service.timelineId) {
      this.driveCampaignId = this.ProjectV2Service.timelineId;
    }
    // this.ProjectV2Service.timelineId
    this.workspaceId = this.route.snapshot.params['workspaceId'];
    if (this.workspaceId) {
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 1, `/workspaces/campaign-drive?wid=${this.cryptoService.encryptString(this.workspaceId)}`);
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 2, `/workspaces/campaigndrive/${this.workspaceId}/general`);
    }
    this.authUser = this.authService.getAuthUser();
    this.route.queryParams.subscribe(query => {
      if (query.from == 'notification') {
        this.fromNotification = true;
        this.isNotificationView = true;
      } else {
        this.isNotificationView = false;
        this.fromNotification = false;
      }
      if (this.driveCampaignId) {
        this.getTimeline();
      } else {
        //this.router.navigate([`/workspaces/campaigndrive/${this.workspaceId}/general`]);
      }
    })
    this.milestoneFilterValue = this.milestoneFilterDropdown[0];
  }

  //get Timeline
  // async getTimeline() {
  //   if (this.ProjectV2Service.timelineData && !this.fromNotification) {
  //     let driveCamapign = await this.timelineService.getTimeLine(this.workspaceId, this.driveCampaignId);
  //     console.log('driveCamapign', driveCamapign)
  //     if (driveCamapign) {
  //       this.timelineData = driveCamapign;
  //       if (this.timelineData && this.timelineData.banner) {
  //         this.timelineData.banner = this.timelineData.banner + `?v=${this.cacheBuster}`;
  //       }
  //       let criteria = JSON.parse(this.timelineData.criteria);
  //       this.summaryDetail = criteria;

  //       this.timelineService.componentName.next({
  //         componentName: 'recapeComponent',
  //         bannerUrl: ''
  //       });
  //       this.timelineService.componentName.next({
  //         componentName: 'recapeComponentInside',
  //         bannerUrl: ''
  //       });
  //       // });
  //     }

  //   }
  //   this.headerTitle = this.ProjectV2Service.workspaceName;
  //   this.headerSubtitle = this.timelineData.name;
  //   if (this.timelineData && this.timelineData.id && !this.fromNotification) {
  //     this.isNotificationView = false;
  //     const dateObj = new Date(this.timelineData.expectedMad);
  //     const formattedDate = dateObj.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('.');
  //     this.expectedMad = formattedDate;
  //     this.priority = this.timelineData.priority;

  //     // get destructured timeline data
  //     this.timeline = await this.ProjectV2Service.handleTimelineData(this.timelineData);

  //     // filter milestones
  //     this.timeLineMilestoneData = await this.ProjectV2Service.filterMilestonesFromTimeline(this.timeline);
  //     this.filteredTimelineMilestone = this.timeLineMilestoneData;
  //     this.namesAndColors = this.timeline
  //       .map(item => {
  //         if (item.items.length > 0) {
  //           return {
  //             name: item.name,
  //             blockColor: item.blockColor
  //           };
  //         }
  //       })
  //       .filter((item, index, self) => {
  //         return item && index === self.findIndex((t) => t.name === item.name);
  //       });
  //   } else if (this.fromNotification) {
  //     this.isNotificationView = true;
  //     this.getNotificationTimeline();
  //   } else {
  //     this.router.navigate([
  //       `/workspaces/campaigndrive/${this.workspaceId}/general`
  //     ]);
  //   }
  // }
  async getTimeline() {
    try {
      // if (this.ProjectV2Service.timelineData && !this.fromNotification) {
      const driveCampaign = await this.timelineService.getTimeLine(this.workspaceId, this.driveCampaignId);
      if (driveCampaign) {
        this.timelineData = driveCampaign;
        if (this.timelineData.banner) {
          this.timelineData.banner = `${this.timelineData.banner}?v=${this.cacheBuster}`;
        }
        const criteria = JSON.parse(this.timelineData.criteria);
        this.summaryDetail = criteria;
        try {
          this.assetBundle = JSON.parse(this.timelineData.assetList);
        } catch (e) {
          this.assetBundle = [];
        }
        this.assetStar = this.summaryDetail['Asset Bundle'];
        this.timelineService.componentName.next({
          componentName: 'recapeComponent',
          bannerUrl: ''
        });
        this.timelineService.componentName.next({
          componentName: 'recapeComponentInside',
          bannerUrl: ''
        });
      }
      // }

      this.headerTitle = this.ProjectV2Service.workspaceName;
      this.headerSubtitle = this.timelineData?.name;
      if (this.timelineData?.id && !this.fromNotification) {
        this.isNotificationView = false;
        const formattedDate = new Date(this.timelineData.expectedMad).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('.');
        this.expectedMad = formattedDate;
        this.priority = this.timelineData.priority;

        // get destructured timeline data
        this.timeline = await this.ProjectV2Service.handleTimelineData(this.timelineData);

        // filter milestones
        this.timeLineMilestoneData = await this.ProjectV2Service.filterMilestonesFromTimeline(this.timeline);
        this.filteredTimelineMilestone = this.timeLineMilestoneData;
        this.namesAndColors = this.timeline
          .map(item => item.items.length > 0 ? { name: item.name, blockColor: item.blockColor } : null)
          .filter((item, index, self) => item && index === self.findIndex(t => t.name === item.name));
      }


      if (this.fromNotification) {
        this.isNotificationView = true;
        this.getNotificationTimeline();
      } else if (!this.timelineData?.id) {
        this.router.navigate([`/workspaces/campaigndrive/${this.workspaceId}/general`]);
      }
    } catch (error) {
      console.error('An error occurred while fetching timeline data:', error);
    }
  }

  //toggle timeline block
  toggleProductBlock() {
    this.showProductBlock = !this.showProductBlock;
  }

  toggleCampiagnBlock() {
    this.showCampiagnBlock = !this.showCampiagnBlock;
  }

  toggleMagellanBlockBlock() {
    this.showMagellanBlock = !this.showMagellanBlock;
  }

  //scroll timeline
  timelineScrollOnWheel() {
    const scrollContainer = document.querySelector('.timeline-scroll') as HTMLElement;
    scrollContainer.addEventListener('wheel', (event) => {
      event.preventDefault();
      scrollContainer.scrollLeft += event.deltaY;
    });
  }

  //scroll timeline on click
  timelineScrollOnClick() {
    const scrollContainer = document.getElementById("scroll-container");
    const scrollLeftButton = document.getElementById("scroll-left-button");
    const scrollRightButton = document.getElementById("scroll-right-button");
    scrollLeftButton.addEventListener("click", () => {
      scrollContainer.scrollLeft -= 10;
    });
    scrollRightButton.addEventListener("click", () => {
      scrollContainer.scrollLeft += 10;
    });
  }

  //toggle full timeline view
  viewFullTimeline() {
    this.isFullView = !this.isFullView;
  }

  //toggle visualization block
  openMilestoneBlock(index) {
    this.milestoneBlockIndex = index;
    this.visualizations[index].showMilestone = !this.visualizations[index].showMilestone;
    this.isVisulizationEdit = !this.isVisulizationEdit;
  }

  //search milestone
  searchTimeline() {
    if (this.timelinesearch) {
      this.timeLineMilestoneData = this.filteredTimelineMilestone.filter(obj => {
        let isMatched = true;
        if (this.timeLineMilestoneData) {
          if (!obj.name) {
            isMatched = false;
          } else {
            isMatched = obj.name.toLowerCase().indexOf(this.timelinesearch.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.timeLineMilestoneData = this.filteredTimelineMilestone;
          }
        }
        return isMatched;
      })
    } else {
      this.timeLineMilestoneData = this.filteredTimelineMilestone;
    }
  }

  //add timeline milestone
  addMilestone(item, visualizationId, index) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);

    if (visualizationIndex > -1) {
      const visualization = this.visualizations[visualizationIndex];

      if (!visualization.milestone) {
        visualization.milestone = [];
      }
      if (!visualization.chronologicalSortedMilestones && !visualization.colorSortedMilestones) {
        visualization.chronologicalSortedMilestones = [];
        visualization.colorSortedMilestones = [];
      }

      // Check if the item already exists in the visualization.milestone array
      const itemExists = visualization.milestone.some(m => m.name === item.name && m.mad === item.mad && m?.blockName == item?.blockName);

      if (!itemExists) {
        visualization.chronologicalSortedMilestones.push(item);
        visualization.colorSortedMilestones.push(item)
        visualization.milestone.push(item);
      }

      this.visualizations[visualizationIndex] = visualization;
      this.visualizations.forEach(v => {
        if (this.milestoneFilterValue === 'Both') {
          this.isBothSelected = true;
        } else {
          this.isBothSelected = false;
        }
        v.milestone.sort((a, b) => this.sortMilestoneByMad(a, b, index));
      });
    }

    return item;
  }

  removeMilestone(item, visualizationId) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      const visualization = this.visualizations[visualizationIndex];
      if (visualization.milestone) {
        visualization.milestone = visualization.milestone.filter(function (obj) {
          return obj !== item;
        });
        if (visualization.chronologicalSortedMilestones && visualization.colorSortedMilestones) {
          visualization.chronologicalSortedMilestones = visualization.chronologicalSortedMilestones.filter(function (obj) {
            return obj !== item;
          });
          visualization.colorSortedMilestones = visualization.colorSortedMilestones.filter(function (obj) {
            return obj !== item;
          });
        }
        this.visualizations[visualizationIndex] = visualization;
      }
    }
    return item;
  }

  //drag milestone
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      //update timeline for filter
      this.filteredTimelineMilestone = this.filteredTimelineMilestone.filter(function (obj) {
        return obj !== event.item.data;
      });
      //below code for move item in same container
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    } else {
      //update timeline for filter
      this.filteredTimelineMilestone = this.filteredTimelineMilestone.filter(function (obj) {
        return obj !== event.item.data;
      });
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  createVisualization() {
    const visualization = {
      id: Date.now(),
      name: `Visualization ${this.visualizations.length + 1}`,
      milestone: [],
      showMilestone: false,
      selectedColors: this.namesAndColors,
      isBothSelected: false
    };
    this.visualizations.push(visualization);

    // open visualization by default
    this.openMilestoneBlock(this.visualizations.length - 1);

    // scroll to bottom of page
    setTimeout(() => {
      this.moveToBottom();
    }, 200);
  }

  editVisualizationName(visualizationNameElement, visualizationId) {
    if (!visualizationNameElement) {
      return;
    }
    const originalName = this.visualizations[visualizationId].name;
    const input = document.createElement("input");
    input.type = "text";
    input.value = originalName;
    input.addEventListener("blur", () => {
      const newName = input.value.trim();
      if (newName) {
        this.visualizations[visualizationId].name = newName;
        // update visualization name in UI
        visualizationNameElement.innerHTML = newName;
      } else {
        // reset the visualization name if input value is empty
        visualizationNameElement.innerHTML = originalName;
      }
      // remove the input element from the DOM
      input.remove();
    });
    input.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        input.blur();
      }
    });
    visualizationNameElement.innerHTML = "";
    visualizationNameElement.appendChild(input);
    input.focus();
    // select the input text
    input.select();

  }

  deleteVisualization(visualizationIndex) {
    // remove visualization UI element from the DOM
    this.visualizations.splice(visualizationIndex, 1);
  }

  downloadTimeline() {
    this.modal = this.modalService.open(DownloadTimelineComponent, { size: 'lg' });
    this.modal.componentInstance.visualizations = this.visualizations;
    this.modal.componentInstance.timelineData = this.timelineData;
    this.modal.componentInstance.isBothSelected = this.isBothSelected;
    if (this.isFullView) {
      this.modal.componentInstance.isFullTimeline = this.isFullView;
      this.modal.componentInstance.productTimeline = this.productTimeline;
      this.modal.componentInstance.campaignTimeline = this.campaignTimeline;
    } else {
      this.modal.componentInstance.isFullTimeline = false;
    }
  }

  filterMilestone(event: any, index: number) {
    let value = event.target.value;
    this.milestoneFilterValue = value;
    this.visualizations[index].milestone.sort((a, b) => this.sortMilestoneByMad(a, b, index));

    const selectedVisualization = this.visualizations[index];
    selectedVisualization.milestoneSelect = this.milestoneFilterValue;

    // Update isBothSelected based on visualization index
    selectedVisualization.isBothSelected = value === 'Both';
  }


  groupMilestoneByColor(milestones: any[]) {
    const groups = {};
    milestones.forEach(milestone => {
      const color = milestone.color;
      if (groups[color]) {
        groups[color].push(milestone);
      } else {
        groups[color] = [milestone];
      }
    });
    return groups;
  }

  sortMilestoneByMad = (a, b, index) => {
    if (this.milestoneFilterValue === 'Chronological order') {
      // sort by mad in descending order
      this.isBothSelected = false;
      const aMad = parseInt(a.mad.replace('M-', ''));
      const bMad = parseInt(b.mad.replace('M-', ''));
      return bMad - aMad;
    } else if (this.milestoneFilterValue === 'Timeline Order') {
      this.isBothSelected = false;
      if (a.blockColor === b.blockColor) {
        // Sort by mad in descending order for milestones with the same color
        const aMad = parseFloat(a.mad.replace(/M-/, ''));
        const bMad = parseFloat(b.mad.replace(/M-/, ''));
        return bMad - aMad;
      } else {
        // Sort by the order of appearance in the timeline for milestones with different colors
        if (this.visualizations[index]?.milestone) {
          const sortedMilestoneNames = this.timeLineMilestoneData
            .filter(item => this.visualizations[index].milestone.some(milestone => milestone.name === item.name))
            .map(item => item.name);
          const aIndex = sortedMilestoneNames.indexOf(a.name);
          const bIndex = sortedMilestoneNames.indexOf(b.name);
          return aIndex - bIndex;
        }
      }
    } else if (this.milestoneFilterValue === 'Both') {
      this.isBothSelected = true;

      // sort milestones first by color and then by mad within each color group
      if (this.visualizations[index]?.milestone) {
        const sortedByColorMilestones = this.visualizations[index].milestone.slice().sort((milestoneA, milestoneB) => {
          if (milestoneA.blockColor === milestoneB.blockColor) {
            const aMad = parseFloat(milestoneA.mad.replace(/M-/, ''));
            const bMad = parseFloat(milestoneB.mad.replace(/M-/, ''));
            return bMad - aMad;
          } else {
            const sortedMilestoneNames = this.timeLineMilestoneData
              .filter(item => this.visualizations[index].milestone.some(milestone => milestone.name === item.name))
              .map(item => item.name);
            const aIndex = sortedMilestoneNames.indexOf(milestoneA.name);
            const bIndex = sortedMilestoneNames.indexOf(milestoneB.name);
            return aIndex - bIndex;
          }
        });

        // sort milestones by mad in descending order regardless of color
        const sortedByMadMilestones = this.visualizations[index].milestone.slice().sort((milestoneA, milestoneB) => {
          const aMad = parseInt(milestoneA.mad.replace('M-', ''));
          const bMad = parseInt(milestoneB.mad.replace('M-', ''));
          return bMad - aMad;
        });

        this.visualizations[index].colorSortedMilestones = sortedByColorMilestones;
        this.visualizations[index].chronologicalSortedMilestones = sortedByMadMilestones;
      }
    }
  }


  moveToBottom() {
    const pageHeight = window.outerHeight;
    window.scrollBy({
      top: pageHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  addAllMilestones(visualizationId) {
    this.timeLineMilestoneData.forEach((item, index) => {
      this.addMilestone(item, visualizationId, index);
    });

    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      this.visualizations[visualizationIndex].isRemoveAllMilestone = true;
    }

    this.selectAllColors(visualizationId);
  }

  removeAllMilestones(visualizationId) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      this.visualizations[visualizationIndex].isRemoveAllMilestone = false;
    }

    this.timeLineMilestoneData.forEach((item, index) => {
      this.removeMilestone(item, visualizationId);
    });

    this.deselectAllColors(visualizationId);
  }

  getColorKeys() {
    const colorKeys: { name: string, blockColor: string }[] = [];

    this.timeline.forEach((item: any) => {
      const { name, blockColor } = item;
      colorKeys.push({ name, blockColor });
    });

    return colorKeys;
  }

  isSelected(color: string): boolean {
    return this.selectedColors.includes(color);
  }

  getOptionText(colorKey: any): string {
    if (this.selectedColors.length > 1) {
      return this.selectedColors.length.toString();
    } else {
      return colorKey.name;
    }
  }

  addColorGroup(visualizationId) {
    const selectedMilestone = [];
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    this.timeLineMilestoneData.forEach(item => {
      if (this.visualizations[visualizationIndex].selectedColors.includes(item.blockColor)) {
        selectedMilestone.push(item);
      } else {
        this.removeMilestone(item, visualizationId);
      }
    });

    if (selectedMilestone.length > 0) {
      selectedMilestone.forEach((item, index) => {
        this.addMilestone(item, visualizationId, index);
      });
    } else {
      const visualization = this.visualizations[visualizationIndex];
      visualization.isRemoveAllMilestone = false;
    }

    // Update selectedColors for the specific visualization
    if (visualizationIndex > -1) {
      this.visualizations[visualizationIndex].selectedColors = this.visualizations[visualizationIndex].selectedColors.slice();
    }
  }

  selectAllColors(visualizationId) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      const selectedColors = this.namesAndColors.map(color => color.blockColor);
      this.visualizations[visualizationIndex].selectedColors = selectedColors;
      this.addColorGroup(visualizationId);
    }
  }

  deselectAllColors(visualizationId) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      this.visualizations[visualizationIndex].selectedColors = [];
      this.addColorGroup(visualizationId);
    }
  }

  toggleMilestoneButton(visualizationId: number) {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);
    if (visualizationIndex > -1) {
      if (this.visualizations[visualizationIndex].isRemoveAllMilestone) {
        this.removeAllMilestones(visualizationId);
      } else {
        this.addAllMilestones(visualizationId);
      }
    }
  }

  // Add this method to the component
  getButtonTitle(visualizationId: number): string {
    const visualizationIndex = this.visualizations.findIndex(v => v.id === visualizationId);

    if (visualizationIndex > -1) {
      return this.translatePipe.transform(this.visualizations[visualizationIndex].isRemoveAllMilestone ? 'Remove All Milestones' : 'Add All Milestones');
    }

    return '';
  }

  async getNotificationTimeline() {
    if (this.loarealDummyDataService.mainTimeline) {
      this.timelineData = this.loarealDummyDataService.mainTimeline;
      this.summaryDetail = this.timelineData.criteria;
    } else {
      this.timelineData = this.loarealDummyDataService.filteredProjects[0];
      this.summaryDetail = this.timelineData.criteria;
    }
    try {
      this.assetBundle = JSON.parse(this.timelineData.assetList);
    } catch (e) {
      this.assetBundle = [];
    }
    this.assetStar = this.summaryDetail['Asset Bundle'];
    if (this.timelineData && this.timelineData.id) {
      const dateObj = new Date(this.timelineData.expectedMad);
      const formattedDate = dateObj.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('.');
      this.expectedMad = formattedDate;
      this.priority = this.timelineData.priority;

      // get destructured timeline data
      this.timeline = await this.ProjectV2Service.handleTimelineData(this.timelineData);

      // filter milestones
      this.timeLineMilestoneData = await this.ProjectV2Service.filterMilestonesFromTimeline(this.timeline);
      this.filteredTimelineMilestone = this.timeLineMilestoneData;
      this.namesAndColors = this.timeline
        .map(item => {
          if (item.items.length > 0) {
            return {
              name: item.name,
              blockColor: item.blockColor
            };
          }
        })
        .filter((item, index, self) => {
          return item && index === self.findIndex((t) => t.name === item.name);
        });
    }
  }

  assetBundleUpdated(e) {
    this.timelineData.assetList = JSON.stringify(e);
    this.projectV2ServiceService.createTimeline(this.timelineData).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (result: any) => {
        console.log(result);
      }
    });
  }
}
