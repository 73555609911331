<div class="progress-circle" [style.transform]="'rotate(-90deg)'">
  <svg [attr.width]="diameter" [attr.height]="diameter">

      <circle fill="none" [attr.stroke]="bgColor" class="bg-circle" [attr.cx]="position" [attr.cy]="position" [attr.r]="radius"
        [style.stroke-width]="strokeWidth" />
      <circle fill="none" class="progress-circle" [attr.stroke]="percentageColor" [attr.cx]="position" [attr.cy]="position"
        [attr.r]="radius" [style.stroke-dasharray]="strokeDasharray" [style.stroke-dashoffset]="strokeDashoffset"
        [style.stroke-width]="strokeWidth" />

  </svg>
</div>
