import { FileUploader, SignedURLUploadRequest, UploadNotificationRequest, UploadRequest } from "./file.uploader";
import { MTMCustomFile, TransferType } from "./mtm-custom-file";
import {
  listenerFileUploadCompleted,
  listenerResumableUploadFailed, listenerUploadProgressChanged,
  listenerUploadResumeFailed,
  listenerUploadStatusChanged
} from "./listeners";
import { TransferProgress } from "./transfer-progress";
import { Observable, Subject, empty, zip } from "rxjs";
import { TransferStatus } from "./transfer-status";
import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { ApiService, UrlSanitizer } from "../api.service";
import { TransferDbManagerService } from "./transfer-db-manager.service";
import { UploadChunkRecord, UploadRecord } from "./idx-schema";
import { take } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  LimitReachedPopupComponent
} from "../../../subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component";
import { SUBSCRIPTION_LIMIT } from "../../../subscriptions/models/const";
import { TranslatePipe } from "../../pipes/translate.pipe";
import { Injectable, SecurityContext } from "@angular/core";
import { HelperService } from "../helper.service";
import { IS_UNCANCELABLE_REQUEST } from "../../models";
import DOMPurify from "dompurify";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

const chunkSize = 16 * 1024 * 1024; //16Mb
const uploadDbChunkSize: number = 32 * 1024 * 1024; //32Mb

export interface PreUploadPayload {
  filesContentTypes: Record<string, string>;
  filesSizes: Record<string, number>;
  fileAddedTime: Record<string, number>;
  parentId?: string;
  directory: string;
  files: any;
  message: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class SignedUrlUploader implements FileUploader {
  constructor(private http: HttpClient,
    private apiService: ApiService,
    private dbManager: TransferDbManagerService,
    private modalService: NgbModal,
    private translatePipe: TranslatePipe) {
  }

  uploadFiles(request: UploadRequest) {
    this.constructUploadPipelines(request);
    this.preUpload(request as SignedURLUploadRequest);
  }

  myEmptyObservable: Observable<any> = empty();

  cancelUpload(file: MTMCustomFile): Observable<any> {
    if (file.currentRequest) {
      file.currentRequest.unsubscribe();
    }

    if (file.transferType != TransferType.ResumableSignedURL) {
      return this.myEmptyObservable;
    }

    const result$ = new Subject();
    this.http.delete(file.sessionUrl, {
      observe: 'response',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: () => {
        this.removeUploadFromDb(result$, file);
      }, error: (error: HttpErrorResponse) => {
        this.removeUploadFromDb(result$, file);
      }
    });

    return result$.asObservable();
  }

  pauseUpload(file: MTMCustomFile) {
    file.uploadStatusCode = TransferStatus.PAUSED;
    if (file.currentRequest) {
      file.currentRequest.unsubscribe();
    }
    listenerUploadStatusChanged.next(file);
    this.dbManager.updateUpload(file.dbFileObject.id, {
      status: TransferStatus.PAUSED
    }).subscribe();
  }

  resumeUpload(file: MTMCustomFile) {
    const wasInactive = file.uploadStatusCode == TransferStatus.INACTIVE;
    file.uploadStatusCode = TransferStatus.SENDING;
    file.retryCount = 0;
    listenerUploadStatusChanged.next(file);

    if (!wasInactive) {
      this.checkInterruptedUploadInfo(file);
      return;
    }

    const removeUploadAndNotify = (file: any) => {
      this.dbManager.deleteUpload(file.dbFileObject.id)
        .subscribe({
          next: () => {
            listenerUploadResumeFailed.emit(file);
            listenerResumableUploadFailed.emit(file);
          }
        })
    }

    this.dbManager.getUpload(file.dbFileObject.id)
      .subscribe({
        next: (data: UploadRecord | undefined) => {
          if (!data) {
            //corrupt data
            removeUploadAndNotify(file);
            return;
          }

          const totalParts = Math.ceil(data.totalSize * 1.0 / uploadDbChunkSize);
          this.dbManager.countUploadChunks(file.dbFileObject.id)
            .subscribe({
              next: (count: number) => {
                if (totalParts != count) {
                  //corrupt data
                  removeUploadAndNotify(file);
                  return;
                }

                this.handleUploadStep(file);
                this.checkInterruptedUploadInfo(file);
              }
            });
        }
      });
  }

  //get existing upload info from google cloud storage
  private checkInterruptedUploadInfo(file: MTMCustomFile) {
    console.log('file', file)
    console.log('file.sessionUrl', file.sessionUrl)
    const locationResponseRe = /bytes=(\d+)\-(\d+)/;
    let safeurl = UrlSanitizer.sanitizeUrl(file.sessionUrl)
    this.http.put(safeurl, null, {
      headers: {
        'Content-Range': `bytes */${file.fileSize}`
      },
      observe: 'response',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: () => {
        file.progress = 100;
        file.retryCount = 0;
        file.progress$.next({
          status: TransferStatus.UPLOADED,
          startIndex: file.fileSize - 1,
          step: 'next'
        });
      },
      error: (error) => {
        if (error.status === 308) {
          let rangeResp = error.headers.get('Range') || '';
          if (rangeResp) {
            let match = rangeResp.match(locationResponseRe);
            if (match && match.length >= 3) {
              file.retryCount = 0;
              let nextStart = parseInt(match[2].toString(), 10);
              file.progress$.next({
                status: TransferStatus.SENDING,
                startIndex: nextStart,
                step: ''
              });
              return;
            }
          }
        }
        file.progress$.next({
          status: TransferStatus.SENDING,
          startIndex: 0,
          step: ''
        });
      }
    })
  }

  private constructUploadPipelines(request: UploadRequest) {
    request.files.forEach(item => {
      switch (request.uploadType) {
        case TransferType.Reference:
          item.pipelines = [
            'preUpload',
            'postUpload'
          ];
          break;

        case TransferType.ResumableSignedURL:
          item.pipelines = [
            'preUpload',
            'resumableUpload',
            'postUpload',
            'triggerNotification'
          ];
          break;

        default:
          item.pipelines = [
            'preUpload',
            'upload',
            'postUpload',
            'triggerNotification'
          ];
          break;
      }

      const referenceRequest = request as SignedURLUploadRequest;
      if (referenceRequest) {
        item.asReference = request.uploadType == TransferType.Reference;
        item.postUploadUrl = referenceRequest.postUploadUrl;
        item.parentId = referenceRequest.parentId;
      }

      //TODO: add hooks here for processing pipelines

      if (item.pipelines.length > 0) {
        item.step = item.pipelines[0];
        listenerUploadStatusChanged.next(item);
      }

      this.handleUploadStep(item);
    });
  }

  private proceedNextUploadStep(mtmFile: MTMCustomFile) {
    let currentIndex = mtmFile.pipelines.indexOf(mtmFile.step);
    if (currentIndex > -1 && currentIndex < mtmFile.pipelines.length - 1) {
      currentIndex++;
      mtmFile.step = mtmFile.pipelines[currentIndex];
      listenerUploadStatusChanged.next(mtmFile);
      return;
    }

    mtmFile.step = 'done';
    const done = () => {
      listenerUploadStatusChanged.next(mtmFile);
      listenerFileUploadCompleted.emit(mtmFile);
      if (mtmFile.cleanUp) {
        mtmFile.cleanUp();
      } else {
        mtmFile.progress$.complete();
        mtmFile.progress$ = null;
      }
    }

    if (mtmFile.isResumable()) {
      this.dbManager.deleteUpload(mtmFile.dbFileObject.id).subscribe({
        next: () => {
          console.log(`deleted upload ${mtmFile.dbFileObject.id}`);
          done();
        },
        error: err => {
          console.error('failed to delete upload in indexed db', err);
        }
      });
    } else {
      done();
    }
  }

  private handleUploadStep(mtmFile: MTMCustomFile) {
    if (!mtmFile.progress$) {
      mtmFile.progress$ = new Subject<TransferProgress>();
    }

    mtmFile.progress$.subscribe({
      next: (progress: TransferProgress) => {
        mtmFile.startIndex = progress.startIndex;

        switch (progress.step) {
          case 'next':
            this.proceedNextUploadStep(mtmFile);
            break;
          case 'error':
            if (!navigator.onLine) {
              mtmFile.uploadStatusCode = TransferStatus.ERROR;
              listenerUploadStatusChanged.next(mtmFile);
              return;
            } else {

              mtmFile.retryCount++;
              if (mtmFile.retryCount >= mtmFile.maxRetry) {
                mtmFile.uploadStatusCode = TransferStatus.ERROR;
                this.triggerNotification(mtmFile);
                listenerUploadStatusChanged.next(mtmFile);
                return;
              }
            }
            break;
          default:
            if (progress.step) {
              mtmFile.step = progress.step;
            }
            break;
        }

        switch (mtmFile.step) {
          case 'resumableUpload':
            if (mtmFile.startIndex == 0) {
              if (!mtmFile.sessionUrl) {
                this.initiateResumableUpload(mtmFile);
              } else {
                //if resumed from inactive when no uploaded chunks
                this.uploadFileChunk(mtmFile);
              }
            } else {
              mtmFile.updatePercentage();
              this.dbManager.updateUpload(mtmFile.dbFileObject.id, {
                status: mtmFile.uploadStatusCode,
                step: mtmFile.step,
                percentage: mtmFile.progress,
                startIndex: progress.startIndex,
              }).subscribe();
              if (mtmFile.uploadStatusCode != TransferStatus.PAUSED) {
                mtmFile.uploadStatusCode = TransferStatus.SENDING;
                this.uploadFileChunk(mtmFile);
              }
            }
            break;

          case 'postUpload':
            this.postUpload(mtmFile);
            break;
          case 'upload':
            this.directUpload(mtmFile);
            break;
          case 'triggerNotification':
            this.triggerNotification(mtmFile);
            break;
        }
      },
      error: (error) => {
        mtmFile.uploadStatusCode = TransferStatus.ERROR;
      }
    });
  }


  private initiateResumableUpload(file: MTMCustomFile) {
    const initialUrl = file.dbFileObject.signedURL;
    const { progress$ } = file;
    const params: string[] = ['uploadType=resumable'];
    params.push(`name=${file.name}`);
    const initialUploadUrl: string = initialUrl;
    //don't encode the URL
    let safeurl = UrlSanitizer.sanitizeUrl(initialUploadUrl)

    file.currentRequest = this.http.post(safeurl, {}, {
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
        'x-goog-resumable': 'start'
      },
      observe: 'response',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: (response: HttpResponse<any>) => {
        let sessionUrl: string = response.headers.get('Location') || '';
        if (!sessionUrl) {
          progress$.error('no location header for session url from metadata');
          return;
        }
        file.sessionUrl = sessionUrl;
        console.log('session url', sessionUrl);
        this.recordUploadToDb(file);
      },
      error: (error) => {
        console.error(error);
        progress$.error(error);
      }
    });
  }


  private uploadFileChunk(file: MTMCustomFile) {
    const { sessionUrl, startIndex } = file;
    const startPart = Math.floor(startIndex / uploadDbChunkSize);
    const endIndex = Math.min(startIndex + chunkSize, file.size) - 1;
    const endPart = Math.floor(endIndex / uploadDbChunkSize);
    const requests: Array<Observable<UploadChunkRecord | undefined>> = [];
    const uploadId = file.dbFileObject.id;

    requests.push(this.dbManager.getUploadChunk(uploadId, startPart));
    if (endPart != startPart) {
      requests.push(this.dbManager.getUploadChunk(uploadId, endPart));
    }

    if (startPart >= 2) {
      this.dbManager.deleteUploadChunk(uploadId, startPart - 2).subscribe();
    }

    zip(...requests).pipe(take(1))
      .subscribe({
        next: (result: Array<UploadChunkRecord | undefined>) => {
          if (result[0] == undefined || (result[1] == undefined && requests.length == 2)) {
            return;
          }
          const totalBytes = endIndex - startIndex + 1;
          let bytesToUpload = new Uint8Array(totalBytes)
          const startPartIndex = startIndex % uploadDbChunkSize;
          const endPartIndex = endIndex % uploadDbChunkSize;

          if (result.length == 1) {
            bytesToUpload.set(result[0].content.slice(startPartIndex, endPartIndex + 1));
          } else if (result[1]) {
            const firstBytes = result[0].content.slice(startPartIndex);
            bytesToUpload.set(firstBytes);
            bytesToUpload.set(result[1].content.slice(0, endPartIndex + 1), firstBytes.length);
          }
          result = null; //try to free memory
          const buffer = bytesToUpload.buffer.slice(0, totalBytes);
          const locationResponseRe = /bytes=(\d+)\-(\d+)/;
          let safeurl = UrlSanitizer.sanitizeUrl(sessionUrl)

          //don't encode the URL !
          file.currentRequest = this.http.put(safeurl, buffer, {
            headers: {
              'Content-Range': `bytes ${startIndex}-${endIndex}/${file.size}`,
            },
            observe: 'events',
            context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true),
            reportProgress: true,
          }).subscribe({
            next: (response: HttpEvent<ArrayBuffer>) => {
              bytesToUpload = null; //try to free memory
              switch (response.type) {
                case HttpEventType.UploadProgress:
                  let percentage = Math.floor((file.startIndex + response.loaded) * 100 / file.fileSize);
                  if (percentage > 100) {
                    percentage = 100;
                  }
                  file.progress = percentage;
                  file.uploadStatusCode = TransferStatus.SENDING;
                  this.dbManager.updateUpload(file.dbFileObject.id, {
                    percentage: file.progress,
                    status: TransferStatus.SENDING
                  }).subscribe();
                  break;
                case HttpEventType.Response:
                  file.progress = 100;
                  file.retryCount = 0;
                  file.progress$.next({
                    status: TransferStatus.UPLOADED,
                    startIndex: endIndex,
                    step: 'next'
                  });
                  break;
              }

            },
            error: (error: HttpErrorResponse) => {
              if (error.status === 308) {
                let rangeResp = error.headers.get('Range') || '';
                let match = rangeResp.match(locationResponseRe);
                if (!match || match.length < 3) {
                  file.progress$.error('failed to get range header');
                  return;
                }
                file.retryCount = 0;
                let nextStart = parseInt(match[2].toString(), 10);
                file.progress$.next({
                  status: TransferStatus.SENDING,
                  startIndex: nextStart,
                  step: ''
                });
                return;
              }
              file.progress$.next({
                status: TransferStatus.ERROR,
                startIndex,
                error: error.message,
                step: 'error'
              })
            }
          });
        },
        error: (error) => {
          console.error(error);
          file.progress$.next({
            status: TransferStatus.ERROR,
            startIndex,
            error: error.message,
            step: 'error'
          });
        }
      });
  }

  private setupBatchNotification(request: SignedURLUploadRequest) {
    const preUploadResponse = request.files.filter(f => f.dbFileObject).map(f => f.dbFileObject);
    const notificationRequest: UploadNotificationRequest = {
      objects: preUploadResponse,
      referenceId: request.files[0].entity.id
    };
    this.apiService.newPost('/api/notifications/batch/files', notificationRequest, { uncancelable: true })
      .subscribe({
        next: (response: UploadNotificationRequest) => {
          const batchId = response.id;
          request.files.forEach((file: MTMCustomFile) => {
            if (!file.dbFileObject) {
              return;
            }
            file.notificationId = batchId;
            file.progress$.next({
              status: TransferStatus.SENDING,
              startIndex: 0,
              step: 'next'
            });
          });
        },
        error: (err) => {

        }
      });
  }

  private preUpload(request: SignedURLUploadRequest) {
    const mtmFiles = request.files;
    const contentTypes: Record<string, string> = {};
    const filesSizes: Record<string, number> = {};
    const filesAddedTime: Record<string, number> = {};

    mtmFiles.forEach(file => {
      contentTypes[file.fileName] = file.fileType || file.type || (file as any).contentType;
      filesSizes[file.fileName] = file.fileSize;
      filesAddedTime[file.fileName] = file.createTime || +new Date;

      if (!contentTypes[file.fileName]) {
        if (file.fileName?.endsWith('.psb')) {
          contentTypes[file.fileName] = 'application/vnd.3gpp.pic-bw-small';
        } else if(file.fileName?.endsWith('.psd')) {
          contentTypes[file.fileName] = 'image/vnd.adobe.photoshop';
        }
      }
    });

    const payload: PreUploadPayload = {
      filesContentTypes: contentTypes,
      filesSizes: filesSizes,
      fileAddedTime: filesAddedTime,
      parentId: request.parentId || '00000000-0000-0000-0000-000000000000',
      directory: '/',
      files: null,
      message: null
    };



    let safeurl = UrlSanitizer.sanitizeUrl(request.preUploadUrl)

    this.apiService.newPost(safeurl, payload, { uncancelable: true })
      .subscribe({
        next: (response: any) => {

          response.forEach((dbElement: any) => {
            const index = mtmFiles.findIndex(item => item.fileName === dbElement.displayName);
            if (index == -1) {
              return;
            }
            const mtmFile = mtmFiles[index];
            mtmFile.setDbFileObject(dbElement);
            mtmFile.preUploadUrl = request.preUploadUrl;

            if (mtmFile.extraHandlers && mtmFile.extraHandlers['previewUrl']) {
              try {
                mtmFile.extraInfo.previewUrl = mtmFile.extraHandlers['previewUrl'](dbElement);
                delete mtmFile.extraHandlers['previewUrl'];
              } catch (ex) {
                console.log('error in previewUrl handler', ex);
              }
            }

            if (request.uploadType == TransferType.Reference) {
              mtmFile.progress$.next({
                status: TransferStatus.SENDING,
                startIndex: 0,
                step: 'next'
              });
            }
          });

          if (response.length > 0 && request.uploadType != TransferType.Reference) {
            this.setupBatchNotification(request);
          }

        },
        error: err => {
          let modalRef: NgbModalRef = null;
          if (err.errorCode === 'UPLOAD_TO_STORAGE_ERROR') {
            if (err.message) {
              if (err.message.includes('Number of files exceeded')) {
                modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
                modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.NUMBER_OF_FILES;
                modalRef.componentInstance.forceRedirect = false;
              } else if (err.message.includes('shortageInBytes')) {
                modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
                modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ACTIVE_STORAGE;
                modalRef.componentInstance.forceRedirect = false;
              }
            }
          }
          mtmFiles.forEach(mtmFile => {
            mtmFile.progress$.error({
              error: err,
              handled: true
            });
          });
        }
      });
  }

  private postUpload(file: MTMCustomFile) {
    let payload: any = {};

    if (file.asReference) {
      payload = [file.dbFileObject.id];
    } else {
      if (!file.postUploadPayload) {
        payload = [file.dbFileObject.id];
      } else {
        if (typeof file.postUploadPayload === 'string') {
          payload = JSON.parse(file.postUploadPayload);
        } else {
          payload = file.postUploadPayload;
        }
      }
    }
    //TODO: remove this postUploadCounter once upload test is done
    (window as any).postUploadCounter = (window as any).postUploadCounter || 1;
    let safeurl = UrlSanitizer.sanitizeUrl(file.postUploadUrl)

    file.currentRequest = this.apiService.newPost(safeurl, payload, { uncancelable: true })
      .subscribe({
        next: (response: any) => {
          try {
            let fileRef: any = null;
            if (response.files?.length) {
              fileRef = response.files.find(f => f.id == file.dbFileObject.id);
            } else if (response.length == 1) {
              fileRef = response[0].id == file.dbFileObject.id ? response[0] : null;
            }
            if (fileRef) {
              file.setDbFileObject(fileRef);
            }
            if (file.postUploadAttributes) {
              file.setPostUploadAttributes(response);
            }
            //TODO: remove this postUploadCounter once upload test is done
            console.log(`finished post upload # ${(window as any).postUploadCounter}`);
            (window as any).postUploadCounter++;
          } catch (e) {
            console.error(e);
          }

          listenerUploadStatusChanged.emit(file);
          file.uploadStatusCode = TransferStatus.COMPLETED;
          file.progress$.next({
            status: TransferStatus.COMPLETED,
            startIndex: file.startIndex,
            step: 'next'
          });
        },
        error: (err) => {
          console.error('post upload request failed:', err);
          file.progress$.error({
            error: err
          });
        }
      });
  }

  private triggerNotification(file: MTMCustomFile) {
    const relevantStatusCodes = [TransferStatus.COMPLETED, TransferStatus.ERROR];
    if (!relevantStatusCodes.includes(file.uploadStatusCode) || !file.notificationId) {
      file.progress$.next({
        status: file.uploadStatusCode,
        startIndex: file.startIndex,
        step: 'next'
      });
      return;
    }

    const requestBody: any = {
      fileIds: [file.dbFileObject.id],
      id: file.notificationId,
      referenceId: file.entity?.id,
      success: !!(file.uploadStatusCode == TransferStatus.COMPLETED)
    };

    if (file.entity?.version) {
      requestBody.versionNumber = file.entity.version;
    }

    this.apiService.newPut(`/api/notifications/batch/files/${file.notificationId}`, requestBody, { uncancelable: true })
      .subscribe({
        next: (response: any) => {
          if (file.uploadStatusCode != TransferStatus.ERROR) {
            file.progress$.next({
              status: file.uploadStatusCode,
              startIndex: file.startIndex,
              step: 'next'
            });
          }
        },
        error: (err) => {
          console.error('trigger notification request failed:', err);
          if (file.uploadStatusCode != TransferStatus.ERROR) {
            file.progress$.next({
              status: file.uploadStatusCode,
              startIndex: file.startIndex,
              step: 'next'
            });
          }
        }
      });
  }

  private recordUploadToDb(file: MTMCustomFile) {
    this.recordUpload(file).subscribe({
      next: (success) => {
        this.recordUploadChunks(file, 0).subscribe({
          next: (success) => {
            //this.initiateResumableUpload(file);
            this.uploadFileChunk(file);
          },
        })
      }
    });
  }

  private recordUpload(file: MTMCustomFile): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.dbManager.getUpload(file.dbFileObject.id).subscribe({
      next: (upload: UploadRecord) => {
        if (upload) {
          subject.next(false);
          subject.complete();
          return;
        }

        const record = file.toUploadRecord();
        this.dbManager.addUpload(record).subscribe({
          next: () => {
            subject.next(true);
            subject.complete();
          },
          error: (err) => {
            console.error(err);
            subject.error(err);
          }
        })
      },
      error: (err) => {
        console.error(err);
        subject.error(err);
      }
    });

    return subject.asObservable();
  }

  private recordUploadChunks(file: MTMCustomFile, chunkIndex: number): Observable<boolean> {
    const startChunkIndex = chunkIndex * uploadDbChunkSize;

    if (startChunkIndex >= file.size) {
      return new Observable<boolean>(observer => {
        observer.next(true);
        observer.complete();
      });
    }

    const sourceFile = file.file;
    let endChunkIndex = Math.min(startChunkIndex + uploadDbChunkSize, file.size);
    const chunk = sourceFile.slice(startChunkIndex, endChunkIndex);
    const reader = new FileReader();
    const subject = new Subject<boolean>();
    reader.onload = async (readerEvent: any) => {
      const bytes = new Uint8Array(readerEvent.target.result as ArrayBuffer);
      const record: UploadChunkRecord = {
        uploadId: file.dbFileObject.id,
        part: chunkIndex,
        content: bytes
      };
      this.dbManager.addUploadChunk(record).subscribe({
        next: () => {

          this.recordUploadChunks(file, chunkIndex + 1).subscribe({
            next: () => {
              subject.next(true);
              subject.complete();
            }, error: (error) => {
              console.error(error);
              subject.error(error);
            }
          });
        },
        error: (error) => {
          console.error(error);
          subject.error(error);
        }
      });
    };
    reader.readAsArrayBuffer(chunk);
    return subject.asObservable();
  }

  private removeUploadFromDb(result$: Subject<any>, file: MTMCustomFile) {
    this.dbManager.deleteUpload(file.dbFileObject.id).subscribe({
      next: () => {
        file.cleanUp();
        result$.next(undefined);
        result$.complete();
      },
      error: (error) => {
        console.error(error);
        result$.error(error);
      }
    });
  }

  //non resumable upload
  private directUpload(file: MTMCustomFile) {
    const signedUrl = file.dbFileObject.signedURL;
    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name && name != 'Content-Type') {
        headers[name] = values[0];
      }
    });
    headers['Content-Type'] = file.file.type;

    //don't encode the url
    let safeurl = UrlSanitizer.sanitizeUrl(signedUrl)

    file.currentRequest = this.http.put(safeurl, file.file, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: (response: any) => {
        switch (response.type) {
          case HttpEventType.UploadProgress:
            file.uploadStatusCode = TransferStatus.SENDING;
            const actualPercentage = 100 * response.loaded / response.total
            file.progress = Math.round(actualPercentage);
            if (file.progress > 0) {
              this.calculateRemainingTime(file, actualPercentage);
              listenerUploadProgressChanged.next(file);
            }
            break;
          case HttpEventType.Response:
            file.progress = 100;
            file.extraInfo.remainingTime = 0;
            file.uploadStatusCode = TransferStatus.UPLOADED;
            file.progress$.next({
              status: TransferStatus.UPLOADED,
              startIndex: file.fileSize,
              step: 'next'
            });
            break;
        }
      },
      error: (error) => {
        console.error(error);
        file.uploadStatusCode = TransferStatus.ERROR;
        file.progress$.error({
          error: error
        });
      }
    });
  }

  private calculateRemainingTime(file: MTMCustomFile, percentage: number) {
    const elapsedTime = Date.now() - file.createTime;
    const expectedDuration = 100 / file.progress * elapsedTime;
    file.extraInfo.remainingTime = expectedDuration - elapsedTime;
  }
}
