import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';

export interface Mission {
  id: string;
  sequence: number;
  exerciseTitle: string;
  instruction: string;
  failMessage: string;
  attemptExceedMessage: string;
  hint: string;
  completionMessage: string;
  modalPosition: string;
  targetElementId: string;
  elementBackground: string;
  scrollTo: any;
  pathUrl: string;
  nextPathUrl: string;
  totalAttempt: number;
  usedAttempt: number;
  completionPoint: number;
  nextMissionDelay: number;
  inProgress: boolean;
  completed: boolean;
  numberOfAttempt?: 0;
}

export interface ScenarioInterface  {
  id: string
  scenarioName: string;
  description: string;
  totalPoint: number;
  missions: Mission[];
}

@Injectable({
  providedIn: 'root'
})

export class SelfTrainerService {
  public playPause: EventEmitter<boolean> = new EventEmitter<boolean>();
  startSelfTrainer: boolean = false;
  allMissionsCommpleted: boolean = false;
  showSelfTrainerModal: boolean = false;
  showDangerOverlay: boolean = false;
  isUserPerformingTask: boolean = false;
  showCompletionMessage: boolean = false;
  isLoading: boolean = false;
  selfTrainerStepData: object = {};
  scenario: any;
  missions: any[]=[];
  activeMission: Mission = null;
  totalPoints: number = 0;
  scoredPoints: number = 0;
  clickCount: number = 0;
  usedAttempt: number = 0;
  targetElementRect: any = null;
  mainOverlayPosition: string = 'fixed';
  completedMissions:Mission[] =[];
  completedScenarioMissions: ScenarioInterface ;
  isMissionPaused: boolean = false;
  missionOnHold: Mission = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService
  ) {
    // get scenario from json
    // this.getScenario().subscribe((item: any) => {
    //   this.scenario = item[0];
    //   this.missions = this.scenario.missions;
    //   console.log('this.missions.length', this.missions.length)
    //   this.completedScenarioMissions = this.scenario;
    //   this.completedScenarioMissions['scenarioId'] = this.scenario.id;
    
    //   this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
    //   console.log('this.missions', this.missions)
    //   this.totalPoints = this.missions.length * 100;
    // });

    // get AllScenario  from api
    // this.getAllScenarioById('510b013f-362c-499d-83f7-a36c13c24072').subscribe((scenario:ScenarioInterface)=>{
    //   console.log('scenario', scenario)
    //   this.scenario = scenario;
    //   console.log('this.scenario', this.scenario)
    //   this.missions = this.scenario.missions;
    //   this.completedScenarioMissions = this.scenario;
    //   this.completedScenarioMissions.missions = [];
    
    //   this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
    //   console.log('this.missions', this.missions)
    //   this.totalPoints = this.missions.length * 100;
    // });
    
    // get current user scenario and missions
    // this.getCurrentUserScenarios();
    
    this.waitForAction();
    
  }

  /**
   * @returns current user scenarios 
   */
  getCurrentUserScenarios(username: string): Observable<ScenarioInterface> {
    return this.apiService.httpGet(`/api/gamifications/user-activities/${username}/current`).pipe(
        map((response: any) => {
            return response as ScenarioInterface;
        })
    );
}

  /**
   * 
   * @returns all mission from json file
   */
  getScenario(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/scenarios.json');
  }

  /**
   * 
   * @returns all scenarios using scenario id
   */
  getAllScenarioById(scenarioId: string): Observable<ScenarioInterface> {
    // scenarioId = '4c9da07c-3a34-4e62-9b79-b5cb2250f926';
    return this.apiService.httpGet(`/api/gamifications/scenarios/${scenarioId}`).pipe(
      map((response: any) => {
        // Assuming the response contains the mission object
        return response as ScenarioInterface;
      })
    );
  }

  /**
   * 
   * @returns all scenarios based comapny
   */
  getScenarioByCompanyId(companyId: string): Observable<any> {
    return this.apiService.httpGet(`/api/gamifications/scenarios/${companyId}/comapny`)
  }

  /**
   * 
   * @returns current user gamification activity 
   */
  getCurrentUserActivity():Observable<Mission>{
    return this.apiService.httpGet(`/api/gamifications/user-activities/current`).pipe(
      map((response: any) => {
        // Assuming the response contains the mission object
        return response as Mission;
      })
    );
  }

  /**
   * 
   * @returns all missions using scenario id 
   */
  getAllMissionsByScenarioId(scenarioId: string):Observable<Mission>{
    return this.apiService.httpGet(`/api/gamifications/missions/${scenarioId}/scenarios`).pipe(
      map((response: any) => {
        return response as Mission;
      })
    );
  }

  /**
   * 
   * create or update mission using scenarioid
   */
  createOrUpdateMissionByScenarioId(scenarioId: string, missions: Mission): Observable<any> {
    return this.apiService.httpPost(`/api/gamifications/missions/${scenarioId}`, missions);
  }

  /**
   * 
   * delete any mission using mission id and scenario id
   */
  deleteMissionById(missionId: string, scenarioId: string): Observable<any> {
    return this.apiService.httpDelete(`/api/gamifications/missions/${missionId}/scenario/${scenarioId}`);
  }
  
  /**
   * 
   * @returns update mission which is completed by user 
   */
  updatesUserActivity(completedMission:Mission) {
    completedMission.inProgress = false;
    completedMission.completed = true;
    this.completedScenarioMissions.missions.push(completedMission)
    // console.log('send completedMission', completedMission)
    return this.apiService.httpPost('/api/gamifications/user-activities',completedMission).pipe(
      map((response: any) => {   
      })
    );
  }

  /**
   * Leader board api section 
   */

  /**
   * @returns leader board users
   */

  getLeaderBoardUserByScenarioId(url: string,token:String): Observable<any> {
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtb2hpdC5idW1iK2xvcmVhbEBtdG0udmlkZW8iLCJleHAiOjE3MTg0NDM1NTZ9.bdDQdk-8nVkpDCd_JUtwMzW9wuE4kErU9lpnPZxEH4Jf9HBaqtHBX_sqG2hTMidHmpo1okOYFeHTy-WkfOQyPg';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get(url, { headers });
  }


  /**
   * @returns detail of leader board by scenario id and user email
   */
  getLeaderBoardDetail(url: string ,token:string){
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtb2hpdC5idW1iK2xvcmVhbEBtdG0udmlkZW8iLCJleHAiOjE3MTg0NDM1NTZ9.bdDQdk-8nVkpDCd_JUtwMzW9wuE4kErU9lpnPZxEH4Jf9HBaqtHBX_sqG2hTMidHmpo1okOYFeHTy-WkfOQyPg';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get(url, { headers });
    // return this.http.get(url);
    // return this.apiService.httpGet(`/api/gamifications/leaderboard/${scenarioId}/users/${userEmail}/detail`)
  }

  /**
   * End
   */


  /**
   * @returns login detail
   */
  async newLogin(trainerUserName?: string): Promise<any> {
    const body = { username: trainerUserName };
    const url = '/api/auth/login/training';
    return this.apiService.httpPost(url, body).toPromise();
  }
  
  activateTargetElement() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    // if (targetElement) {
      this.scrollToTargetElement();
      targetElement.style.position = "relative";
      targetElement.style.zIndex = "99999";
    // }
  }

  deactivateTargetElement() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    if (targetElement) {
      this.scrollToTargetElement();
      targetElement.style.zIndex = "9";
    } else {
      console.warn('target element already deactivated')
    }
  }

  activateHint() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    if (this.activeMission.elementBackground) {
      targetElement.style.backgroundColor = this.activeMission.elementBackground;
    }
    this.scrollToTargetElement();
    const elementRect = targetElement.getBoundingClientRect();
    this.selfTrainerStepData = {
      id: this.activeMission.id,
      text: this.activeMission.hint,
      position: this.activeMission.modalPosition,
      rect: elementRect,
    }
    this.showSelfTrainerModal = true;
  }

  scrollToTargetElement() {
      const targetElement = document.getElementById(this.activeMission.targetElementId);
      // if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: this.activeMission.scrollTo,
        });
        // }
  }

  startSelfTrainerMission() {
    if (!this.activeMission) {
      this.activeMission = this.missions[0];
      const url = this.activeMission.pathUrl;
      this.router.navigate([`${url}`]);
    }
    this.activateTargetElement();
    this.isLoading = false;
    this.showCompletionMessage = false;
    this.isUserPerformingTask = false;
    this.startSelfTrainer = true;
  }

  getHelpText() {
    switch (this.clickCount) {
      case 0:
      case 1:
      case 2:
        if (this.showDangerOverlay) {
          return this.activeMission?.failMessage;
        } else {
          return this.activeMission?.instruction;
        }

      case 3:
        if (this.showSelfTrainerModal) {
          return this.activeMission?.attemptExceedMessage;
        } else {
          return this.activeMission?.instruction;
        }

      default:
        if (this.showDangerOverlay) {
          return this.activeMission?.failMessage;
        } else if (this.showSelfTrainerModal) {
          return this.activeMission?.attemptExceedMessage;
        } else {
          return this.activeMission?.instruction;
        }
    }
  }

  updateUsedAttempt() {
    if (this.usedAttempt < 3) {
      this.usedAttempt++;
    }
  }

  hideTrainerModalAndStartTask() {
    if (this.activeMission) {
      this.updatesUserMissionActivity(this.activeMission);
    }
    this.updatesUserActivity(this.activeMission);
    this.showDangerOverlay = false;
    this.showSelfTrainerModal = false;
    this.isUserPerformingTask = true;
    this.isLoading = false;
    this.clickCount = 0;
    // this.isMissionPaused = false;
    this.deactivateTargetElement();
    this.startNextMission();
  }

  /**
     * 
     * @returns update mission which is completed by user 
     */
  updatesUserMissionActivity(mission: Mission) {
    const foundItem = this.completedMissions.find(item => item.id === mission.id);
    if (!foundItem) {
      this.scoredPoints += mission.completionPoint
      this.completedMissions.push(mission);
      // delete this.completedScenarioMissions['description'];
      // delete this.completedScenarioMissions['totalPoint'];
      // delete this.completedScenarioMissions['scenarioName'];

      // this.completedScenarioMissions['id'] = mission.id;
      // this.completedScenarioMissions.missions = []
      // this.completedScenarioMissions.missions.push(mission);
      // console.log('this.completedScenarioMissions', this.completedScenarioMissions)
      // // this.updatesUserActivity(this.completedScenarioMissions);
      // this.updatesUserActivity(this.completedScenarioMissions).subscribe({
      //   next:(data)=>{
      //     console.log('data', data)
      //   },
      //   error:(err)=>{
      //     console.error('err', err)
      //   }
      // })
    }
  }

  showCompletionMessageModal() {
    this.isLoading = false;
    this.showCompletionMessage = true;
  }

  handleCompletionModalNextBtn() {
    // console.log("next button function called!");
    this.showCompletionMessage = false;
    this.isLoading = true;
    const nextPageUrl = this.activeMission?.nextPathUrl;
    // console.log('nextPageUrl', nextPageUrl)
    this.router.navigate([`${nextPageUrl}`]);
    this.hideTrainerModalAndStartTask();
  }

  startNextMission() {
    if (this.activeMission && !this.isMissionPaused) {
      this.isLoading = true;
      const currentMissionId = this.activeMission.id;
      const missionCompleted = this.completedMissions.find(mission => {
      if (mission.id === this.activeMission.id ) {
          return mission
        }
      });
    const nextMissionOnHold = this.missions.find((item: any)=> item.sequence === missionCompleted.sequence+1)
      const currentMissionSequence = this.activeMission.sequence;
      const nextMissionSequence = currentMissionSequence + 1;
      const nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
      this.missionOnHold = nextMission;

      // if (!this.isMissionPaused) {
      if (nextMission) {
        setTimeout(() => {
          this.activeMission = nextMission;
          this.startSelfTrainerMission();
        }, nextMission.nextMissionDelay);
      } else {
        // console.log("No mission left!!");
        this.allMissionsCommpleted = true;
        this.isLoading = true;
        this.activeMission = null;
        setTimeout(() => {
          this.isLoading = false;
          this.showCompletionMessage = true;
        }, 3000);
      }
    } else {
      this.activeMission = null;
      this.isLoading = false;
    }
    // }
  }

  handleStartAgainBtn() {
    this.showCompletionMessage = false;
    this.isLoading = false;
    this.showDangerOverlay = false;
    this.showSelfTrainerModal = false;
    this.isUserPerformingTask = false;

    // reset all values
    this.totalPoints = 0;
    this.scoredPoints = 0;
    this.clickCount = 0;
    this.usedAttempt = 0;

    // restart trainer
    const url = this.missions[0].pathUrl;
    this.router.navigate([`${url}`]);
    this.isLoading = true;
    setTimeout(() => {
      this.startSelfTrainerMission();
    }, 5000);
  }

  waitForDomUpdation() {
    this.isLoading = true;
  }

  // waiting for user operation once click on next function will trigger
  waitForAction(){
    this.playPause.subscribe(data => {
      this.isMissionPaused = false;
      if (data) {
        this.activeMission = this.missionOnHold;
        this.hideTrainerModalAndStartTask();
      } 
    })
  }

  playNextMission() {
    this.isMissionPaused = false;
    if (this.missionOnHold) {
      this.activeMission = this.missionOnHold;
    }
        // this.hideTrainerModalAndStartTask();

    // console.log('this.completedMissions', this.completedMissions);
    // console.log('this.missions', this.missions);
    // const currentMissionSequence = this.missionOnHold.sequence;
    // const nextMissionSequence = currentMissionSequence + 1;
    // const nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
    // this.activeMission = nextMission;
    // console.log('playNextMissionnextMission', nextMission)
  }
  updateActiveMissionForCreateTask(){
     // console.log('this.missions', this.missions);
     this.isMissionPaused = false;
     if (this.missionOnHold) {
       const currentMissionSequence = this.missionOnHold.sequence;
       const nextMissionSequence = currentMissionSequence + 1;
       const nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
       this.activeMission = nextMission;
      }
    }
}
