<div class="col-lg-12 col-md-12 col-sm-1 container" [style.max-width]="'1239px'"
	[ngStyle]="{'display': isNotificationView || productionView ? 'none' :'block' }"
	*ngIf="!isNotificationView || !productionView">
	<h4 class="text-left mt-3 pl-3 c-full-white" *ngIf="!project">{{status | translate}}</h4>
	<h4 class="text-left mt-3 pl-3 c-full-white" *ngIf="project">{{project.name}}</h4>
	<!-- <h5 class="text-left mt-3 pl-3 text-uppercase c-full-white" *ngIf="!project">{{links[index]?.text || '' | translate }}</h5>
	<h6 class="text-left mt-3 pl-3" *ngIf="!project">{{links[index]?.subText || '' | translate:{'TOTALSECTIONS':totalSections} }}</h6> -->
	<p class="text-left pl-3">
	</p>
	<ul class="list-inline steps">
		<li class="list-inline-item"
			[ngClass]="{ 'active': activePage == 'general' }">
			<a (click)="goToLink('general')">
				<i *ngIf="isComplete('general')" class="fa fa-check-circle" aria-hidden="true"></i>
				<div *ngIf="!isComplete('general')" class="step-number">{{1}}</div>
				<span class="text-uppercase">{{'general' | translate}}</span>
			</a>
		</li>
		<li class="list-inline-item"
			[ngClass]="{ 'active': activePage == 'recap' }">
			<a (click)="goToLink('recap')" [class.disabled]="!project">
				<i *ngIf="isComplete('recap')" class="fa fa-check-circle" aria-hidden="true"></i>
				<div *ngIf="!isComplete('recap')" class="step-number">{{2}}</div>
				<span class="text-uppercase">{{'recap' | translate}}</span>
			</a>
		</li>
		<div *ngIf="this.isTimeLineId" class="reset-btn-div align-items-center">
			<a class="btn mtm-button c-mtm-full-white _600 f-12 d-flex align-items-center p-0"
				(click)="goToLink('general')">{{'generateNewTimeLine' | translate}}</a>
		</div>
	</ul>
</div>
<router-outlet></router-outlet>