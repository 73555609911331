import {MTMFileDownload} from "app/shared/services/signed-url/mtm-file-download";
import {Component, EventEmitter, inject, Input, Output} from "@angular/core";
import {FileTransferService, TransferStatus} from "app/shared/services/signed-url";
import {Router} from "@angular/router";

@Component({
  selector: 'mtm-download-popup-item',
  templateUrl: './download-popup-item.component.html',
  styleUrls: ['./download-popup-item.component.scss']
})
export class DownloadPopupItemComponent {
  @Input() file: MTMFileDownload;

  transferService = inject(FileTransferService);
  router = inject(Router);

  transferStatus = TransferStatus;
  inProgressStatusList = [TransferStatus.WAITING, TransferStatus.DOWNLOADING];
  resumableStatusList = [TransferStatus.INACTIVE, TransferStatus.PAUSED];

  stopEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  pauseDownload(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.pauseDownload(this.file);
  }

  resumeDownload(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.resumeDownload(this.file);
  }

  cancelDownload(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.cancelDownload(this.file);
  }

  removeFromList(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.cancelDownload(this.file);
  }

  handleDownloadError(event: MouseEvent) {
    if (this.file?.isResumable) {
      this.transferService.resumeDownload(this.file);
    } else {
      this.removeFromList(event);
    }
  }

}
