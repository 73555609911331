import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/shared/interfaces';
import { AuthService } from 'app/shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { TimelineService } from 'app/shared/services/timeline.service';
import { UserService } from 'app/shared/services/user.service';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'mtm-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
  @Input() workspaceId: string;
  authUser: any;
  ngUnsubscribe = new Subject();
  participants: any[] = [];
  backupParticipants: any[] = [];
  existingOwners: any[] = [];
  teams: any = [];
  searchInput: string;
  isDropDownOpen: boolean = false;
  selectedUser: any[]=[];
  selectedUserDetail: any;
  selectedTeam:any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    public timelineService: TimelineService,
    private overlayService: OverlayService
  ) {
    // this.searchInput$.pipe(
    //   debounceTime(300) // Adjust the debounce time as needed
    // ).subscribe(value => {
    //   this.searchParticipants(value);
    // });
  }


  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.getParticipants();
    this.getTeams();
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  getParticipants() {
    this.userService.getUsersByCompanyId(this.authUser.companyId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (users) => {
          this.participants = users;
          this.backupParticipants = users;
          this.participants.filter(participant => {
            participant.selected = false;
            return participant;
          });
          this.backupParticipants.filter(participant => {
            participant.selected = false;
            return participant;
          });
        },
        error: (err) => {
          console.error('err', err);
        },
        complete: () => {

        },
      })
  }

  getTeams() {
    this.userService.getWorkspaceTeams(this.workspaceId).subscribe({
      next: (value: any) => {
        if(value.length) {
          value.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }        
        this.teams = value.filter(w => w.projectId == HelperService.getEmptyUUID());
      },
      error: (err) => {
        console.error('err', err);
      },
      complete: () => {

      },
    })
  }

  searchParticipants() {
    this.isDropDownOpen = true;
    if (this.searchInput) {
      this.participants = this.backupParticipants.filter(user => {
        let isMatched = true;
        if (this.searchInput) {
          if (!user.fullName) {
            isMatched = false;
          } else {
            isMatched = user.fullName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.participants = this.backupParticipants;
          }
        }
        return isMatched;
      });
    } else {
      this.participants = this.backupParticipants;
    }
  }

  selectUser(user) {
    console.log('user', user);
  
    const { username } = user;
    const indexOfUsername = this.selectedUser.findIndex(selectedUser => selectedUser.username === username);
  
    // Deselect all other users
    this.selectedUser.forEach(selectedUser => {
      selectedUser.selected = false;
    });
  
    if (indexOfUsername === -1) {
      // The participant is not in the list, so add them.
      this.selectedUser = [user];
      user.selected = true;
      this.selectedUserDetail = { ...user };
    } else {
      // The participant is already in the list, so remove them.
      this.selectedUser = [];
      this.selectedUserDetail = null;
    }
  }
  

  cancel() {
    this.activeModal.close();
  }

  onTeamSelect(event: any) {
    const selectedValue = event.target.value;
    this.selectedTeam = this.teams.find(team => team.id === selectedValue);
  }

  addTeamMember(){
    if (!this.selectedTeam) {
      this.overlayService.showError('Please select a team', 'Error');
      return;
    }
    
    if (this.selectedTeam.members.includes(this.selectedUserDetail.username)) {
      this.overlayService.showWarning('User is already a member of the team.');
      return;
    }
    
    this.selectedTeam.members.push(this.selectedUserDetail.username);
    
    this.userService.addTeamMember(this.selectedTeam)
      .pipe(
        tap({
          next: (value: any) => {
            console.log('Updated team', value);
            // Additional logic if needed
          },
          error: (err) => {
            console.log('Error', err);
          },
          complete: () => {
            this.overlayService.showSuccess('Member added successfully', 'Success');
            this.cancel();
          }
        })
      )
      .subscribe();
    
  }
}
