import {Pipe, PipeTransform} from "@angular/core";
import {HelperService} from "../services/helper.service";

@Pipe({
  name: 'initialName',
})
export class InitialNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    return HelperService.getInitial(value);
  }
}
