import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { OverlayService } from "../../shared/services/overlayService";
import { NotificationService } from "../../shared/services/notification.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'mtm-verify-2fa-form',
	templateUrl: './verify-2fa.component.html',
	styleUrls: ['./verify-2fa.component.scss']
})
export class Verify2faComponent implements OnInit, AfterViewInit, OnDestroy {
	private _mechanism: string = "sms";
	@Input() timeout: number = 180;
	@Input() email: string;
	private remainingAttempt: number = 3;
	private ngUnsubscribe = new Subject();
	private _maxAttempt: number = 3;
	private _timeoutEnabled = false;
	verifyForm: UntypedFormGroup;
	maxLength: number = 6;

	private countdownId: any = null;
	private remainingSeconds: number = 0;
	remainingTime: string = '';
	isLoading: boolean = false;

	@ViewChild("tfacode", { static: true }) codeRef: ElementRef;

	get mechanism(): string {
		return this._mechanism;
	}

	@Input()
	set mechanism(value: string) {
		this._mechanism = value;
		this.maxLength = value.toLowerCase() != 'authenticator' ? 4 : 6;
	}

	get maxAttempt(): number {
		return this._maxAttempt;
	}
	@Input()
	set maxAttempt(value: number) {
		this._maxAttempt = value;
		this.remainingAttempt = value;
	}

	get timeoutEnabled(): boolean {
		return this._timeoutEnabled;
	}

	@Input()
	set timeoutEnabled(value: boolean) {
		this._timeoutEnabled = value;
		this.clearTimer();
		this.remainingTime = '';
		if (value && this.timeout) {
			this.remainingSeconds = this.timeout;
			this.setTimer();
		}
	}

	constructor(private authService: AuthService, private fb: UntypedFormBuilder, private notificationService: NotificationService,
		private activeModal: NgbActiveModal
	) {
	}

	ngOnInit() {
		this.verifyForm = this.fb.group({
			'code': ['', Validators.required]
		});
	}

	ngAfterViewInit() {
		if (this.codeRef)
			this.codeRef.nativeElement.focus();
	}

	private clearTimer() {
		if (this.countdownId) {
			clearInterval(this.countdownId);
			this.countdownId = null;
		}
	}

	private setTimer() {
		this.countdownId = setInterval(
			() => {
				this.remainingSeconds--;
				if (this.remainingSeconds == 0) {
					clearInterval(this.countdownId);
					this.activeModal.dismiss();
				}
				this.remainingTime = this.formatTime(this.remainingSeconds);
			}, 1000);
	}

	private toggleCountdown(isOff: boolean) {
		if (isOff) {
			this.clearTimer();
		} else {
			this.setTimer();
		}

	}

	ngOnDestroy() {
		this.clearTimer();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	formatTime(seconds): string {
		const format = val => `0${Math.floor(val)}`.slice(-2)
		const hours = seconds / 3600
		const minutes = (seconds % 3600) / 60
		const formatArr = [];
		if (hours >= 1)
			formatArr.push(hours);
		formatArr.push(minutes);
		formatArr.push(seconds % 60);
		return formatArr.map(format).join(':')
	}

	verify() {
		const code = this.verifyForm.value.code;

		if (!code)
			return;

		this.isLoading = true;
		this.toggleCountdown(true);
		this.authService.verify2fa(this.email, code, this._mechanism)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: data => {
					this.activeModal.close(true);
				},
				error: error => {
					this.isLoading = false;
					this.remainingAttempt--;
					if (this.remainingAttempt > 0) {
						this.notificationService.open({
							title: 'MTM',
							description: `${error.message}\nPlease, try again.`,
							confirmBtn: 'Accept'
						});
						this.toggleCountdown(false);
					} else {
						console.error(error);
						this.activeModal.dismiss();
					}
				}
			});
	}

	cancel() {
		this.activeModal.dismiss();
	}
}
