import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { UiSwitchModule } from 'ngx-ui-switch';
import { HttpClientModule } from '@angular/common/http';
import { DragulaModule } from 'ng2-dragula';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { MomentModule } from 'angular2-moment';
import { LinkyModule } from 'angular-linky';
// import { Ng2FileDropModule } from "ng2-file-drop";
import { NgxFileDropModule } from 'ngx-file-drop';

// import { ImageCropperComponent } from "ng2-img-cropper";


/* ----------- * PIPES * ----------- */
import { KeysPipe } from 'app/shared/pipes/keys.pipe';
import { MtmCurrencyPipe } from 'app/shared/pipes/mtm-currency.pipe';
import { MtmDatePipe } from 'app/shared/pipes/mtm-date.pipe';
import { MtmDatePipe2 } from 'app/shared/pipes/mtm-date.pipe2';
import { ToTimePipe } from 'app/shared/pipes/number-to-time.pipe';
import { OrdinalPipe } from 'app/shared/pipes/ordinal.pipe'
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { FilterPipe } from 'app/shared/pipes/search-file.pipe';
import { FilterReviewPipe } from "app/shared/pipes/filter-review.pipe";
import { CleanerPipe } from "app/shared/pipes/cleaner.pipe";
import { ObjectToKeysPipe } from "app/shared/pipes/object-to-keys.pipe";
import { FilterSearchUserPipe } from "app/shared/pipes/filter-search-user.pipe";
import { FilterSortUserPipe } from "app/shared/pipes/filter-sort-user.pipe";
import { FilterSearchProjectPipe } from "app/shared/pipes/filter-search-projects.pipe";
import { ProjectSortingPipe } from 'app/shared/pipes/filter-sorting-project.pipe';
import { ParseUrl } from "app/shared/pipes/parse-url.pipe";
import { CleanUrl } from "app/shared/pipes/clean-link.pipe";
import { SearchCompanyPipe } from "app/shared/pipes/search-company.pipe";
import { SortCompanyPipe } from "app/shared/pipes/sort-company.pipe";
import { PipeDropdownSearch } from 'app/shared/components/mtm-dropdown';
import { MTMTelInputPipe } from 'app/shared/components/mtm-tel-input/pipe/mtm-tel-input.pipe';
import { ArraySortPipe } from "app/shared/pipes/array-sort.pipe";

/* ----------- * DIRECTIVES * ----------- */
import { UserAvatarDirective } from "app/shared/directives/user-avatar.directive";
import { GroupAvatarDirective } from "app/shared/directives/group-avatar.directive";
import { HasUserAccessDirective } from 'app/shared/directives/user-access.directive';
import { MtmCurrencyDirective } from "app/shared/directives/currency.directive";
import { CommentMinButtonsDirective } from 'app/shared/components/comment/comment-min-buttons/comment-min-buttons.directive';
import { CommentTextAreaDirective } from 'app/shared/directives/comment-textarea.directive';
import { MTMDatePickerDirective } from 'app/shared/directives/mtm-datepicker.directive';
import { SSProfilePreviewImageDirective } from 'app/shared/directives/ss-profile-preview-image.directive';
import { OneShotDownpaymentDirective } from "app/shared/directives/one-shot-downpayment.directive";
import { AssetPreviewDirective } from "app/shared/directives/asset-preview.directive";

/* ----------- * PAGES * ----------- */
import { ProjectCompanyComponent } from 'app/layouts/my-projects/new-project/project-company/project-company.component';

/* ----------- * COMPONENTS * ----------- */
import { MTMTelInputComponent } from 'app/shared/components/mtm-tel-input/mtm-tel-input.component';
import { LoadingDataComponent } from "app/shared/components/loading-data/loading-data.component";
import { MTMMainInfiniteScrol } from 'app/shared/components/mtm-main-infinite-scroll/mtm-main-infinite-scroll.component';
import { UploadFileComponent } from "app/shared/components/mtm-upload-file/upload-file.component";
import { NewUploadFilesPreviewThumpnailComponent } from 'app/shared/components/newupload-files-preview-thumpnail/newupload-files-preview-thumpnail.component';
import { MTMDropdown } from 'app/shared/components/mtm-dropdown';
import { UserCardComponent } from "app/shared/components/user-card/user-card.component";
import { ConversationCardComponent } from "app/shared/components/conversation-card/conversation-card.component";
import { EmbedVideoComponent } from 'app/shared/components/embed-video/embed-video.component';
import { CircleProgressComponent } from "app/on-boarding/profile-avatar/circle-progress/circle-progress.component";
import { ButtonComponent } from "app/shared/components/button/button.component";
import { SimpleChatPopupComponent } from 'app/layouts/text-chat-bottom/simple-chat-popup/simple-chat-popup.component';
import { NotificationViewComponent } from "app/layouts/user-notifications/notification-view/notification-view.component";
import { FileUploadItem } from 'app/shared/components/filesupload';
import { MTMTermConditionCheck } from 'app/terms-conditions/terms-condition-check/terms-condition-check.component';
import { VideoChatIncomingComponent } from 'app/layouts/video-chat/video-chat-incoming/video-chat-incoming.component';
import { FilterDropdownComponent } from 'app/shared/components/filter-dropdown/filter-dropdown.component';

/* ----------- * MODAL_COMPONENTS * ----------- */
import { UploadModalComponent } from "app/on-boarding/profile-avatar/upload-modal/upload-modal.component";
import { CreditCardDetailsModalComponent } from "app/payment/credit-card-details/credit-card-details-modal.component";
import { CompletePaymentModalComponent } from "app/payment/complete-payment-modal/complete-payment-modal.component";
import { DownPaymentInfoModalComponent } from "../payment/downpayment-info-modal/downpayment-info-modal.component";
import { PaymentDetailsModalComponent } from "../payment/payment-details/payment-details-modal.component";
import { InviteCompanyProjectDetailModalComponent } from "../layouts/prod-partner/company-profiles/invite-company-project-detail/invite-company-project-detail-modal.component";
import { ProjectProposalNDAModalComponent } from "../layouts/my-projects/project-proposal/project-proposal-NDA/project-proposal-NDA";
import { TranslatePipe } from "../shared/pipes/translate.pipe";
import { PaymentWarningModalComponent } from "../payment/payment-warning-modal/payment-warning-modal.component";
import { CommentDatePipe } from "app/shared/components/comment/pipe/comment-date-pipe.pipe";
import { ActivityLogComponent } from "../layouts/activity-log/activity-log.component";
import { MTMFileDownloadControl } from "app/shared/components/mtm-file-download/mtm-file-download-control/mtm-file-download-control.component";
import { MTMFileDownloadList } from "app/shared/components/mtm-file-download/mtm-file-download-list/mtm-file-download-list.component";
import { MTMFileDownloadUploadPreview } from "app/shared/components/mtm-file-download/mtm-file-download-upload-preview/mtm-file-download-upload-preview.component";
import { MTMDownloadUploadProgressIcon } from "app/shared/components/mtm-download-upload-progress-icon/mtm-download-upload-progress-icon.component";
import { ProjectDescription } from "app/shared/components/project-description/project-description.component";
import { InViewDirective } from "app/shared/directives/in-view.directive";
import { MentionModule } from 'angular-mentions';
import { MtmChatModule } from "./mtm-chat-module/mtm-chat.module";
import { FileNameDisplayDirective } from "app/shared/directives/filename-display.directive";
import { ReviewUiLinkSettingsComponent } from "app/shared/components/review-link/review-link-settings/review-link-settings.component";
import { ReviewUiLinkPasswordComponent } from "app/shared/components/review-link/review-link-password/review-link-password.component";
import { ReviewUiLinkIdentityComponent } from "app/shared/components/review-link/review-link-identity/review-link-identity.component";
import { ReviewLinkAssetsPreviewComponent } from "app/shared/components/review-link/review-link-assets-preview/review-link-assets-preview.component";
import { MtmAssetsDropdownComponent } from "app/shared/components/review-link/review-link-settings/mtm-assets-dropdown/mtm-assets-dropdown.component";
import { MtmInviterDropdownComponent } from "app/shared/components/review-link/review-link-settings/mtm-inviter-dropdown/mtm-inviter-dropdown.component";
import { LoginFormComponent } from "../login/login-form/login-form.component";
import { ReviewLinkNotFoundComponent } from "app/shared/components/review-link/review-link-not-found/review-link-not-found.component";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CompanyProfileViewComponent } from "../shared/components/company-profile-view/company-profile-view.component";
import { DashboardSection } from "app/layouts/my-projects/dashboard/dashboard-section/dashboard-section.component";
import { DashboardSubSection } from "app/layouts/my-projects/dashboard/dashboard-sub-section/dashboard-sub-section.component";
import { PreviewAllAssetComponent } from "app/shared/components/preview-asset/preview-all-asset/preview-all-asset.component";
import { SubsectionVersionListComponent } from "../shared/components/subsection-version-list/subsection-version-list.component";
import { MasterFileSelectionComponent } from "app/layouts/diffusion/export/master-create/master-file-selection/master-file-selection.component";
import { HierarchicalFilesComponent, HRBadgebtn, HRGridFile, HRListFile, HRPreviewFile } from "app/shared/components/hierarchical-files";
import { MediaPlayerComponent } from "app/shared/components/media-player/media-player.component";
import { AudioAnnotation } from "app/shared/components/audio-annotation/audio-annotation.component";
import { ImageAnnotation } from "app/shared/components/image-annotation/image-annotation.component";
import { PdfAnnotation } from "app/shared/components/pdf-annotation/pdf-annotation.component";
import { CommentAnnotation } from "app/shared/components/comment-annotation/comment-annotation.component";
import { ImageDrawingTools } from "app/shared/components/media-player/image-drawing-tools/image-drawing-tools.component";
import { VideojsAnnotationBarList } from "app/shared/components/media-player/videojs-annotation-bar-list/videojs-annotation-bar-list.component";
import { CommentComponent } from "app/shared/components/comment/comment.component";
import { Verify2faComponent } from "../login/verify-2fa/verify-2fa.component";
import { SceneComponent } from "app/layouts/shooting/scene/scene.component";
import { SceneCreateComponent } from "app/layouts/shooting/scene/scene-create/scene-create.component";
import { SceneGridComponent } from "app/layouts/shooting/scene/scene-grid/scene-grid.component";
import { ImageCropperModule } from "app/shared/components/image-cropper/image-cropper.module";
import { MtmItemIconComponent } from "app/shared/components/mtm-item-icon/mtm-item-icon.component";
import { ProjectTypesModalComponent } from "app/shared/components/project-types-modal/project-types-modal.component";
import { PaymentMethodModalComponent } from "app/shared/components/payment-method-modal/payment-method-modal.component";
import { RecoveryPasswordComponent } from "app/login/recovery-password/recovery-password.component";
import { CompanyRolesAndPermissionComponent } from "app/shared/components/company-roles-and-permissions/company-roles-and-permissions.component";
import { PlaceAutocompleteDirective } from "app/shared/directives/place-autocomplete.directive";
import { UploadFileModalComponent } from "app/shared/components/upload-file-modal/upload-file-modal.component";
import { DragDirective } from "app/shared/directives/dragDrop.directive";

import { DownpaymentService } from "app/shared/services/downpayment.service";
import { FinalpaymentService } from "app/shared/services/finalpayment.service";
import { FormatVersionNamePipe } from "../shared/pipes/format-version-name.pipe";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { MtmTimePipe } from "app/shared/pipes/mtm-time.pipe";
import { projectFilterPipe } from "app/shared/pipes/project-filter.pipe";
import { LanguageFilterPipeFilterPipe, SubtitleFilterPipeFilterPipe } from "app/layouts/post-prod/subtitles/subtitles.filter";
import { SubtitlePipe } from "app/layouts/post-prod/subtitles/subtitle.pipe";
import { PreviewAssetComponent } from "app/shared/components/preview-asset/preview-asset.component";
import { RecentActivityComponent } from "../layouts/my-projects/dashboard/recent-activity/recent-activity.component";
import { LastConversationComponent } from "../layouts/my-projects/dashboard/last-conversation/last-conversation.component";
import { CalendarWidgetComponent } from "../layouts/my-projects/dashboard/calendar-widget/calendar-widget.component";
import { KeyFileComponent } from "../layouts/my-projects/dashboard/key-file/key-file.component";
import { TaskWidgetComponent } from "../layouts/my-projects/dashboard/task-widget/task-widget.component";
import {
    ImageAnnotationToolbarComponent
} from "../shared/components/image-annotation-toolbar/image-annotation-toolbar.component";
import { SubsectionSliderComponent } from "../shared/components/subsection-slider/subsection-slider.component";
import { PreviewAssetListComponent } from "app/shared/components/preview-asset-list/preview-asset-list.component";
import { archivedProjectFilterPipe } from "app/shared/pipes/archived-project-filter.pipe";
import { RightPaneContentDirective } from "../shared/directives/right-pane.directive";
import { CreateTaskComponent } from "app/shared/components/task/create-task/create-task.component";
import { PreviewTaskComponent } from "app/shared/components/task/preview-task/preview-task.component";
import { TaskAssetPreviewComponent } from "app/shared/components/task/task-assets-preview/task-assets-preview.component";
import { SingleTaskComponent } from "app/shared/components/task/single-task/single-task.component";
import { TaskDropdownComponent } from "app/shared/components/task/task-dropdown/task-dropdown.component";
import { TaskListComponent } from "app/shared/components/task/task-list/task-list.component";
import { MtmDateTimePipe } from "app/shared/pipes/mtm-date-time.pipe";
import { ObserveThisDirective } from "../shared/directives/observe-this.directive";
import { KeyFileSelectorComponent } from "../shared/components/key-files/key-file-selector/key-file-selector.component";
import { MoveKeyFileComponent } from "../shared/components/key-files/move-key-file/move-key-file.component";
import { MtmAssetControl } from "../shared/components/mtm-asset-control/mtm-asset-control.component";
import { MtmProjectAssetTree } from "../shared/components/mtm-asset-control/mtm-project-asset-tree/mtm-project-asset-tree.component";
import { MtmAssetTree } from "../shared/components/mtm-asset-control/mtm-project-asset-tree/mtm-asset-tree/mtm-asset-tree.component";
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import momentPlugin from '@fullcalendar/moment';
import { CacheBusterImagePipe } from "../shared/pipes/cache-buster-image.pipe";
import { NgSelectModule } from "@ng-select/ng-select";
import { FilterUser } from "app/shared/pipes/user.filter";
import { SimpleProjectFilterPipe } from "../shared/pipes/simple-project-filter.pipe";
import { DateCellRendererComponent } from "../shared/components/grid/renderer/date-cell/date-cell-renderer.component";
import {
    DateTimeCellRendererComponent
} from "../shared/components/grid/renderer/date-time-cell/date-time-cell-renderer.component";
import { QuillModule } from 'ngx-quill';
import { AudioRecordingService } from "../shared/services/audio-recording.service";
import { VideoRecordingService } from "../shared/services/video-recording.service";
import { CommentPreviewComponent } from "app/shared/components/comment-preview/comment-preview.component";
import { MtmTextEditor } from "./mtm-chat-module/mtm-text-editor/mtm-text-editor.component";
import { TimelineSkeletonComponent } from "app/shared/skeletons/timeline-skeleton/timeline-skeleton.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GridSkeletonComponent } from "app/shared/skeletons/grid-skeleton/grid-skeleton.component";
import { VideoRecorderModalComponent } from "./mtm-chat-module/mtm-text-editor/video-recorder-modal/video-recorder-modal.component";
import { AudioRecorderModalComponent } from "./mtm-chat-module/mtm-text-editor/audio-recorder-modal/audio-recorder-modal.component";
import { PasswordToggleDirective } from "../shared/directives/password-toggle.directive";
import { RoundedNumberPipe } from "../shared/pipes/limit-number.pipe";
import {
    ProjectsOwnershipTransferComponent
} from "../shared/components/projects-ownership-transfer/projects-ownership-transfer.component";
import { TeamEditorComponent } from "../shared/components/team-editor/team-editor.component";
import { MtmAssetNameComponent } from "app/shared/components/asset-name/asset-name.component";
import { MtmAssetListComponent } from "app/shared/components/asset-list/asset-list.component";
import { MtmEditAssetModalComponent } from "app/shared/components/asset-list/edit-asset-modal/edit-asset-modal.component";
import { MtmAssetTypeSelectorComponent } from "app/shared/components/asset-type-selector/asset-type-selector.component";
import { MtmEditableLabel } from "app/shared/components/editable-label/editable-label.component";
import {
    SubscriptionBannerComponent
} from "../subscriptions/shared/components/subscription-banner/subscription-banner.component";
import { MtmDownloadSubtitleComponent } from "../shared/components/download-subtitle/download-subtitle.component";
import { LanguageIconStatusComponent } from "app/layouts/post-prod/subtitles/subtitles-grid/language-icon-status/language-icon-status.component";
import { UrlShortenerComponent } from "app/shared/components/url-shortener/url-shortener.component";
import { UrlShortenerEditorComponent } from "app/shared/components/url-shortener/editor/url-shortener-editor.component";
import { PasswordCheckerComponent } from "../shared/components/password-checker/password-checker.component";
import { PasswordStrengthComponent } from "app/shared/components/password-strength-meter/password-strength-meter.component";
import { Ng5SliderModule } from "ng5-slider";
import { MtmFileSizePipe } from "app/shared/pipes/mtm-file-size.pipe";
import { ShortenedUrlDirective } from "../shared/directives/shortened-url.directive";
import { MtmFileExtensionPipe } from "../shared/pipes/mtm-file-extension.pipe";
import { GeneralLogComponent } from "../layouts/activity-log/general-log/general-log.component";
import { LanguageSelectorComponent } from "app/shared/components/language-selector/language-selector.component";
import { ThrottleClickDirective } from "../shared/directives/throttled-click.directive";
import { MtmImageComponent } from "../shared/components/mtm-image/mtm-image.component";

import { MtmBreadcrumbComponent } from "../shared/components/mtm-breadcrumb/mtm-breadcrumb.component";
import { ProjectStagesComponent } from "app/layouts/my-projects/new-project/project-stages/project-stages.component";
import {
    RadialTransferBarComponent
} from "../shared/components/filesupload/radial-transfer-bar/radial-transfer-bar.component";
import {
    UploadPopupItemComponent
} from "../shared/components/filesupload/upload-popup/upload-popup-item/upload-popup-item.component";
import { UploadPopupComponent } from "../shared/components/filesupload/upload-popup/upload-popup.component";
import {
    DownloadPopupItemComponent
} from "../shared/components/filesupload/download-popup/download-popup-item/download-popup-item.component";
import { DownloadPopupComponent } from "../shared/components/filesupload/download-popup/download-popup.component";
import { CommingsoonComponent } from "app/commingsoon/commingsoon.component";
import { InitialNamePipe } from "../shared/pipes/initial-name.pipe";

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    momentPlugin
]);


@NgModule({
    declarations: [
        // ImageCropperComponent,
        ProjectCompanyComponent,
        MTMTelInputComponent,
        LoadingDataComponent,
        MTMMainInfiniteScrol,
        UploadFileComponent,
        NewUploadFilesPreviewThumpnailComponent,
        MTMDropdown,
        UserCardComponent,
        ConversationCardComponent,
        EmbedVideoComponent,
        CircleProgressComponent,
        MtmItemIconComponent,
        MtmImageComponent,
        ButtonComponent,
        SimpleChatPopupComponent,
        NotificationViewComponent,
        FileUploadItem,
        MTMTermConditionCheck,
        VideoChatIncomingComponent,
        ActivityLogComponent,
        MTMFileDownloadControl,
        MTMFileDownloadList,
        MTMFileDownloadUploadPreview,
        MTMDownloadUploadProgressIcon,
        ProjectDescription,
        UploadModalComponent,
        CreditCardDetailsModalComponent,
        DownPaymentInfoModalComponent,
        CompletePaymentModalComponent,
        InviteCompanyProjectDetailModalComponent,
        PaymentDetailsModalComponent,
        PaymentMethodModalComponent,
        ProjectProposalNDAModalComponent,
        PaymentWarningModalComponent,
        UserAvatarDirective,
        GroupAvatarDirective,
        HasUserAccessDirective,
        MtmCurrencyDirective,
        CommentMinButtonsDirective,
        CommentTextAreaDirective,
        MTMDatePickerDirective,
        SSProfilePreviewImageDirective,
        AssetPreviewDirective,
        OneShotDownpaymentDirective,
        PasswordToggleDirective,
        PlaceAutocompleteDirective,
        RightPaneContentDirective,
        ShortenedUrlDirective,
        KeysPipe,
        MtmCurrencyPipe,
        MtmDatePipe,
        MtmDatePipe2,
        MtmTimePipe,
        ToTimePipe,
        MtmDateTimePipe,
        MtmFileSizePipe,
        MtmFileExtensionPipe,
        RoundedNumberPipe,
        CacheBusterImagePipe,
        projectFilterPipe,
        archivedProjectFilterPipe,
        OrdinalPipe,
        SafePipe,
        FilterPipe,
        FilterReviewPipe,
        CleanerPipe,
        ObjectToKeysPipe,
        FilterSearchUserPipe,
        FilterSortUserPipe,
        FilterSearchProjectPipe,
        SimpleProjectFilterPipe,
        ProjectSortingPipe,
        FormatVersionNamePipe,
        ParseUrl,
        CleanUrl,
        SearchCompanyPipe,
        SortCompanyPipe,
        PipeDropdownSearch,
        MTMTelInputPipe,
        ArraySortPipe,
        TranslatePipe,
        CommentDatePipe,
        InitialNamePipe,
        InViewDirective,
        FileNameDisplayDirective,
        ReviewUiLinkSettingsComponent,
        ReviewUiLinkPasswordComponent,
        ReviewUiLinkIdentityComponent,
        ReviewLinkAssetsPreviewComponent,
        MtmAssetsDropdownComponent,
        MtmInviterDropdownComponent,
        ReviewLinkNotFoundComponent,
        LoginFormComponent,
        RecoveryPasswordComponent,
        Verify2faComponent,
        CompanyProfileViewComponent,
        PreviewAllAssetComponent,
        DashboardSection,
        DashboardSubSection,
        RecentActivityComponent,
        LastConversationComponent,
        CalendarWidgetComponent,
        KeyFileComponent,
        SubsectionSliderComponent,
        MasterFileSelectionComponent,
        HierarchicalFilesComponent,
        HRGridFile,
        HRListFile,
        HRPreviewFile,
        HRBadgebtn,
        MediaPlayerComponent,
        VideojsAnnotationBarList,
        ImageAnnotationToolbarComponent,
        ImageAnnotation,
        AudioAnnotation,
        PdfAnnotation,
        CommentAnnotation,
        ImageDrawingTools,
        CommentComponent,
        SceneComponent,
        SceneCreateComponent,
        SceneGridComponent,
        ProjectTypesModalComponent,
        CompanyRolesAndPermissionComponent,
        UploadFileModalComponent,
        DragDirective,
        ObserveThisDirective,
        ThrottleClickDirective,
        SubtitleFilterPipeFilterPipe,
        LanguageFilterPipeFilterPipe,
        SubtitlePipe,
        PreviewAssetComponent,
        PreviewAssetListComponent,
        TaskWidgetComponent,
        SingleTaskComponent,
        CreateTaskComponent,
        PreviewTaskComponent,
        TaskAssetPreviewComponent,
        TaskListComponent,
        TaskDropdownComponent,
        KeyFileSelectorComponent,
        MoveKeyFileComponent,
        MtmAssetControl,
        MtmProjectAssetTree,
        MtmAssetTree,
        FilterDropdownComponent,
        FilterUser,
        DateCellRendererComponent,
        DateTimeCellRendererComponent,
        MtmTextEditor,
        CommentPreviewComponent,
        TimelineSkeletonComponent,
        GridSkeletonComponent,
        VideoRecorderModalComponent,
        AudioRecorderModalComponent,
        ProjectsOwnershipTransferComponent,
        TeamEditorComponent,
        UrlShortenerComponent,
        UrlShortenerEditorComponent,
        MtmAssetNameComponent,
        MtmAssetListComponent,
        MtmEditAssetModalComponent,
        MtmAssetTypeSelectorComponent,
        MtmEditableLabel,
        SubscriptionBannerComponent,
        MtmDownloadSubtitleComponent,
        LanguageIconStatusComponent,
        PasswordCheckerComponent,
        PasswordStrengthComponent,
        GeneralLogComponent,
        LanguageSelectorComponent,
        MtmBreadcrumbComponent,
        ProjectStagesComponent,
        RadialTransferBarComponent,
        UploadPopupItemComponent,
        UploadPopupComponent,
        DownloadPopupItemComponent,
        DownloadPopupComponent,
        CommingsoonComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgClickOutsideDirective,
        DragulaModule,
        TagInputModule,
        UiSwitchModule,
        NgbModule,
        MomentModule,
        NgxFileDropModule,
        NgxPageScrollCoreModule.forRoot({ duration: 2500, easingLogic: EasingLogic }),
        NgxAutoScrollModule,
        LinkyModule,
        MentionModule,
        MtmChatModule,
        AutocompleteLibModule,
        ImageCropperModule,
        PickerModule,
        FullCalendarModule,
        NgSelectModule,
        QuillModule,
        NgxSkeletonLoaderModule,
        Ng5SliderModule,
        NgOptimizedImage,
        // KanbanModule
    ],
    providers: [
        FinalpaymentService,
        DownpaymentService,
        AudioRecordingService,
        VideoRecordingService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [
        // ImageCropperComponent,
        ProjectCompanyComponent,
        MTMTelInputComponent,
        LoadingDataComponent,
        MTMMainInfiniteScrol,
        UploadFileComponent,
        NewUploadFilesPreviewThumpnailComponent,
        MTMDropdown,
        UserCardComponent,
        ConversationCardComponent,
        EmbedVideoComponent,
        CircleProgressComponent,
        MtmItemIconComponent,
        MtmImageComponent,
        ButtonComponent,
        SimpleChatPopupComponent,
        NotificationViewComponent,
        FileUploadItem,
        MTMTermConditionCheck,
        VideoChatIncomingComponent,
        MTMFileDownloadControl,
        MTMFileDownloadList,
        MTMDownloadUploadProgressIcon,
        ProjectDescription,
        UploadModalComponent,
        CreditCardDetailsModalComponent,
        PaymentDetailsModalComponent,
        PaymentMethodModalComponent,
        DownPaymentInfoModalComponent,
        CompletePaymentModalComponent,
        InviteCompanyProjectDetailModalComponent,
        ProjectProposalNDAModalComponent,
        PaymentWarningModalComponent,
        UserAvatarDirective,
        GroupAvatarDirective,
        HasUserAccessDirective,
        MtmCurrencyDirective,
        CommentMinButtonsDirective,
        CommentTextAreaDirective,
        MTMDatePickerDirective,
        SSProfilePreviewImageDirective,
        AssetPreviewDirective,
        OneShotDownpaymentDirective,
        PasswordToggleDirective,
        PlaceAutocompleteDirective,
        RightPaneContentDirective,
        ShortenedUrlDirective,
        KeysPipe,
        MtmCurrencyPipe,
        MtmDatePipe,
        MtmDatePipe2,
        MtmTimePipe,
        ToTimePipe,
        MtmDateTimePipe,
        CacheBusterImagePipe,
        RoundedNumberPipe,
        RoundedNumberPipe,
        projectFilterPipe,
        archivedProjectFilterPipe,
        OrdinalPipe,
        SafePipe,
        FilterPipe,
        FilterReviewPipe,
        CleanerPipe,
        ObjectToKeysPipe,
        FilterSearchUserPipe,
        FilterSortUserPipe,
        FilterSearchProjectPipe,
        SimpleProjectFilterPipe,
        ProjectSortingPipe,
        ParseUrl,
        CleanUrl,
        SearchCompanyPipe,
        SortCompanyPipe,
        PipeDropdownSearch,
        ArraySortPipe,
        TranslatePipe,
        CommentDatePipe,
        FormatVersionNamePipe,
        InitialNamePipe,
        InViewDirective,
        FileNameDisplayDirective,
        ReviewUiLinkSettingsComponent,
        ReviewUiLinkPasswordComponent,
        ReviewUiLinkIdentityComponent,
        ReviewLinkAssetsPreviewComponent,
        MtmAssetsDropdownComponent,
        MtmInviterDropdownComponent,
        ReviewLinkNotFoundComponent,
        LoginFormComponent,
        RecoveryPasswordComponent,
        CompanyProfileViewComponent,
        PreviewAllAssetComponent,
        DashboardSection,
        DashboardSubSection,
        MasterFileSelectionComponent,
        HierarchicalFilesComponent,
        HRGridFile,
        HRListFile,
        HRPreviewFile,
        HRBadgebtn,
        MediaPlayerComponent,
        VideojsAnnotationBarList,
        ImageAnnotation,
        PdfAnnotation,
        CommentAnnotation,
        ImageAnnotationToolbarComponent,
        AudioAnnotation,
        ImageDrawingTools,
        CommentComponent,
        DragDirective,
        ObserveThisDirective,
        ThrottleClickDirective,
        SubtitleFilterPipeFilterPipe,
        LanguageFilterPipeFilterPipe,
        SubtitlePipe,
        PreviewAssetComponent,
        PreviewAssetListComponent,
        RecentActivityComponent,
        LastConversationComponent,
        CalendarWidgetComponent,
        KeyFileComponent,
        SubsectionSliderComponent,
        TaskWidgetComponent,
        SingleTaskComponent,
        CreateTaskComponent,
        PreviewTaskComponent,
        TaskAssetPreviewComponent,
        TaskListComponent,
        TaskDropdownComponent,
        KeyFileSelectorComponent,
        MoveKeyFileComponent,
        MtmAssetControl,
        MtmProjectAssetTree,
        MtmAssetTree,
        FilterDropdownComponent,
        FilterUser,
        DateCellRendererComponent,
        DateTimeCellRendererComponent,
        MtmTextEditor,
        CommentPreviewComponent,
        TimelineSkeletonComponent,
        GridSkeletonComponent,
        ProjectsOwnershipTransferComponent,
        TeamEditorComponent,
        UrlShortenerComponent,
        UrlShortenerEditorComponent,
        MtmAssetNameComponent,
        MtmAssetListComponent,
        MtmEditAssetModalComponent,
        MtmAssetTypeSelectorComponent,
        MtmEditableLabel,
        SubscriptionBannerComponent,
        LanguageIconStatusComponent,
        MtmDownloadSubtitleComponent,
        PasswordCheckerComponent,
        PasswordStrengthComponent,
        MtmFileSizePipe,
        MtmFileExtensionPipe,
        GeneralLogComponent,
        LanguageSelectorComponent,
        MtmBreadcrumbComponent,
        ProjectStagesComponent,
        RadialTransferBarComponent,
        UploadPopupItemComponent,
        UploadPopupComponent,
        DownloadPopupItemComponent,
        DownloadPopupComponent,
        CommingsoonComponent
    ]
})
export class SharedModule {
}
function EasingLogic(t: number, b: number, c: number, d: number): number {
    throw new Error("Function not implemented.");
}

