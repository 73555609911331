<div class="modal-container py-2 px-4">
	<div class="modal-header">
		<div class="d-flex flex-column align-items-center w-100">
			<div class="flag-wrapper mb-2 d-inline-block">
				<mtm-status-flag [source]="subSection" [flagWidth]="30" [flagHeight]="30"></mtm-status-flag>
			</div>
			<h4 class="modal-title text-uppercase">{{subSection.name | translate }}</h4>
		</div>

	</div>
	<div class="modal-body">
		<form [formGroup]="editSubSectionForm" (ngSubmit)="onSubmit()">
			<ng-template #popContent let-error="message">{{ error }}!</ng-template>
			<div class="d-flex flex-column flex-md-row align-center-start mb-3" [ngbPopover]="popContent" triggers="manual"
				#popover="ngbPopover" container="body" placement="bottom"
				(click)="popover.isOpen() ? popover.close() : null">
				<div class="d-flex flex-column align-center-start mr-0 mr-md-3">
					<label>{{'Start Date'|translate}}</label>
					<div class="input-group position-relative">
						<input class="form-control date-picker" formControlName="startDate" ngbDatepicker
							#start="ngbDatepicker" placeholder="Start Date" firstDayOfWeek="1" [minDate]="minDate"
							[maxDate]="maxDate" (click)="start.toggle()">
						<img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" (click)="start.toggle()"
							priority />
					</div>
				</div>
				<div class="d-flex flex-column align-center-start mr-0 mr-md-3">
					<label>{{'End Date'|translate}}</label>
					<div class="input-group position-relative">
						<input class="form-control date-picker" formControlName="endDate" ngbDatepicker
							#end="ngbDatepicker" placeholder="End Date" firstDayOfWeek="1" [minDate]="minDate"
							[maxDate]="maxDate" (click)="end.toggle()">
						<img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" (click)="end.toggle()"
							priority />
					</div>
				</div>


				<div class="d-flex flex-column align-center-start flex-fill">
					<label>{{'Status'|translate}}</label>
					<div class="d-flex" ngbDropdown placement="bottom">
						<div class="d-flex align-items-center justify-content-center selected-status px-2 w-100"
							ngbDropdownToggle [class.status-to-come]="statusValue === ProjectStatus.TO_COME"
							[class.status-beware]="statusValue === ProjectStatus.BEWARE"
							[class.status-in-progress]="statusValue === ProjectStatus.IN_PROGRESS"
							[class.status-upcoming-expiry]="statusValue === ProjectStatus.UPCOMING_EXPIRY"
							[class.status-delayed]="statusValue === ProjectStatus.DELAYED"
							[class.status-completed]="statusValue === ProjectStatus.COMPLETED">
							<mtm-status-flag [status]="statusValue" [flagWidth]="21"
								[flagHeight]="21"></mtm-status-flag>
							<span class="status-label mx-2">{{ ('sectionStatus_' + statusValue) | translate}}</span>
							<i class="fa fa-caret-down" aria-hidden="true"></i>
						</div>
						<div ngbDropdownMenu>
							<a *ngFor="let statusItem of targetStatusList"
								class="dropdown-item d-flex align-items-center justify-content-center status-item px-2"
								[class.status-to-come]="statusItem === ProjectStatus.TO_COME"
								[class.status-beware]="statusItem === ProjectStatus.BEWARE"
								[class.status-in-progress]="statusItem === ProjectStatus.IN_PROGRESS"
								[class.status-upcoming-expiry]="statusItem === ProjectStatus.UPCOMING_EXPIRY"
								[class.status-delayed]="statusItem === ProjectStatus.DELAYED"
								[class.status-completed]="statusItem === ProjectStatus.COMPLETED"
								(click)="changeStatus(statusItem)">
								<mtm-status-flag [status]="statusItem" [flagWidth]="21"
									[flagHeight]="21"></mtm-status-flag>
								<span class="status-label mx-2">{{ ('sectionStatus_' + statusItem) | translate}}</span>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div *ngIf="isValidDate()" class="col text-danger mb-3">
					Start date should be before the end date
				</div>
			</div>

			<div class="row">
				<div class="col">
					<label>{{'Description'|translate}}</label>

					<div class="input-group">
						<textarea class="form-control" placeholder=" " formControlName="description"
							rows="8"></textarea>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column flex-md-row align-items-center justify-content-center mt-4 mb-1 buttons">
				<button type="button" class="btn btn-large text-uppercase cancel-btn mr-0 mr-md-3 w-100 mb-3" (click)="cancel()">{{'cancel'
					| translate}}</button>
				<button *ngIf="isDeletable" class="btn btn-large text-uppercase btn-danger btn-delete mr-0 mr-md-3 w-100 mb-3"
					type="button" (click)="delete()" [disabled]="sending">
					{{'Delete' | translate}}
				</button>
				<button type="submit" class="btn btn-large text-uppercase apply-btn w-100 mb-3" [disabled]="sending">{{'apply' |
					translate}}</button>
			</div>
		</form>
	</div>
</div>
