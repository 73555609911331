<div class="upload-popup-container" *ngIf="filesToUpload.length">
  <div class="d-flex align-items-center justify-content-between px-2 caption-container">
    <span class="caption">{{caption | translate: captionParams}}</span>
    <span (click)="toggleExpanded($event)" class="toggle-container">
      <i class="fa" [class.fa-chevron-down]="isExpanded" [class.fa-chevron-up]="!isExpanded"></i>
    </span>
  </div>
  <div *ngIf="remainingTimeText" class="px-2 d-flex align-items-center justify-content-start remaining-container">{{remainingTimeText}}</div>
  <div class="uploads-container" [class.active]="isExpanded">
    <div class="upload-list">
      <div class="upload-list-inner" [class.p-2]="isExpanded">
        <mtm-upload-popup-item *ngFor="let file of filesToUpload" [file]="file" class="upload-item"></mtm-upload-popup-item>
      </div>
    </div>
  </div>
</div>
