import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from './shared/services/auth.service';
import { SURLFUManagerService, SURLFileUploadService } from 'app/shared/services/signed-url';
import { TranslateService } from "./shared/services/translate.service";
import { UserService } from "./shared/services/user.service";
import { VersionCheckService } from "./shared/services/version-check.service";
import { ApiService } from "./shared/services/api.service";
import { SubscriptionService } from './subscriptions/services/subscription.service';
import { PaymentInvoiceService } from './account/manages/pages/billing/services/payment-invoice.service';
import { HelperService } from './shared/services/helper.service';
import { ScriptService } from "./shared/services/script.service";
import { HelpHeroService } from "./shared/services/help-hero.service";


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'app works!';

	constructor(public router: Router,
		private versionCheckService: VersionCheckService,
		public authService: AuthService,
		private apiService: ApiService,
		private subscriptionService: SubscriptionService,
		private paymentInvoiceService: PaymentInvoiceService,
		private serviceSURLFileUpload: SURLFileUploadService,
		private serviceSURLFUM: SURLFUManagerService, private translate: TranslateService, public userService: UserService,
		private helpHeroService: HelpHeroService,
		private scriptService: ScriptService) {


		translate.use(localStorage.getItem('auth_user_language') || 'en_us').then(() => {
			// console.log(translate.data);
		});

		const authUser = this.authService.getAuthUser();
		if (authUser) {
			//this.userService.changeUserProfileSettingLang(localStorage.getItem('auth_user_language') || 'en-us').subscribe(userProfileSettings => { });
			this.subscriptionService.getCurrentPlan().subscribe(res => {
				this.authService.setAuthUserSubscription(res);
				this.helpHeroService.identifyUser(authUser);
				if (res.planType) {
					this.paymentInvoiceService.getUpcomingPaymentInvoices().subscribe(res => {
						this.authService.setAuthUserUpcomingBilling(res);
					}, err => {
						console.log(err);
					});
				}
			});
		}
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				let lang = event.urlAfterRedirects.split('/').pop();
				if (lang == "fr" || lang == "en") {
					if (lang == "en")
						lang = "en_us";

					translate.use(lang || 'en_us').then(() => {
						console.log(translate.data);
					});
				}

				ga('set', 'page', event.urlAfterRedirects);

				// Set the user ID using signed-in username
				if (this.authService.getAuthUser() && this.authService.getAuthUserName())
					ga('set', 'userId', this.authService.getAuthUserName());

				ga('send', 'pageview');
			}
		});
	}

	ngOnInit() {
		console.log(this.title);
		this.serviceSURLFUM.setRefService(this.serviceSURLFileUpload)
		this.versionCheckService.initVersionCheck()
		this.apiService.healthCheck()
		this.scriptService.loadScripts();
	}
}
