import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface StepButton {
  class?: string;
  text?: Button;
  isLoaded?: boolean;
  action?: () => void;
  actionOption?: ActionOption;
  secondary?: boolean;
  disabled?: boolean;
  type: string
}

type ActionOption = 'next' | 'complete' | 'skip' | 'exit' | 'back';
type Button = 'NEXT' | 'COMPLETED' | 'SKIP' | 'EXIT' | 'DONE' | 'GO';

@Injectable({
  providedIn: 'root'
})

export class UserTourService {
  public buttonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tourCompleted: EventEmitter<void> = new EventEmitter<void>();
  public isTourStart: EventEmitter<boolean> = new EventEmitter<boolean>();
  public getElementId: EventEmitter<string> = new EventEmitter<string>();
  public helperData: EventEmitter<object> = new EventEmitter<object>();

  userTourActivity: any[] = [];
  opacityValue: number;
  tourID: any;

  constructor(
    private http: HttpClient) {
  }

  trackUserTourActivity(id: string, isComplete: boolean) {
    console.log('id', id)
    let tourActivity = {
      id: id,
      completed: isComplete
    }
    this.userTourActivity.push(tourActivity);
    console.log('this.userTourActivity', this.userTourActivity)
  }

  getUserTourActivity() {
    return this.userTourActivity;
  }

  loadTourSteps(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/userTourSteps.json');
  }

  loadHelper(): Observable<any> {
    return this.http.get<any>(`assets/json/userTourHelper.json`);
  }
}
