import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * This component is used to display a warning modal to the user indicating that they are inactive
 * and will be logged out soon if no further action is taken.
 * The logout time and mechanism is handled in the main layout/parent component.
 *
 * @param {number} countdown - The number of seconds remaining before the user is logged out.
 */
@Component({
  selector: 'warning-inactive-modal',
  styleUrls: ['./warning-inactive-modal.component.scss'],
  templateUrl: './warning-inactive-modal.component.html'
})
export class WarningInactiveModalComponent {
  @Input() countdown: number;

  constructor(public activeModal: NgbActiveModal) {}

}