import { Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MtmBreadcrumbService, MtmBreadcrumbLink, MtmBreadcrumbLinkWithCurrentUrl } from "app/shared/services/mtm-breadcrumb.service";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: 'mtm-breadcrumb-component',
    templateUrl: './mtm-breadcrumb.component.html',
    styleUrls: ['./mtm-breadcrumb.component.scss']
})
export class MtmBreadcrumbComponent implements OnInit, OnDestroy {

    linkForDesktop: MtmBreadcrumbLink[] = [];
    otherLinkForDesktop: MtmBreadcrumbLink[] = [];

    linkForSmall: MtmBreadcrumbLink[] = [];
    otherLinkForSmall: MtmBreadcrumbLink[] = [];

    ngUnsubscribe = new Subject();
    links: MtmBreadcrumbLink[] = [];

    forCurrentUrl_linkForDesktop: MtmBreadcrumbLinkWithCurrentUrl[] = [];

    constructor(private router: Router, private mtmBreadcrumbService: MtmBreadcrumbService) {
        this.mtmBreadcrumbService.$linkSubject.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next: (links: MtmBreadcrumbLink[]) => {
                this.links = links;
                this.setBreadcrumbForLayout();
                this.forCurrentUrl_linkForDesktop = this.setCurrntLinksForDesktop(this.linkForDesktop);
            },
            error: (error) => {
                console.log(error);
            }
        })
    }

    appendQueryParams(url: string, queryParams?: { [key: string]: any }): string {
        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString();
            return `${url}?${queryString}`;
        }
        return url;
    }

    setCurrntLinksForDesktop(links: MtmBreadcrumbLink[]): MtmBreadcrumbLinkWithCurrentUrl[] {
        return links.map(link => {

            const linkUrl = Array.isArray(link.url) ? link.url.join('/') : link.url;
            const linkWithParams = this.appendQueryParams(linkUrl, link.queryParams);

            return {
                ...link,
                isCurrentUrl: !link.url || this.router.url === linkWithParams
            };
        });
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    goToPage(paths: string[], queryParams: any) {
        if (!paths?.length) {
            return;
        }
        this.router.navigate(paths, { queryParams });
    }

    setBreadcrumbForLayout() {
        const desktopSlice = this.links.length - 3 < 0 ? 0 : this.links.length - 3;
        const smallSlice = this.links.length - 1 < 0 ? 0 : this.links.length - 1;
        this.linkForDesktop = this.links.slice(desktopSlice, this.links.length);
        if (this.linkForDesktop.length < this.links.length) {
            this.otherLinkForDesktop = this.links.slice(0, this.links.length - this.linkForDesktop.length);
        }
        this.linkForSmall = this.links.slice(smallSlice, this.links.length);
        if (this.linkForSmall.length < this.links.length) {
            this.otherLinkForSmall = this.links.slice(0, this.links.length - this.linkForSmall.length);
        }
    }

}