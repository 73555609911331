<div class="container p-0">
	<div class="scrollable-container">
		<div class="nav-handler left d-flex align-items-center justify-content-center" #scrollLeft
			*ngIf="isLeftArrowVisible" (mousedown)="decrementScroll()" (mouseleave)="stopScroll()"
			(mouseup)="stopScroll()" (touchstart)="decrementScroll()">
			<i class="mtm-icon mtm-icon-left-triangle-white"></i>
		</div>
		<div class="section-subsection-selection-container" #scrollElement (scroll)="checkScrollOffset()">
			<div class="sections-subsection-info">
				<div class="progress line-h-unset d-flex flex-nowrap">
					<div class="progress-bar" oneShotDownPayment [project]="project" [index]="i" [section]="section"
						[ngClass]="{'inactive': expand && sectionSelected && sectionSelected !== section, 'selected': sectionSelected?.name == section.name }"
						*ngFor="let section of service.sections; let i = index" [style.width]="'100%'"
						[style.border]="'1px solid rgba(255,255,255,0.4)'" [class.unclear]="!barClarity[i]"
						(click)="switchSection(section, i)">
						<span class="text-uppercase text-nowrap">{{section.name | translate}}</span>
					</div>
				</div>
				<div class="row m-0 py-2 px-4 sections-container d-flex align-items-center" *ngIf="sectionSelected"
					[class.unclear]="!barClarity[position]">
					<ul class="nav stages-created d-flex align-items-center mt-2 mr-2 w-100" [dragula]="'subsections'"
						[dragulaModel]="subsections" (dragulaModelChange)="subsections = $event"
						[ngClass]="{ 'empty-box-drop': !(subsections | cleaner).length }">
						<li class="nav-item subsection-button" *ngFor="let subsection of subsections | cleaner"
							(click)="edit(subsection)" id="{{ subsection.id }}">
							<div class="d-flex align-items-center h-100 v-100">
								<div class="d-block flex-fill">
									<div class="d-flex align-items-center h-100" *ngIf="!subsection.id">
										<i class="fa fa-spinner fa-pulse fa-fw fa-2x" aria-hidden="true"></i>
									</div>
									<div class="col p-0" *ngIf="subsection.id">
										<span class="dates" [ngClass]="{'badge': !hasDate(subsection)}"
											*ngIf="hasDate(subsection)">
											<i class="fa fa-calendar fa-fw mr-2" aria-hidden="true"></i>
											<small class="font-weight-bold">
												<span
													*ngIf="subsection.startDate && subsection.endDate">{{subsection.startDate
													|
													mtmDate}}
													- {{subsection.endDate | mtmDate}}</span>
											</small>
										</span>
										<p class="m-0" [ngClass]="{'section-without-dates': !hasDate(subsection)}">
											<span class="text-uppercase">{{ subsection.name | translate}}</span>
										</p>
									</div>
								</div>
								<div class="d-block pl-2">
									<i class="fa fa-times"
										(click)="promptDelete(subsection);$event.stopPropagation()"></i>
								</div>
							</div>
						</li>
						<li class="nav-item empty-box drop-here" style="min-width: 123px">
							<div class="nav-content d-flex align-items-center h-100 w-100">
								<p class="m-0">
									<span class="text-uppercase _700">{{'addHere' | translate}}</span>
								</p>
							</div>
						</li>
					</ul>
					<!-- <ul class="mt-2 mb-0" [ngClass]="{ 'empty-box': !(subsections | cleaner).length }">
						<li class="nav-item drop-here m-0 mr-3" *ngIf="draggables.length">
							<div class="nav-content d-flex align-items-center h-100 w-100">
								<p class="m-0">
									<span class="text-uppercase _700">{{'addHere' | translate}}</span>
								</p>
							</div>
						</li>
					</ul> -->
				</div>
			</div>
		</div>
		<div class="nav-handler right d-flex align-items-center justify-content-center" #scrollRight
			*ngIf="isRightArrowVisible" (mousedown)="incrementScroll()" (mouseleave)="stopScroll()"
			(touchstart)="incrementScroll()" (mouseup)="stopScroll()">
			<i class="mtm-icon mtm-icon-right-triangle-white"></i>
		</div>
	</div>
	<div class="d-block mt-4" *ngIf="sectionSelected?.section!='SHOOTING'">
		<span class="text-info">{{ 'projectTextInfo' | translate }}</span>
	</div>
	<div class="d-block drag-and-drop py-3 px-0" *ngIf="sectionSelected">
		<ul class="nav w-100 stages-draggables d-flex flex-column flex-md-row" [dragula]="'subsections'"
			[dragulaModel]="draggables" *ngIf="sectionSelected.section!='SHOOTING'">
			<li class="nav-item subsection-draggable" id="{{ draggable.subSection }}"
				*ngFor="let draggable of draggables | cleaner" [style.background-color]="'#000'">
				<div class="nav-content d-flex align-items-center">
					<p class="m-0 w-100 text-center flex-fill text-nowrap">
						<span class="text-uppercase font-weight-bold">{{ draggable.name | translate }}</span>
					</p>
				</div>
			</li>
		</ul>
		<p class="m-0 w-100 d-flex shooting-note" *ngIf="sectionSelected.section=='SHOOTING'">
			<span class="text-uppercase text-nowrap font-weight-bold mr-2">{{'pleaseNote' | translate}}</span>
			<span>{{'shootingNote' | translate}}</span><br /><br />
		</p>
	</div>
	<div class="d-flex flex-column flex-md-row m-0 justify-content-between footer-content">
		<button *ngIf="isProposal && service.project?.id"
			class="btn btn-lg border-dark btn-light mr-2 mb-3 text-uppercase" type="button" (click)="returnToProject()">
			<span>{{'returnProject' | translate}}</span>
		</button>
		<button type="button" class="btn btn-lg btn-dark ml-md-auto text-uppercase mb-3"
			(click)="continue()">{{'project_goToPermissions' | translate}}</button>
	</div>
</div>