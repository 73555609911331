import { CanActivateFn, Router, RouterModule, Routes } from "@angular/router";
import { EditWorkspaceComponent } from "./components/edit-workspace/edit-workspace.component";
import { inject, NgModule } from "@angular/core";
import { CreateWorkspaceComponent } from "./components/create-workspace/create-workspace.component";
import { WorkspaceCreationGuard } from "./guards/workspace-creation.guard";
import { GeneralLogComponent } from "../layouts/activity-log/general-log/general-log.component";
import { AuthService } from "app/shared/services/auth.service";
import { WorkspaceService } from "app/shared/services/workspace.service";

const canRedirectToWorkspaces: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const authService = inject(AuthService);
	const workspaceService = inject(WorkspaceService);
	const hasLtgPermission = authService.checkLtgPermission();

	if(! hasLtgPermission) {
		workspaceService.unsetActiveWorkspace();
		router.navigate(['/projects']);
		return false;
	}

	router.navigate(['/workspaces']);
	return false;
};

export const routes: Routes = [{
	path: '',
	pathMatch: 'full',
	canActivate: [canRedirectToWorkspaces]
}, {
	path: 'new',
	component: CreateWorkspaceComponent,
	canActivate: [WorkspaceCreationGuard]
}, {
	path: 'edit/:workspaceId',
	component: EditWorkspaceComponent
}]

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class WorkspaceRoutingModule {

}
