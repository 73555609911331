<div class="container p-3" [style.max-width]="'none'">
	<loading-data #loadingRef></loading-data>
	<slctr-hrfiles-cmp *ngIf="hrFiles" [hrModel]="hrFiles" [viewMode]="'GRID'" [isDisplayTrashBtn]="false"
		[stillLoading]="activeRequest!==0" [showNumberOfFiles]="true">
	</slctr-hrfiles-cmp>
	<div class="row my-4 ml-1 mr-1 justify-content-between">
		<button class="btn btn-lg btn-danger mr-2" type="button" *ngIf="service.project.id" (click)="returnToProject()">
			<span>{{'returnProject' | translate}}</span>
		</button>
	</div>
</div>