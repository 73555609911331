<div class="verify-2fa-container">
	<div class="modal-header">
		<h4 class="modal-title">{{'login_verify2fa_title' | translate }}</h4>
	</div>
	<div class="modal-body">
		<form *ngIf="verifyForm" (ngSubmit)="verify()" [formGroup]="verifyForm">
			<div class="container">
				<div class="row">
					<div class="form-group verification-group">
						<label class="mr-2">{{'login_verify2fa_prompt' | translate }}</label>
						<input #tfacode type="text" formControlName="code" name="code" [attr.maxlength]="maxLength"
							autocomplete="off" class="form-control">
					</div>
				</div>
				<div class="row" *ngIf="remainingTime">
					<div class="remaining-time">
						{{'login_verify2fa_remainingTime'| translate}} {{remainingTime}}
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn mr-3" (click)="cancel()" [disabled]="isLoading">{{'cancel' | translate }}</button>
		<button type="button" class="btn btn-confirm" (click)="verify()" [disabled]="isLoading">{{'confirm' | translate }}</button>
	</div>
</div>
