import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { UserService } from 'app/shared/services/user.service';

@Component({
  selector: 'mtm-summary-block-cpd',
  templateUrl: './summary-block-cpd.component.html',
  styleUrls: ['./summary-block-cpd.component.scss']
})
export class SummaryBlockCpdComponent implements OnInit {
@Input() timelineData: any;
@Input() isDownloadComponentActive: boolean =false;
summaryDetail:any;
priority: string = '';
colors = {
  'CAMPAIGN': '#000000',
  'PRODUCT': '#1E43A1',
  'MAGELLAN': '#1E43A1',
  'COLLECTOR / PRODUCT': '#086289',
  'GIFT': '#923065',
  'SET': '#502B9D'
}
workspaceName: string;
companyUsers: any[] = [];
companyId: any;
isCpd: boolean = false;
  constructor(
    private ProjectV2Service: ProjectV2ServiceService,
    private authService: AuthService ,
    private userService: UserService) { }

  ngOnInit(): void {
    const { companyId } = this.authService.getAuthUser();
    this.companyId = companyId;
    try {
      if (this.timelineData) {
        this.workspaceName = this.ProjectV2Service.workspaceName;
        this.priority = this.timelineData.priority;
        if (typeof this.timelineData.criteria == "string") {
          let criteria = JSON.parse(this.timelineData.criteria);
          this.summaryDetail = criteria;
        } else {
          let criteria = this.timelineData.criteria;
          this.summaryDetail = criteria;
        }
      }
    } catch (error) {
      console.error('Error in ngOnInit:', error);
      // Handle the error as needed
    }
    this.getCompanyusers();
  }

  getCompanyusers() {
    this.userService.getUsersByCompanyId(this.companyId)
      .subscribe(data => {
        this.companyUsers = data;
      });
  }

  getUserInfo(email) {
    const user = this.companyUsers?.find(user => user.username === email);
    return user ? user.fullName : email;
  }
}
