<div class="d-flex align-items-center download-popup-item-container">
  <img [src]="file?.iconUrl" onError="this.src='assets/img/icons/file.svg'" type="image/svg+xml" width="28">
  <div class="d-flex flex-column align-items-start name-column">
    <div class="filename mr-auto flex-fill" fileNameDisplay [fileName]="file?.displayName" [title]="file?.displayName"
         ></div>
    <div class="d-inline-block file-size">{{ file.size | mtmFileSize }}</div>
  </div>
  <mtm-radial-transfer-bar [percentage]="file.percentage" *ngIf="inProgressStatusList.includes(file.status)" />
  <div class="d-flex align-items-center buttons">
    <button class="btn px-0 action-btn btn-error" *ngIf="file.status === transferStatus.ERROR"
            (click)="handleDownloadError($event)">
      <i class="fa fa-exclamation"></i>
    </button>
    <button class="btn px-0 action-btn btn-completed" *ngIf="file.status === transferStatus.COMPLETED"
            (click)="removeFromList($event)">
      <i class="fa fa-check"></i>
    </button>
    <button class="btn p-0 action-btn btn-pause"
            *ngIf="file.isResumable && file.status === transferStatus.DOWNLOADING" (click)="pauseDownload($event)">
      <i class="fa fa-pause"></i>
    </button>
    <button class="btn p-0 action-btn btn-resume"
            *ngIf="file.isResumable && resumableStatusList.includes(file.status)"
            (click)="resumeDownload($event)">
      <i class="fa fa-play"></i>
    </button>
    <button class="btn p-0 action-btn btn-delete" *ngIf="file.status !== transferStatus.COMPLETED"
            (click)="cancelDownload($event)">
      <i class="fa fa-times"></i>
    </button>
  </div>
</div>
